import FirestoreUtils from "@/classes/FirestoreUtils";
import store from '@/store'

export default class MensajeriaImpl {
    constructor ( ){

    }
    
    static crearMsgAut(infoMessage) {
        if (infoMessage.number) {
            let collection = FirestoreUtils.getCollectionAddFirestore("mensajesAutomaticos");

            collection.add({
                descripcion: infoMessage.descripcion,
                fecha: infoMessage.fecha,
                idUsuarioAsesor: infoMessage.idUsuarioAsesor,
                idGrupoInformacion: infoMessage.idGrupoInformacion,
                origen: infoMessage.origen,
                idOrigen: infoMessage.idOrigen,
                activa: true,
                ejecutada: false,
                idPersonaAsociada: infoMessage.idPersonaAsociada,
                idEtiquetaEmbudo: infoMessage.idEtiquetaEmbudo,
                idOrganizacion: store.getters.getIdOrganizacion, 
                destinatario: infoMessage.number, 
                hostConsumo: infoMessage.hostConsumo,
                permiteInactivar: infoMessage.permiteInactivar

            }).catch(
                function(error) {
                    console.error("Error adding mensajesAutomaticos: ", error);
                    // this.$store.commit("detenerLoading");

                }.bind(this)
            );
        }
    }

    static async modificarMsgAut(infoMessage) {

        let MsgAutDoc = await FirestoreUtils.getDocumentFirestore("mensajesAutomaticos", null, false, "idOrigen", infoMessage.idOrigen);
            
        if (MsgAutDoc) {
            MsgAutDoc.ref.update({
                fecha: infoMessage.fecha,
                descripcion: infoMessage.descripcion,
                idUsuarioAsesor: infoMessage.idUsuarioAsesor,
                idGrupoInformacion: infoMessage.idGrupoInformacion,
                idPersonaAsociada: infoMessage.idPersonaAsociada,
                idEtiquetaEmbudo: infoMessage.idEtiquetaEmbudo,
                destinatario: infoMessage.number, 
                hostConsumo: infoMessage.hostConsumo,
                permiteInactivar: infoMessage.permiteInactivar
            }).catch(
                function(error) {
                    console.error("Error Updating document mensajesAutomaticos: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        } else {
            this.crearMsgAut(infoMessage);
        }

    }

    static async eliminarMsgAut(idOrigen) {
        let alertaDoc = await FirestoreUtils.getDocumentFirestore("mensajesAutomaticos", null, false, "idOrigen", idOrigen);
            
        if (alertaDoc) {
            alertaDoc.ref.delete().catch(
                function(error) {
                    console.error("Error eliminando document mensajesAutomaticos: ", error);

                }.bind(this)
            );
        }
    }

    static getTelefonoMovil(codArea, telefonoMovil) {
        let telefonoMovilReturn;

        if (telefonoMovil) {
            if (codArea) {
                telefonoMovilReturn = codArea + telefonoMovil;
            } else {
                telefonoMovilReturn = '+57' + telefonoMovil;
            }
        }

        return telefonoMovilReturn;
    }
}