export default {
    install(Vue, options) {
        Vue.mixin({
            methods: {
                hasRole(role, resource) {
                    let evaluation;
                    // Evaluación requerida para el caso en el que el valor llegue vacío
                    if (resource) {
                        var resourceTmp = resource.split("_")[0];

                        if (this.$router.app.$store.getters.getListRecursos[resourceTmp]) {
                            evaluation = this.$router.app.$store.getters.getListRecursos[resourceTmp][role] == true;
                        } else {
                            evaluation = false;
                        }
                    } else {
                        var routeTmp = this.$route.name.split("_")[0];

                        if(this.$router.app.$store.getters.getListRecursos[routeTmp]){
                            evaluation = this.$router.app.$store.getters.getListRecursos[routeTmp][role] == true;
                        }else{
                            evaluation = false;
                        }
                    }
                    return evaluation;
                }
            }
        });

        Vue.prototype.$canRead = function (resource) {
            return this.hasRole('read', resource);
        };
        Vue.prototype.$canWrite = function (resource) {
            return this.hasRole('write', resource);
        };
        Vue.prototype.$canUpdate = function (resource) {
            return this.hasRole('update', resource);
        };
        Vue.prototype.$canDelete = function (resource) {
            return this.hasRole('delete', resource);
        };
    }
}