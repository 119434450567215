import accionesComp from "@/components/AccionesComponent";
import { firebase, auth } from '@/firebase/firebaseInit.js';

export default {
    props: {
        pProducto: {type: Object, required: false}
    },
    components: {
        accionesComp,
    },
    data() {
        return {
            producto : {
                nombre: '',
                descripcion: '',
                codigoInterno: '',
                precio: 0,
                imagen: '',
                idGrupoInformacion : ''
                
            },
            listGruposInformacion : []
            
        }
    },

    watch: {
        '$store.state.loading': function (val, oldVal) {
            if(val == false) {
                this.$refs.form.validate();

            }
        }
    },

    computed: { 
        precioProducto:{
          get () {
            return this.formatMoneda(this.producto.precio);
          },
          set (newValue) {
            this.producto.precio = this.getOriginalFormatMoneda(newValue);
          }
            
        },
    },
    
    mounted() {
        this.inicializar();

    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoading");
            this.loadProducto();
            this.loadGruposInformacion();
            
        },

        loadProducto() {
            if (this.pProducto) {
                this.producto = this.pProducto;

            } else {
                firebase.auth().onAuthStateChanged((user) =>{
                    if (!this.$store.getters.getSesionIniciada) {
                        return;
                    }
                    if(user){
                        this.producto.idGrupoInformacion= this.$store.getters.getIdGrupoInfomacion ? this.$store.getters.getIdGrupoInfomacion : this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL;
                    }
                });
                
            }
            
            setTimeout(function(){
                this.$store.commit("detenerLoading");
            }.bind(this), 1000);
            
        },

        loadGruposInformacion() {
            // if (!this.$store.getters.getIdGrupoInfomacion) {
            //     this.listGruposInformacion.push({id:'', nombre: this.$t('message.general.sinGrupo')});
            // }

            this.getCollectionListFirestore('gruposDeInformacion').get()
            .then((querySnapshot) => {
                this.listGruposInformacion.push({
                    id: this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL, 
                    nombre: this.$t('message.general.global')
                });

                querySnapshot.forEach((doc) => {  
                    this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                });
                this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

            
        },

        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.producto.docRef) {
                        this.actualizarProducto();
    
                    } else {
                        this.crearProducto();
    
                    }
                }
            });
        },

        
        crearProducto() {
            let productosCollection = this.getCollectionAddFirestore("productos");

            productosCollection.add({
                nombre: this.producto.nombre,
                nombreSearchTermsArray: this.getSearchTermsArray(this.producto.nombre),
                descripcion: this.producto.descripcion,
                codigoInterno: this.producto.codigoInterno,
                precio: this.producto.precio,
                imagen: this.producto.imagen,
                idGrupoInformacion: this.producto.idGrupoInformacion

            }).then(
                function(docRef) {
                    this.producto.docRef= docRef;
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
            
             
        },

        actualizarProducto() {
            this.producto.docRef.set({
                nombre: this.producto.nombre,
                nombreSearchTermsArray: this.getSearchTermsArray(this.producto.nombre),
                descripcion: this.producto.descripcion,
                codigoInterno: this.producto.codigoInterno,
                precio: this.producto.precio,
                imagen: this.producto.imagen,
                idGrupoInformacion: this.producto.idGrupoInformacion

            }).then(
                function() {
                    // console.log("Document Update: ");
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );


        }

    }

}