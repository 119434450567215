import accionesComp from "@/components/AccionesComponent";
import historiaClinicaComp from "@/views/historiaClinica/HistoriaClinica_det.vue";
import { firebase, auth } from '@/firebase/firebaseInit.js';
import {FIREBASE_SERVICES} from '@/axios/http-common';

export default {
    components: {
        accionesComp,
        historiaClinicaComp
    },
    data() {
        return {
            listHistorias: [],
            listGruposInformacion: [],
            listUsuarios: [],
            filtros: {
                idPersona: null,
                idGrupoInformacion: this.$store.getters.getIdGrupoInfomacion,
                idOrden: 1,
                activo: true,
                idUsuarioAsesor: ''
            },
            contMostrarCompHistoria: 0,
            historia:null,
            listenerHistoriasStop: null,
            listenerHistoriasSnStop: null,
            limpiarDataTable: true

        }
    },

    watch: {
    },

    computed: {
    },

    beforeDestroy() {
        if (this.listenerHistoriasStop) {
            this.listenerHistoriasStop();
        }
        if (this.listenerHistoriasSnStop) {
            this.listenerHistoriasSnStop();
        }     

    },
    
    created() {
        this.inicializar();

    },

    mounted(){
    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 3);
            this.setFechasFilter();
            this.loadGruposInformacion();
            firebase.auth().onAuthStateChanged((user) =>{
                if (!this.$store.getters.getSesionIniciada) {
                    return;
                }
                if(user){
                    this.loadUsuarios(user.uid);
                    this.consultar(user.uid);

                }
            });
            // this.setListenerModal();
            
        },

        setListenerModal() {
            // $('#popupHistoriaClinica').on('shown.bs.modal', function (e) {
            //     this.consultar(auth.currentUser.uid);
            // }.bind(this))

            $('#popupHistoriaClinica').modal().on('shown', function(){
                $('body').css('overflow', 'hidden');
            }).on('hidden', function(){
                $('body').css('overflow', 'auto');
            })
        },

        limpiarFiltros() {
            this.filtros.idPersona= null
            // this.filtros.idTipoDocumento = '';
            // this.filtros.numeroIdentificacion = '';
            this.filtros.idUsuarioAsesor = '';
            this.filtros.etiquetas=[];

            this.consultar();
        },

        setFechasFilter(){
            var fechaDesde = new Date();
            fechaDesde.setDate(fechaDesde.getDate() - 60);

            this.filtros.fechaDesde= this.$moment(fechaDesde).format("YYYY-MM-DD");
            this.filtros.fechaHasta= this.$moment(new Date()).format("YYYY-MM-DD");
        },
        
        loadGruposInformacion() {
            if (!this.$store.getters.getIdGrupoInfomacion) {
                this.listGruposInformacion.push({ id: '', nombre: this.$t('message.general.select_todos') });
            }

            this.getCollectionListFirestore('gruposDeInformacion').get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listGruposInformacion.push({ id: doc.id, nombre: doc.data().nombre });
                        // console.log(JSON.stringify(doc.data()));

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document:", error);
                    }.bind(this)
                );


        },

        loadUsuarios(uid) {
            var collection = this.getCollectionListFirestore('usuarios');
            collection.get()
                .then((querySnapshot) => {
                    this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });

                    querySnapshot.forEach((doc) => {
                        this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        collection = collection.where('id', '==', uid);
                        collection.get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function (errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );
                    }.bind(this)
                );
        },

        async consultar(uid) {
            this.destroyDataTable('#dataTable');
            this.limpiarDataTable= false;
            this.listHistorias = [];
            await this.consultaConAsesor(uid);
            if (!this.$store.getters.isAdmin && !this.filtros.idUsuarioAsesor && this.$store.getters.getIdGrupoInfomacion) {
                await this.consultaSinAsesor(uid);
            }
            
        },

        async consultaConAsesor(uid){
            let collection;

            /* Obtenemos un listado de historias para el escuchador que esta pendiente
            del cambio de nombres de la historia o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('historiaClinica');
            collection = this.getCollectionFiltered(collection, uid, true);

            if (this.listenerHistoriasStop) {
                this.listenerHistoriasStop();
            }
            this.listenerHistoriasStop = await this.getListenerHistorias(collection);

        },

        async consultaSinAsesor(uid){
            let collection;
            /* Obtenemos un listado de historias para el escuchador que esta pendiente
            del cambio de nombres de la historia o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('historiaClinica');
            collection = this.getCollectionFiltered(collection, uid, false);

            if (this.listenerHistoriasSnStop) {
                this.listenerHistoriasSnStop();
            }
            this.listenerHistoriasSnStop = await this.getListenerHistorias(collection);

        },

        getCollectionFiltered(pCollection, uid, sinAsesor) {
            var collection = pCollection;

            if (this.filtros.idPersona) {
                collection = collection.where("idPersona", "==", this.filtros.idPersona);

            } else {

                if (this.filtros.fechaDesde && this.filtros.fechaHasta) {
                    var fechaDesde= this.$moment(this.filtros.fechaDesde, "YYYY-MM-DD").toDate();
                    var fechaHasta= this.$moment(this.filtros.fechaHasta, "YYYY-MM-DD").toDate();
                    fechaHasta.setDate(fechaHasta.getDate() + 1);
                    collection = collection.where("fechaCreacion", ">=", fechaDesde).where("fechaCreacion", "<=", fechaHasta);
    
                }

                // if (this.filtros.nombres) {
                //     collection = collection.where("nombresSearchTermsArray", "array-contains", this.filtros.nombres.toLowerCase());
                // }

            }

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (sinAsesor) {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                } else {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);
                }
            } else {
                if (this.filtros.idUsuarioAsesor) {
                    collection = collection.where("idUsuarioAsesor", "==", this.filtros.idUsuarioAsesor);
                }
            }
    
            return collection;
        },

        async getListenerHistorias(collection) {
            return await collection.onSnapshot((snapshot) => {
                this.cleanDatatable();

                for (const change of snapshot.docChanges()) {
                    let historia = change.doc.data();

                    if (change.type === "added") {
                        historia["id"] = change.doc.id;
                        historia["docRef"] = change.doc.ref;
                        this.listHistorias.push(historia);
                    }
                    if (change.type === "modified") {
                        let historiaDestino = this.listHistorias.find((element) => element.id == change.doc.id);
                        
                        if (historiaDestino) {
                            this.setHistoriaProperties(historiaDestino, historia);
                        }
                    }
                    if (change.type === "removed") {
                        let indexHistoria = this.listHistorias.findIndex((element) => element.id == change.doc.id);
                        
                        if (indexHistoria >= 0) {
                            this.listHistorias.splice(indexHistoria, 1);
                        }
                    }

                };

                this.setDataTable();
                this.$store.commit("detenerLoading");
                $('#popupFiltro').modal('hide');

            });
        },

        cleanDatatable() {
            if(this.limpiarDataTable){
                this.destroyDataTable('#dataTable');
            } else {
                this.limpiarDataTable= true;
            }
        },

        setDataTable() {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.applyDataTable('#dataTable', 2, null, [[1,'asc']]);
            });
        },

        setHistoriaProperties(historiaDestino, historiaOrigen) {
            if (historiaDestino) {
                Object.assign(historiaDestino, historiaOrigen);
     
            }
        },

        eliminar(historia) {
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: { no: this.$t('message.general.no'), yes: this.$t('message.general.si') },
                callback: confirm => {
                    if (confirm) {
                        this.eliminarHistoria(historia);
                    }
                }
            });

        },

        eliminarHistoria(historia) {
            this.$store.commit("ejecutarLoading");

            if (historia) {
                historia.docRef.delete().then(() => {
                    // this.consultar();
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        },

        mostrarHistoria(historia) {
            this.contMostrarCompHistoria++;
            this.historia = historia;
            setTimeout(function () {$('#popupHistoriaClinica').modal('show');}.bind(this), 100);

   
        },

        descargarHistoria(idHistoria) {
            this.$store.commit("ejecutarLoading");
            FIREBASE_SERVICES.defaults.responseType = 'blob';
            FIREBASE_SERVICES.get(
                '/historiaMedica-getPdfHistoriaMedica',
                {params: {idHistoriaClinica: idHistoria}}
            ).then(
                function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Historia_' + idHistoria + '.pdf');
                    document.body.appendChild(link);
                    link.click();
                    this.$store.commit("detenerLoading");
                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                    return true;
                }.bind(this)
            );
        },

        descargarFormula(idHistoria) {
            this.$store.commit("ejecutarLoading");
            FIREBASE_SERVICES.defaults.responseType = 'blob';
            FIREBASE_SERVICES.get(
                '/historiaMedica-getPdfFormulaMedica',
                {params: {idHistoriaClinica: idHistoria}}
            ).then(
                function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Formula_' + idHistoria + '.pdf');
                    document.body.appendChild(link);
                    link.click();
                    this.$store.commit("detenerLoading");
                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                    return true;
                }.bind(this)
            );
        },

    }

}