<template>
    <div>
        <div id="fondoTransparente" class="fondoTransparente" v-if="$store.state.loading"></div>
        <div id="loader" class="loader" v-if="$store.state.loading"></div>

        <alerts-component />
        <vue-confirm-dialog></vue-confirm-dialog>

        <router-view />
    </div>
</template>

<script>
import AlertsComponent from "./components/AlertsComponent.vue";

export default {
    name: "App",
    components: {
        AlertsComponent,
    },
    data() {
        return {
        };
    },

    watch: {

    },

    created() {
        this.$store.commit("initLoading");
        this.$store.commit("initAlert");
        this.$moment.locale(this.$store.state.locale);
        
    },

    methods: {
        
    }
};
</script>

<style>
@import "./assets/fonts/fontawesome-all.min.css";
@import "./assets/fonts/font-awesome.min.css";
@import "./assets/fonts/fontawesome5-overrides.min.css";
@import "./assets/css/style.css";
</style>
