import accionesComp from "@/components/AccionesComponent";

export default {
    components: {
        accionesComp,
    },
    data() {
        return {
            listPlantillas : [],
            filtros: {
                idOrden: 1,
                activo : true,
                
            }

        }
    },

    watch: {
        
    },

    computed: {
        
    },

    created() {
        this.inicializar();

    },
    methods: {
        inicializar() {
            this.consultar();
        },

        limpiarFiltros() {
            this.filtros= {}
            this.consultar();
        },

        async consultar() {
            this.$store.commit("ejecutarLoading");
            this.destroyDataTable('#dataTable');
            this.listPlantillas = [];

            let collection = this.getCollectionListFirestore("plantillas", null, true);
            // collection = this.getCollectionFiltered(collection);
            let querySnapshot = await collection.get().catch(
                function(error) {
                    console.log("Error getting document plantillas:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );

            if (querySnapshot && !querySnapshot.empty) {
                for (let doc of querySnapshot.docs){
                    var plantilla = doc.data();
                    plantilla["id"] = doc.id;
                    plantilla["docRef"] = doc.ref;
                    this.listPlantillas.push(plantilla)
                }
            }

            this.setDataTable();
            this.$store.commit("detenerLoading");
            $('#popupFiltro').modal('hide');

           
        },

        getCollectionFiltered(pCollection){
            var collection = pCollection;

            if (this.filtros.nombre) {
                collection = collection.where("nombreSearchTermsArray", "array-contains", this.filtros.nombre.toLowerCase());
            }

            return collection;
        },

        setDataTable() {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.applyDataTable('#dataTable', 1);
            });
        },

        eliminar(plantillas){
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: {no: this.$t('message.general.no'), yes: this.$t('message.general.si')},
                callback: confirm => {
                    if (confirm) {
                        this.eliminarPlantilla(plantillas);
                    }
                }
            });
                
        },

        eliminarPlantilla(plantillas){
            this.$store.commit("ejecutarLoading");
                
            if (plantillas) {
                plantillas.docRef.delete().then(() => {
                    this.consultar();
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        }

    }

}