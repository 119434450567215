<template>
    <div role="alert" class="alert-containter" v-if="$store.getters.getListErrors.length > 0">
                    
        <div    v-for="(mensaje, index) in $store.getters.getListErrors" 
                :key="index" 
                role="alert" 
                :class="'alert alert-' + mensaje.tipo + ' text-left alert-style'">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
            <h4 class="alert-heading">{{mensaje.titulo}}</h4>
            <p>{{mensaje.descripcion}}</p>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
    .alert-containter{
        width: 50%;
        position: fixed;
        padding: 12px; 
        right:1rem; 
        top:5rem; 
        z-index:1100;
    }

    .alert-style{
        padding: 12px;
        margin: 19px;
        box-shadow: 8px 4px 18px 0px rgb(163,163,163);
    }
</style>