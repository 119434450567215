import accionesComp from "@/components/AccionesComponent";

export default {
    components: {
        accionesComp,
    },
    data() {
        return {
            listUsuarios : [],
            listGruposInformacion : [],
            listPerfiles : [],
            filtros: {
                nombres: '',
                apellidos: '',
                idPerfil:'',
                idGrupoInformacion : this.$store.getters.getIdGrupoInfomacion,
                idIsAdmin: 0,
                usuario: '',
                idOrden: 1,
                activo : true

            },
            // options: ["foo", "bar", "baz"]

        }
    },
    created() {
        this.inicializar();

    },
    methods: {
        inicializar() {
            this.loadGruposInformacion();
            this.loadPerfiles();
            this.consultar();
        },

        loadGruposInformacion() {
            // if (!this.$store.getters.getIdGrupoInfomacion) {
                this.listGruposInformacion.push({id:'', nombre: this.$t('message.general.select_todos')});
            // }

            this.getCollectionListFirestore('gruposDeInformacion').get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {  
                    this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                });

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                }.bind(this)
            );

            
        },

        loadPerfiles() {
            this.listPerfiles.push({id:'', nombre: this.$t('message.general.select_todos')});

            this.getCollectionListFirestore('perfiles').get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {  
                    this.listPerfiles.push({id:doc.id, nombre:doc.data().nombre});

                });

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                }.bind(this)
            );

            
        },

        async consultar() {
            // this.$emit('pruebita');
            this.destroyDataTable('#dataTable');
            this.listUsuarios = [];
            this.$store.commit("ejecutarLoading");
            
            let collection = this.getCollectionListFirestore('usuarios');

            collection = this.getCollectionFiltered(collection);

            let querySnapshot = await collection.get();
            // collection.get().then(async (querySnapshot) => {
                // querySnapshot.forEach(async (doc) => {
            if (!querySnapshot.empty) {
                for await(let doc of querySnapshot.docs){
                    var usuario = doc.data();

                    let nombreGrupoDeInformacion = '';
                    
                    if (usuario.idGrupoInformacion != this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL) {
                        try {
                            let gruposDeInformacionCollection = this.getCollectionListFirestore('gruposDeInformacion');
                            gruposDeInformacionCollection=gruposDeInformacionCollection.where("idGrupoInformacion", "==", usuario.idGrupoInformacion);
                            let grupoDeInformacion = await gruposDeInformacionCollection.get();
                            if(grupoDeInformacion.docs[0]){
                                nombreGrupoDeInformacion = grupoDeInformacion.docs[0].data().nombre;
                              }else{
                                  nombreGrupoDeInformacion = this.$t('message.general.sinGrupo');
                              }
                        } catch (error) {
                            nombreGrupoDeInformacion = usuario.idGrupoInformacion;
                            
                        }
                    } else {
                        nombreGrupoDeInformacion = usuario.idGrupoInformacion;

                    }

                    try {
                        let perfilesCollection = this.getCollectionListFirestore("perfiles",usuario.perfil);
                        let perfil = await perfilesCollection.get();
                        if(perfil.docs[0]){
                            usuario["nombrePerfil"] = perfil.docs[0].data().nombre;
                          }else{
                            usuario["nombrePerfil"] = 'NaN';
                          }
                    } catch (error) {
                        usuario["nombrePerfil"] = "Error";
                        
                    }


                    usuario["id"] = doc.id;
                    usuario["docRef"] = doc.ref;
                    usuario["nombreGrupoDeInformacion"]= nombreGrupoDeInformacion;
                   
                    this.listUsuarios.push(usuario)

                }
                // });

                this.setResponsiveTable();
                this.applyDataTable('#dataTable', 4);
                $('#popupFiltro').modal('hide');
            }
            this.$store.commit("detenerLoading");

            // }.bind(this)).catch(
            //     function(error) {
            //         console.log("Error getting document:", error);
            //         this.$store.commit("detenerLoading");
            //     }.bind(this)
            // );
        },
        
        getCollectionFiltered(pCollection){
            var collection = pCollection;

            if (this.filtros.nombres) {
                collection = collection.where("nombresSearchTermsArray", "array-contains", this.filtros.nombres.toLowerCase());
            }

            if (this.filtros.apellidos) {
                collection = collection.where("apellidosSearchTermsArray", "array-contains", this.filtros.apellidos.toLowerCase());
            }

            if (this.filtros.idPerfil) {
                collection = collection.where("perfil", "==", this.filtros.idPerfil);
            }

            if (this.filtros.idGrupoInformacion) {
                collection = collection.where("idGrupoInformacion", "==", this.filtros.idGrupoInformacion);
            }

            if (this.filtros.usuario) {
                collection = collection.where("usuario", "==", this.filtros.usuario);
            }

            if (this.filtros.idIsAdmin) {
                
                collection = collection.where("isAdmin", "==", this.filtros.idIsAdmin == 1 ? true : false);
            }

            return collection;
        },

        eliminar(usuario){
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: {no: this.$t('message.general.no'), yes: this.$t('message.general.si')},
                callback: confirm => {
                    if (confirm) {
                        this.eliminarUsuario(usuario);
                    }
                }
            });
                
        },

        eliminarUsuario(usuario){
            this.$store.commit("ejecutarLoading");
                
            if (usuario) {
                usuario.docRef.delete().then(() => {
                    this.consultar();
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        }

    }

}