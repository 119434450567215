import store from '../store'
import firebase from 'firebase/app';

export default class FirestoreUtils {
                
    static getDocumentFirestore(collectionName, pk, sinGrupoInformacion, pkAlterna, pkAlternaValue) {
        return new Promise((resolve, reject) => {
            try {
                let firestoreOrg = firebase.firestore().collection("Organizaciones").doc(store.getters.getIdOrganizacion);
                var collection = firestoreOrg.collection(collectionName);

                if (!sinGrupoInformacion && store.getters.getIdGrupoInfomacion) {
                    collection = collection.where('idGrupoInformacion', 'in', [store.getters.getIdGrupoInfomacion, store.state.constantes.GRUPO_INFORMACION_GLOBAL]);
                }
                
                if (pk) {
                    collection = collection.where('__name__', '==', pk);
                }

                if (pkAlterna) {
                    collection = collection.where(pkAlterna, '==', pkAlternaValue);
                }

                collection.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        resolve(doc);

                    });
                    resolve(null);
                    
                }).catch(() => {
                    resolve(null);
                    
                });
            } catch(e) {
                console.log(e);
                reject(null);

            }
        });
    }

    static getCollectionListFirestore(collectionName, pk, sinGrupoInformacion) {
        let firestoreOrg = firebase.firestore().collection("Organizaciones").doc(store.getters.getIdOrganizacion);
        var collection = firestoreOrg.collection(collectionName);

        if (!sinGrupoInformacion && store.getters.getIdGrupoInfomacion) {
            collection = collection.where('idGrupoInformacion', 'in', [store.getters.getIdGrupoInfomacion, store.state.constantes.GRUPO_INFORMACION_GLOBAL]);
        }
        
        if (pk) {
            collection = collection.where('__name__', '==', pk);
        }

        return collection;

    }

    static getCollectionAddFirestore(collectionName, pk) {
        let firestoreOrg = firebase.firestore().collection("Organizaciones").doc(store.getters.getIdOrganizacion);
        var collection = firestoreOrg.collection(collectionName);

        return collection;

    }

}