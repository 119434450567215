import accionesComp from "@/components/AccionesComponent";
// import axios from 'axios';
import {DIGITAL_OCEAN_SERVICES} from '@/axios/http-common';
import {FIREBASE_SERVICES} from '@/axios/http-common';

export default {
    components: {
        accionesComp,
    },
    data() {
        return {
            organizacion : {
                idTipoDocumento: this.$store.state.constantes.TIPO_DOCUMENTO_DEFECTO,
                numeroIdentificacion: '',
                nombre: '',
                telefonoMovil: '',
                telefonoFijo: '',
                email: '',
                direccion: '',
                idPais: this.$store.state.constantes.ubicacionesGeograficas.PAIS_INICIAL,
                idDepartamento:0,
                idCiudad:0,
                horaIni: '00:00',
                horaFin: '23:59',
                msgBienvenida: '',
                msgHorario: ''
                
            },
            listColecciones : [],
            base64QrCodeWs: "",
            mostrarQrWhatsApp: false,
            sesionWhatsAppIniciada: false,
            verificacionWhatsAppIniciada: false,
            myIntervalWs: null
            
        }
    },

    watch: {
        '$store.state.loading': function (val, oldVal) {
            if(val == false) {
                setTimeout(function(){
                    this.$refs.form.validate();
                }.bind(this), 1000);
                

            }
        },
        'organizacion.idPais': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                this.organizacion.idDepartamento = 0;
                this.organizacion.idCiudad = 0;
            }
        },
        'organizacion.idDepartamento': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                this.organizacion.idCiudad = 0;
            }
        }
    },

    computed: {
        listPais: function () {
            return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                ubicacion => ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_PAIS
            );
        },
        listDepartamento: function () {
            return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                ubicacion =>    ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_DEPARTAMETO && 
                                ubicacion.idUbicacionPadre == this.organizacion.idPais
            );
        },
        listCiudad: function () {
            return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                ubicacion =>    ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_CIUDAD && 
                                ubicacion.idUbicacionPadre == this.organizacion.idDepartamento
            );
        }
    },
    
    mounted() {
        this.inicializar();

    },

    beforeDestroy() {
        if (this.myIntervalWs) {
            clearInterval(this.myIntervalWs);
        }
    },
    
    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoading", 2);
            this.loadColecciones();
            this.loadOrganizacion();
            this.existSesionWhatsApp();
            
        },

        loadColecciones() {
            this.getCollectionListFirestore('modulosRecursos', null, true).get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.collection("recursosMod").get()
                    .then((querySnapshotRecur) => {
                        querySnapshotRecur.forEach((recurso) => {
                            this.listColecciones.push(recurso.data().clave);
        
                        });

                        this.$store.commit("detenerLoading");

                    }).catch(
                        function(error) {
                            this.$store.commit("detenerLoading");
                            console.log("Error getting recursos mod:", error);

                        }.bind(this)
                    );
                });

            }).catch(
                function(error) {
                    this.$store.commit("detenerLoading");
                    console.log("Error getting modulos recursos:", error);

                }.bind(this)
            );
        },

        async loadOrganizacion() {
            var doc = await this.getDocumentFirestore("organizacion", this.$store.getters.getIdOrganizacion, true);
            if (doc) {
                this.organizacion = doc.data();
                this.organizacion["id"] = doc.id;
                this.organizacion["docRef"] = doc.ref;

                setTimeout(function(){
                    this.$store.commit("detenerLoading");
                }.bind(this), 1000);
                
            
            } else {
                this.$store.commit("detenerLoading");
            }
            
        },

        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                    this.actualizarOrganizacion();
    
                }
            });
        },

        actualizarOrganizacion() {
            this.organizacion.docRef.set({
                idTipoDocumento: this.organizacion.idTipoDocumento,
                numeroIdentificacion: this.organizacion.numeroIdentificacion,
                nombre: this.organizacion.nombre,
                telefonoMovil: this.organizacion.telefonoMovil,
                telefonoFijo: this.organizacion.telefonoFijo,
                email: this.organizacion.email,
                direccion: this.organizacion.direccion,
                idPais: this.organizacion.idPais,
                idDepartamento: this.organizacion.idDepartamento,
                idCiudad: this.organizacion.idCiudad,
                idGrupoInformacion: '', 
                coleccionesGlobales: this.organizacion.coleccionesGlobales,
                horaIni: this.organizacion.horaIni ? this.organizacion.horaIni : '',
                horaFin: this.organizacion.horaFin ? this.organizacion.horaFin : '',
                msgBienvenida: this.organizacion.msgBienvenida ? this.organizacion.msgBienvenida : '',
                msgHorario: this.organizacion.msgHorario ? this.organizacion.msgHorario : ''

            }, { merge: true }).then(
                function() {
                    // console.log("Document Update: ");
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );


        },

        verificarSesionWhatsApp() {
            return setInterval(
                function () {
                    this.existSesionWhatsApp();
                }.bind(this)
                , 5000
            );
        
        },

        existSesionWhatsApp() {
            DIGITAL_OCEAN_SERVICES.get(
                '/existSesion',
                {params: {idOrganizacion: this.$store.getters.getIdOrganizacion, hostConsumo: window.location.host}}
            ).then(
                function (response) {
                    this.verificacionWhatsAppIniciada = true;
                    this.sesionWhatsAppIniciada = response.data.sesionWhatsAppIniciada;
                    this.base64QrCodeWs = response.data.base64QrCodeWs;

                    if (response.data.base64QrCodeWs && !this.sesionWhatsAppIniciada) {
                        this.mostrarQrWhatsApp = true;
                        this.$store.commit("detenerLoading");
                    } else {
                        this.mostrarQrWhatsApp = false;
                    }

                    if (this.sesionWhatsAppIniciada && this.myIntervalWs) {
                        clearInterval(this.myIntervalWs);
                        this.myIntervalWs = null;
                        this.mostrarQrWhatsApp = false;
                    }

                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                }.bind(this)
            );
        },

        async getQrWhatsApp() {
            this.$store.commit("ejecutarLoading");
            
            await DIGITAL_OCEAN_SERVICES.get(
                '/init',
                {params: {
                    idOrganizacion: this.$store.getters.getIdOrganizacion, 
                    hostConsumo: window.location.host,
                    horaIni: this.organizacion.horaIni,
                    horaFin: this.organizacion.horaFin,
                    msgBienvenida: this.organizacion.msgBienvenida,
                    msgHorario : this.organizacion.msgHorario
                }}
            ).then(
                function (response) {
                    if (this.myIntervalWs) {
                        clearInterval(this.myIntervalWs);
                    }

                    this.myIntervalWs = this.verificarSesionWhatsApp();
                    
                    response.data;

                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);

                }.bind(this)
            );
        },

        cerrarSesionWhatsApp() {
            this.$store.commit("ejecutarLoading");

            DIGITAL_OCEAN_SERVICES.get(
                '/clearSesion',
                {params: {idOrganizacion: this.$store.getters.getIdOrganizacion, hostConsumo: window.location.host}}
            ).then(
                function (response) {
                    this.verificacionWhatsAppIniciada = true;
                    this.sesionWhatsAppIniciada = !response.data;
                    this.$store.commit("detenerLoading");
                    
                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                }.bind(this)
            );
        }

    }

}