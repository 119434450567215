<template>
	<div class="border rounded d-inline accionesComponent">
		<div class="dropdown no-arrow">
			<button
				id="btnDropdown"
				class="btn btn-link btn-sm dropdown-toggle"
				data-toggle="dropdown"
				aria-expanded="false"
				type="button"
			>
				<i class="fas fa-ellipsis-v text-gray-400"></i>
			</button>

			<div class="dropdown-menu shadow dropdown-menu-right animated--fade-in">
				<slot>

				</slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'accionesComp',
		created() {
			// this.$parent.$on('pruebita', () => {
            //     alert("Metodo invocado");
            // });
		},
		mounted() {
			setTimeout(function () {
				document.getElementById("btnDropdown").click();
			}.bind(this), 1000);
			
		}
	};
</script>

<style>
</style>