import accionesComp from "@/components/AccionesComponent";
import cancionesComp from "@/views/canciones/Canciones_det.vue";
import { firebase, auth } from '@/firebase/firebaseInit.js';

export default {
    components: {
        accionesComp,
        cancionesComp
    },
    data() {
        return {
            listCanciones: [],
            listCancionesFind: [],
            listGruposInformacion: [],
            listUsuarios: [],
            filtros: {
                idCancion: null,
                idGrupoInformacion: '',
                idGenero:null,
                idUsuarioAsesor: '',
                idLista: null
            },
            contMostrarCompCancion: 0,
            cancion:null,
            listenerCancionesStop: null,
            listenerCancionesSnStop: null,
            listenerCancionesFindStop: null,
            listenerCancionesFindSnStop: null,
            limpiarDataTable: true,
            listaAsociada:{id: null, nombre: null, labelBusqueda: null, fecha: null},
            listasAsociadasCanciones: [],
            listGenero: []
        }
    },

    watch: {
    },

    computed: {
    },

    beforeDestroy() {
        if (this.listenerCancionesStop) {
            this.listenerCancionesStop();
        }
        if (this.listenerCancionesSnStop) {
            this.listenerCancionesSnStop();
        } 
        
        if (this.listenerCancionesFindStop) {
            this.listenerCancionesFindStop();
        }
        if (this.listenerCancionesFindSnStop) {
            this.listenerCancionesFindSnStop();
        } 

    },
    
    created() {
        this.inicializar();

    },

    mounted(){
    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 5);
            this.setFechasFilter();
            this.loadGruposInformacion();
            this.loadListasAsociadasCanciones();
            this.loadGeneroCanciones();
            firebase.auth().onAuthStateChanged((user) =>{
                if (!this.$store.getters.getSesionIniciada) {
                    return;
                }
                if(user){
                    this.loadUsuarios(user.uid);
                    this.consultar(user.uid);
                    this.consultarCancionesFind(user.uid);

                }
            });
            
        },

        limpiarFiltros() {
            this.filtros.idCancion= null;
            this.filtros.idGrupoInformacion = '';
            this.filtros.idGenero = null;
            this.filtros.idUsuarioAsesor = '';
            this.filtros.idLista= null;
            this.setFechasFilter();

            this.consultar();
        },

        setFechasFilter(){
            let fechaDesde = new Date();
            fechaDesde.setDate(fechaDesde.getDate() - 60);

            this.filtros.fechaDesde= this.$moment(fechaDesde).format("YYYY-MM-DD");
            this.filtros.fechaHasta= this.$moment(new Date()).format("YYYY-MM-DD");
        },
        
        loadGruposInformacion() {
            if (!this.$store.getters.getIdGrupoInfomacion) {
                this.listGruposInformacion.push({ id: '', nombre: this.$t('message.general.select_todos') });
            }

            this.getCollectionListFirestore('gruposDeInformacion').get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listGruposInformacion.push({ id: doc.id, nombre: doc.data().nombre });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document:", error);
                    }.bind(this)
                );


        },

        loadListasAsociadasCanciones() {
            this.getCollectionListFirestore('canciones_listas', null, true).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listasAsociadasCanciones.push(
                            {   id: doc.id, 
                                docRef: doc.ref,
                                nombre: doc.data().nombre, 
                                labelBusqueda: doc.data().nombre + ' ' + this.formatSecondsToDate(doc.data().fecha.seconds, "DD/MM/YYYY"), 
                                fecha: doc.data().fecha
                            }
                        );
                        

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document:", error);
                    }.bind(this)
                );
        },

        loadGeneroCanciones() {
            this.getCollectionListFirestore('canciones_genero', null, true).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listGenero.push(
                            {   id: doc.data().id, 
                                descripcion: doc.data().descripcion
                            }
                        );
                        

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document:", error);
                    }.bind(this)
                );
        },

        loadUsuarios(uid) {
            let collection = this.getCollectionListFirestore('usuarios');
            collection.get()
                .then((querySnapshot) => {
                    this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });

                    querySnapshot.forEach((doc) => {
                        this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        collection = collection.where('id', '==', uid);
                        collection.get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function (errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );
                    }.bind(this)
                );
        },

        async consultar(uid) {
            this.destroyDataTable('#dataTable');
            this.limpiarDataTable= false;
            this.listCanciones = [];
            await this.consultaConAsesor(uid);
            if (!this.$store.getters.isAdmin && !this.filtros.idUsuarioAsesor && this.$store.getters.getIdGrupoInfomacion) {
                await this.consultaSinAsesor(uid);
            }
            
        },

        async consultaConAsesor(uid){
            let collection;

            /* Obtenemos un listado de canciones para el escuchador que esta pendiente
            del cambio de nombres de la cancion o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('canciones');
            collection = this.getCollectionFiltered(collection, uid, true);

            if (this.listenerCancionesStop) {
                this.listenerCancionesStop();
            }
            this.listenerCancionesStop = await this.getListenerCanciones(collection);

        },

        async consultaSinAsesor(uid){
            let collection;
            /* Obtenemos un listado de canciones para el escuchador que esta pendiente
            del cambio de nombres de la cancion o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('canciones');
            collection = this.getCollectionFiltered(collection, uid, false);

            if (this.listenerCancionesSnStop) {
                this.listenerCancionesSnStop();
            }
            this.listenerCancionesSnStop = await this.getListenerCanciones(collection);

        },

        getCollectionFiltered(pCollection, uid, sinAsesor) {
            let collection = pCollection;
            
            if (this.filtros.idCancion) {
                collection = collection.where("__name__", "==", this.filtros.idCancion);

            }

            if (this.filtros.idLista) {
                collection = collection.where("listasAsociadas", "array-contains", this.filtros.idLista);
            }

            if (this.filtros.idGenero) {
                collection = collection.where("idGenero", "==", this.filtros.idGenero);
            }

            if (this.filtros.idGrupoInformacion) {
                collection = collection.where("idGrupoInformacion", "==", this.filtros.idGrupoInformacion);
            }

            // if (    this.filtros.fechaDesde && this.filtros.fechaHasta && 
            //         this.filtros.idCancion == null && this.filtros.idGrupoInformacion == '' &&
            //         this.filtros.idGenero == null && this.filtros.idUsuarioAsesor == '' &&
            //         this.filtros.idLista == null
            // ) {
            //     let fechaDesde= this.$moment(this.filtros.fechaDesde, "YYYY-MM-DD").toDate();
            //     let fechaHasta= this.$moment(this.filtros.fechaHasta, "YYYY-MM-DD").toDate();
            //     fechaHasta.setDate(fechaHasta.getDate() + 1);
            //     collection = collection.where("fechaCreacion", ">=", fechaDesde).where("fechaCreacion", "<=", fechaHasta);

            // }

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (sinAsesor) {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                } else {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);
                }
            } else {
                if (this.filtros.idUsuarioAsesor) {
                    collection = collection.where("idUsuarioAsesor", "==", this.filtros.idUsuarioAsesor);
                }
            }
    
            return collection;
        },

        async getListenerCanciones(collection) {
            return await collection.onSnapshot((snapshot) => {
                this.cleanDatatable();

                for (const change of snapshot.docChanges()) {
                    let cancion = change.doc.data();

                    if (change.type === "added") {
                        cancion["id"] = change.doc.id;
                        cancion["docRef"] = change.doc.ref;
                        this.listCanciones.push(cancion);
                    }
                    if (change.type === "modified") {
                        let cancionDestino = this.listCanciones.find((element) => element.id == change.doc.id);
                        
                        if (cancionDestino) {
                            this.setCancionProperties(cancionDestino, cancion);
                        }
                    }
                    if (change.type === "removed") {
                        let indexCancion = this.listCanciones.findIndex((element) => element.id == change.doc.id);
                        
                        if (indexCancion >= 0) {
                            this.listCanciones.splice(indexCancion, 1);
                        }
                    }

                };

                this.setDataTable();
                this.$store.commit("detenerLoading");
                $('#popupFiltro').modal('hide');

            });
        },

        cleanDatatable() {
            if(this.limpiarDataTable){
                this.destroyDataTable('#dataTable');
            } else {
                this.limpiarDataTable= true;
            }
        },

        setDataTable() {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.applyDataTable('#dataTable', 4, null, [[0,'asc'], [1,'asc'], [2,'asc'], [3,'asc']]);
            });
        },
        
        setCancionProperties(cancionDestino, cancionOrigen) {
            if (cancionDestino) {
                Object.assign(cancionDestino, cancionOrigen);
     
            }
        },

        async consultarCancionesFind(uid) {
            this.listCancionesFind = [];
            await this.consultaCancionesFindConAsesor(uid);
            if (!this.$store.getters.isAdmin && !this.filtros.idUsuarioAsesor && this.$store.getters.getIdGrupoInfomacion) {
                await this.consultaCancionesFindSinAsesor(uid);
            }
            
        },

        async consultaCancionesFindConAsesor(uid){
            let collection;

            /* Obtenemos un listado de canciones_find para el escuchador que esta pendiente
            del cambio de nombres de la cancion*/
            collection = this.getCollectionListFirestore('canciones_find');
            collection = this.getCancionesFindCollectionFiltered(collection, uid, true);

            if (this.listenerCancionesFindStop) {
                this.listenerCancionesFindStop();
            }
            this.listenerCancionesFindStop = await this.getListenerCancionesFind(collection);

        },

        async consultaCancionesFindSinAsesor(uid){
            let collection;
            /* Obtenemos un listado de canciones_find para el escuchador que esta pendiente
            del cambio de nombres de la cancion*/
            collection = this.getCollectionListFirestore('canciones_find');
            collection = this.getCancionesFindCollectionFiltered(collection, uid, false);

            if (this.listenerCancionesFindSnStop) {
                this.listenerCancionesFindSnStop();
            }
            this.listenerCancionesFindSnStop = await this.getListenerCancionesFind(collection);

        },

        getCancionesFindCollectionFiltered(pCollection, uid, sinAsesor) {
            let collection = pCollection;

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (sinAsesor) {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                } else {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);
                }
            }
    
            return collection;
        },

        async getListenerCancionesFind(collection) {
            return await collection.onSnapshot((snapshot) => {

                for (const change of snapshot.docChanges()) {
                    let cancion = change.doc.data();

                    if (change.type === "added") {
                        cancion["idDoc"] = change.doc.id;
                        this.listCancionesFind.push(cancion);
                    }
                    if (change.type === "modified") {
                        let cancionDestino = this.listCancionesFind.find((element) => element.id == change.doc.id);
                        
                        if (cancionDestino) {
                            this.setCancionProperties(cancionDestino, cancion);
                        }
                    }
                    if (change.type === "removed") {
                        let indexCancion = this.listCancionesFind.findIndex((element) => element.id == change.doc.id);
                        
                        if (indexCancion >= 0) {
                            this.listCancionesFind.splice(indexCancion, 1);
                        }
                    }

                };

                this.$store.commit("detenerLoading");

            });
        },

        eliminar(cancion) {
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: { no: this.$t('message.general.no'), yes: this.$t('message.general.si') },
                callback: confirm => {
                    if (confirm) {
                        this.eliminarCancion(cancion);
                    }
                }
            });

        },

        eliminarCancion(cancion) {
            this.$store.commit("ejecutarLoading");

            if (cancion) {
                cancion.docRef.delete().then(() => {
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        },

        mostrarCancion(cancion) {
            this.contMostrarCompCancion++;
            this.cancion = cancion;
            setTimeout(function () {$('#popupCancion').modal('show');}.bind(this), 100);

   
        },

        adminListas() {
            this.listaAsociada= {
                id: null, 
                nombre: null,
                fecha: this.$moment(new Date()).format("YYYY-MM-DD")
            };
            
            setTimeout(function () {$('#popupListas').modal('show');}.bind(this), 100);

        },

        guardarLista() {
            this.$store.commit("ejecutarLoading");
                
            if (this.listaAsociada.docRef) {
                this.actualizarLista();

            } else {
                this.crearLista();

            }
   
        },

        crearLista() {
            let cancionesListasCollection = this.getCollectionAddFirestore("canciones_listas");

            cancionesListasCollection.add({
                nombre: this.listaAsociada.nombre,
                fecha: this.$moment(this.listaAsociada.fecha, "YYYY-MM-DD").toDate()
                
            }).then(
                function(docRef) {
                    this.listaAsociada.id= docRef.id;
                    this.listaAsociada.docRef= docRef;
                    this.listaAsociada.labelBusqueda= this.listaAsociada.nombre;
                    this.listaAsociada.fecha = {seconds:this.$moment(this.listaAsociada.fecha, "YYYY-MM-DD").toDate().getTime() / 1000};

                    this.listasAsociadasCanciones.push(this.listaAsociada);
                    this.adminListas();

                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        },

        actualizarLista() {
            this.listaAsociada.docRef.update({
                nombre: this.listaAsociada.nombre,
                fecha: this.$moment(this.listaAsociada.fecha, "YYYY-MM-DD").toDate()
            }, { merge: true }).then(
                function() {
                    this.listaAsociada.fecha = {seconds:this.$moment(this.listaAsociada.fecha, "YYYY-MM-DD").toDate().getTime() / 1000};
                    this.adminListas();
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });
                    this.$store.commit("detenerLoading");
                    
                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        },

        updateLista(lista) {
            this.listaAsociada=lista;
            this.listaAsociada.fecha = this.formatSecondsToDate(lista.fecha.seconds, "YYYY-MM-DD");
        },

        eliminarLista(idLista) {

        },

        getGeneroById(idGenero) {
            let descripcionGenero = "";

            if (idGenero) {
                let genero = this.listGenero.filter(
                    genero => genero.id == idGenero
                );

                if (genero?.length > 0) {
                    descripcionGenero = genero[0].descripcion;
                }

            }

            return descripcionGenero;
            
        }

    }

}