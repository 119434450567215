import { render, staticRenderFns } from "./Productos_det.vue?vue&type=template&id=eae46f70&"
import script from "./Productos_det.js?vue&type=script&lang=js&"
export * from "./Productos_det.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports