
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { constantes } from './constantes';
import SecureLS from "secure-ls";


Vue.use(Vuex);

var ls = new SecureLS({ encodingType: 'rc4', isCompression: true });

export default new Vuex.Store({
	state: {
		// user: {
		// 	nameUser:"", 
		// 	userAuthenticated:false
		// },
		userName: "",
		admin:false,
		idOrganizacion: "",
		nombreOrganizacion: "",
		idGrupoInformacion: "",
		listRecursos: {},		
		loading: false,
		contadorLoading: 0,
		listAlert: [],
		locale: 'es',
		listIdiomas: [
			{ locale: 'es', descripcion: 'Español' },
			{ locale: 'en', descripcion: 'English' }
		],
		moneda: {
			simboloMoneda: 'COP $',
			digitosDecimales: 2,
			separador: ','
		},
		constantes,
		tablasTipo:{},
		listModulosRecursos: {},
		msgPersona:{},
		token: null,
		sesionIniciada: false,
		ultimaPlantilla: '',
		listPersonasFind: [],
		idModoCancion: 1
	},
	mutations: {
		setToken(state, payload) {
			state.token = payload
		},
		setUser(state, payload) {
			state.user = payload
		},
		setUserName(state, userName) {
			state.userName = userName
		},

		setUserAuthenticated(state, userAuthenticated) {
			state.user.userAuthenticated = userAuthenticated
		},

		initLoading(state) {
			state.loading = false;
			state.contadorLoading = 0;
		},

		ejecutarLoadingCount(state, contadorInicial) {
			if (state.contadorLoading <= 0) {
				state.loading = true;
				state.contadorLoading = contadorInicial;
			}

		},

		ejecutarLoading(state) {
			// if (!this.isUrlExcepcion(pUrl)) {
			if (state.contadorLoading <= 0) {
				state.loading = true;

			}

			state.contadorLoading++;
			// }
		},

		detenerLoading(state) {
			// if (!this.isUrlExcepcion(pUrl)) {
			state.contadorLoading--;

			if (state.contadorLoading <= 0) {
				state.loading = false;
				state.contadorLoading = 0;
			}
			// }
		},

		detenerLoadingCount(state, contadorInicial) {
			state.contadorLoading = state.contadorLoading - contadorInicial;

			if (state.contadorLoading <= 0) {
				state.loading = false;
				state.contadorLoading = 0;
			}
		},

		setDigitosDecimalesMoneda(state, valor) {
			state.config.moneda.digitosDecimales = valor
		},

		initAlert(state){
			state.listAlert = [];
		},

		addAlert(state, alerta) {
			state.listAlert.push(alerta);
		},

		deleteAlert(state, alerta) {
			var index = state.listAlert.indexOf(alerta);

			if (index !== -1) {
				state.listAlert.splice(index, 1);
			}
		},

		setLocale(state, locale) {
			state.locale = locale;
		},

		setIdOrganizacion(state, payload) {
			state.idOrganizacion = payload;
		},

		setNombreOrganizacion(state, payload) {
			state.nombreOrganizacion = payload;
		},

		setIdGrupoInformacion(state, payload) {
			state.idGrupoInformacion = payload;
		},

		setAdmin(state, payload) {
			state.admin = payload;
		},

		logOut: (state, value) =>
			state.idOrganizacion = "",

			
		addRecurso(state, {key, value}){
			state.listRecursos[key] = value;

		},

		initRecursos(state){
			state.listRecursos = {};
		},

		setTablasTipo(state, payload) {
			state.tablasTipo = payload;
		},
		addModuloRecursos(state, {key, value}){
			state.listModulosRecursos[key] = value;
		},
		cleanListModulosRecursos(state){
			state.listModulosRecursos = {};
		},
		setMsgPersona(state, msgPersona) {
			state.msgPersona = msgPersona;
		},
		setSesionIniciada(state, value) {
			state.sesionIniciada = value;
		},
		setUltimaPlantilla(state, value) {
			state.ultimaPlantilla = value;
		},
		initListPersonasFind(state) {
			state.listPersonasFind = [];
		},
		setListPersonasFind(state, value) {
			state.listPersonasFind = value;
		},
		addListPersonasFind(state, persona) {
			state.listPersonasFind.push(persona);;
		},
		updatePersonaFind(state, persona) {
			let personaDestino = state.listPersonasFind.find((element) => element.id == persona.id);

			if (personaDestino) {
				personaDestino.labelBusqueda= persona.labelBusqueda;
				personaDestino.fechaCreacion= persona.fechaCreacion;
			}
		},
		removePersonaFind(state, persona) {
			let indexPersona = state.listPersonasFind.findIndex((element) => element.id == persona.id);

			if (indexPersona >= 0) {
			    state.listPersonasFind.splice(indexPersona, 1);
			}
		},
		setModoCancion(state, idModo){
			state.idModoCancion = idModo;
		}

		
	},

	actions: {
		addAlert({ commit }, alerta) {
			commit('addAlert', alerta);
			setTimeout(() => {
				commit('deleteAlert', alerta);
			}, 6000)
		}

	},

	getters: {
		getToken(state) {
			return state.token
		},

		getListErrors(state) {
			return state.listAlert
		},

		getListIdiomas(state) {
			return state.listIdiomas
		},
		getUserName(state) {
			return state.userName;
		},
		getIdOrganizacion(state) {
			return state.idOrganizacion;
		},
		getNombreOrganizacion(state) {
			return state.nombreOrganizacion;
		},
		getIdGrupoInfomacion(state) {
			return state.idGrupoInformacion;
		},
		isAdmin(state) {
			return state.admin;
		},
		getListRecursos(state) {
			return state.listRecursos;
		},
		getListModulosRecursos(state) {
			return state.listModulosRecursos;
		},
		getSesionIniciada(state) {
			return state.sesionIniciada;
		},
		getUltimaPlantilla(state) {
			return state.ultimaPlantilla;
		},
		getModoCancion(state) {
			return state.idModoCancion;
		}
	},
	modules: {
	},
	plugins: [

		createPersistedState({
			key: 'vuex',
			reducer(val) {
				if (val.idOrganizacion === "") { // return empty state when user logged out                
					return {}
				}
				return val
			},
		/*	storage: {
				getItem: key => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: key => ls.remove(key),
			}*/
		})
		/*createPersistedState({
		storage: {
		  getItem: key => ls.get(key),
		  setItem: (key, value) => ls.set(key, value),
		  removeItem: key => ls.remove(key),
		  removeAll: () => ls.removeAll()
		}
	  })*/]
})
