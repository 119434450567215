import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from "./i18n";
import vSelect from 'vue-select';
import "./vee-validate";
import Paginate from 'vuejs-paginate';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/bootstrap/js/bootstrap.min.js';
import './assets/datatable/jquery.dataTables.css';
import './assets/datatable/jquery.dataTables.js';
import './assets/js/chart.min.js';
import './assets/js/bs-init.js';
import 'vue-select/dist/vue-select.css';
import Logger from './plugins/vue-logger';
import Roles from './plugins/vue-roles';
import Utils from './plugins/utils';
import RangoFechasUtil from './plugins/rangoFechasUtil';
import FirestoreUtils from './plugins/firestoreUtils';
import VueConfirmDialog from 'vue-confirm-dialog';
import VueTextareaAutosize from 'vue-textarea-autosize';

// import CKEditor from '@ckeditor/ckeditor5-vue2';

window.$ = require('jquery');
window.jQuery = require('jquery');

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
	store.commit("detenerLoading");

	if (err) {
		console.error(err);
		store.dispatch("addAlert", {
			tipo: "danger",
			titulo: "Error",
			descripcion: "Se presento un error inesperado",
		});
	}
	// alert("Error");
};

Vue.use(Logger);
Vue.use(Roles);
Vue.use(Utils);
Vue.use(FirestoreUtils);
Vue.use(VueConfirmDialog)
Vue.use(require('vue-moment'));
Vue.use(RangoFechasUtil);
Vue.use(VueTextareaAutosize);

// Vue.use(CKEditor);

// Busqueda interactiva en elementos select
Vue.component('v-select', vSelect);
Vue.component('paginate', Paginate);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
