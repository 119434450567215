<template>
  <footer class="bg-white sticky-footer">
    <div class="container my-auto">
      <div class="text-center my-auto copyright">
        <span>Copyright © Four Brains 2021 - Versión: {{ version }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
import packageJSON from '../../package.json';
export default {
  data () {
    return {
      version: packageJSON.version
    }
  }
};
</script>

<style>
</style>