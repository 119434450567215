import accionesComp from "@/components/AccionesComponent";
import plantillasComp from "@/components/plantillas/PlantillasComponent.vue";
import { firebase } from '@/firebase/firebaseInit.js';

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import jsPDF from 'jspdf'
// import html2pdf from 'html2pdf.js'

export default {
    props: {
        pPlantilla: {type: Object, required: false}
    },
    components: {
        accionesComp,
        plantillasComp
    },
    data() {
        return {
            plantilla : {
                nombre: '',
                html: ''
                
            },
            listGruposInformacion : []

            // pdfData:null,
            // customToolbar: [[]]
            // editor: ClassicEditor
        }
    },

    watch: {
        '$store.state.loading': function (val, oldVal) {
            if(val == false) {
                this.$refs.form.validate();

            }
        }
    },

    computed: { 

    },
    
    mounted() {
        this.inicializar();

    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoading");
            this.loadGruposInformacion();
            this.loadPlantilla();
            
        },

        loadGruposInformacion() {

            this.getCollectionListFirestore('gruposDeInformacion').get()
            .then((querySnapshot) => {
                this.listGruposInformacion.push({
                    id: this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL, 
                    nombre: this.$t('message.general.global')
                });

                querySnapshot.forEach((doc) => {  
                    this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                });
                this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

            
        },

        loadPlantilla() {
            if (this.pPlantilla) {
                this.plantilla = Object.assign({} , this.pPlantilla);

            } else {
                firebase.auth().onAuthStateChanged((user) =>{
                    if (!this.$store.getters.getSesionIniciada) {
                        return;
                    }
                    if(user){
                        this.plantilla.idUsuarioAsesor= user.uid;
                        this.plantilla.idGrupoInformacion= this.$store.getters.getIdGrupoInfomacion ? this.$store.getters.getIdGrupoInfomacion : this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL;
                    }
                });
                
            }
            
            setTimeout(function(){
                this.$store.commit("detenerLoading");
            }.bind(this), 1000);
            
        },

        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.plantilla.docRef) {
                        this.actualizarPlantilla();
    
                    } else {
                        this.crearPlantilla();
    
                    }
                }
            });
        },

        crearPlantilla() {
            let plantillasCollection = this.getCollectionAddFirestore("plantillas");

            plantillasCollection.add({
                nombre: this.plantilla.nombre,
                nombreSearchTermsArray: this.getSearchTermsArray(this.plantilla.nombre),
                html: this.plantilla.html,
                idGrupoInformacion: this.plantilla.idGrupoInformacion

            }).then(
                function(docRef) {
                    this.plantilla.docRef= docRef;
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
            
             
        },

        actualizarPlantilla() {
            this.plantilla.docRef.set({
                nombre: this.plantilla.nombre,
                nombreSearchTermsArray: this.getSearchTermsArray(this.plantilla.nombre),
                html: this.plantilla.html,
                idGrupoInformacion: this.plantilla.idGrupoInformacion

            }).then(
                function() {
                    // console.log("Document Update: ");
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );


        },

        onRetornarPdf(pdfBase64) {
            // console.log(pdfBase64);
        }

        // onProgress(event) {
        //     console.log(`Processed: ${event} / 100`);
        // },
        // hasGenerated() {
        //     alert("PDF generated successfully!");
        // },
        // generatePDF() {
        //     this.$refs.html2Pdf.generatePdf();
        // },
        // generatePDF() {      
        //     const pdf = new jsPDF({
        //         orientation: 'p',
        //         unit: 'pt',
        //         format: 'letter',
        //         putOnlyUsedFonts:true
        //     })

        //     pdf.html(this.plantilla.html, { 
        //         callback: function (pdf) {
        //             const pdfData = pdf.output('datauristring') // Codifica el PDF en base64
        //             this.contentBase64 = pdfData;
        //             console.log(pdfData) // Aquí obtendrás la cadena codificada en base64 del PDF

        //             setTimeout(function(){
        //                 $('#popupvistaPrevia').modal('show');
        //             }.bind(this), 1000);
                    
                    
        //         }.bind(this),
        //         margin: [20, 20, 20, 20],
        //         autoPaging: 'text',
        //         x: 0,
        //         y: 0,
        //         width: 600, //target width in the PDF document
        //         windowWidth: 700 //window width in CSS pixels
        //     })
        // }
        // generatePDF() {      
        //     html2pdf()
        //         .set({ scale: 0.8 }) // Ajusta la escala del contenido (valor de ejemplo, puedes ajustarlo según tus necesidades)
        //         .from(this.plantilla.html)
        //         .outputPdf('datauristring') // Codifica el PDF en base64
        //         .then((pdfData) => {
        //             this.contentBase64 = pdfData;
        //             setTimeout(function(){
        //                 $('#popupvistaPrevia').modal('show');
        //             }.bind(this), 1000);
        //             // console.log(pdfData) // Aquí obtendrás la cadena codificada en base64 del PDF
        //         })
        // }
        // hasDownloaded (blobPdf) {
        //     console.log(`PDF has downloaded yehey`)
        //     console.log(blobPdf)
        // },
        // async beforeDownload ({ html2pdf, options, pdfContent }) {
        //     await html2pdf().set(options).from(pdfContent).toPdf().output('datauristring').then((pdf) => {
        //         // this.contentBase64 = pdf;
        //         // $('#popupvistaPrevia').modal('show');
        //         // console.log(pdf);
        //         var downloadLink = document.createElement("a");
        //         var fileName = "Archivo.pdf";
        //         downloadLink.href = pdf;
        //         downloadLink.download = fileName;
        //         downloadLink.click();
        //     });
        // },
        // downloadDocument() {
        //     var linkSource = this.contentBase64;
        //     var downloadLink = document.createElement("a");
        //     var fileName = "Archivo.pdf";
        //     downloadLink.href = linkSource;
        //     downloadLink.download = fileName;
        //     downloadLink.click();
        // },

        

    }

}