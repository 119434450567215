export default class Punto {
    constructor ( x , y ){
        this.x = x;
        this.y = y;
    }
    
    static distancia ( a , b) {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
    
        return Math.sqrt ( dx * dx + dy * dy );
    }
}