export const MESSAGE_ES = {
    tablasTipo: {
        tipoDocumento: {
            nit: 'Nit',
            cedulaCiudadania: 'Cédula de ciudadanía',
            CedulaExtranjeria: 'Cédula de extrangeria',
            telefonoMovil: 'Número celular'
        },
        tipoGenero: {
            masculino: 'Masculino',
            femenino: 'Femenino'
        },
        tipoPersona: {
            vendedor: 'Vendedor',
            lead: 'Lead',
            empleado: 'Empleado',
            proveedor: 'Proveedor',
            medico: 'Medico',
            paciente: 'Paciente'
        }
    },

    paginator: {
        anterior: 'Ant',
        siguiente: 'Sig'
    },

    general:{
        guardarExitoso: 'Registro guardado correctamente',
        eliminarExitoso: 'Registro eliminado correctamente',
        guardarFallido: 'Error al guardar la información. ',
        errorExisteRegistro: 'Ya existe la información',
        dashboard: 'Tablero',
        acciones: 'Acciones',
        consultar: 'Consultar',
        nuevo: 'Nuevo',
        eliminar: 'Eliminar',
        guardar: 'Guardar',
        filtros: 'Filtros',
        seleccionFiltros: 'Seleccione sus filtros',
        aplicar: 'Aplicar',
        cerrar: 'Cerrar',
        volver: 'Volver',
        select_todos: 'Todos',
        sinGrupo: 'Sin grupo',
        eliminarConfirm: '¿En realidad desea eliminar este registro?',
        si: 'Si',
        no: 'No',
        accionesVer: 'Ver',
        accionesEditar: 'Editar',
        accionesEliminar: 'Eliminar',
        accionesDescargar: 'Descargar',
        grupoDeInformacion: 'Grupo de información',
        grupoDeInformacionGlobal: 'Grupo de información global',
        startdate: 'Fecha desde',
        enddate: 'Fecha hasta',
        mensaje: 'Mensaje',
        errorInesperado: 'Se presentó un error inesperado, por favor contacte al administrador de la plataforma.',
        notificacion: 'Notificación de mensajes entrantes',
        limpiarFiltros: 'Limpiar filtros',
        limpiar: 'Limpiar',
        fechaCreacion: 'Fecha creacion',
        excel: 'Excel',
        errorConsultaFiltros: 'Se presentó un error consultando la información',
        refrescar: 'Refrescar',
        global: 'Global',
        gestionDocumental: 'Gestión documental',
        fecha: 'Fecha',
        retornar: 'Retornar',
        enviar:'Enviar',
        agregar: 'Agregar'
    },

    home: {
        bienvenidos: 'Ingresar'
    },

    login: {
        ingresar: 'Ingresar',
        mensaje_ingresar:'Bienvenido',
        userName: 'Usuario',
        placeHolderUserName: 'Ingrese su correo electronico de usuario',
        contrasena: 'Contraseña',
        loginFail: 'Usuario o clave incorrectos',
        placeHolderContrasenia: 'Ingrese la contraseña',
        loginCamposVacios: 'Debe diligenciar primero usuario y contraseña',
        recordarContrasena: 'Recordar contraseña',
        recordarSucces: 'Se envió un email con la nueva contraseña',
        celular: 'Celular',
        placeHolderCelular: 'Ingrese el número celular',
        recordar: 'Recordar',
        cancelar: 'Cancelar',
        rememberFail: 'Usuario o celular incorrectos',
        olvidarContrasenaTitle: '¿Has olvidado tu contraseña?',
        olvidarContrasena: 'No hay nungun problema, ingresa tu dirección de correo electronico registrada y da click en el boton recuperar contraseña para que te llegue un correo con el link de recuperación.',
        recuperarContrasena: 'Recuperar contraseña',
        errorRecuperar: 'Se presento un error recuperando su contraseña o el correo electronico no est registrado'
    },

    menuPrincipal: {
        organizacion: 'Organización',
        perfiles: 'Perfiles',
        usuarios: 'Usuarios',
        gruposDeInformacion: 'Grupos de información',
        menuPrincipal: 'Menú principal',
        productos: 'Productos & Servicios',
        embudo: 'Embudo',
        personas: 'Personas',
        tableroControl: 'Tablero de control',
        reporteB: 'Reporte B',
        mensajes: 'Mensajeria',
        calendario: 'Calendario',
        historiaClinica: 'Historia Clinica',
        plantillas: 'Plantillas',
        canciones: 'Canciones'
    },

    modulos: {
        configuracion: 'Configuración',
        diaDia: 'Día a Día',
        reportes: 'Reportes'
    },

    recursos: {
        organizacion: 'Organización',
        perfiles: 'Perfiles',
        usuarios: 'Usuarios',
        gruposDeInformacion: 'Grupos de información',
        menuPrincipal: 'Menú principal',
        productos: 'Productos & Servicios',
        embudo: 'Embudos',
        crearEmbudos: 'Crear embudos',
        personas: 'Personas',
        tableroControl: 'Tablero de control',
        reporteB: 'Reporte B',
        mensajes: 'Mensajeria',
        calendario: 'Calendario',
        alertas: 'Alertas',
        tareas: 'Tareas',
        historiaClinica: 'Historia Clinica',
        mensajesAutomaticos: 'Mensajes automaticos',
        plantillas: 'Plantillas',
        archivos: 'Gestion documental',
        canciones: 'Canciones'

    },

    usuarios: {
        nombres:'Nombre',
        nombre_placeholder: 'Ingrese el nombre de usuario',
        apellidos:'Apellido',
        apellidos_placeholder: 'Ingrese el apellido del usuario',
        usuario:'Usuario',
        usuario_placeholder: 'Ingrese el correo del ususario',
        perfil:'Perfil',
        grupoInformacion:'Grupo de información',
        administrador: 'Administrador',
        si:'Si',
        no:'No',
        clave: 'Contraseña',
        clave_placeholder: 'Ingrese su contraseña',
        claveDos:'Repita la contraseña',
        claveDos_placeholder:'Repita su contraseña',

        mensajeAlerta: '¿Esta seguro de querer inactivar el usuario?',
        activarUsuario: '¿Esta seguro de querer activar el usuario?',
        ordenUsuarioAsc:'Por usuario (Ascendente)',
        ordenUsuarioDes:'Por usuario (Desc)',
        ordenPerfilAsc:'Por perfil (Ascendente)',
        ordenPerfilDes:'Por perfil (Desc)',
    },

    usuariosDetalle: {
        usuario:'Configuración usuario:',
        config_general: 'Configuración general',
        usuarios: 'Usuario',
        contrasena: 'Contraseña',
        persona:'Persona',
        perfiles:'Perfiles',
        anadir:'Añadir',
        nombre:'Nombre',
        email: 'Correo',
        resta_contrasena:'Restablecer contraseña',
        contra_anterior:'Contraseña anterior',
        nueva_contrasena: 'Nueva contraseña',
        confir_contrasena:'Confirmar contraseña',
        cargarLogo: 'Click aquí para agregar una imagen',
        addPerfil:'Seleccione el perfil que desea relacionar a este usuario',
        PerfilSlectExistente: 'El perfil seleccionado ya existe en el listado de perfiles.',
        validacionPerfilVacio: 'Debe seleccionar al menos un perfil',
        errorGuardarImagen: 'Se presentó un error guardando la imagen del usuario',
        activo: 'Activo',
        usuarioExiste: 'El email de usuario que desea registrar ya existe.'
    },

    perfiles: {
        perfil_filtro:'Perfiles filtro:',
        cant_user: 'Cantidad de usuarios:',
        cant_recursos:'Cantidad recursos:',
        filtrar_perfiles:'Filtrar perfiles',
        filtro_nombre_placeholder: 'Nombre del perfil',
        filtro_grupo_info: 'Grupo de información',
        nombre:'Nombre',
        grupo:'Grupo',
        mensajeAlerta: '¿Esta seguro de querer inactivar el perfil?. Por que probablemente existen usuarios que tengan asociado ese perfil y quedaran sin permisos.',
        activarPerfil: '¿Esta seguro de querer activar el perfil?',
        ordenPerfilAsc:'Por perfil (Ascendente)',
        ordenPerfilDes:'Por perfil (Desc)',
    },

    perfilesDetalle: {
        nombre:'Nombre',
        nombre_placeholder: 'Nombre del perfil',
        modulo:'Módulo',
        nombre_recurso:'Nombre recurso',
        permisos: 'Permisos',
        consulta:'Consultar',
        modificacion:'Modificar',
        eliminar:'Eliminar',
        agregar:'Agregar',
        volver:'Volver'
    },

    personas: {
        nombres_fil:'Nombre',
        nombres_fil_placeholder: 'Ingrese los nombres de la persona',
        apellidos_fil:'Apellido',
        apellidos_fil_placeholder: 'Ingrese los apellidos de de la persona',

        nombres:'Nombre',
        apellidos:'Apellido',
        telefonoMovil: 'Celular',
        email: 'Email'

    },

    personasDetalle: {
        general: 'General',
        tipoDocumento: 'Tipo documento',
        numeroIdentificacion: 'Número documento',
        numeroIdentificacion_placeholder: 'Ingrese su número de documento',
        nombres:'Nombres',
        nombres_placeholder: 'Ingrese los nombres de la persona',
        apellidos:'Apellidos',
        apellidos_placeholder: 'Ingrese los apellidos de la persona',
        telefonoMovil: 'Celular',
        telefonoMovil_placeholder: 'Ingrese su número de celular',
        telefonoFijo: 'Teléfono fijo',
        telefonoFijo_placeholder: 'Ingrese su número de telefono fijo',
        email: 'Email',
        email_placeholder: 'Ingrese el email de la persona',
        direccion: 'Dirección',
        direccion_placeholder: 'Ingrese la dirección de la persona',
        pais: 'País',
        departamento: 'Departamento',
        ciudad: 'Ciudad',
        tipoPersona: 'Tipo Persona',
        genero: 'Genero',
        grupoDeInformacion: 'Grupo de información',
        usuarioAsesor: 'Usuario asesor',
        personaExiste: "Ya existe una persona registrada con el tipo documento y número documento ingresados",
        movilExiste: "Ya existe una persona registrada con el mismo numero celular registrado",
        asesorMensajeria: "Asesor mensajeria",
        codArea: "Código de área",
        camposObligatorios: "Existen campos obligatorios sin diligenciar.",
        etiquetas: "Etiquetas",
        sinAsesor: "Sin asesor",
        otros: 'Otros',
        observaciones: 'Observaciones',
        personasAsociadas: 'Personas asociadas'
    },

    organizacion: {
        general: 'General',
        tipoDocumento: 'Tipo documento',
        numeroIdentificacion: 'Número identifición',
        numeroIdentificacion_placeholder: 'Ingrese el número de indentificaciòn',
        nombres:'Nombre',
        nombres_placeholder: 'Ingrese el nombre de la organización',
        telefonoMovil: 'Celular',
        telefonoMovil_placeholder: 'Ingrese el número de celular',
        telefonoFijo: 'Teléfono fijo',
        telefonoFijo_placeholder: 'Ingrese el número de telefono fijo',
        email: 'Email',
        email_placeholder: 'Ingrese el email de la organización',
        dirección: 'Dirección',
        direccion_placeholder: 'Ingrese la dirección de la organización',
        pais: 'País',
        departamento: 'Departamento',
        ciudad: 'Ciudad',
        coleccionesGlobales: 'Colecciones globales',
        redes: 'Redes',
        horaIni: 'Hora inicio (HH24:MI)',
        horaFin: 'Hora fin (HH24:MI)',
        msgBienvenida: 'Mensaje de bienvenida',
        msgHorario: 'Mensaje de horario laboral'
        
    },

    producto: {
        nombre:'Nombre',
        codigoInterno: 'SKU',
        precio: 'Precio'
    },

    productosDet: {
        general: 'General',
        nombre: 'Nombre',
        nombre_placeholder: 'Ingrese el nombre del producto o servicio',
        codigo: 'SKU',
        codigo_placeholder: 'Ingrese el código interno del producto o servicio',
        precio: 'Precio',
        precio_placeholder: 'Ingrese el precio del producto o servicio',
        grupoDeInformacion: 'Grupo de información',
        descripcion: 'Descripción',
        descripcion_placeholder: 'Ingrese la descripción del producto o servicio'
    },

    embudo: {
        nuevo: 'Nuevo embudo',
        nuevaTarjeta: 'Nueva tarjeta',
        nombre:'Nombre',
        nombre_placeholder: 'Ingrese el nombre del embudo',
        etapas: 'Etapas',
        nombreLead:'Nombre',
        nombreProducto: 'Producto o Servicio',
        precioProducto: 'Precio',
        grupoInformacion: 'Grupo de información',
        cantidadProducto: 'Cantidad',
        etapa: 'Etapa',
        totalProducto: 'Total',
        evento: 'Evento',
        fecha: 'Fecha',
        fechaCreacion: 'Fecha creación',
        url: 'Url',
        programarAlerta: 'Programar alerta',
        programarMsgAutomatico: 'Programar mensaje',
        observaciones: 'Observaciones',
        observaciones_placeholder: 'Ingrese las observaciones del evento',
        acciones: 'Acciones',
        eventos: 'Eventos',
        programado: 'Programado:',
        nuevoEvento: 'Nuevo Evento',
        tipoEventoTitulo: 'Tipo Evento',
        estadoEventoTitulo: 'Estado Evento',
        cantidadProductos: 'Cantidad de productos/servicios:',
        cantidadTarjetas:'Cantidad de tarjetas:',
        totalEtapa:'Total',
        tipoEvento : {
            llamada: 'Llamada', 
            tarea: 'Tarea', 
            wp: 'WhatsApp',
            wmail: 'Email',
            reunion: 'Reunion'
        },
        estadoEvento : {
            pendiente: 'Pendiente', 
            proceso: 'En proceso',
            finalizado: "Finalizado", 
            cancelado: "Cancelado"
        },
        fechaDesde: 'Fecha Desde',
        fechaHasta: 'Fecha Hasta',
        fechaDesdePersonas: 'Fecha Desde Personas',
        fechaHastaPersonas: 'Fecha Hasta Personas',
        confirmacionMsgAutomatico: '¿Esta seguro de programar un mensaje automático?',
        usuarioParaAlerta: 'Usuario para alerta',
        personaParaMensaje: 'Persona para mensaje programado',
        historicoTarjeta: 'Historico de tarjeta',
        tableHistoricoTarjeta: {
            etapa: 'Etapa',
            usuario: 'Usuario',
            fechaIni: 'Fecha Inicio',
            fechaFin: 'Fecha Fin',
            tiempo: 'Tiempo (Dias)'
        }
    },

    grupoDeInformacion: {
        nombre_placeholder: 'Ingrese el nombre del grupo de información'
    },

    mensajeria: {
        contactos: "Contactos",
        chat: "Chat",
        enviar: "Enviar mensaje",
        noEnviado: "No enviado",
        modificarAsesor: "¿Desea modificar el asesor actual?",
        sinNumeroCelular: "La persona actual no tiene un numero de celular registrado.",
        errorEnviandoArchivo: "Se presento un error enviando el archivo, intentalo nuevamente.",
        retomarPlantilla: "Recuperar plantilla"
    },

    calendario: {
        observaciones: 'Observaciones'
    },

    tareas: {
        titulo: 'Tareas',
        etapaPendientes: 'Pendientes',
        etapaHaciendo: 'Haciendo',
        etapaFinalizado: 'Finalizado',
        escribeAqui: 'Escribe aqui tu tarea.',
        editarTarea: 'Editrar tarea'
    },

    historiaFiltros:{
        titulo: 'Historias médicas',
        seleccionTipoHistoria: 'Seleccione el tipo de historia que desea crear.',
        botonSeleccionHistGeneral: 'General',
        botonSeleccionHistGinecologo: 'Ginecologo',
        medicoTratante: 'Medico Tratante',
        rango_fechaDesde:'Desde',
        rango_fechaHasta: 'Hasta',
        fecha: 'Fecha',
        identificacion: 'Identificación',
        nombresPaciente: 'Nombres',
        apellidosPaciente: 'Apellidos',
        ver: 'Ver',
        imprimir: 'Imprimir',
        nota: 'Nota',
        filtrarHistoria: 'Filtrar Historia',
        ordenNombrePacienteAsc:'Por nombre paciente (Ascendente)',
        ordenNombrePacienteDes:'Por nombre paciente (Desc)',
        ordenFechaHistoriaAsc:'Por fecha historia (Ascendente)',
        ordenFechaHistoriaDes:'Por fecha historia (Desc)',
        descargarHistoria: 'Historia',
        descargarFormula: 'Fórmula'
    },

    historiaDetalle:{
        datosPaciente: 'Datos paciente',
        paciente: 'Paciente   ',
        nombre: 'Nombre',
        crear:'Crear',
        buscarPaciente: 'Nombres Apellidos Cedula',
        crearPaciente: 'Crear Paciente',
        historiaClinica: 'Historia Clinica',
        fecha: 'Fecha',
        medicoTratante: 'Medico',
        especialidad: 'Especialidad',
        historia: 'Historia',
        antecedentes: 'Antecedentes',
        personales: 'Personales',
        patologias: 'Patologias',
        traumaticas: 'Traumaticas',
        quirurgicas: 'Quirurgicas',
        farmologicas: 'Farmologicas',	
        alergias: 'Alergias',	
        transfuciones: 'Transfuciones',	
        inmunizaciones: 'Inmunizaciones',
        ginecobstetricos: 'Ginecobstetricos',
        menarquia: 'Menarquia',
        ciclos:	'Ciclos',
        planificacion: 'Planificación',	
        fum: 'FUM',
        familiares: 'Familiares',
        revisionPorSistemas: 'Revisión Por Sistemas',
        sistemasGenerales: 'Sistemas Generales',
        sistemaRespiratorio: 'Sistema Respiratorio',	
        sistemaCardiovascular: 'Sistema Cardiovascular',
        sistemaGastrointestinal: 'Sistema Gastrointestinal',
        sistemaGenitoUrinario: 'Sistema Genito Urinario',
        sistemaGenitoNeurologico: 'Sistema Neurologico',
        examenFisico: 'Examen Físico',
        estadoGeneral: 'Estado General',
        signosVitales: 'Signos Vitales',
        presionarteriar: 'Presión Arteriar',
        pas: 'PAS',
        mmgh: 'mmgh',
        pad: 'PAD',
        pam: 'PAM',
        frecuenciaCardiaca: 'Frecuencia Cardiaca',
        latmin: 'Lat/Min',
        frecuenciaRespiratoria: 'Frecuencia Respiratoria',
        rpm: 'RPM',
        temperatura: 'Temperatura',
        grados: 'ºC',
        saturaciondeOxigeno: 'Saturación de Oxigeno',
        porcentaje: '%',
        peso: 'Peso',
        kg: 'KG',
        talla: 'Talla',
        cm: 'CM',
        imc: 'IMC',
        imcPlaceHolder: 'Kg/M2',
        cabezayCuello: 'Cabeza y Cuello',
        normal: 'Normal',
        anormal: 'Anormal',
        ojos: 'Ojos',
        oidos: 'Oidos',
        nariz: 'Nariz',
        boca: 'Boca',
        cuello: 'Cuello',
        normalAnormal: 'Normal/Anormal',
        analisisyPlan: 'Analisis y Plan',
        diagnostico: 'Diagnostico',
        conducta: 'Conducta',
        prescripcion: 'Prescripción de Medicamento',
        nombreDeMedicamento: 'Medicamento',
        presentacion: 'Presentación',
        dosis: 'Dosis',
        via: 'Via',
        frecuencia: 'Frecuencia',
        duracion: 'Duración',
        observaciones: 'Observaciones',
        tipo: 'Tipo',
        unidad: 'Unidad',
        acompanante:'Acompañante',
        parentesco:'Parentesco',
        motivoConsultaEnfermedad: 'Motivo consulta y enfermedad actual',
        motivoConsulta: 'Motivo consulta',
        enfermedadActual: 'Enfermedad actual'
    },

    plantillasFil: {
        nombrePlantilla: 'Nombre plantilla',
        nombrePlantilla_placeholder: 'Ingrese aqui el nombre de la plantilla.'
    },

    plantillasDet: {
        general: 'General',
        nombre: 'Nombre plantilla',
        nombrePlantilla_placeholder: 'Ingrese aqui el nombre de la plantilla.',
        html: 'Html',
        limiteTamanio: 'El tamaño del archivo excede el límite.',
        variables: 'Variables'
    },

    gestionDocumental: {
        nombreArchivo: "Nombre del archivo",
        archivoNuevo: 'Archivo nuevo'
        
    },

    canciones: {
        nombre: 'Nombre cancion',
        tonalidad: 'Tonalidad',
        listasAsociadas: 'Listas asociadas',
        modo: 'Modo',
        transportar: 'Transportar',
        genero: 'Género',
        adminListas: 'Administrar listas',
        nombreLista: 'Nombre lista',
        orden: 'Orden',
        secuencia: 'Secuencia'
    }

}
