import accionesComp from "@/components/AccionesComponent";
import { firebase, auth } from '@/firebase/firebaseInit.js';
import {FIREBASE_SERVICES} from '@/axios/http-common';
import gestionDocumentalComponent from "@/components/gestionDocumental/GestionDocumentalComponent.vue";
import PersonasImpl from "@/classes/personas/PersonasImpl";

export default {
    name: 'personasComp',
    props: {
        pPersona: {type: Object, required: false},
        pMostrarVolver: {type: Boolean, required: false, default: true}
    },
    components: {
        accionesComp,
        gestionDocumentalComponent
    },
    data() {
        return {
            persona : {
                idTipoDocumento: this.$store.state.constantes.TIPO_DOCUMENTO_DEFECTO,
                idTipoDocumentoTmp: null,
                numeroIdentificacion: '',
                numeroIdentificacionTmp: '',
                nombres: '',
                apellidos: '',
                codArea: '+57',
                telefonoMovil: '',
                telefonoMovilTmp: '',
                telefonoFijo: '',
                email: '',
                direccion: '',
                idPais: this.$store.state.constantes.ubicacionesGeograficas.PAIS_INICIAL,
                idDepartamento:0,
                idCiudad:0,
                idTipoPersona: this.$store.state.constantes.TIPO_PERSONA_DEFECTO,
                idGenero: '',
                asesorMensajeria: '',
                etiquetas:[],
                idUsuarioAsesor: null,
                observaciones: '',
                listPersonasAsociadas: [],
                listPersonasAsociadasTmp: []
                
            },
            listGruposInformacion : [],
            listUsuarios : [],
            listEtiquetasPersonas: [],
            listEtiquetasPersonaOriginal: [],
            mostrarCompGestionDoc: false
            
        }
    },

    watch: {
        '$store.state.loading': function (val, oldVal) {
            if(val == false) {
                this.$refs.form.validate();

            }
        },
        'persona.idPais': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                this.persona.idDepartamento = 0;
                this.persona.idCiudad = 0;
            }
        },
        'persona.idDepartamento': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                this.persona.idCiudad = 0;
            }
        }
    },

    computed: {
        listPais: function () {
            return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                ubicacion => ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_PAIS
            );
        },
        listDepartamento: function () {
            return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                ubicacion => ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_DEPARTAMETO &&
                    ubicacion.idUbicacionPadre == this.persona.idPais
            );
        },
        listCiudad: function () {
            return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                ubicacion => ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_CIUDAD &&
                    ubicacion.idUbicacionPadre == this.persona.idDepartamento
            );
        },
        isUsuarioPersonaInListUsuarios: function () {
            return this.listUsuarios.findIndex( usuario => usuario.id == this.persona.idUsuarioAsesor) >= 0;
        },
    },
    
    mounted() {
        this.inicializar();

    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 4);
            this.loadUsuarios();
            this.loadGruposInformacion();
            this.loadEtiquetasPersonas();
            this.setCodArea();
            this.loadPersona();
            this.$parent.$on('guardarPersona',this.guardar);
        },

        setCodArea() {
            setTimeout(function () {
				var input = document.querySelector("#txtCodArea");
                window.intlTelInput(input, {
                    allowExtensions: true,
                    initialCountry: "co",
                    numberType: "MOBILE",
                    utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@16.0.3/build/js/utils.js",
                });
			}.bind(this), 500);
                
        },

        loadPersona() {
            if (this.pPersona) {
                // this.pPersona.etiquetas = this.getEtiquetaPersona(this.pPersona.etiquetas);
                let personaTmp = Object.assign({} , this.pPersona);
                let etiquetas = Object.assign({} , personaTmp.etiquetas);
                personaTmp.etiquetas = this.getEtiquetaPersona(etiquetas);
                this.listEtiquetasPersonaOriginal = this.getEtiquetaPersona(etiquetas);
                this.persona = personaTmp;
                this.persona.idTipoDocumentoTmp = this.persona.idTipoDocumento;
                this.persona.numeroIdentificacionTmp = this.persona.numeroIdentificacion;
                this.persona.telefonoMovilTmp = this.persona.telefonoMovil;
                this.persona.codArea= this.persona.codArea ? this.persona.codArea : '+57';
                this.persona.fechaCreacionOriginal = this.persona.fechaCreacionOriginal ? this.persona.fechaCreacionOriginal : this.persona.fechaCreacion;
                this.persona.listPersonasAsociadasTmp = this.persona.listPersonasAsociadas ? this.persona.listPersonasAsociadas : [];
                // this.persona.etiquetas = this.getEtiquetaPersona(this.persona.etiquetas);
                this.mostrarCompGestionDoc = true;

            } else {
                firebase.auth().onAuthStateChanged((user) =>{
                    if (!this.$store.getters.getSesionIniciada) {
                        return;
                    }
                    if(user){
                        this.persona.idUsuarioAsesor= user.uid;
                        this.persona.idGrupoInformacion= this.$store.getters.getIdGrupoInfomacion ? this.$store.getters.getIdGrupoInfomacion : this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL;
                        this.mostrarCompGestionDoc = true;
                    }
                });
                
            }
            
            this.$store.commit("detenerLoading");
        },

        loadUsuarios() {
            let  collection = this.getCollectionListFirestore('usuarios');
            collection.get()
                .then((querySnapshot) => {
                    this.listUsuarios.push({id:'', descripcion: this.$t('message.personasDetalle.sinAsesor')});
                    querySnapshot.forEach((doc) => {  
                        this.listUsuarios.push({id:doc.id, descripcion:doc.data().usuario});

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function(error) {

                        collection = collection.where('id', '==', auth.currentUser.uid);
                        collection.get()
                            .then((querySnapshot) => {
                                this.listUsuarios.push({id:'', descripcion: this.$t('message.personasDetalle.sinAsesor')});
                                querySnapshot.forEach((doc) => {  
                                    this.listUsuarios.push({id:doc.id, descripcion:doc.data().usuario});

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function(errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );

                    }.bind(this)
                );
        },

        loadGruposInformacion() {
            setTimeout(function(){
                this.getCollectionListFirestore('gruposDeInformacion').get()
                .then((querySnapshot) => {
                    // if (!this.$store.getters.getIdGrupoInfomacion) {
                    this.listGruposInformacion.push({
                        id: this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL, 
                        nombre: this.$t('message.general.global')
                    });
                    // }
                    querySnapshot.forEach((doc) => {  
                        this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function(error) {
                        console.log("Error getting grupos de informacion:", error);
                        this.$store.commit("detenerLoading");

                    }.bind(this)
                );
            }.bind(this), 1000);
            
        },

        loadEtiquetasPersonas() {
            this.getCollectionListFirestore('tablasTipo', null, true).doc("listEtiquetasPersonas").get().then(
                function(doc) {
                    if (doc.exists) {
                        this.listEtiquetasPersonas = doc.data().valor;
                    } else {
                        console.log("No such document listEtiquetasPersonas!");
                    }
                    this.$store.commit("detenerLoading");
                }.bind(this) 
            ).catch((error) => {
                this.$store.commit("detenerLoading");
                console.log("Error configurando la tabla tipo listEtiquetasPersonas:", error);
            });
        },

        getEtiquetaPersona(etiquetas) {
            var listEtiquetas=[];
            if (etiquetas) {
                var index = 0;
                for (const property in etiquetas) {
                    let etiqueta = {"id":index,"descripcion":property};
                    listEtiquetas.push(etiqueta);
                    index++;
                }
            }
            return listEtiquetas;
        },

        verificarPersonaRepetida(noConsultarServicio){
            if( this.persona.idTipoDocumento && 
                this.persona.numeroIdentificacion && 
                (this.persona.idTipoDocumento != this.persona.idTipoDocumentoTmp ||
                this.persona.numeroIdentificacion != this.persona.numeroIdentificacionTmp)
                
            ){
                if (!noConsultarServicio) {
                    this.$store.commit("ejecutarLoading");
                    return this.isPersonaExiste();
                } else {
                    return true
                }
                
            } else {
                return false;
                
            }
        },

        async isPersonaExiste() {
            return await FIREBASE_SERVICES.get(
                '/personas-personaExiste',
                {params: {
                          idOrganizacion: this.$store.getters.getIdOrganizacion,
                          idTipoDocumento: this.persona.idTipoDocumento,
                          numeroIdentificacion: this.persona.numeroIdentificacion
                         }
                }
            ).then(
                function (response) {
                    this.$store.commit("detenerLoading");
                    if (response.data.existe) {
                        this.$store.dispatch("addAlert", {
                            tipo: "warning",
                            titulo: "",
                            descripcion: this.$t('message.personasDetalle.personaExiste')
                        });
                        this.persona.numeroIdentificacion='';
                        return true;
                    } else {
                        return false;
                    }
                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                    return true;
                }.bind(this)
            );
        },

        verificarMovilRepetido(noConsultarServicio){
            if( this.persona.telefonoMovil && 
                this.persona.telefonoMovil != this.persona.telefonoMovilTmp
            ){
                if (!noConsultarServicio) {
                    this.$store.commit("ejecutarLoading");
                    this.isMovilExiste();
                } else {
                    return true
                }
                
            } else {
                return false;

            }
        },

        async isMovilExiste() {
            return await FIREBASE_SERVICES.get(
                '/personas-personaExisteByMovil',
                {params: {
                          idOrganizacion: this.$store.getters.getIdOrganizacion,
                          telefonoMovil: this.persona.telefonoMovil
                        }
                }
            ).then(
                function (response) {
                    this.$store.commit("detenerLoading");
                    if (response.data.existe) {
                        this.$store.dispatch("addAlert", {
                            tipo: "warning",
                            titulo: "",
                            descripcion: this.$t('message.personasDetalle.movilExiste')
                        });
                        this.persona.telefonoMovil='';
                        return true;
                    } else {
                        return false;
                    }
                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                    return true;
                }.bind(this)
            );
        },

        guardar() {
            this.$refs.form.validate().then(async success => {
                
                if (success) {
                    this.$store.commit("ejecutarLoadingCount", 2);
                    
                    let isPersonaExiste = this.verificarPersonaRepetida(true) && await this.isPersonaExiste();
                    let isMovilExiste = this.verificarMovilRepetido(true) && await this.isMovilExiste();

                    if (isPersonaExiste || isMovilExiste) {
                        
                        this.$store.commit("detenerLoadingCount", 2);
                        return;
                    }
                    
                    // this.$store.commit("ejecutarLoading");
                    
                    if (this.persona.docRef) {
                        this.actualizarPersona();
    
                    } else {
                        this.crearPersona();
    
                    }
                    this.actualizarListEtiquetasPersonas();
                } else {
                    this.$store.dispatch("addAlert", {
                        tipo: "warning",
                        titulo: "",
                        descripcion: this.$t('message.personasDetalle.camposObligatorios')
                    });
                }
            });
        },

        
        crearPersona() {
            let personasCollection = this.getCollectionAddFirestore("personas");

            personasCollection.add({
                idTipoDocumento: this.persona.idTipoDocumento,
                numeroIdentificacion: this.persona.numeroIdentificacion,
                nombres: this.persona.nombres,
                nombresSearchTermsArray: this.getSearchTermsArrayV2(this.persona.nombres),
                apellidos: this.persona.apellidos,
                apellidosSearchTermsArray: this.getSearchTermsArrayV2(this.persona.apellidos),
                codArea: this.persona.codArea ? this.persona.codArea : '+57',
                telefonoMovil: this.persona.telefonoMovil,
                telefonoFijo: this.persona.telefonoFijo,
                email: this.persona.email,
                direccion: this.persona.direccion,
                idPais: this.persona.idPais,
                idDepartamento: this.persona.idDepartamento,
                idCiudad: this.persona.idCiudad,
                idTipoPersona: this.persona.idTipoPersona,
                idGenero: this.persona.idGenero, 
                idGrupoInformacion: this.persona.idGrupoInformacion,
                idUsuarioAsesor : this.persona.idUsuarioAsesor,
                fechaCreacion: Date.now(),
                fechaCreacionOriginal: new Date(),
                temporal: false,
                asesorMensajeria: this.persona.asesorMensajeria,
                etiquetas: this.persona.etiquetas.reduce((a, v) => ({ ...a, [v.descripcion ? v.descripcion : v]: true}), {}),
                observaciones: this.persona.observaciones,
                listPersonasAsociadas: this.persona.listPersonasAsociadas
            }).then(
                function(docRef) {
                    // console.log("Document written with ID: ", docRef.id);
                    this.persona.id= docRef.id;
                    this.persona.docRef= docRef;
                    this.persona.idTipoDocumentoTmp = this.persona.idTipoDocumento;
                    this.persona.numeroIdentificacionTmp = this.persona.numeroIdentificacion;
                    this.persona.telefonoMovilTmp = this.persona.telefonoMovil;
                    
                    this.$emit('guardarGestionDocumental', {idDocumento: docRef.id});

                    // this.$emit('addNewPersonToList', Object.assign({} , this.persona));
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });
                    $('#popupPersonas').modal('hide');
                    this.crearEstadisticas(this.persona.etiquetas, new Date(), true);
                    this.asociarPersonasBidireccional(this.persona.id, this.persona.listPersonasAsociadas, []);
                    
                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        actualizarPersona() {
            this.persona.docRef.update({
                idTipoDocumento: this.persona.idTipoDocumento,
                numeroIdentificacion: this.persona.numeroIdentificacion,
                nombres: this.persona.nombres,
                nombresSearchTermsArray: this.getSearchTermsArrayV2(this.persona.nombres),
                apellidos: this.persona.apellidos,
                apellidosSearchTermsArray: this.getSearchTermsArrayV2(this.persona.apellidos),
                codArea: this.persona.codArea ? this.persona.codArea : '+57',
                telefonoMovil: this.persona.telefonoMovil,
                telefonoFijo: this.persona.telefonoFijo,
                email: this.persona.email,
                direccion: this.persona.direccion,
                idPais: this.persona.idPais,
                idDepartamento: this.persona.idDepartamento,
                idCiudad: this.persona.idCiudad,
                idTipoPersona: this.persona.idTipoPersona,
                idGenero: this.persona.idGenero, 
                idGrupoInformacion: this.persona.idGrupoInformacion,
                idUsuarioAsesor : this.persona.idUsuarioAsesor,
                fechaCreacion: Date.now(),
                fechaCreacionOriginal:  this.persona.fechaCreacionOriginal ? this.persona.fechaCreacionOriginal : new Date(),
                temporal: false,
                asesorMensajeria: this.persona.asesorMensajeria ? this.persona.asesorMensajeria : '',
                etiquetas: this.persona.etiquetas.reduce((a, v) => ({ ...a, [v.descripcion ? v.descripcion : v]: true}), {}),
                observaciones: this.persona.observaciones ? this.persona.observaciones : '',
                listPersonasAsociadas: this.getPersonasAsociadas()
            }, { merge: true }).then(
                function() {
                    this.$emit('guardarGestionDocumental', {idDocumento: this.persona.id});
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });
                    this.crearEstadisticas(this.persona.etiquetas, this.persona.fechaCreacionOriginal, false);
                    this.asociarPersonasBidireccional(this.persona.id, this.persona.listPersonasAsociadas ? this.persona.listPersonasAsociadas : [], this.persona.listPersonasAsociadasTmp);
                    $('#popupPersonas').modal('hide');
                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );


        },

        getPersonasAsociadas() {
            if (!this.persona.listPersonasAsociadas) {
                this.persona.listPersonasAsociadas = [];

            }else if (this.persona.listPersonasAsociadas.includes(this.persona.id)) {
                let index = this.persona.listPersonasAsociadas.findIndex((idPersonaFind) => idPersonaFind == this.persona.id);
                if (index >= 0) {
                    this.persona.listPersonasAsociadas.splice(index, 1);
                }
            }

            return this.persona.listPersonasAsociadas;
        },

        crearEstadisticas(etiquetas, fechaCreacionOriginal, personaNueva) {
            try {
                let existenEtiquetasNuevas = false;
                let fechaCreacionOriginalTmp = fechaCreacionOriginal ? fechaCreacionOriginal : new Date();
                if (fechaCreacionOriginalTmp && fechaCreacionOriginalTmp.hasOwnProperty('seconds')) {
                    let fechaTmp = new Date(0);
                    fechaCreacionOriginalTmp = fechaTmp.setSeconds(fechaCreacionOriginalTmp.seconds);
                }
                let fechaDia = this.$moment(fechaCreacionOriginalTmp).format("DD/MM/YYYY");

                // Se buscan las etiquetas nuevas para agregar o modificar la cantidad existente
                let listEtiquetasTmp = etiquetas.filter(etiqueta => !this.listEtiquetasPersonaOriginal.find( etiquetaPO => etiquetaPO.descripcion == etiqueta.descripcion));
                for (const etiqueta of listEtiquetasTmp) {
                    this.actualizarEstadistica(fechaDia, etiqueta.descripcion, 1, true);
                    existenEtiquetasNuevas = true;
                }

                // Se buscan las etiquetas que se eliminaron para restar la cantidad de las estadisticas para dicha etiqueta.
                listEtiquetasTmp = this.listEtiquetasPersonaOriginal.filter(etiquetaPO => !etiquetas.find( etiqueta => etiqueta.descripcion == etiquetaPO.descripcion));
                for (const etiqueta of listEtiquetasTmp) {
                    this.actualizarEstadistica(fechaDia, etiqueta.descripcion, -1, false);

                }

                // Se resta uno a la estadistica de la etiqueta "Sin etiquetar", por que inicialmente estaba sin etiqueta
                // y le agregaron al menos una.
                if (this.listEtiquetasPersonaOriginal.length == 0 && existenEtiquetasNuevas && !personaNueva)  {
                    this.actualizarEstadistica(fechaDia, this.$store.state.constantes.tipoEtiqueta.ETIQUETA_DEFAULT, -1, false);
                    
                }

                // Se suma uno a la estadistica de la etiqueta "Sin etiquetar", por que originalmente tenia etiquetas, 
                // pero se las eliminaron.
                if ((this.listEtiquetasPersonaOriginal.length > 0 && etiquetas.length == 0) || (personaNueva && etiquetas.length == 0)) {
                    this.actualizarEstadistica(fechaDia, this.$store.state.constantes.tipoEtiqueta.ETIQUETA_DEFAULT, 1, true);

                }

                if (personaNueva) {
                    this.actualizarEstadistica(fechaDia, this.$store.state.constantes.tipoEtiqueta.ETIQUETA_NUEVAS_PERSONAS, 1, true);
                    
                }

                this.listEtiquetasPersonaOriginal = etiquetas;
            } catch (error) {
                console.error("Error calculando las estadisticas para la persona: ", error);
            }

        },

        actualizarEstadistica(fechaDia, descripcionEtiqueta, cantidadParam, insertarSiNoSeEncuentra) {
            let collection = this.getCollectionListFirestore("personas_estadisticas", null, true);
            collection = collection.where("fechaDia", "==", fechaDia).where("etiqueta", "==", descripcionEtiqueta);
            collection = collection.where("tipoEstadistica", "==", this.$store.state.constantes.tipoEstadisticaPersona.CANT_PERSONAS_DIA_ETIQUETA);

            collection.get().then((querySnapshot) => {
                // Si encontramos la estadistica, procedemos a actualizarla aumentando uno en la cantidad.
                querySnapshot.forEach((doc) => {
                    var cantidad = doc.data().cantidad + cantidadParam;
                    if (cantidad < 0){
                        cantidad = 0;
                    }

                    doc.ref.update({
                        cantidad: cantidad
                    }, { merge: true }).catch(
                        function(error) {
                            console.error("Error Updating document personas_estadisticas: ", error);
        
                        }.bind(this)
                    );
        
                });

                // Si no se encuentra la estadistica se procede a crearla con cantidad inicial en 1
                if (querySnapshot.empty && insertarSiNoSeEncuentra) {
                    let personasCollection = this.getCollectionAddFirestore("personas_estadisticas");
                    
                    personasCollection.add({
                        tipoEstadistica:1,
                        fechaDia: fechaDia,
                        etiqueta: descripcionEtiqueta,
                        cantidad: 1,
                        fecha: this.$moment(fechaDia, "DD/MM/YYYY").toDate(),
                        idGrupoInformacion: this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL
                    }).catch(
                        function(error) {
                            console.error("Error adding document personas_estadisticas: ", error);
        
                        }.bind(this)
                    );
                }

            }).catch(
                function(error) {
                    console.log("Error getting document personas_estadisticas:", error);
                }.bind(this)
            );
        },

        actualizarListEtiquetasPersonas() {
            let listEtiquetasPersonasRef = this.getCollectionListFirestore('tablasTipo', null, true).doc("listEtiquetasPersonas");
            for (let etiqueta of this.persona.etiquetas) {
                if (!etiqueta.hasOwnProperty('id')) {
                    let etiquetaExiste = this.listEtiquetasPersonas.find((element) => element.descripcion.toLowerCase() == (etiqueta.hasOwnProperty('descripcion') ? etiqueta.descripcion.toLowerCase() : etiqueta.toLowerCase()));
                    
                    if (!etiquetaExiste) {
                        let etiquetaTmp = {
                            id: this.listEtiquetasPersonas.length + 1,
                            descripcion: etiqueta.hasOwnProperty('descripcion') ? etiqueta.descripcion : etiqueta
                        
                        }

                        listEtiquetasPersonasRef.update({
                            valor: firebase.firestore.FieldValue.arrayUnion(etiquetaTmp)
                        });
                        this.listEtiquetasPersonas.push(etiquetaTmp);
                    }
                }
            }
            this.$store.commit("detenerLoading");
        },

        perderFoco() {
            let codArea = document.getElementsByClassName("iti__selected-flag")[0].title;
            this.persona.codArea = codArea.split(":")[1].trim()
            document.getElementById("txtTelMovilPersona").focus();
        },

        asociarPersonasBidireccional(idPersona, listPersonasAsociadas, listPersonasAsociadasTmp) {
            let listaPersonasAsociadasEliminar = listPersonasAsociadasTmp.filter(idPersonaFind => !listPersonasAsociadas.includes(idPersonaFind));
            this.procesarPersonasAsociadas(idPersona, listPersonasAsociadas, true);
            this.procesarPersonasAsociadas(idPersona, listaPersonasAsociadasEliminar, false);

        },

        procesarPersonasAsociadas(idPersona, listPersonasAsociadas, insertar) {
            listPersonasAsociadas.forEach(async idPersonaAsociada => {
                let personaAsociada = await PersonasImpl.getPersonaById(idPersonaAsociada);
                if (personaAsociada?.docRef && idPersona != idPersonaAsociada) {
                    let listPersonasAsociadasTmp = personaAsociada.listPersonasAsociadas ? personaAsociada.listPersonasAsociadas : [];
                    
                    if (insertar) {
                        if (!listPersonasAsociadasTmp.includes(idPersona)) {
                            listPersonasAsociadasTmp.push(idPersona);
                            this.updatePersonaAsociada(personaAsociada, listPersonasAsociadasTmp);
                        }
                    } else {
                        let index = listPersonasAsociadasTmp.findIndex((idPersonaFind) => idPersonaFind == idPersona);
                        if (index >= 0) {
                            listPersonasAsociadasTmp.splice(index, 1);
                            this.updatePersonaAsociada(personaAsociada, listPersonasAsociadasTmp);
                        }
                    }
                }
            });
        },

        updatePersonaAsociada(personaAsociada, listPersonasAsociadasTmp) {
            personaAsociada.docRef.update({
                listPersonasAsociadas: listPersonasAsociadasTmp
            }, { merge: true }).catch(
                function(error) {
                    console.error("Error asociando personas ID=: " + idPersona , error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        }

    }

}