import store from '../store'

export default {
    install(Vue, options) {
        Vue.mixin({
            data() {
                return {
                    variableGlobal: '',
                    dataTable: {},
                    paginaActual: {}
                }
            },

            computed: {
                listPersonasFind: function () {
                    return this.$store.state.listPersonasFind;
                },
                listPais: function () {
                    return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                        ubicacion => ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_PAIS
                    );
                },
                listDepartamento: function () {
                    return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                        ubicacion => ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_DEPARTAMETO &&
                            ubicacion.idUbicacionPadre == this.filtros.idPais
                    );
                },
                listCiudad: function () {
                    return this.$store.state.tablasTipo.tipoUbicacionGeografica.filter(
                        ubicacion => ubicacion.idTipo == this.$store.state.constantes.ubicacionesGeograficas.TIPO_CIUDAD &&
                            ubicacion.idUbicacionPadre == this.filtros.idDepartamento
                    );
                }
            },
            
            methods: {
                setResponsiveTable() {
                    // setTimeout(function () {
                        $('table').each(function () {
                            var thetable = $(this);
                            $(this).find('tbody td').each(function () {
                                $(this).attr('data-heading', thetable.find('thead th:nth-child(' + ($(this).index() + 1) + ')').text());
                            });
                        });
                    // }, 300);

                },

                destroyDataTable(idTable) {
                    if (idTable && this.dataTable[idTable]) {
                        try {
                            this.paginaActual[idTable] = this.dataTable[idTable].page();
                        } catch (error) {
                            this.paginaActual[idTable] = 0;
                        }
                        
                        this.dataTable[idTable].destroy();
                        // this.estadoActualDataTable[idTable] = this.dataTable[idTable].state.save();
                        // this.dataTable[idTable].state.clear();
                        
                        
                    }
                    
                },

                applyDataTable(idTable, cantColumnas, columnDefs, order, mantenerPaginador) {
                    if (idTable) {
                        // setTimeout(function () {
                            if ( $.fn.dataTable.isDataTable(idTable) ) {
                                this.dataTable[idTable] = $(idTable).DataTable();
                            } else {
                                // if (!recuperarEstado){
                                //     this.dataTable[idTable].state.clear();
                                // }

                                this.dataTable[idTable] = $(idTable).DataTable({
                                    // stateSave: recuperarEstado ? true : false,
                                    retrieve: true,
                                    columnDefs: columnDefs ? columnDefs : [
                                        { "orderable": true, "targets": Array.from({length: cantColumnas}, (_, i) => i) },
                                        { "orderable": false, "targets": [cantColumnas]}
                                    ],
                                    order: order ? order : [[0,'asc'], [1,'asc']],
                                    // bSort: bSort ? true : false,
                                    responsive: true,
                                    pageLength: 10,
                                    lengthMenu: [5, 10, 25, 50],
                                    language: {
                                        info: "Mostrando _PAGE_ de _PAGES_",
                                        lengthMenu: "Mostrar _MENU_ ",
                                        paginate: {
                                            first: "Primero",
                                            last: "Último",
                                            next: '<em class="bx bxs-chevron-right d-lg-none d-md-none b-block"></em><span class="d-md-block d-none">Siguiente</span> ',
                                            previous: '<em class="bx bxs-chevron-left d-lg-none d-md-none b-block"></em><span class="d-md-block d-none">Anterior</span> '
                                        }
                                    },
                        
                                    dom:
                                        '<"top"<"row mx-0 datatable-cont-inputs"<"col-sm-12 d-flex justify-content-end px-0"l>>>rt<"bottom dataTables_wrapper dataTables_paginate mb-1 flex-md-row flex-column align-items-center justify-content-between"ip><"clear">',
                        
                                });

                                if (mantenerPaginador) {
                                    this.dataTable[idTable].page(this.paginaActual[idTable]).draw(false);
                                }
                                // if (recuperarEstado && this.estadoActualDataTable[idTable]) {
                                //     this.dataTable[idTable].state.load(this.estadoActualDataTable[idTable]);
                                // }
                            }
                        // }.bind(this), 300);
                    }
                },

                drawTable(idTable, lista) {
                    if (idTable && this.dataTable[idTable]) {
                        let currentPage = this.dataTable[idTable].page();
                        // this.dataTable[idTable].clear().rows.add(lista).draw();
                        this.dataTable[idTable].draw();
                        this.dataTable[idTable].page(currentPage).draw(false);
                    }
                },

                setLastPageInDataTable(idTable){
                    this.dataTable[idTable] = $(idTable).DataTable();
                    this.dataTable[idTable].page('last').draw('page');
                },

                getSearchTermsArray(cadena) {
                    var listTermsTmp;
                    var listTerms = [];
                    try {
                        listTermsTmp = cadena.split(" ");

                        for (let termino of listTermsTmp) {
                            if (termino) {
                                listTerms.push(termino.toLowerCase());
                            }
                        }

                    } catch (error) {
                        listTerms = [];
                    }

                    return listTerms;

                },

                getSearchTermsArrayV2(cadena) {
                    var listTermsTmp;
                    var termsObj = {};
                    try {
                        listTermsTmp = cadena.split(" ");
                
                        for (let termino of listTermsTmp) {
                            if (termino) {
                                termsObj[termino.toLowerCase()] = true;
                            }
                        }
                
                    } catch (error) {
                        termsObj = {};
                    }
                
                    return termsObj;
                
                },

                isInSearchTerms(terminosString, searchTerms) {
                    let encontrado = true;
                    if (terminosString) {
                        for (let termino of terminosString.toLowerCase().split(" ")) {
                            if (termino && !searchTerms[termino]) {
                                encontrado= false;
                                break;
                            }
                        }
                    }
                    return encontrado;
                },

                isInSearchTermsV2(terminosArray, searchTerms) {
                    let encontrado = true;
                    if (terminosArray && terminosArray.length > 0) {
                        for (let termino of terminosArray) {
                            if (termino && !searchTerms[termino]) {
                                encontrado= false;
                                break;
                            }
                        }
                    }
                    return encontrado;
                },

                getFormatterPeso() {
                    return new Intl.NumberFormat('es-CO', {
                        style: 'currency',
                        currency: 'COP',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })
                },

                formatMoneda(value) {
                    if (isNaN(value)) return value;

                    return this.getFormatterPeso().format(value);

                },

                getOriginalFormatMoneda(value) {
                    // let separadorGrupo = this.getFormatterPeso().formatToParts(value).find(parte => parte.type == "group").value;
                    return value.replaceAll(".", "").replaceAll(",", "").replace("$", "").replaceAll(" ", "")
                },

                formatSecondsToDate(seconds, formato) {
                    if (!seconds) return '';

                    var fecha = new Date(0); // Epoch
                    fecha.setSeconds(seconds);

                    return this.$moment(fecha).format(formato);

                }               
            },
            watch: {
                '$store.state.locale': function (val, oldVal) {
                    this.$moment.locale(val);
                }
            }
        });

    }
}


