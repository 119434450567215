import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import store from '../store';


// Desarrollo y pruebas
// const config = {
//     apiKey: "AIzaSyC_3ZJu5zrkEWAGFlE2l-Od76N8HsO_U64",
//     authDomain: "siutebrainsprueba.firebaseapp.com",
//     databaseURL: "https://siutebrainsprueba.firebaseio.com",
//     projectId: "siutebrainsprueba",
//     storageBucket: "siutebrainsprueba.appspot.com",
//     messagingSenderId: "687916717083",
//     appId: "1:687916717083:web:a3876f2d9491b3dc4f14be",
//     measurementId: "G-WYTP86PRY0"
// };

// Produccion
const config = {
    databaseURL: "https://suitebrains-produccion.firebaseio.com",
    apiKey: "AIzaSyBeZY1rP9c4b3pl-CkirUyeBcpgFiZuYVw",
    authDomain: "suitebrains-produccion.firebaseapp.com",
    projectId: "suitebrains-produccion",
    storageBucket: "suitebrains-produccion.appspot.com",
    messagingSenderId: "585191692888",
    appId: "1:585191692888:web:3ccf5f38c164599dcf082d",
    measurementId: "G-RRTVR7PE62"
};





firebase.initializeApp(config);

firebase.auth().onAuthStateChanged(user => {
    if (!user) {
        store.commit("logOut");
    }
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

/*if (location.hostname === "localhost") {
    firestore.useEmulator("localhost", 8082);
    auth.useEmulator("http://localhost:9098");
}*/

export { firebase, auth, firestore, storage };