import accionesComp from "@/components/AccionesComponent";
import SelectorPlantillasComp from "@/components/plantillas/SelectorPlantillasComponent.vue";
import { Chat } from 'vue-quick-chat'
import 'vue-quick-chat/dist/vue-quick-chat.css';
import {DIGITAL_OCEAN_SERVICES} from '@/axios/http-common';
import { firebase, auth } from '@/firebase/firebaseInit.js';


export default {
    name: 'mensajeriaComp',
    props: {
        persona: {required: false},
        listPlantillas: {required: false, default() {return []}}
    },
    components: {
        accionesComp,
        Chat,
        SelectorPlantillasComp
    },
    data() {
        return {
            visible: true,
            myself: {
                name: 'Suite Brains',
                id: 1,
                profilePicture: '../logoFourBrainsPeq.png'
            },
            participants: [
                {
                    name: this.persona.nombres,
                    id: 2,
                    profilePicture: '../avatar5.jpeg'
                }
            ],
            toLoad: [
                // {
                //     content: 'Hey, John Doe! How are you today?',
                //     myself: false,
                //     participantId: 2,
                //     timestamp: {year: 2011, month: 3, day: 5, hour: 10, minute: 10, second: 3, millisecond: 123},
                //     uploaded: true,
                //     viewed: true,
                //     type: 'text'
                // },
                // {
                //     content: "Hey, Adam! I'm feeling really fine this evening.",
                //     myself: true,
                //     participantId: 3,
                //     timestamp: {year: 2011, month: 3, day: 5, hour: 19, minute: 10, second: 3, millisecond: 123},
                //     uploaded: true,
                //     viewed: true,
                //     type: 'text'
                // },
            ],
            messages: [
                // {
                //     content: 'received messages',
                //     myself: false,
                //     participantId: 1,
                //     timestamp: {year: 2019, month: 3, day: 5, hour: 20, minute: 10, second: 3, millisecond: 123},
                //     type: 'text'
                // },
                // {
                //     content: 'sent messages',
                //     myself: true,
                //     participantId: 3,
                //     timestamp: {year: 2019, month: 4, day: 5, hour: 19, minute: 10, second: 3, millisecond: 123},
                //     type: 'text'
                // },
                // {
                //     content: 'other received messages',
                //     myself: false,
                //     participantId: 2,
                //     timestamp: {year: 2019, month: 5, day: 5, hour: 10, minute: 10, second: 3, millisecond: 123},
                //     type: 'text'
                // }
            ],
            chatTitle: this.$t("message.mensajeria.chat"),
            placeholder: this.$t("message.mensajeria.enviar"),
            colors: {
                header: {
                    bg: '#3f85db',
                    text: '#fff'
                },
                message: {
                    myself: {
                        bg: '#a584d1',
                        text: '#fff'
                    },
                    others: {
                        bg: '#83b5d3',
                        text: '#fff'
                    },
                    messagesDisplay: {
                        bg: '#f7f3f3'
                    }
                },
                submitIcon: '#b91010',
                submitImageIcon: '#b91010',
            },
            borderStyle: {
                topLeft: "10px",
                topRight: "10px",
                bottomLeft: "10px",
                bottomRight: "10px",
            },
            hideCloseButton: false,
            submitIconSize: 25,
            closeButtonIconSize: "20px",
            asyncMode: false,
            scrollBottom: {
                messageSent: true,
                messageReceived: true
            },
            displayHeader:true,
            profilePictureConfig: {
                others: true,
                myself: true,
                styles: {
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%'
                }
            },
            timestampConfig: {   
                format: 'DD HH:mm',
                relative: false
            },
            // there are other options, you can check them here
            // https://soapbox.github.io/linkifyjs/docs/options.html
            linkOptions: {
                myself: {
                    className: 'myLinkClass',
                    events: {
                        click: function (e) {
                            // alert('Link clicked!');
                        },
                        mouseover: function (e) {
                            // alert('Link hovered!');
                        }
                    },
                    format: function (value, type) {
                        if (type === 'url' && value.length > 50) {
                            value = value.slice(0, 50) + '…';
                        }
                        return value;
                    }
                },
                others: {
                    className: 'othersLinkClass',
                    events: {
                        click: function (e) {
                            // alert('Link clicked!');
                        },
                        mouseover: function (e) {
                            // alert('Link hovered!');
                        }
                    },
                    format: function (value, type) {
                        if (type === 'url' && value.length > 50) {
                            value = value.slice(0, 50) + '…';
                        }
                        return value;
                    }
                }
            },
            listenerMensajesStop: null,
            messageContent: "",
            isImageOrPdf: "",
            contentBase64: "",
            extensionFile: "",
            mostrarComponentePdf: false
            // mostrarBotonEnviar: false,
            
            
        }
    },

    watch: {

    },

    computed: { 

    },

    mounted() {
        if (this.persona.idPersona) {
            this.inicializar();
        }
    },

    beforeDestroy() {
        if (this.listenerMensajesStop) {
            this.listenerMensajesStop();
        }
        if (this.listenerMensajesStopSA) {
            this.listenerMensajesStopSA();
        }
        
    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoading");
            this.setListeners();

            firebase.auth().onAuthStateChanged((user) =>{
                if (!this.$store.getters.getSesionIniciada) {
                    return;
                }
                if(user){
                    this.consultar(user.uid);

                }
            });
           
        },

        setListeners() {
            const element = document.querySelector(".message-input");
            element.addEventListener("dblclick", () => {
                this.messageContent = document.getElementsByClassName("message-input")[0].innerHTML;
                $('#popupMensaje').modal('show');
            });

            $(".dropdown-menu.emoji").click(function(e){
                e.stopPropagation();
            })
        },

        async consultar(uid) {
            var uidTmp = uid;
            if (!uidTmp) {
                uidTmp = auth.currentUser.uid

            }

            this.messages = [];

            await this.consultaConAsesor(uidTmp);

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                await this.consultaSinAsesor(uidTmp);
            }

        },

        async consultaConAsesor(uid){
            let collection = this.getCollectionListFirestore('mensajes');
            collection = this.getCollectionFiltered(collection, uid, true);

            if (this.listenerMensajesStop) {
                this.listenerMensajesStop();
            }

            this.listenerMensajesStop = await this.getMensajes(collection);
        },

        async consultaSinAsesor(uid){
            let collection = this.getCollectionListFirestore('mensajes');
            collection = this.getCollectionFiltered(collection, uid, false);

            if (this.listenerMensajesStopSA) {
                this.listenerMensajesStopSA();
            }

            this.listenerMensajesStopSA = await this.getMensajes(collection);
        },

        getCollectionFiltered(pCollection, uid, sinAsesor) {
            var collection = pCollection;

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (sinAsesor) {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                } else {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);
                }

            }
            
            collection = collection.where('idPersona', '==', this.persona.idPersona).orderBy('fecha');
    
            return collection;
        },

        async getMensajes(collection) {
            return await collection.onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        var mensaje = change.doc.data();
                        mensaje["id"] = change.doc.id;
                        mensaje["docRef"] = change.doc.ref;
                        mensaje.content = change.doc.data().contenido;
                        mensaje.myself = change.doc.data().mySelf;
                        mensaje.participantId = change.doc.data().mySelf ? 1 : 2;
                        mensaje.uploaded = true;

                        if (!mensaje.type) {
                            mensaje.type = 'text';

                        } else if (mensaje.type == 'ptt') {
                            mensaje.type = 'image';
                            mensaje.src = "../audio.png";

                        } else if (mensaje.type != 'chat' && mensaje.type != 'text') {
                            mensaje.type = 'image';
                            mensaje.src = "../file.png";

                        }

                        var fecha = new Date(0);
                        fecha.setSeconds(change.doc.data().fecha.seconds);

                        mensaje.timestamp= {
                            year: fecha.getFullYear(), 
                            month: fecha.getMonth() + 1, 
                            day: fecha.getDate(), 
                            hour: fecha.getHours(), 
                            minute: fecha.getMinutes(), 
                            second: fecha.getSeconds(), 
                            millisecond: fecha.getMilliseconds()
                        };

                        this.messages.push(mensaje);
                    }
                });

                this.ordenarLista(this.messages);

                setTimeout(() => {
                    let element = document.getElementsByClassName("container-message-display")[0];
                    if (element && element.scrollHeight) {
                        element.scrollTop = element.scrollHeight + 5000;

                    }
                }, 1000);
                
                
                this.$store.commit("detenerLoading");
            });

        },

        ordenarLista(listMensajes) {
            listMensajes.sort(function (a, b) {
                // if (a.fechaUltimoMensaje && b.fechaUltimoMensaje && a.fechaUltimoMensaje.seconds > b.fechaUltimoMensaje.seconds) {
                //     return 1;
                // }
                // if (a.fechaUltimoMensaje && b.fechaUltimoMensaje && a.fechaUltimoMensaje.seconds < b.fechaUltimoMensaje.seconds) {
                //     return -1;
                // }
                // a must be equal to b
                let vlr = 0;
                let vlrA = a.fecha ? a.fecha.seconds : 0;
                let vlrB = b.fecha ? b.fecha.seconds : 0;

                vlr = vlrA - vlrB;

                return vlr;
            });
        },

        onType: function (event) {
            //here you can set any behavior
        },

        loadMoreMessages(resolve) {
            setTimeout(() => {
                resolve(this.toLoad); //We end the loading state and add the messages
                //Make sure the loaded messages are also added to our local messages copy or they will be lost
                this.messages.unshift(...this.toLoad);
                this.toLoad = [];
            }, 1000);
        },

        async onMessageSubmit(message) {
            this.$store.commit("ejecutarLoading");
            let contenidoMsg = message.content;
            if (this.persona.telefonoMovil) {
                await DIGITAL_OCEAN_SERVICES.get(
                    '/sendMessage',
                    {params: {
                        idOrganizacion: this.$store.getters.getIdOrganizacion, 
                        number: this.getTelefonoMovil(this.persona.codArea, this.persona.telefonoMovil), 
                        text: message.content,
                        hostConsumo: window.location.host,
                        idPersona: this.persona.idPersona
                    }}
                ).then(
                    function (response) {
                        if (response.data) {
                            this.crearMensaje(message, response.data);

                        } else {
                            message.content = this.$t('message.mensajeria.noEnviado');
                            message.uploaded = false;
                            this.$store.commit("detenerLoading");
                            document.getElementsByClassName("message-input")[0].innerHTML = contenidoMsg;
                        }

                    }.bind(this)
                ).catch(
                    function (error) {
                        message.content = this.$t('message.mensajeria.noEnviado');
                        message.uploaded = false;
                        this.$store.commit("detenerLoading");
                        document.getElementsByClassName("message-input")[0].innerHTML = contenidoMsg;
                        console.log(error);
                    }.bind(this)
                )
            } else {
                this.$store.dispatch("addAlert", {
                    tipo: "warning",
                    titulo: "",
                    descripcion: this.$t('message.mensajeria.sinNumeroCelular')
                });
            }

        },

        crearMensaje(message, idMsg) {
            let mensajesCollection = this.getCollectionAddFirestore("mensajes");

            mensajesCollection.add({
                remitente: null,
                destinatario: this.persona.telefonoMovil,
                idPersona: this.persona.idPersona,
                contenido: message.content,
                fecha: new Date(),
                origen: 'ws',
                idGrupoInformacion: this.persona.idGrupoInformacion,
                mySelf: true,
                idUsuarioAsesor: this.persona.idUsuarioAsesor,
                type: message.type,
                idMsg: idMsg
            }).then(
                function(docRef) {
                    message.uploaded = true
                    this.$store.commit("detenerLoading");
                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        onClose() {
            this.visible = false;
        },

        onImageSelected(infoMessage){
            let file = infoMessage.file;
            let message = infoMessage.message;
            message.content = file.name;

            let blob = file.slice();
            var reader = new FileReader();
            reader.readAsDataURL(blob); 

            reader.onloadend = function() {
                this.enviarImagen(message, file.type, file.name, reader.result);

            }.bind(this)

        },

        enviarImagen(message, type, name, base64data) {
            let dataFile = base64data.includes("data:application/octet-stream;base64,") ?
                "data:application/octet-stream;base64," : "data:application/pdf;base64,";

            DIGITAL_OCEAN_SERVICES.post(
                '/sendMessageMedia',
                {   idOrganizacion: this.$store.getters.getIdOrganizacion, 
                    number: this.getTelefonoMovil(this.persona.codArea, this.persona.telefonoMovil), 
                    imgBase64: base64data.split(dataFile)[1],
                    type: type,
                    name: name,
                    hostConsumo: window.location.host,
                    idPersona: this.persona.idPersona
                }
            ).then(
                function (response) {
                    if (response.data) {
                        message.src = "../file.png"
                        this.crearMensaje(message, response.data);
                        
                    } else {
                        message.src = "../file-error.png"
                        message.uploaded = true;
                        this.$store.dispatch("addAlert", {
                            tipo: "warning",
                            titulo: "",
                            descripcion: this.$t('message.mensajeria.errorEnviandoArchivo')
                        });
                        console.log(error);
                        this.$store.commit("detenerLoading");
                    }
                    

                }.bind(this)
            ).catch(
                function (error) {
                    message.src = "../file-error.png"
                    message.uploaded = true;
                    this.$store.dispatch("addAlert", {
                        tipo: "warning",
                        titulo: "",
                        descripcion: this.$t('message.mensajeria.errorEnviandoArchivo')
                    });
                    console.log(error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            )   
        },

        onImageClicked(message){
            this.$store.commit("ejecutarLoading");
            DIGITAL_OCEAN_SERVICES.post(
                '/downloadMedia',
                {   idOrganizacion: this.$store.getters.getIdOrganizacion, 
                    // number: !message.mySelf ? message.remitente : message.destinatario, 
                    number: this.getTelefonoMovil(this.persona.codArea, this.persona.telefonoMovil), 
                    id: message.idMsg.idQuotedMsg ? message.idMsg.idQuotedMsg : message.idMsg.id,
                    hostConsumo: window.location.host,
                    idPersona: this.persona.idPersona
                }
            ).then(
                function (response) {
                    if (response.data) {
                        var extension = response.data.split("base64,")[0].split("data:")[1].split("/")[1].split(";")[0].toLowerCase();
                        this.extensionFile = extension;

                        if (extension == "ogg") {
                            var elementVideo = document.createElement('video');
                            elementVideo.setAttribute("controls", "");
                            elementVideo.setAttribute("autoplay", "");
                            elementVideo.setAttribute("name", "media");
                            elementVideo.setAttribute("style", "height: 37px; width: 300px;");

                            var elementSource = document.createElement('source');
                            elementSource.setAttribute("src", response.data);
                            elementSource.setAttribute("type", "audio/ogg");

                            elementVideo.appendChild(elementSource);

                            var nodoPadre = document.getElementById("div_" + message.idMsg.id);
                            var nodoHijo = document.getElementById("img_" + message.idMsg.id);

                            if (nodoPadre) {
                                nodoPadre.removeChild(nodoHijo);
                                nodoPadre.appendChild(elementVideo);
                            }

                            // var snd = new Audio(response.data);
                            // snd.play();

                        } else if (["jpeg", "jpg", "png", "bmp", "pdf"].includes(extension)) {
                            this.isImageOrPdf = extension == "pdf" ? 'pdf' : 'img';
                            this.contentBase64 = response.data;
                            this.mostrarComponentePdf = false;
                            setTimeout(function () {
                                this.mostrarComponentePdf = true;
                                $('#popupvistaPrevia').modal('show');
                            }.bind(this), 100);
                            
                            
                        } else {
                            this.downloadDocument(response.data, extension);
                            // var linkSource = response.data;
                            // var downloadLink = document.createElement("a");
                            // var fileName = "Archivo." + extension;
                            // downloadLink.href = linkSource;
                            // downloadLink.download = fileName;
                            // downloadLink.click();

                        }
                    }
                    this.$store.commit("detenerLoading");
                }.bind(this)
            ).catch(
                function (error) {
                    console.log(error);
                }.bind(this)
            ) 
        
        },

        onDeleteClicked(message){
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: { no: this.$t('message.general.no'), yes: this.$t('message.general.si') },
                callback: confirm => {
                    if (confirm) {
                        this.eliminarMensaje(message);
                    }
                }
            });
            
        },

        eliminarMensaje(message) {
            this.$store.commit("ejecutarLoading");
            DIGITAL_OCEAN_SERVICES.post(
                '/deleteMessage',
                {   idOrganizacion: this.$store.getters.getIdOrganizacion, 
                    // number: !message.mySelf ? message.remitente : message.destinatario, 
                    number: this.getTelefonoMovil(this.persona.codArea, this.persona.telefonoMovil), 
                    id: message.idMsg.idQuotedMsg ? message.idMsg.idQuotedMsg : message.idMsg.id,
                    hostConsumo: window.location.host
                }
            ).then(
                function (response) {
                    if (response.data) {
                        message.docRef.delete().then(() => {
                            let index = this.messages.findIndex(element => element.id === message.id);
                            if (index >= 0) {
                                this.messages.splice(index, 1);
                            }
                            this.$store.dispatch("addAlert", {
                                tipo: "success",
                                titulo: "",
                                descripcion: this.$t('message.general.eliminarExitoso')
                            });
                        }).catch((error) => {
                            console.error("Error removing document: ", error);
                            this.$store.commit("detenerLoading");
                        });
                    }
                    this.$store.commit("detenerLoading");
                }.bind(this)
            ).catch(
                function (error) {
                    console.log(error);
                }.bind(this)
            )
        },

        downloadDocument(data, extension) {
            var linkSource = data ? data : this.contentBase64;
            var downloadLink = document.createElement("a");
            var fileName = "Archivo." + (extension ? extension : this.extensionFile);
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },

        getTelefonoMovil(codArea, telefonoMovil) {
            let telefonoMovilReturn;

            if (telefonoMovil) {
                if (codArea) {
                    telefonoMovilReturn = codArea + telefonoMovil;
                } else {
                    telefonoMovilReturn = '+57' + telefonoMovil;
                }
            }

            return telefonoMovilReturn;
        },

        cerrarModalMensaje() {
            document.getElementsByClassName("message-input")[0].innerHTML = this.messageContent;
            $('#popupMensaje').modal('hide');
        },

        cerrarModalVistaPrevia() {
            this.mostrarBotonEnviar=false;
            $('#popupvistaPrevia').modal('hide');
        },

        onOpenHtmlEditor() {
            $('#popupPlantilla').modal('show');
        },

        onRetornarPdf(pdfBase64) {debugger
            this.$store.commit("ejecutarLoading");
            let message= {
                content: "Plantilla",
                src: "",
                type: "image",
                uploaded: false,
                viewed: false
            }
            this.enviarImagen(message, "application/pdf", "Plantilla.pdf", pdfBase64);
            $('#popupPlantilla').modal('hide');
        }
    }
}