import {MESSAGE_ES} from './mensajes_es';
import {MESSAGE_EN} from './mensajes_en';
import {MESSAGE_FIELDS_ES} from './mensajes_fields_es';
import {MESSAGE_FIELDS_EN} from './mensajes_fields_en';
import es from "vee-validate/dist/locale/es.json";
import en from "vee-validate/dist/locale/en.json";

export const messages = {
    es: {
        message: MESSAGE_ES,
        fields: MESSAGE_FIELDS_ES,
        validation: es.messages
    },
    en: {
        message: MESSAGE_EN,
        fields: MESSAGE_FIELDS_EN,
        validation: en.messages
    }
}