import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import MenuPrincipal from '../views/MenuPrincipal.vue'
import Login from '@/views/seguridad/Login.vue'
import RecuperarContrasena from '@/views/seguridad/recuperarContrasena/Recuperar_contrasena.vue'
import NotFound from '../views/NotFound.vue'
import { menuPrincipalFeatures } from './menu_principal_routes'

import Pruebas from '@/views/Pruebas.vue'
import Profile from '../views/Profile.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/recuperar-contrasena',
		name: 'recuperar-contrasena',
		component: RecuperarContrasena
	},
	{
		path: '/menuPrincipal',
		name: 'menuPrincipal',
		component: MenuPrincipal,
		children: menuPrincipalFeatures,
		meta: { requiresAuth: true }
	},
	{
		path: '/pruebas',
		name: 'pruebas',
		component: Pruebas
	},
	{
		path: '/profile',
		name: 'profile',
		component: Profile
	},
	{
		path: "/:catchAll(.*)",
		component: NotFound
	},

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach(async (to, from, next) => {
	if (to.meta.requiresAuth) {
		var toName = to.name.split("_")[0];
		var recurso = router.app.$store.getters.getListRecursos[toName];

		if (router.app.$store.getters.getIdOrganizacion != ""
			&& recurso
			&& router.app.$store.getters.getListRecursos[toName].read
		) {
			next()
		} else {
			next('/login')
		}
	} else {
		next()
	}

});

router.beforeResolve((to, from, next) => {
	// If this isn't an initial page load.
	if (to.name) {
		// Start the route progress bar.
		store.commit("ejecutarLoading");
	}
	next()
});

router.afterEach((to, from) => {
	// Complete the animation of the route progress bar.
	store.commit("detenerLoading");
});

export default router

