<template>
    <div>
		<div id="page-top">
			<div id="wrapper">
				<div class="d-flex flex-column" id="content-wrapper">
					<div id="content" style="margin-top: 5rem">
						<nav class="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar fixed-top">
							<div class="container">
								<div class="sidebar-brand-icon; col; align-items:center; display:flex;">
									<img 	class="flex-grow-1 bg-login-image"
                                        	style="width: 12%; margin-left:20%; "
                                        	src="@/assets/img/logoFourBrains.png"
                                    />
								</div>
								<h1 class="col" style="font-size:2vw;">SUITE BRAINS</h1>
								
								<!-- <div class="collapse navbar-collapse" id="navbarNav">
									<ul class="navbar-nav ml-auto">
										<li class="nav-item"><a class="nav-link active" href="index.html">Home</a></li>
										<li class="nav-item"><a class="nav-link" href="projects-grid-cards.html">Projects</a></li>
										<li class="nav-item"><a class="nav-link" href="cv.html">CV</a></li>
										<li class="nav-item"><a class="nav-link" href="hire-me.html">Hire me</a></li>
									</ul>
								</div> -->
								<div class="btn btn-primary btn-lg border rounded-pill col; align-items:center; display:flex;" style="background:#6ab794; a:link{color:white}">
									<router-link :to="'/login'" class="small" style="color:white">
										{{ $t("message.home.bienvenidos") }}
									</router-link>

									<br />

									<!-- <button @click="logOut()">LogOut</button> -->
								</div>
							</div>
						</nav>

						<!-- Contenido -->
						<div class="container-fluid" style="background-color:white">
							<main class="page lanidng-page">
								<!-- Background video -->
<div class="video-background-holder ">
  	<div class="video-background-overlay"></div>
  		<video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        	<source src="https://drive.google.com/uc?export=view&id=1BGOfP1Fb0Hp2IOUYjXEFaPlxx8o5Q9q8" type="video/mp4">
    	</video>
  			<div class="video-background-content container h-100">
    			<div class="d-flex h-100 text-center align-items-center">
      				<div class="w-100 text-white">
        				<h1 class="display-4" style="color:black; text-transform:uppercase; font-size:4vw;">una nueva forma de administrar tus clientes</h1>
        				<p class="lead mb-0" style="color:black; ">By Four Brains</p>
        				<p class="lead"> <a href="#masinfo" class="text-white btn btn-primary btn-lg border rounded-pill" style="background:#4e73df"> <u>MÁS INFORMACIÓN</u></a></p>
      				</div>
    			</div>
  			</div>
		</div>
								<!-- End -->

 <div id="masinfo"  class="col-lg-6" style=" padding-top:3%; padding-bottom:0%">
                <div class="effect-5 card shadow" >
                    <div class="card-body p-5">
                        <h2 style="text-align: center" class="h5">"Suite brains es más que solo una herramienta de software, es una oportunidad para fortalecer las relaciones con tus clientes y llevar tu negocio al siguiente nivel"</h2>
                    </div>
                </div>
            </div>

	
	<section class="py-5 header">
    <div class="container py-4">
        <div class="row">
            <div class="col-md-3">
                <!-- Tabs nav -->
                <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link mb-3 p-3 shadow active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i class="fa fa-user-circle-o mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Canales mensajería</span></a>

                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <i class="fa fa-calendar-minus-o mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Seguimiento de conversaciones</span></a>

                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <i class="fa fa-star mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Notificaciones y recordatorios</span></a>

                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                        <i class="fa fa-check mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Análisis de datos</span></a>
                    </div>
            </div>


            <div class="col-md-9">
                <!-- Tabs content -->
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade shadow rounded bg-white show active p-5" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <h4 class="font-italic mb-4">Todas tus conversaciones en un solo lugar</h4>
                        <p class="font-italic text-muted mb-2">la integración con aplicaciones de mensajería es una característica clave para mejorar la eficiencia de la comunicación y la relación con los clientes, así como para automatizar procesos y tomar decisiones informadas sobre el negocio.<br><br>La integración con aplicaciones de mensajería también permite automatizar algunos procesos, lo que permite una comunicación más rápida y eficiente.</p>
                    </div>
                    
                    <div class="tab-pane fade shadow rounded bg-white p-5" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <h4 class="font-italic mb-4">Organiza, supervisa y encuentra</h4>
                        <p class="font-italic text-muted mb-2">La característica de seguimiento de las conversaciones permite registrar y almacenar todas las interacciones con los clientes, ya sea mediante correo electrónico, mensajería instantánea, chats, o llamadas telefónicas. Esto permite tener un registro completo y detallado de las conversaciones, lo que es útil para identificar patrones y tendencias en las interacciones con los clientes.<br><br>Permite registrar y rastrear las conversaciones con los clientes, lo que facilita el seguimiento y la gestión de las interacciones.</p>
                    </div>
                    
                    <div class="tab-pane fade shadow rounded bg-white p-5" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <h4 class="font-italic mb-4">No olvides lo importante</h4>
                        <p class="font-italic text-muted mb-2">permite configurar notificaciones y recordatorios automatizados para tareas importantes. Esto puede incluir recordatorios para llamadas de seguimiento, cumpleaños de clientes, y vencimiento de contratos. Estos recordatorios automatizados ayudan a mantener una buena relación con los clientes y a no perder oportunidades de negocio.</p>
                    </div>
                    
                    <div class="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                        <h4 class="font-italic mb-4">Enfoca tus metas</h4>
                        <p class="font-italic text-muted mb-2">El análisis de datos ayuda a obtener información valiosa sobre los clientes, como sus necesidades, preferencias, y tendencias. También se pueden identificar patrones y tendencias en las interacciones con los clientes, lo que ayuda a mejorar la estrategia de marketing y ventas.<br><br>Además, permite generar informes y estadísticas, esto permite hacer un seguimiento de las conversaciones, identificar oportunidades de negocio, y evaluar el desempeño del equipo de ventas y atención al cliente.<br><br>La función de análisis de datos es una herramienta poderosa para mejorar la eficiencia de la comunicación y la relación con los clientes, así como para tomar decisiones informadas sobre el negocio.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
           
<section class="py-4 py-xl-5">
        <div class="container">
            <div class="bg-light border rounded border-light overflow-hidden">
                <div class="row no-gutters">
                    <div class="col-md-6">
                        <div class="text-white p-4 p-md-5">
                            <h2 class="font-weight-bold text-dark mb-3">CONTACTA A NUESTRO EQUIPO DE VENTAS</h2>
                            <p class="mb-4 text-dark">Obten un mes de prueba para que veas lo que la suite puede hacer por tu negocio</p><a class="btn btn-primary btn-lg mr-2" role="button" href="https://wa.link/4lc9jn" target="_blank">CONTACTAR</a>
                        </div>
                    </div>
                    <div class="col-md-6 order-first order-md-last" style="min-height: 250px;"><video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" width="560" height="315">
        	<source src="https://drive.google.com/uc?export=view&id=17D5pPMuVQVi23ExKDiGl48kDueRYyQWp" type="video/mp4">
    	</video></div>
                </div>
            </div>
        </div>
    </section>


	<!-- Background video -->
<div class="video-background-holder">
  	<div class="video-background-overlay"></div>
  		<video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        	<source src="https://drive.google.com/uc?export=view&id=1jt5DmL0mBryhT9Kb0eBtLSlwKD-kWsMC" type="video/mp4">
    	</video>
  			<div class="video-background-content container h-100">
    			<div class="d-flex h-100 text-center align-items-center">
      				<div class="w-100 text-white">
        				<p class="lead mb-0" style="color:black; text-transform:uppercase ">Una correcta administración de la información es la clave para construir relaciones sólidas y duraderas con los clientes y aumentar las ventas</p>
      				</div>
    			</div>
  			</div>
		</div>
								<!-- End -->


							
							

								
									
							</main>
							
						</div>
							
							
					</div>

					<footer-component/>
					
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import FooterComponent from "../components/FooterComponent.vue";
export default {
  name: "Home",
  components: {
    FooterComponent,
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>
