import axios from 'axios';
import store from '../store'
import firebase from 'firebase/app';

// function getUrl(){
// 	let url;
// 	try {
// 		webpackHotUpdate
// 		url = 'http://localhost:3000/ws/';
// 	} catch (error) {
// 		url = 'https://back.suitebrains.com/ws/';
// 	}
	
// 	return url;
// }

function getUrl(){
	let url;

	if (window.location.host == 'localhost:8080') {
		url = 'http://localhost:3000/ws/';
	} else if (window.location.host == 'siutebrainsprueba.web.app') {
		url = 'https://back.suitebrains.com/pruebas/ws/';
	} else if (window.location.host == 'suitebrains.com') {
		url = 'https://back.suitebrains.com/ws/';
	}
	
	return url;
}

const DIGITAL_OCEAN_SERVICES = axios.create({
	baseURL: getUrl(),
	// auth:'eyJhbGciOiJSUzI1NiIsImtpZCI6ImI5ODI2ZDA5Mzc3N2NlMDA1ZTQzYTMyN2ZmMjAyNjUyMTQ1ZTk2MDQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vc2l1dGVicmFpbnNwcnVlYmEiLCJhdWQiOiJzaXV0ZWJyYWluc3BydWViYSIsImF1dGhfdGltZSI6MTYwNzEzMzUwNiwidXNlcl9pZCI6InlmVVpNSjVMTjFXTmhqbjc4ajJqS0VRbDcybjEiLCJzdWIiOiJ5ZlVaTUo1TE4xV05oam43OGoyaktFUWw3Mm4xIiwiaWF0IjoxNjA3MTMzNTA2LCJleHAiOjE2MDcxMzcxMDYsImVtYWlsIjoieWFsb3BlekBvdXRsb29rLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJ5YWxvcGV6QG91dGxvb2suY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.T429W1IvHBUGfV6Ty-rV_C2xgBR2dJ2L_Klb2KXbjEa1bLIxCGU4kRQMSTxiw7D6a79RQOMfoUAvBXSXSAV-o3Dk85rCtTUypovU5dzRa-2rxRDM81n40oEK4eRRP1cxhftRFQUmIiWNNwDgQMkoIIBrWeUx2TyybR58VCBwXLrRvJXmHrDAdASAKnl4jbrdQmeIJ9MFdN24fPa55ufvtaZwVhKYC8drng5xvF5OFW8zgiRRvEBebMc2WAnTRi4P_BBcgHpZWy1xVJe_pqrrssE3gFbeTxEjCHF-dBlbI7vEM1BapocHUJGa8jT7JPqHAHTpc8M9teyE3aUPIi2FqA',
	headers: {
		"Content-Type":"application/json",
		"Access-Control-Allow-Origin": "*",
		"Authorization": "Bearer " + store.getters.getToken
	}

})

const FIREBASE_SERVICES = axios.create({
	baseURL: '',
	// auth:'eyJhbGciOiJSUzI1NiIsImtpZCI6ImI5ODI2ZDA5Mzc3N2NlMDA1ZTQzYTMyN2ZmMjAyNjUyMTQ1ZTk2MDQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vc2l1dGVicmFpbnNwcnVlYmEiLCJhdWQiOiJzaXV0ZWJyYWluc3BydWViYSIsImF1dGhfdGltZSI6MTYwNzEzMzUwNiwidXNlcl9pZCI6InlmVVpNSjVMTjFXTmhqbjc4ajJqS0VRbDcybjEiLCJzdWIiOiJ5ZlVaTUo1TE4xV05oam43OGoyaktFUWw3Mm4xIiwiaWF0IjoxNjA3MTMzNTA2LCJleHAiOjE2MDcxMzcxMDYsImVtYWlsIjoieWFsb3BlekBvdXRsb29rLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJ5YWxvcGV6QG91dGxvb2suY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.T429W1IvHBUGfV6Ty-rV_C2xgBR2dJ2L_Klb2KXbjEa1bLIxCGU4kRQMSTxiw7D6a79RQOMfoUAvBXSXSAV-o3Dk85rCtTUypovU5dzRa-2rxRDM81n40oEK4eRRP1cxhftRFQUmIiWNNwDgQMkoIIBrWeUx2TyybR58VCBwXLrRvJXmHrDAdASAKnl4jbrdQmeIJ9MFdN24fPa55ufvtaZwVhKYC8drng5xvF5OFW8zgiRRvEBebMc2WAnTRi4P_BBcgHpZWy1xVJe_pqrrssE3gFbeTxEjCHF-dBlbI7vEM1BapocHUJGa8jT7JPqHAHTpc8M9teyE3aUPIi2FqA',
	headers: {
		"Content-Type":"application/json",
        "Access-Control-Allow-Origin": "*"
	}
})

// Interceptor de solicitudes para establecer el token en los encabezados
FIREBASE_SERVICES.interceptors.request.use(async (config) => {
	const user = firebase.auth().currentUser;
	if (user) {
	  const token = await user.getIdToken(true);
	  config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
  });

export {DIGITAL_OCEAN_SERVICES, FIREBASE_SERVICES}


// SERVICIOS.interceptors.request.use(
// 	config => {
// 		store.commit("ejecutarLoading");
// 		// this.ejecutarLoader(config.url);

// 		return config
// 	},
// 	error => {
// 		// var url = error.config.url;
// 		// var baseUrl = error.config.baseURL;
// 		store.commit("detenerLoading");
// 		// this.detenerLoader(url.split(baseUrl)[1]);

// 		return Promise.reject(error)
// 	}
// );

// SERVICIOS.interceptors.response.use(
// 	response => {
// 		// var url = response.config.url;
// 		// var baseUrl = response.config.baseURL;
// 		store.commit("detenerLoading");
// 		// this.detenerLoader(url.split(baseUrl)[1]);

// 		return response
// 	},
// 	error => {
// 		// var url = error.config.url;
// 		// var baseUrl = error.config.baseURL;
// 		store.commit("detenerLoading");
// 		// this.detenerLoader(url.split(baseUrl)[1]);
// 		// this.$validator.validateAll();

// 		return Promise.reject(error)
// 	}
// );