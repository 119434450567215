import { firebase, auth } from '@/firebase/firebaseInit.js';
import draggable from "vuedraggable";
import rawDisplayer from "vuedraggable";
import accionesComp from "@/components/AccionesComponent";

export default {
    name: 'TaskComp',
    components: {
        draggable,
        rawDisplayer,
        accionesComp
    },
    data() {
        return {
            listUsuarios: [],
            listEtapas: [
                {
                    nombre: this.$t('message.tareas.etapaPendientes'),
                    listTareas: []
                },
                {
                    nombre: this.$t('message.tareas.etapaHaciendo'),
                    listTareas: []
                },
                {
                    nombre: this.$t('message.tareas.etapaFinalizado'),
                    listTareas: []
                }
                
            ],
            filtros: {idUsuarioAsesor:''},
            tarea: null,
            tareaTmp: {fechaCreacion: ''}
        }
    },
    mounted() {
        this.inicializar();
        this.listenerPopup();
    },

    beforeDestroy() {

    },

    watch: {
        'filtros.idUsuarioAsesor': function (uid, oldVal) {
            this.$store.commit("ejecutarLoading");
            this.consultar(uid);
        }
    },

    methods: {
        listenerPopup() {
            $('#popupTareas').on('shown.bs.modal', function (e) {
                this.consultar(auth.currentUser.uid);
            }.bind(this))
    
        },

        inicializar() {
            this.$store.commit("ejecutarLoading");

            firebase.auth().onAuthStateChanged((user) =>{
                if (!this.$store.getters.getSesionIniciada) {
                    return;
                }
                if(user){
                    this.filtros.idUsuarioAsesor = user.uid;
                    this.loadUsuarios(user.uid);

                }
            });

        },

        loadUsuarios(uid) {
            var collection = this.getCollectionListFirestore('usuarios');
            collection.get()
                .then((querySnapshot) => {
                    this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });

                    querySnapshot.forEach((doc) => {
                        this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        collection = collection.where('id', '==', uid);
                        collection.get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function (errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );
                    }.bind(this)
                );
        },

        async consultar(uid) {
            var uidTmp = uid;
            if (!uidTmp) {
                uidTmp = auth.currentUser.uid
            }

            this.listEtapas = [
                {
                    nombre: this.$t('message.tareas.etapaPendientes'),
                    listTareas: []
                },
                {
                    nombre: this.$t('message.tareas.etapaHaciendo'),
                    listTareas: []
                },
                {
                    nombre: this.$t('message.tareas.etapaFinalizado'),
                    listTareas: []
                }
                
            ];

            await this.consultaConAsesor(uidTmp);
            if (!this.$store.getters.isAdmin && !this.filtros.idUsuarioAsesor && this.$store.getters.getIdGrupoInfomacion) {
                await this.consultaSinAsesor(uidTmp);
            }  
        },

        async consultaConAsesor(uid){
            let collection = this.getCollectionListFirestore("tareas", null, true);
            collection = this.getCollectionFiltered(collection, uid, true);
            await this.getTareas(collection);
        },

        async consultaSinAsesor(uid){
            let collection = this.getCollectionListFirestore("tareas", null, true);
            collection = this.getCollectionFiltered(collection, uid, false);
            await this.getTareas(collection);
        },

        getCollectionFiltered(pCollection, uid, sinAsesor) {
            var collection = pCollection;
            // collection = collection.where('idUsuarioAsesor', '==', uid);
            
            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (sinAsesor) {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                } else {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);
                }

            } else {
                if (this.filtros.idUsuarioAsesor) {
                    collection = collection.where("idUsuarioAsesor", "==", this.filtros.idUsuarioAsesor);
                }
            }
    
            return collection;
        },

        async getTareas(collection) {
            await collection.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var tarea = doc.data();
                    tarea["id"] = doc.id;
                    tarea["docRef"] = doc.ref;
                    tarea["descripcionTmp"] = tarea.descripcion;

                    if (tarea.idEtapa != undefined) {
                        this.listEtapas[tarea.idEtapa].listTareas.push(tarea);
                    }
                });

                this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    if (error.code == "permission-denied") {
                        console.log("Permisos insuficientes sobre tareas");
                    } else {
                        console.log("Error getting tareas:", error);
                    }
                    
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );
        },

        addTarea(indexEtapa) {
            this.$store.commit("ejecutarLoading");
            let collection = this.getCollectionAddFirestore("tareas");
            let tarea = {
                idEtapa: indexEtapa,
                descripcion: '',
                fechaCreacion: new Date(),
                idUsuarioAsesor: auth.currentUser.uid
            };

            collection.add(tarea).then(
                function(docRef) {
                    tarea["id"] = docRef.id;
                    tarea["docRef"] = docRef;
                    tarea["descripcionTmp"] = tarea.descripcion;
                    tarea.fechaCreacion.seconds = tarea.fechaCreacion.getTime() / 1000;

                    this.listEtapas[tarea.idEtapa].listTareas.push(tarea);

                    this.$store.commit("detenerLoading");

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding tarea: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        editarTarea(tarea) {
            this.tarea = tarea;
            this.tareaTmp = {
                idEtapa: tarea.idEtapa,
                fechaCreacion: this.formatSecondsToDate(tarea.fechaCreacion.seconds, "YYYY-MM-DDTHH:mm"),
                descripcion: tarea.descripcion,
                idUsuarioAsesor: tarea.idUsuarioAsesor
            };

        },

        guardarTarea() {
            this.$store.commit("ejecutarLoading");
            let fechaCreacionTmp = this.tareaTmp.fechaCreacion;
            this.tareaTmp.fechaCreacion= this.$moment(this.tareaTmp.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate()

            this.tarea.docRef.set(this.tareaTmp, { merge: true }).then(
                function() {
                    this.tarea.idEtapa= this.tareaTmp.idEtapa,
                    this.tarea.descripcion= this.tareaTmp.descripcion;
                    this.tarea.descripcionTmp= this.tareaTmp.descripcion;
                    this.tarea.idUsuarioAsesor= this.tareaTmp.idUsuarioAsesor;
                    this.tarea.fechaCreacion= {
                        nanoseconds: 0,
                        seconds: this.tareaTmp.fechaCreacion.getTime()/1000
                    }

                    this.$store.commit("detenerLoading");

                }.bind(this)
            ).catch(
                function(error) {
                    this.tareaTmp.fechaCreacion=fechaCreacionTmp;
                    console.error("Error Updating document tarea: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        modificarDescripcionTarea(tarea) {
            if (tarea.descripcion != tarea.descripcionTmp) {
                this.$store.commit("ejecutarLoading");
                tarea.docRef.set({
                    descripcion: tarea.descripcion
                }, { merge: true }).then(
                    function() {
                        // console.log("Document Update: ");
                        tarea.descripcionTmp = tarea.descripcion;
                        this.$store.commit("detenerLoading");
    
                    }.bind(this)
                ).catch(
                    function(error) {
                        console.error("Error Updating document tarea: ", error);
                        this.$store.commit("detenerLoading");
    
                    }.bind(this)
                );
            }

        },

        onMove({ relatedContext, draggedContext }) {
            // console.log(draggedContext.element);
            // console.log(relatedContext.component.$el.id);
            this.$store.commit("ejecutarLoading");

            var tarea = draggedContext.element;
            tarea.idEtapa = Number(relatedContext.component.$el.id);
            
            tarea.docRef.set({
                idEtapa: tarea.idEtapa
            }, { merge: true }).then(
                function() {
                    // console.log("Document Update: ");
                    this.$store.commit("detenerLoading");

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document tarea: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

            return true;
            

            // const relatedElement = relatedContext.element;
            // const draggedElement = draggedContext.element;
            // return (
            //   (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            // );
        },

        eliminar(tarea) {
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: { no: this.$t('message.general.no'), yes: this.$t('message.general.si') },
                callback: confirm => {
                    if (confirm) {
                        this.eliminarTarea(tarea);
                    }
                }
            });

        },

        eliminarTarea(tarea) {
            this.$store.commit("ejecutarLoading");

            if (tarea) {
                tarea.docRef.delete().then(() => {
                    let index = this.listEtapas[tarea.idEtapa].listTareas.findIndex(element => element.id === tarea.id);
                    if (index >= 0) {
                        this.listEtapas[tarea.idEtapa].listTareas.splice(index, 1);
                    }

                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        },

        getNombreUsuario(idUsuarioAsesor) {
            let usuario = this.listUsuarios.find(usuario => usuario.id==idUsuarioAsesor);
            if (usuario) {
                return usuario.descripcion;
            }
        },

        log: function (evt) {
            // window.console.log(evt);
        },

    }
};