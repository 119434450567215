import accionesComp from "@/components/AccionesComponent";

export default {
    props: {
        pGrupoInformacion: {type: Object, required: false}
    },
    components: {
        accionesComp,
    },
    data() {
        return {
            usuario: {},
            grupoDeInformacion: {
                nombre: '',
                idGrupoInformacion : ''
            }            
        }
    },

    created() {
       this.inicializar();

    },

    watch: {
        '$store.state.loading': function (val, oldVal) {
            if(val == false) {
                this.$refs.form.validate();

            }
        }
    },
    
    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoading");
            this.loadGrupoInformacion();
        },

        loadGrupoInformacion() {
            if (this.pGrupoInformacion) {
                this.grupoDeInformacion = this.pGrupoInformacion;
            }
            setTimeout(function(){
                this.$store.commit("detenerLoading");
            }.bind(this), 1000);
        },
       
        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.grupoDeInformacion.docRef) {
                        this.actualizarGrupoDeInformacion();
    
                    } else {
                        this.crearGrupoDeInformacion();
    
                    }
                }
            });
        },

        
        crearGrupoDeInformacion() {
            let gruposDeInformacionCollection = this.getCollectionAddFirestore("gruposDeInformacion");

            gruposDeInformacionCollection.add({
                nombre: this.grupoDeInformacion.nombre
            }).then(
                function(docRef) {
                    this.grupoDeInformacion.docRef= docRef;
                    this.grupoDeInformacion.idGrupoInformacion = docRef.id;
                    this.actualizarGrupoDeInformacion();
                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        actualizarGrupoDeInformacion() {
            this.grupoDeInformacion.docRef.set({
                nombre: this.grupoDeInformacion.nombre,
                idGrupoInformacion: this.grupoDeInformacion.idGrupoInformacion
  
            }).then(
                function() {
                    // console.log("Document Update: ");
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );


        }

    }

}