import FirestoreUtils from "@/classes/FirestoreUtils";
// import store from '@/store'

export default class PersonasImpl {
    constructor ( ){

    }
    
    static async getPersonaById(idPersona) {
        let persona = null;
        
        if (idPersona) {
            let doc = await FirestoreUtils.getDocumentFirestore("personas", idPersona, false);
                
            if (doc) {
                persona = doc.data();
                persona["id"] = doc.id;
                persona["docRef"] = doc.ref;
 
            }
        }

        return persona;
    }

}