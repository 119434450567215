import accionesComp from "@/components/AccionesComponent";
import mensajeriaComp from "@/components/mensajeria/MensajeriaComponent.vue";
import personaComp from "@/views/personas/Personas_det.vue";
import { firebase, auth } from '@/firebase/firebaseInit.js';

export default {
    components: {
        accionesComp,
        mensajeriaComp,
        personaComp
    },
    data() {
        return {
            listPersonas: [],
            // listGruposInformacion: [],
            listUsuarios: [],
            filtros: {
                idTipoDocumento: 0,
                idGrupoInformacion: this.$store.getters.getIdGrupoInfomacion,
                idOrden: 1,
                activo: true,
                idPais: this.$store.state.constantes.ubicacionesGeograficas.PAIS_INICIAL,
                idDepartamento: 0,
                idCiudad: 0,
                idTipoPersona: this.$store.state.constantes.TIPO_PERSONA_DEFECTO,
                idUsuarioAsesor: '',
                etiquetas:[]
            },
            mostrarCompMsg: false,
            mostrarCompPersona: true,
            personaMsg:{},
            persona:null,
            listenerPersonasStop: null,
            listenerPersonasSnStop: null,
            listEtiquetasPersonas: [],
            limpiarDataTable: true,
            editandoPersonas: false,
            countRecargarDatatable: 0,
            mostrarRefrescar:false

        }
    },

    watch: {
        'filtros.idPais': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                this.filtros.idDepartamento = 0;
                this.filtros.idCiudad = 0;
            }
        },
        'filtros.idDepartamento': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                this.filtros.idCiudad = 0;
            }
        }
    },

    computed: {
    },

    beforeDestroy() {
        if (this.listenerPersonasStop) {
            this.listenerPersonasStop();
        }
        if (this.listenerPersonasSnStop) {
            this.listenerPersonasSnStop();
        }     

    },
    created() {
        this.inicializar();

    },
    mounted(){
        // this.validadorFechaInicioFechaFinHandler('inputStartdate','inputEnddate',1);
        // this.$refs.personaComp.$on('addNewPersonToList', this.addNewPersonToList);
        // this.$root.$on('addNewPersonToList', this.addNewPersonToList);
    },
    methods: {
        prueba() {
            
        },

        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 3);

            this.setCountRecargarDatatable();
            // this.loadGruposInformacion();
            this.loadEtiquetasPersonas();
            firebase.auth().onAuthStateChanged((user) =>{
                if (!this.$store.getters.getSesionIniciada) {
                    return;
                }
                if(user){
                    // if (!this.$store.getters.isAdmin) {
                    //     this.filtros.idUsuarioAsesor = user.uid;
                    // }

                    this.loadUsuarios(user.uid);
                    this.consultar(user.uid, false);

                }
            });
            
            
        },

        setCountRecargarDatatable() {
            if (!this.$store.getters.isAdmin) {
                this.countRecargarDatatable = 2
            } else {
                this.countRecargarDatatable = 1;
            } 
        },

        limpiarFiltrosExeptoidTipoDocumentoYNumeroIdentificacionSinConsultar() {
            this.filtros.nombres = '';
            this.filtros.apellidos = '';
            this.filtros.idPais = this.$store.state.constantes.ubicacionesGeograficas.PAIS_INICIAL;
            this.filtros.idDepartamento = 0;
            this.filtros.idCiudad = 0;
            this.filtros.idTipoPersona = this.$store.state.constantes.TIPO_PERSONA_DEFECTO;
            this.filtros.idUsuarioAsesor = '';
            this.filtros.etiquetas=[];
            this.mostrarRefrescar = false;

            // if (!this.$store.getters.isAdmin) {
            //     this.filtros.idUsuarioAsesor = auth.currentUser.uid;
            // }

            this.setCountRecargarDatatable();
        },

        limpiarFiltrosSinConsultar() {
            this.filtros.idTipoDocumento = 0;
            this.filtros.numeroIdentificacion = '';
            this.filtros.nombres = '';
            this.filtros.apellidos = '';
            this.filtros.idPais = this.$store.state.constantes.ubicacionesGeograficas.PAIS_INICIAL;
            this.filtros.idDepartamento = 0;
            this.filtros.idCiudad = 0;
            this.filtros.idTipoPersona = this.$store.state.constantes.TIPO_PERSONA_DEFECTO;
            this.filtros.idUsuarioAsesor = '';
            this.filtros.etiquetas=[];
            this.mostrarRefrescar = false;

            // if (!this.$store.getters.isAdmin) {
            //     this.filtros.idUsuarioAsesor = auth.currentUser.uid;
            // }

            this.setCountRecargarDatatable();
        },

        limpiarFiltrosExceptoRangoFechasFilter(){
            this.limpiarFiltrosSinConsultar();
            this.$store.commit("ejecutarLoading");
            this.consultar(null, false);
        },

        limpiarFiltros() {
            this.limpiarFiltrosSinConsultar();
            this.setDefaultDateRangeFilter();
            this.$store.commit("ejecutarLoading");
            this.consultar(null, false);
        },

        loadEtiquetasPersonas() {
            this.getCollectionListFirestore('tablasTipo', null, true).doc("listEtiquetasPersonas").get().then(
                function(doc) {
                    if (doc.exists) {
                        this.listEtiquetasPersonas = doc.data().valor;
                    } else {
                        console.log("No such document listEtiquetasPersonas!");
                    }
                    this.$store.commit("detenerLoading");
                }.bind(this) 
            ).catch((error) => {
                this.$store.commit("detenerLoading");
                console.log("Error configurando la tabla tipo listEtiquetasPersonas:", error);
            });
        },

        loadUsuarios(uid) {
            let collection = this.getCollectionListFirestore('usuarios');

            collection.get()
                .then((querySnapshot) => {
                    this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });
                    querySnapshot.forEach((doc) => {
                        this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        collection = collection.where('id', '==', uid);
                        collection.get()
                            .then((querySnapshot) => {
                                this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });
                                querySnapshot.forEach((doc) => {
                                    this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function (errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );
                    }.bind(this)
                );
        },

        consultaFromFiltros(invalid) {
            
            if(this.filtros.nombres && this.filtros.apellidos){
                $("#dataTable").DataTable().column(0).search(this.filtros.nombres).column(1).search(this.filtros.apellidos).draw();
             }else if(this.filtros.nombres){
                 $("#dataTable").DataTable().column(0).search(this.filtros.nombres).draw();
             }else if(this.filtros.apellidos){
                 $("#dataTable").DataTable().column(1).search(this.filtros.apellidos).draw();
             } else {
                this.$store.commit("ejecutarLoading");
                this.mostrarRefrescar = false;
                this.setCountRecargarDatatable();
                this.consultar(null, invalid);
             }

        },

        async consultar(uid, invalid) {
            if (invalid) {
                return;
            }

            // this.$emit('pruebita');
            this.destroyDataTable('#dataTable');
            this.limpiarDataTable= false;
            this.listPersonas = [];

            await this.consultaConAsesor(uid);
            if (!this.$store.getters.isAdmin && !this.filtros.idUsuarioAsesor && this.$store.getters.getIdGrupoInfomacion) {
                await this.consultaSinAsesor(uid);
            }
            
        },

        async consultaConAsesor(uid){
            let collection;

            /* Obtenemos un listado de personas para el escuchador que esta pendiente
            del cambio de nombres de la persona o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('personas');
            collection = this.getCollectionFiltered(collection, uid, true);

            if (this.listenerPersonasStop) {
                this.listenerPersonasStop();
            }
            this.listenerPersonasStop = await this.getListenerPersonas(collection);

        },

        async consultaSinAsesor(uid){
            let collection;
            /* Obtenemos un listado de personas para el escuchador que esta pendiente
            del cambio de nombres de la persona o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('personas');
            collection = this.getCollectionFiltered(collection, uid, false);

            if (this.listenerPersonasSnStop) {
                this.listenerPersonasSnStop();
            }
            this.listenerPersonasSnStop = await this.getListenerPersonas(collection);

        },

        getCollectionFiltered(pCollection, uid, incluirAsesor) {
            let collection = pCollection;

            if (this.filtros.numeroIdentificacion) {
                collection = collection.where("numeroIdentificacion", "==", this.filtros.numeroIdentificacion);

                if (this.filtros.idTipoDocumento) {
                    collection = collection.where("idTipoDocumento", "==", this.filtros.idTipoDocumento);
                }

            } else {
                if(!this.$communicates.filtros.startdate || !this.$communicates.filtros.enddate){
                    return false;

                } else {
                    let startdateParts =this.$communicates.filtros.startdate.split('-');
                    let enddateParts = this.$communicates.filtros.enddate.split('-');
                    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
                    // January - 0, February - 1, etc.
                    let start = new Date(startdateParts[0], startdateParts[1] - 1, startdateParts[2]); 
                    let end = new Date(enddateParts[0], enddateParts[1] - 1, enddateParts[2], 23,59,59); 

                    collection = collection.where("fechaCreacion", ">=", start.getTime()).where("fechaCreacion", "<=", end.getTime());
                }

                if (this.filtros.idPais) {
                    collection = collection.where("idPais", "==", this.filtros.idPais);
                }

                if (this.filtros.idDepartamento) {
                    collection = collection.where("idDepartamento", "==", this.filtros.idDepartamento);
                }

                if (this.filtros.idCiudad) {
                    collection = collection.where("idCiudad", "==", this.filtros.idCiudad);
                }

                if (this.filtros.idTipoPersona) {
                    collection = collection.where("idTipoPersona", "==", this.filtros.idTipoPersona);
                }

                collection = collection.where("temporal", "==", false);
            }

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (incluirAsesor) {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);
                    
                } else {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                }
            } else {
                if (this.filtros.idUsuarioAsesor) {
                    collection = collection.where("idUsuarioAsesor", "==", this.filtros.idUsuarioAsesor);
                }
            }
    
            return collection;
        },

        async getListenerPersonas(collection) {
            return await collection.onSnapshot((snapshot) => {          
                if(this.countRecargarDatatable > 0 || this.editandoPersonas){
                    this.cleanDatatable();
                }

                for (const change of snapshot.docChanges()) {
                    let persona = change.doc.data();
                    persona["id"] = change.doc.id;
                    persona["docRef"] = change.doc.ref;

                    /* No se puede agregar si el usuario no es el que en realidad lo esta agregando */
                    if ((this.countRecargarDatatable > 0 || this.editandoPersonas) && change.type === "added") {
                        if ((this.filtros.idTipoDocumento || this.filtros.numeroIdentificacion) || 
                            (this.isInFilters(persona))
                        ){
                            this.listPersonas.push(persona);
                        }

                    } else if (change.type === "modified") {
                        let personaDestino = this.listPersonas.find((element) => element.id == persona.id);
                        
                        if (personaDestino) {
                            this.setPersonaProperties(personaDestino, persona);
                        }
                    } else if (this.editandoPersonas && change.type === "removed") {
                        /* No se puede eliminar si el usuario no es el que en realidad lo esta eliminando */
                        let indexPersona = this.listPersonas.findIndex((element) => element.id == persona.id);
                        
                        if (indexPersona >= 0) {
                            this.listPersonas.splice(indexPersona, 1);
                        }
                    }

                };

                if(this.countRecargarDatatable > 0){
                    this.setDataTable(true, false);
                } else {
                    if (this.editandoPersonas) {
                        if(snapshot.docChanges()[0].type == "added"){
                            this.setDataTable(false, false);
                            this.setLastPageDataTable();
                        } else {
                            this.setDataTable(true, true);
                        }

                        $('#popupFiltro').modal('hide');
                        this.editandoPersonas = false;

                    } else if(snapshot.docChanges()[0].type == "modified"){
                        this.refreshTable();
                        
                    } else if (snapshot.docChanges().length > 0) {
                        this.mostrarRefrescar = true;

                    } else {
                        this.mostrarRefrescar = false;

                    }

                }

                this.countRecargarDatatable--;
                this.$store.commit("detenerLoading");
                
              

            });
        },

        isInFilters(persona) {
            let isInfilter = false;

            if (
                // (this.isInSearchTerms(this.filtros.nombres,persona.nombresSearchTermsArray)) &&
                // (this.isInSearchTerms(this.filtros.apellidos,persona.apellidosSearchTermsArray)) &&
                (this.isInSearchTermsV2(this.filtros.etiquetas.map(obj => obj.descripcion),persona.etiquetas)) 
            ) {
                isInfilter = true;
            }

            return isInfilter;
        },

        cleanDatatable() {
            if(this.limpiarDataTable){
                this.destroyDataTable('#dataTable');
            } else {
                this.limpiarDataTable= true;
            }
        },

        setDataTable(ordenar, mantenerPaginador) {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.applyDataTable('#dataTable', 4, null, ordenar ? null : [], mantenerPaginador);
            });
        },

        refreshTable() {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.drawTable('#dataTable', this.listPersonas);
            });
        },

        setLastPageDataTable() {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.setLastPageInDataTable('#dataTable');
            });
        },

        setPersonaProperties(personaDestino, personaOrigen) {
            if (personaDestino) {
                personaDestino.idTipoDocumento= personaOrigen.idTipoDocumento;
                personaDestino.numeroIdentificacion= personaOrigen.numeroIdentificacion;
                personaDestino.nombres= personaOrigen.nombres;
                personaDestino.nombresSearchTermsArray= this.getSearchTermsArray(personaOrigen.nombres);
                personaDestino.apellidos= personaOrigen.apellidos;
                personaDestino.apellidosSearchTermsArray= this.getSearchTermsArray(personaOrigen.apellidos);
                personaDestino.codArea= personaOrigen.codArea;
                personaDestino.telefonoMovil= personaOrigen.telefonoMovil;
                personaDestino.telefonoFijo= personaOrigen.telefonoFijo;
                personaDestino.email= personaOrigen.email;
                personaDestino.direccion= personaOrigen.direccion;
                personaDestino.idPais= personaOrigen.idPais;
                personaDestino.idDepartamento= personaOrigen.idDepartamento;
                personaDestino.idCiudad= personaOrigen.idCiudad;
                personaDestino.idTipoPersona= personaOrigen.idTipoPersona;
                personaDestino.idGenero= personaOrigen.idGenero; 
                personaDestino.idGrupoInformacion= personaOrigen.idGrupoInformacion;
                personaDestino.idUsuarioAsesor= personaOrigen.idUsuarioAsesor;
                personaDestino.fechaCreacion= personaOrigen.fechaCreacion;
                personaDestino.fechaCreacionOriginal= personaOrigen.fechaCreacionOriginal;
                personaDestino.temporal= personaOrigen.temporal;
                personaDestino.asesorMensajeria= personaOrigen.asesorMensajeria;
                personaDestino.etiquetas= personaOrigen.etiquetas;
                personaDestino.observaciones= personaOrigen.observaciones ? personaOrigen.observaciones : '';
                personaDestino.listPersonasAsociadas= personaOrigen.listPersonasAsociadas ? personaOrigen.listPersonasAsociadas : [];

            }
        },

        eliminar(persona) {
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: { no: this.$t('message.general.no'), yes: this.$t('message.general.si') },
                callback: confirm => {
                    if (confirm) {
                        this.eliminarPersona(persona);
                    }
                }
            });

        },

        eliminarPersona(persona) {
            this.$store.commit("ejecutarLoading");

            if (persona) {
                this.editandoPersonas = true;
                persona.docRef.delete().then(() => {
                    // this.consultar();
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    this.editandoPersonas = false;
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        },

        mostrarMensajeria(value, persona) {
            if (persona) {
                this.personaMsg = {
                    idPersona : persona.id,
                    codArea: persona.codArea ? persona.codArea : '+57',
                    telefonoMovil : persona.telefonoMovil,
                    idGrupoInformacion: persona.idGrupoInformacion,
                    idUsuarioAsesor: persona.idUsuarioAsesor,
                    nombres: persona.nombres + (persona.apellidos ? persona.apellidos : "")
                };
            } else {
                this.personaMsg = null;
                
            }
            
            this.mostrarCompMsg = value;
        },

        mostrarPersona(persona) {
            this.mostrarCompPersona = false;
            this.editandoPersonas = true;
            setTimeout(function () {
                this.persona = persona;
                this.mostrarCompPersona = true;
                setTimeout(function () {$('#popupPersonas').modal('show');}.bind(this), 100);
            }.bind(this), 200);
   
        }

    }

}