import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/es.js'
import { firebase, auth } from '@/firebase/firebaseInit.js';

export default {
    components: { 'vue-cal': VueCal },

    data: () => ({
        // splitDays:[{ id: 1, label: 'Dr 1' }, { id: 2, label: 'Dr 2' }, { id: 2, label: 'Dr 3' }],
        filtros: {
            activo: true,
            // idGrupoInformacion: this.$store.getters.getIdGrupoInfomacion
        },
        listGruposInformacion : [],
        listUsuarios: [],
        selectedDate: new Date(),
        selectedEvent:{
            nombre: '',
            numeroIdentificacion: '',
            telefonoMovil: '',
            email: '',
            fecha: '',
            title: '',
            estado: '',
            observaciones: ''
        },
        listEvents: [
            {
                start: '2022-05-11 10:35',
                end: '2022-05-11 11:30',
                title: 'Doctor appointment',
                // split: 1,
                content: '<i class="fas fa-home"></i>',
                class: 'leisure',
                deletable: false,
                resizable: false,
                draggable: false
            }
        ],
        calendarInitialized: false
    }),

    computed: {

    },

    watch: {

    },

    created() {
        this.inicializar();
    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 3);
            this.loadGruposInformacion();
            
            firebase.auth().onAuthStateChanged((user) =>{
                if (!this.$store.getters.getSesionIniciada) {
                    return;
                }
                if(user){
                    this.loadUsuarios(user.uid);
                    this.loadUsusarioAsesorFilter(user.uid);
                    this.calendarInitialized = true;

                }
            });
        },

        limpiarFiltros() {
            this.filtros.idGrupoInformacion= null;
            if (this.$store.getters.isAdmin) {
                this.filtros.idUsuarioAsesor='';
            } else {
                this.filtros.idUsuarioAsesor= auth.currentUser.uid;
            }
            this.consultar(this.filtros.fechaIni, this.filtros.fechaFin);
            
        },

        loadGruposInformacion() {
            setTimeout(function(){
                if (!this.$store.getters.getIdGrupoInfomacion) {
                    this.listGruposInformacion.push({id:'', nombre: this.$t('message.general.sinGrupo')});
                }

                this.getCollectionListFirestore('gruposDeInformacion').get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {  
                        this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function(error) {
                        console.log("Error getting grupos de informacion:", error);
                        this.$store.commit("detenerLoading");

                    }.bind(this)
                );
            }.bind(this), 1000);
            
        },

        loadUsuarios(uid) {
            var collection = this.getCollectionListFirestore('usuarios');
            collection.get()
                .then((querySnapshot) => {
                    this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });

                    querySnapshot.forEach((doc) => {
                        this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        collection = collection.where('id', '==', uid);
                        collection.get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function (errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );
                    }.bind(this)
                );
        },

        loadUsusarioAsesorFilter(uid){
            if (this.$store.getters.isAdmin) {
                this.filtros.idUsuarioAsesor='';
            } else {
                this.filtros.idUsuarioAsesor= uid;
            }
        },

        logEvents(nombre, event) {
            // this.splitDays = [{ id: 1, label: 'D..r 1' }, { id: 2, label: 'D..r 2' }, { id: 2, label: 'D..r 3' }];            
            // this.selectedEvent = event;

            // if (['ready','day','week', 'month', 'year', 'years'].includes(nombre)) {
            if (['ready','view-change'].includes(nombre)) {
                this.filtros.fechaIni = event.startDate;
                this.filtros.fechaFin = event.endDate;
                this.consultar(event.startDate, event.endDate);

            }

        },

        consultar(fechaInicio, fechaFin) {
            this.listEvents = [];
            let collection = this.getCollectionListFirestore('embudos_det_evts');
            collection = this.getCollectionFiltered(collection, fechaInicio, fechaFin);

            collection.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var evento = doc.data();
                    evento["id"] = doc.id;
                    evento["docRef"] = doc.ref;
                    
                    let fechaDesde = new Date(0);
                    fechaDesde = fechaDesde.setSeconds(evento.fechaCreacion.seconds);
                    fechaDesde = this.$moment(fechaDesde).format("YYYY-MM-DD HH:mm");

                    let fechaHasta = new Date(0);
                    fechaHasta = fechaHasta.setSeconds(evento.fechaCreacion.seconds);
                    fechaHasta = this.$moment(fechaHasta + 1800000).format("YYYY-MM-DD HH:mm");

                    let eventoTmp = {
                        start: fechaDesde,
                        end: fechaHasta,
                        title: evento.tipo,
                        // split: 1,
                        content: '(' + evento.estado + ') - ' + evento.observaciones,
                        class: evento.programarAlerta ? 'sport' : 'leisure',
                        deletable: false,
                        resizable: false,
                        draggable: false,
                        estado: evento.estado,
                        observaciones: evento.observaciones,
                        idEmbudoEnc: evento.idEmbudoEnc
                    }

                    this.listEvents.push(eventoTmp);
                    // console.log(JSON.stringify(doc.data()));

                });
                this.$store.commit("detenerLoading");
                // $('#popupFiltro').modal('hide');

            }).catch(
                function(error) {
                    console.log("Error getting embudos enc:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );

            
        },

        getCollectionFiltered(pCollection, fechaInicio, fechaFin){
            var collection = pCollection;

            if (this.filtros.idUsuarioAsesor) {
                collection = collection.where('idUsuarioAsesor', '==', this.filtros.idUsuarioAsesor);
            }

            if (this.filtros.idGrupoInformacion) {
                collection = collection.where("idGrupoInformacion", "==", this.filtros.idGrupoInformacion);
            }

            if (fechaInicio && fechaFin) {
                var fechaDesde= this.$moment(fechaInicio, "YYYY-MM-DD HH:mm").toDate();
                var fechaHasta= this.$moment(fechaFin, "YYYY-MM-DD HH:mm").toDate();
                collection = collection.where("fechaCreacion", ">=", fechaDesde).where("fechaCreacion", "<=", fechaHasta);

            }

            return collection;
        },

        async onEventClick (event, e) {
            // this.selectedEvent = event;

            let etiquetaEmbudoDoc = await this.getDocumentFirestore("embudos_enc", event.idEmbudoEnc);
                
            if (etiquetaEmbudoDoc) {
                let personaDoc = await this.getDocumentFirestore("personas", etiquetaEmbudoDoc.data().idPersonaLead);
                if (personaDoc) {
                    this.selectedEvent.nombre= personaDoc.data().nombres + ' ' + (personaDoc.data().apellidos ? personaDoc.data().apellidos : '');
                    this.selectedEvent.numeroIdentificacion= personaDoc.data().numeroIdentificacion;
                    this.selectedEvent.telefonoMovil= personaDoc.data().telefonoMovil;
                    this.selectedEvent.email= personaDoc.data().email;
                    this.selectedEvent.fecha= event.start;
                    this.selectedEvent.title= event.title;
                    this.selectedEvent.estado= event.estado;
                    this.selectedEvent.observaciones= event.observaciones;

                    $('#modalDetalleEvento').modal('show');

                } else {
                    $('#modalDetalleEvento').modal('show');
                }
                

            } else {
                $('#modalDetalleEvento').modal('show');
            }

            
            // this.showDialog = true
            // this.splitDays = [{ id: 1, label: 'D..r 1' }, { id: 2, label: 'D..r 2' }, { id: 2, label: 'D..r 3' }];
            

            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation();
        },


    },

    
}
