import accionesComp from "@/components/AccionesComponent";
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        accionesComp,
        VueApexCharts
    },
    data() {
        return {
            filtros: {
                idOrden: 1,
                activo: true,
                etiquetas: [this.$store.state.constantes.tipoEtiqueta.ETIQUETA_DEFAULT, this.$store.state.constantes.tipoEtiqueta.ETIQUETA_NUEVAS_PERSONAS]
            },
            chartOptions: {
                chart: {
                  id: 'vuechart-example'
                },
                xaxis: {
                //   categories: ['01/04/2022', '02/04/2022', '03/04/2022', '04/04/2022', '05/04/2022', '06/04/2022', '07/04/2022', '08/04/2022']
                  categories: []
                }
            },
            series: [
                // {
                //     name: 'Feval',
                //     data: [30, 40, 35, 50, 49, 60, 70, 91]
                // },
                // {
                //     name: 'Instagram',
                //     data: [20, 25, 15, 42, 10, 2, 50, 30]
                // }
            ],
            listPersonasEstadisticas: [],
            listEtiquetas: [this.$store.state.constantes.tipoEtiqueta.ETIQUETA_DEFAULT, this.$store.state.constantes.tipoEtiqueta.ETIQUETA_NUEVAS_PERSONAS]

        }
    },

    watch: {
        
    },

    computed: {
        
    },

    created() {
        this.inicializar();

    },
    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 2);
            this.setFechasFilter();
            this.loadEtiquetasPersonas();
            this.consultar();
        },

        limpiarFiltros() {
            this.filtros= {
                idOrden: 1,
                activo: true,
                etiquetas: [this.$store.state.constantes.tipoEtiqueta.ETIQUETA_DEFAULT, this.$store.state.constantes.tipoEtiqueta.ETIQUETA_NUEVAS_PERSONAS]
            };
            this.setFechasFilter();
            this.consultarFiltros();
        },

        setFechasFilter(){
            var fechaDesde = new Date();
            fechaDesde.setDate(fechaDesde.getDate() - 15);

            this.filtros.fechaDesde= this.$moment(fechaDesde).format("YYYY-MM-DD");
            this.filtros.fechaHasta= this.$moment(new Date()).format("YYYY-MM-DD");
        },

        loadEtiquetasPersonas() {
            this.getCollectionListFirestore('tablasTipo', null, true).doc("listEtiquetasPersonas").get().then(
                function(doc) {
                    if (doc.exists) {
                        let listEtiquetasPersonas = doc.data().valor;
                        for (const etiqueta of listEtiquetasPersonas) {
                            this.listEtiquetas.push(etiqueta.descripcion);
                            // this.filtros.etiquetas.push(etiqueta.descripcion);
                        }
                    } else {
                        console.log("No such document listEtiquetasPersonas!");
                    }
                    this.$store.commit("detenerLoading");
                }.bind(this) 
            ).catch((error) => {
                this.$store.commit("detenerLoading");
                console.log("Error configurando la tabla tipo listEtiquetasPersonas:", error);
            });
        },

        consultarFiltros() {
            this.$store.commit("ejecutarLoading");
            this.consultar();
        },

        async consultar() {
            this.listPersonasEstadisticas = [];            
            let collection = this.getCollectionListFirestore("personas_estadisticas", null, true);
            collection = this.getCollectionFiltered(collection);

            let querySnapshot = await collection.get().catch(
                function(error) {
                    console.log("Error getting document:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );

            if (querySnapshot) {
                for (const doc of querySnapshot.docs) {
                    var personaEstadistica = doc.data();
                    personaEstadistica["id"] = doc.id;
                    personaEstadistica["docRef"] = doc.ref;

                    this.listPersonasEstadisticas.push(personaEstadistica)

                };
                this.crearGrafica();
            }
        },

        getCollectionFiltered(pCollection){
            var collection = pCollection;

            if (this.filtros.fechaDesde && this.filtros.fechaHasta) {
                var fechaDesde= this.$moment(this.filtros.fechaDesde, "YYYY-MM-DD").toDate();
                var fechaHasta= this.$moment(this.filtros.fechaHasta, "YYYY-MM-DD").toDate();
                fechaHasta.setDate(fechaHasta.getDate() + 1);
                collection = collection.where("fecha", ">=", fechaDesde).where("fecha", "<=", fechaHasta);
                collection = collection.where("tipoEstadistica", "==", this.$store.state.constantes.tipoEstadisticaPersona.CANT_PERSONAS_DIA_ETIQUETA);
            }

            return collection;
        },

        crearGrafica() {
            var fechaDesde= this.$moment(this.filtros.fechaDesde, "YYYY-MM-DD").toDate();
            var fechaHasta= this.$moment(this.filtros.fechaHasta, "YYYY-MM-DD").toDate();
            var cantDias= Math.floor((fechaHasta - fechaDesde) / (1000 * 60 * 60 * 24)) + 1;
            
            var listCategorias=[];
            var listSeries=[];
            var data={};
            
            for (const etiqueta of this.filtros.etiquetas) {
                data[etiqueta] = [];
            }
            
            for (var dia=0; dia < cantDias; dia++) {
                let fechaDesdeTmp = new Date(fechaDesde);
                var fechaDia = this.$moment(new Date(fechaDesdeTmp.setDate(fechaDesdeTmp.getDate() + dia))).format("DD/MM/YYYY")
                listCategorias.push(fechaDia);
                
                for (const etiqueta of this.filtros.etiquetas) {
                    var estadistica = this.listPersonasEstadisticas.find(personaEstadistica => personaEstadistica.fechaDia == fechaDia && personaEstadistica.etiqueta == etiqueta);
            
                    if (estadistica) {
                        data[etiqueta].push(estadistica.cantidad);
                    } else {
                        data[etiqueta].push(0);
                    }
                }
            }
            
            for (const etiqueta of this.filtros.etiquetas) {
                var serie = {name:etiqueta, data:data[etiqueta]}
                listSeries.push(serie);
            
            }
            this.chartOptions = {
                chart: {
                  id: 'vuechart-example'
                },
                xaxis: {
                  categories: listCategorias
                }
            }

            this.series= listSeries;
            this.$store.commit("detenerLoading");
        }

    }

}