import DashBoard from '../views/DashBoard.vue'
import Table from '../views/Table.vue'

import GruposDeInformacionFil from '@/views/gruposInformacion/GruposDeInformacion_fil.vue'
import GruposDeInformacionDet from '@/views/gruposInformacion/GruposDeInformacion_det.vue'
import Organizacion from '@/views/organizacion/Organizacion.vue'
import Embudos from '@/views/embudo/Embudos.vue'
import PersonasFil from '@/views/personas/Personas_fil.vue'
import PersonasDet from '@/views/personas/Personas_det.vue'
import PerfilesFil from '@/views/seguridad/perfiles/Perfiles_fil.vue'
import PerfilesDet from '@/views/seguridad/perfiles/Perfiles_det.vue'
import UsuariosFil from '@/views/seguridad/usuarios/Usuarios_fil.vue'
import UsuariosDet from '@/views/seguridad/usuarios/Usuarios_det.vue'
import ProductosFil from '@/views/productos/Productos_fil.vue'
import ProductosDet from '@/views/productos/Productos_det.vue'
import Mensajes from '@/views/mensajes/Mensajes.vue'
import TableroControl from '@/views/tableroControl/Tablero_control.vue'
import Calendario from '@/views/calendario/calendario.vue'
import PlantillasFil from '@/views/plantillas/Plantillas_fil.vue'
import PlantillasDet from '@/views/plantillas/Plantillas_det.vue'
import HistoriaClinicaFil from '@/views/historiaClinica/HistoriaClinica_fil.vue'
import HistoriaClinicaDet from '@/views/historiaClinica/HistoriaClinica_det.vue'
import CancionesFil from '@/views/canciones/Canciones_fil.vue'
import CancionesDet from '@/views/canciones/Canciones_det.vue'

export const menuPrincipalFeatures = [
    {
        path:'gruposDeInformacion_fil',
        name:'gruposDeInformacion_fil',
        component:GruposDeInformacionFil,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-sitemap'
        },
        claveRecursoPadre:'gruposDeInformacion'
    },
    {
        path:'gruposDeInformacion_det',
        name:'gruposDeInformacion_det',
        component:GruposDeInformacionDet,
        props:true,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:false,
        },
        claveRecursoPadre:'gruposDeInformacion'
    },    
    {
        path: 'dashBoard',
        name: 'dashBoard',
        component: DashBoard,
        meta: {
            requiresAuth: false,
            visibleLinkMenu: true,
            classCssLinkMenu: 'fas fa-tachometer-alt',
            i18nLinkMenu: 'Dashboard i18n'
        }
    },
    {
        path: 'table',
        name: 'table',
        component: Table,
        meta: {
            requiresAuth: false,
            visibleLinkMenu: true,
            classCssLinkMenu: 'fas fa-table',
            i18nLinkMenu: 'Table i18n'
        }
    },
    {
		path: 'organizacion',
		name: 'organizacion',
		component: Organizacion,
        meta: {
            requiresAuth: true,
            visibleLinkMenu: true,
            classCssLinkMenu: 'fas fa-tasks',
            i18nLinkMenu: 'message.menuPrincipal.organizacion'
        },
        claveRecursoPadre: 'organizacion'
	},
    {
        path:'embudos',
        name:'embudos',
        component:Embudos,
        meta:{
            requiresAuth:false,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-tasks'
        },
        claveRecursoPadre:'embudos'
    },
    {
        path:'personas_fil',
        name:'personas_fil',
        component:PersonasFil,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-user'
        },
        claveRecursoPadre:'personas'
    },
    {
        path:'personas_det',
        name:'personas_det',
        component:PersonasDet,
        props:true,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:false,
            classCssLinkMenu:'fas fa-user',
            i18nLinkMenu:'message.menuPrincipal.personas'
        }
    },
    {
        path:'perfiles_fil',
        name:'perfiles_fil',
        component:PerfilesFil,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-users'
        },
        claveRecursoPadre:'perfiles'
    },
    {
        path:'perfiles_det',
        name:'perfiles_det',
        component:PerfilesDet,
        props:true,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:false,
            classCssLinkMenu:'fas fa-users'
        },
        claveRecursoPadre:'perfiles'
    },
    {
        path:'usuarios_fil',
        name:'usuarios_fil',
        component:UsuariosFil,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-user-cog'
        },
        claveRecursoPadre:'usuarios'
    },
    {
        path:'usuarios_det',
        name:'usuarios_det',
        component:UsuariosDet,
        props:true,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:false,
            classCssLinkMenu:'fas fa-user-cog'
        },
        claveRecursoPadre:'usuarios'
    },
    {
        path:'productos_fil',
        name:'productos_fil',
        component:ProductosFil,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-newspaper'
        },
        claveRecursoPadre:'productos'
    },
    {
        path:'productos_det',
        name:'productos_det',
        props:true,
        component:ProductosDet,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:false,
            classCssLinkMenu:'fas fa-newspaper'
        },
        claveRecursoPadre:'productos'
    },
    {
        path:'mensajes',
        name:'mensajes',
        props:true,
        component:Mensajes,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-envelope'
        },
        claveRecursoPadre:'mensajes'
    },
    {
        path:'tableroControl',
        name:'tableroControl',
        props:true,
        component:TableroControl,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-chart-line'
        },
        claveRecursoPadre:'tableroControl'
    },
    {
        path:'calendario',
        name:'calendario',
        props:true,
        component:Calendario,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-calendar-alt'
        },
        claveRecursoPadre:'calendario'
    },
    {
        path:'historiaClinica_fil',
        name:'historiaClinica_fil',
        props:true,
        component:HistoriaClinicaFil,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-clinic-medical'
        },
        claveRecursoPadre:'historiaClinica'
    },
    {
        path:'historiaClinica_det',
        name:'historiaClinica_det',
        props:true,
        component:HistoriaClinicaDet,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:false,
            classCssLinkMenu:'fas fa-clinic-medical'
        },
        claveRecursoPadre:'historiaClinica'
    },
    {
        path:'plantillas_fil',
        name:'plantillas_fil',
        component:PlantillasFil,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-file-invoice'
        },
        claveRecursoPadre:'plantillas'
    },
    {
        path:'plantillas_det',
        name:'plantillas_det',
        props:true,
        component:PlantillasDet,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:false,
            classCssLinkMenu:'fas fa-file-invoice'
        },
        claveRecursoPadre:'plantillas'
    },
    {
        path:'canciones_fil',
        name:'canciones_fil',
        component:CancionesFil,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:true,
            classCssLinkMenu:'fas fa-music'
        },
        claveRecursoPadre:'canciones'
    },
    {
        path:'canciones_det',
        name:'canciones_det',
        props:true,
        component:CancionesDet,
        meta:{
            requiresAuth:true,
            visibleLinkMenu:false,
            classCssLinkMenu:'fas fa-music'
        },
        claveRecursoPadre:'canciones'
    }

]