import {auth} from '@/firebase/firebaseInit.js';

export default {
  data() {
    return {
        email: ""

    };
  },

  methods: {
    sendPasswordResetEmail() {
        this.$store.commit("ejecutarLoading");
        auth.sendPasswordResetEmail(this.email).then(
            (data) => {
                this.$store.commit("detenerLoading");
                this.$store.dispatch("addAlert", {
                    tipo: "success",
                    titulo: "",
                    descripcion: this.$t('message.login.recordarSucces')
                });
            }
        ).catch(
            (error) => {
                this.$store.commit("detenerLoading");
                this.$store.dispatch("addAlert", {
                    tipo: "warning",
                    titulo: "",
                    descripcion: this.$t('message.login.errorRecuperar')
                });
            }
        );
    }
  }
};