import {firebase, auth, firestore } from '@/firebase/firebaseInit.js';

export default {
  data() {
    return {
        user: {
            email: "",
            password: "",
        },
    };
  },

  methods: {
    async login() {
        this.$store.commit("ejecutarLoading");
        this.$store.commit("initRecursos");
        this.$store.commit("cleanListModulosRecursos", {});

        auth.signInWithEmailAndPassword(this.user.email, this.user.password).then(
            async (data) => {
                const idToken = await auth.currentUser.getIdToken()
                // console.log("tokenId="+idToken);
                this.$store.commit("setToken",idToken);
               // await console.log("Usuario registrado: " + JSON.stringify(data.user.uid));
                await this.setOrganizacionByUsuario(data.user.uid);
                this.$store.commit("setUserName",data.user.displayName);
                
            }
        ).catch(
            (error) => {
                // var errorCode = error.code;
                // var errorMessage = error.message;
                // console.log(
                //     "Ocurrio login firebase: " +
                //     errorCode +
                //     ", " +
                //     errorMessage
                // );
                var msjError = error.code == "auth/wrong-password" ? this.$t('message.login.loginFail') : this.$t('message.general.errorInesperado');

                this.$store.dispatch("addAlert", {
                    tipo: "warning",
                    titulo: "",
                    descripcion: msjError
                });
                this.$store.commit("detenerLoading");
            }
        );
    },

    async setOrganizacionByUsuario(uid) {
        try {
            let organizacionUsuarioDoc = await firestore
                .collection("OrganizacionesUsuarios")
                .doc(uid)
                .get();

            if (organizacionUsuarioDoc.exists) {
                let organizacionUsuarioData = await organizacionUsuarioDoc.data();
                
                this.$store.commit(
                    "setIdOrganizacion",
                    organizacionUsuarioData.idOrganizacion
                );
                this.$store.commit(
                    "setIdGrupoInformacion",
                    organizacionUsuarioData.idGrupoInformacion
                );

                var organizacion = await this.getDocumentFirestore("organizacion", organizacionUsuarioData.idOrganizacion, true);
                console.log(organizacion.data().nombre);
                this.$store.commit(
                    "setNombreOrganizacion",
                    organizacion.data().nombre
                );

                const recursos=  await this.setRecursos(
                    uid,
                    organizacionUsuarioData.idGrupoInformacion,
                    organizacionUsuarioData.idOrganizacion
                );

                const callSetModulosRecursosUsuario =  await this.setModulosRecursosUsuario(recursos);
            
                this.$store.commit("detenerLoading");
                this.$router.push("/menuPrincipal");
                

                return true;
            } else {
                this.$store.commit("detenerLoading");
                console.log("No such document!");

            }
        } catch (error) {
            this.$store.commit("detenerLoading");
            console.log("Error getting document:", error);
        }
    },
    
    async setRecursos(uid, idGrupoInformacion, idOrganizacion) {
        try {
            let firestoreOrg = await firebase.firestore().collection("Organizaciones").doc(idOrganizacion);
            let usuariosCollection = await firestoreOrg.collection("usuarios")
                .where('idGrupoInformacion', '==', idGrupoInformacion)
                .where('__name__', '==', uid).get();

            let recursos={};

            for (const usuarioDoc of usuariosCollection.docs) {
                try {
                
                    let perfileCollection = await firestoreOrg.collection('perfiles')
                        .where('idGrupoInformacion', 'in', [idGrupoInformacion, this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL])
                        .where('__name__', '==', usuarioDoc.data().perfil).get();

                    this.$store.commit("setAdmin",usuarioDoc.data().isAdmin);

                    for (const perfilDoc of perfileCollection.docs) {
                        try {
                            let recursosCollection = await perfilDoc.ref.collection("recursos")
                                .where('idGrupoInformacion', 'in', [idGrupoInformacion, this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL])
                                .get();
                                    
                            for (const recursoDoc of recursosCollection.docs) {
                                var key = recursoDoc.id;
                                var value = recursoDoc.data();
                                recursos[key]=value;
                                this.$store.commit("addRecurso", {key, value});
                            }  

                            this.setTablasTipo();

                        } catch(error) {
                            this.$store.commit("detenerLoading");
                            console.log("Error obteniendo los recursos:", error);
                        }
                    }

                } catch(error) {
                    this.$store.commit("detenerLoading");
                    console.log("Error obteniendo los perfiles:", error);
                }                 
            }  
            
            return recursos;

        } catch(error) {
            this.$store.commit("detenerLoading");
            console.log("Error obteniendo la informacion del usuario:", error);
        }   
    },

    setTablasTipo() {
        this.getCollectionListFirestore('tablasTipo', null, true).doc("list").get().then((doc) => {
            if (doc.exists) {
                this.$store.commit("setTablasTipo", doc.data());
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error configurando las tablas tipo:", error);
        });
    },

    async setModulosRecursosUsuario(recursos) {
        try {
            let routes = this.$router.options.routes;
            const getModulosRecursosUsuario = async () => {
                let allModulosRecursos = await this.getCollectionListFirestore(
                    "modulosRecursos",
                    null,
                    true
                ).get();

                for (const moduloRecursosDoc of allModulosRecursos.docs) {
                    let allRecursosMod = await moduloRecursosDoc.ref
                        .collection("recursosMod")
                        .get();

                    var recursosModulosUsuario = {};
                    for (const recursoModDoc of allRecursosMod.docs) {
                        let recursoModData = recursoModDoc.data();
                        
                        if (recursos[recursoModData.clave]) {
                            let recursoEstatico= {};
                            
                            if(typeof routes.find(x => x.name==="menuPrincipal").children.find(x => x.claveRecursoPadre===recursoModData.clave) !='undefined') {
                                recursoEstatico= routes.find(x => x.name==="menuPrincipal").children.find(x => x.claveRecursoPadre===recursoModData.clave);
                                
                                if(this.$canRead(recursoModData.clave) && recursoEstatico.meta.visibleLinkMenu){
                                    let recursoModulo = {
                                        nombre: recursoModData.nombre,
                                        path: recursoEstatico.path,
                                        clave: recursoModData.clave,
                                        meta: recursoEstatico.meta
                                    };
                                    recursosModulosUsuario[recursoModData.clave] = recursoModulo;
                                }
                            }
                        }
                    }

                    var moduloRecurso = moduloRecursosDoc.data();

                    if (Object.entries(recursosModulosUsuario).length > 0) {
                        moduloRecurso["listRecursos"]={};
                        moduloRecurso.listRecursos = recursosModulosUsuario;
                        var key = moduloRecurso.nombre;
                        var value = moduloRecurso;
                        this.$store.commit("addModuloRecursos", { key, value });
                    }
                }

                return true;
            };

            return await getModulosRecursosUsuario();

        } catch (e) {
                console.error(e);
        }
     
    }
  },
};