import accionesComp from "@/components/AccionesComponent";
import mensajeriaComp from "@/components/mensajeria/MensajeriaComponent.vue";
import personaComp from "@/views/personas/Personas_det.vue";
import { firebase, auth, firestore } from '@/firebase/firebaseInit.js';

export default {
    name: 'mensajeriaCompGlobal',
    props: {
    },
    components: {
        accionesComp,
        mensajeriaComp,
        personaComp
    },
    data() {
        return {
            listPersonas: [],
            listUsuarios: [],
            filtros: {
                idGrupoInformacion: this.$store.getters.getIdGrupoInfomacion,
                idOrden: 1,
                activo: true,
                idPais: 0,
                idDepartamento: 0,
                idCiudad: 0,
                idTipoPersona: '',
                idUsuarioAsesor: '',
                telefonoMovil:0
            },
            contMostrarCompMsg: 0,
            contMostrarCompPersona: 0,
            mostrarListadoPersonas: true,
            personaMsg:{},
            personaTmp:{},
            listenerMensajesStop: null,
            listenerMensajesStopSA: null,
            listenerPersonasStop: null,
            listenerPersonasSnStop: null,
            listPlantillas: [],
            listPersonasAgregar: [],
            cantCiclosMensajes: !this.$store.getters.isAdmin ? 2 : 1,
            cicloMensajesActual: 1,
            mensajesInicializado: false,
            consultaDesdeFiltros: false

        }
    },

    watch: {
        'filtros.idPais': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                this.filtros.idDepartamento = 0;
                this.filtros.idCiudad = 0;
            }
        },
        'filtros.idDepartamento': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                this.filtros.idCiudad = 0;
            }
        },
        /* Se encarga de verificar cuando llega un mensaje nuevo para mantener actualizada la bandeja de mensajes */
        '$store.state.msgPersona': function (val, oldVal) {
            this.cargarMensajeFromAlertas(val);
        }

    },

    computed: {

    },

    created() {
        this.inicializar();

    },

    mounted(){
        this.validadorFechaInicioFechaFinHandler('inputStartdate','inputEnddate',2);

    },

    beforeDestroy() {
        if (this.listenerMensajesStop) {
            this.listenerMensajesStop();
        }
        if (this.listenerMensajesStopSA) {
            this.listenerMensajesStopSA();
        }
        if (this.listenerPersonasStop) {
            this.listenerPersonasStop();
        }
        if (this.listenerPersonasSnStop) {
            this.listenerPersonasSnStop();
        }     

    },

    methods: {
        limpiarFiltros() {
            this.filtros.idTipoDocumento = '';
            this.filtros.numeroIdentificacion = '';
            this.filtros.nombres = '';
            this.filtros.apellidos = '';
            this.filtros.idPais = 0;
            this.filtros.idDepartamento = 0;
            this.filtros.idCiudad = 0;
            this.filtros.idTipoPersona = '';
            this.filtros.idUsuarioAsesor = '';
            this.filtros.telefonoMovil = 0;
            this.setDefaultDateRangeFilter();
            this.$store.commit("ejecutarLoading");
            this.consultaDesdeFiltros = false;
            this.consultar(auth.currentUser.uid);

        },

        /* Este metodo sirve para seleccionar una persona y mostrar sus mensajes cuando el usuario le da clic
        a una de las alertas de mensajeria*/
        cargarMensajeFromAlertas(pMensaje) {
            if (pMensaje) {
                let persona = this.listPersonas.find(element => element.id == pMensaje.idPersona);
                if (persona) {
                    let listMensajes = [];

                    if (persona.messages && persona.messages.length > 0) {
                        listMensajes = persona.messages;
                    } else {
                        listMensajes.push(pMensaje)
                    }
                    
                    let personaMsg = {
                        messages : listMensajes,
                        nombres: this.getNombresPersona(persona.nombres, persona.apellidos),
                        telefonoMovil : pMensaje.remitente,
                        idGrupoInformacion: pMensaje.idGrupoInformacion,
                        idUsuarioAsesor: pMensaje.idUsuarioAsesor,
                        ...persona
                    }
                    this.mostrarMensajeria(personaMsg, true);
                } else {
                    this.$store.commit("detenerLoading");
                }
                this.$store.commit("setMsgPersona", null);
            }
            
        },

        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 3);
            this.loadPlantillas();
            
            firebase.auth().onAuthStateChanged((user) =>{
                if (!this.$store.getters.getSesionIniciada) {
                    return;
                }
                if(user){
                    this.loadUsuarios(user.uid);
                    this.consultar(user.uid);

                }
            });
            
        },

        loadPlantillas() {
            
            this.getCollectionListFirestore("plantillas", null, true).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listPlantillas.push({ id: doc.id, nombre: doc.data().nombre, html: doc.data().html });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document plantillas:", error);
                    }.bind(this)
                );


        },

        loadUsuarios(uid) {
            var collection = this.getCollectionListFirestore('usuarios');
            collection.get()
                .then((querySnapshot) => {
                    this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });

                    querySnapshot.forEach((doc) => {
                        this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        collection = collection.where('id', '==', uid);
                        collection.get()
                            .then((querySnapshot) => {
                                this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });

                                querySnapshot.forEach((doc) => {
                                    this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function (errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );
                    }.bind(this)
                );
        },

        consultaFiltros() {
            this.$store.commit("ejecutarLoading");
            this.setBanderaConsultaDesdeFiltros();
            this.consultar(auth.currentUser.uid);
        },  

        setBanderaConsultaDesdeFiltros() {
            if (this.filtros.idTipoDocumento || this.filtros.numeroIdentificacion || this.filtros.nombres || this.filtros.apellidos ||
                this.filtros.idPais || this.filtros.idDepartamento || this.filtros.idCiudad || this.filtros.idTipoPersona ||
                this.filtros.telefonoMovil
            ) {
                this.consultaDesdeFiltros = true;
            } else {
                this.consultaDesdeFiltros = false;
            }
        },

        async consultar(uid) {
            let uidTmp = uid;
            if (!uidTmp) {
                uidTmp = auth.currentUser.uid

            }

            try {
                this.listPersonas = [];
                await this.consultaPersonasConAsesor(uidTmp);
                if (!this.$store.getters.isAdmin && !this.filtros.idUsuarioAsesor && this.$store.getters.getIdGrupoInfomacion) {
                    await this.consultaPersonasSinAsesor(uidTmp);
                }

                this.ordenarLista(this.listPersonas);
                
                this.consultarMsgConAsesor(uidTmp);
                if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                    this.consultarMsgSinAsesor(uidTmp);
                }

                if (this.$store.state.msgPersona) {
                    this.cargarMensajeFromAlertas(this.$store.state.msgPersona);

                }
            } catch (error) {
                this.$store.dispatch("addAlert", {
                    tipo: "warning",
                    titulo: "",
                    descripcion: this.$t('message.general.errorConsultaFiltros')
                });
            }
                

            this.$store.commit("detenerLoading");
            $('#popupFiltro').modal('hide');
        },

        async consultaPersonasConAsesor(uid){
            let collection;

            /* Obtenemos un listado de personas para el escuchador que esta pendiente
            del cambio de nombres de la persona, fecha ultimo mensaje o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('personas');
            collection = this.getCollectionFiltered(collection, uid, false);
            collection = this.getCollectionPersonasListenerFiltered(collection, uid, true);

            if (this.listenerPersonasStop) {
                this.listenerPersonasStop();
            }
            this.listenerPersonasStop = await this.getListenerPersonas(collection);

            /* Obtenemos un listado de personas para  mostrar en la lista de contactos de mensajeria*/
            collection = this.getCollectionListFirestore('personas');
            collection = this.getCollectionFiltered(collection, uid, true);

            if (collection) {
                await this.getPersonas(collection);
            }
        },

        async consultaPersonasSinAsesor(uid){
            let collection;
            
            /* Obtenemos un listado de personas para el escuchador que esta pendiente
            del cambio de nombres de la persona o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('personas');
            collection = this.getCollectionFiltered(collection, uid, false);
            collection = this.getCollectionPersonasListenerFiltered(collection, uid, false);

            if (this.listenerPersonasSnStop) {
                this.listenerPersonasSnStop();
            }
            this.listenerPersonasSnStop = await this.getListenerPersonas(collection);

            /* Obtenemos un listado de personas para  mostrar en la lista de contactos de mensajeria*/
            collection = this.getCollectionListFirestore('personas');
            collection = this.getCollectionFiltered(collection, uid, false);

            if (collection) {
                await this.getPersonas(collection);
            }
        },

        getCollectionFiltered(pCollection, uid, sinAsesor) {
            let collection = pCollection;
           
            if (this.consultaDesdeFiltros) {
                if (this.filtros.numeroIdentificacion) {
                    collection = collection.where("numeroIdentificacion", "==", this.filtros.numeroIdentificacion);

                    if (this.filtros.idTipoDocumento) {
                        collection = collection.where("idTipoDocumento", "==", this.filtros.idTipoDocumento);
                    }

                }  else {
                    collection = this.getCollectionFechasFiltered(collection);

                    // if (this.filtros.nombres) {
                    //     collection = collection.where("nombresSearchTermsArray", "array-contains", this.filtros.nombres.toLowerCase());
                    // }
        
                    //Se conmenta temporalmente por que firebase no permite usar mas de un array-contains por consulta.
                    // if (this.filtros.apellidos) {
                    //     collection = collection.where("apellidosSearchTermsArray", "array-contains", this.filtros.apellidos.toLowerCase());
                    // }
        
                    if (this.filtros.idPais) {
                        collection = collection.where("idPais", "==", this.filtros.idPais);
                    }
        
                    if (this.filtros.idDepartamento) {
                        collection = collection.where("idDepartamento", "==", this.filtros.idDepartamento);
                    }
        
                    if (this.filtros.idCiudad) {
                        collection = collection.where("idCiudad", "==", this.filtros.idCiudad);
                    }
        
                    if (this.filtros.idTipoPersona) {
                        collection = collection.where("idTipoPersona", "==", this.filtros.idTipoPersona);
                    }

                    if (this.filtros.telefonoMovil) {
                        collection = collection.where("telefonoMovil", "==", this.filtros.telefonoMovil);
                    }
                }

            } else {
                collection = this.getCollectionFechasFiltered(collection);
            }

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (sinAsesor) {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                } else {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);
                }

            } else {
                if (this.filtros.idUsuarioAsesor) {
                    collection = collection.where("idUsuarioAsesor", "==", this.filtros.idUsuarioAsesor);
                }
            }
    
            return collection;
        },

        getCollectionFechasFiltered(pCollection) {
            let collection = pCollection;

            if(!this.$communicates.filtros.startdate || !this.$communicates.filtros.enddate){
                return false;

            } else {
                let startdateParts =this.$communicates.filtros.startdate.split('-');
                let enddateParts = this.$communicates.filtros.enddate.split('-');
                // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
                // January - 0, February - 1, etc.
                let start = new Date(startdateParts[0], startdateParts[1] - 1, startdateParts[2]); 
                let end = new Date(enddateParts[0], enddateParts[1] - 1, enddateParts[2], 23,59,59); 

                // collection = collection.orderBy('fechaUltimoMensaje').startAt(start).endAt(end);
                collection = collection.where("fechaUltimoMensaje", ">=", start).where("fechaUltimoMensaje", "<=", end);
            }

            return collection;
        },

        getCollectionPersonasListenerFiltered(pCollection) {
            let collection = pCollection;
            let fechaConsultaDesde = new Date();
            fechaConsultaDesde.setDate(fechaConsultaDesde.getDate() - 30);

            collection = collection.where("fechaUltimoMensaje", ">=", new Date(fechaConsultaDesde.toDateString()))
       
            return collection;
        },

        async getListenerPersonas(collection) {
            return await collection.onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    
                    if (change.type === "modified") {
                        let persona = change.doc.data();
                        persona["id"] = change.doc.id;
                        persona["docRef"] = change.doc.ref;

                        if ((this.filtros.idTipoDocumento || this.filtros.numeroIdentificacion) || 
                            (this.isInFilters(persona))
                        ){
                            persona["cantMensajes"] = 0;
                            persona["messages"] = [];

                            // this.modifyEtiquetaPersona(persona);
                            this.modificarDatosPersona(persona);
                        }
                    }

                });

                // this.$store.commit("detenerLoading");
                // $('#popupFiltro').modal('hide');

            });
        },
        
        async getPersonas(collection) {
            let querySnapshot = await collection.get();

            for (const doc of querySnapshot.docs) {
                var persona = doc.data();
                persona["id"] = doc.id;
                persona["docRef"] = doc.ref;

                if ((this.filtros.idTipoDocumento || this.filtros.numeroIdentificacion) || 
                    (this.isInFilters(persona))
                ){
                    persona["cantMensajes"] = 0;
                    persona["messages"] = [];
                    persona.idGrupoMensajeria = persona.idGrupoMensajeria ? persona.idGrupoMensajeria : 1;

                    if (!persona.mensajeVisto) {
                        persona.mensajeVisto = 'N';
                    }
                    // this.modifyEtiquetaPersona(persona);
                    this.listPersonas.push(persona);
                }
                
                
            }

            // return await collection.get().then((querySnapshot) => {
            //     querySnapshot.forEach((doc) => {

            //         var persona = doc.data();
            //         persona["id"] = doc.id;
            //         persona["docRef"] = doc.ref;
            //         persona["cantMensajes"] = 0;
            //         persona["messages"] = [];
            //         persona.idGrupoMensajeria = persona.idGrupoMensajeria ? persona.idGrupoMensajeria : 1;

            //         if (!persona.mensajeVisto) {
            //             persona.mensajeVisto = 'N';
            //         }
            //         // this.modifyEtiquetaPersona(persona);
            //         this.listPersonas.push(persona);

            //     });

            //     this.ordenarLista(this.listPersonas);
            //     this.$store.commit("detenerLoading");
            //     $('#popupFiltro').modal('hide');

            // }).catch(
            //     function (error) {
            //         console.log("Error getting document personas:", error);
            //         this.$store.commit("detenerLoading");
            //     }.bind(this)
            // );

        },

        isInFilters(persona) {
            let isInfilter = false;

            if (
                (this.isInSearchTerms(this.filtros.nombres,persona.nombresSearchTermsArray)) &&
                (this.isInSearchTerms(this.filtros.apellidos,persona.apellidosSearchTermsArray)) 
            ) {
                isInfilter = true;
            }

            return isInfilter;
        },

        // modifyEtiquetaPersona(persona) {
        //     if (persona.etiquetas) {
        //         
        //         var listEtiquetas=[];
        //         var index = 0;
        //         for (const property in persona.etiquetas) {
        //             let etiqueta = {"id":index,"descripcion":property};
        //             listEtiquetas.push(etiqueta);
        //             index++;
        //         }
        //         persona.etiquetas = listEtiquetas;
        //     }
        // },

        ordenarLista(listPersonas) {
            listPersonas.sort(function (a, b) {
                // if (a.fechaUltimoMensaje && b.fechaUltimoMensaje && a.fechaUltimoMensaje.seconds > b.fechaUltimoMensaje.seconds) {
                //     return 1;
                // }
                // if (a.fechaUltimoMensaje && b.fechaUltimoMensaje && a.fechaUltimoMensaje.seconds < b.fechaUltimoMensaje.seconds) {
                //     return -1;
                // }
                // a must be equal to b
                let vlr = 0;
                let vlrA = a.fechaUltimoMensaje ? a.fechaUltimoMensaje.seconds : 0;
                let vlrB = b.fechaUltimoMensaje ? b.fechaUltimoMensaje.seconds : 0;

                vlr = vlrB - vlrA;

                return vlr;
            });
        },

        modificarDatosPersona(persona) {
            let indexPersona = this.listPersonas.findIndex(element => element.id == persona.id);
            let personaFind = this.listPersonas[indexPersona];

            if (personaFind) {
                if (persona.fechaUltimoMensaje && personaFind.fechaUltimoMensaje && 
                    persona.fechaUltimoMensaje > personaFind.fechaUltimoMensaje &&
                    indexPersona > 0
                ) {
                    this.arraymove(this.listPersonas, indexPersona, 0);
                }

                this.setPersonaProperties(personaFind, persona);
                // personaFind.asesorMensajeria = persona.asesorMensajeria;
                // personaFind.nombres = persona.nombres;
                // personaFind.apellidos = persona.apellidos;
                // personaFind.mensajeVisto = persona.mensajeVisto;
                // personaFind.fechaUltimoMensaje = persona.fechaUltimoMensaje;
                // personaFind.etiquetas = persona.etiquetas;
            } else {
                this.listPersonas.unshift(persona);
            }
		},

        setPersonaProperties(personaDestino, personaOrigen) {
            if (personaDestino) {
                personaDestino.idTipoDocumento= personaOrigen.idTipoDocumento;
                personaDestino.numeroIdentificacion= personaOrigen.numeroIdentificacion;
                personaDestino.nombres= personaOrigen.nombres;
                personaDestino.nombresSearchTermsArray= this.getSearchTermsArray(personaOrigen.nombres);
                personaDestino.apellidos= personaOrigen.apellidos;
                personaDestino.apellidosSearchTermsArray= this.getSearchTermsArray(personaOrigen.apellidos);
                personaDestino.codArea= personaOrigen.codArea;
                personaDestino.telefonoMovil= personaOrigen.telefonoMovil;
                personaDestino.telefonoFijo= personaOrigen.telefonoFijo;
                personaDestino.email= personaOrigen.email;
                personaDestino.direccion= personaOrigen.direccion;
                personaDestino.idPais= personaOrigen.idPais;
                personaDestino.idDepartamento= personaOrigen.idDepartamento;
                personaDestino.idCiudad= personaOrigen.idCiudad;
                personaDestino.idTipoPersona= personaOrigen.idTipoPersona;
                personaDestino.idGenero= personaOrigen.idGenero; 
                personaDestino.idGrupoInformacion= personaOrigen.idGrupoInformacion;
                personaDestino.idUsuarioAsesor= personaOrigen.idUsuarioAsesor;
                personaDestino.fechaCreacion= Date.now();
                personaDestino.temporal= personaOrigen.temporal;
                personaDestino.asesorMensajeria= personaOrigen.asesorMensajeria;
                personaDestino.etiquetas= personaOrigen.etiquetas;
                personaDestino.mensajeVisto= personaOrigen.mensajeVisto;
                personaDestino.fechaUltimoMensaje= personaOrigen.fechaUltimoMensaje;
                personaDestino.idGrupoMensajeria= personaOrigen.idGrupoMensajeria ? personaOrigen.idGrupoMensajeria : 1;
            }
        },

        consultarMsgConAsesor(uid) {
            /* Obtenemos un listener de mensajes para actualizar el contador de mensajeria y agregar los mensajes entrantes al 
            listado de mensajes de la persona dueña del mensaje entrante, si la persona no existe procedemos a consultar la
            persona asociada y le asociamos el mensaje entrante. */
            let collection = this.getCollectionListFirestore('mensajes');
            collection = this.getCollectionMsgFiltered(collection, uid, true);

            if (this.listenerMensajesStop) {
                this.listenerMensajesStop();
            }

            this.listenerMensajesStop = this.getMensajes(collection);
        },

        consultarMsgSinAsesor(uid) {
            /* Obtenemos un listener de mensajes para actualizar el contador de mensajeria y agregar los mensajes entrantes al 
            listado de mensajes de la persona dueña del mensaje entrante, si la persona no existe procedemos a consultar la
            persona asociada y le asociamos el mensaje entrante. */
            let collection = this.getCollectionListFirestore('mensajes');
            collection = this.getCollectionMsgFiltered(collection, uid, false);

            if (this.listenerMensajesStopSA) {
                this.listenerMensajesStopSA();
            }

            this.listenerMensajesStopSA = this.getMensajes(collection);
        },

        getCollectionMsgFiltered(pCollection, uid, sinAsesor) {
            let collection = pCollection;

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (sinAsesor) {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                } else {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);
                }

            }

            collection = collection.where('visto', '==', false).orderBy('fecha');;
    
            return collection;
        },

        getMensajes(collection) {
            return collection.onSnapshot((snapshot) => {
                this.listPersonasAgregar = [];
                // let numMensajes = snapshot.docChanges().length;
                // let contMensajes = 0;
                snapshot.docChanges().forEach((change) => {
                    // contMensajes++;
                    // console.log(contMensajes)
                    
                    let mensaje = change.doc.data();

                    if (change.type === "added") {
                        mensaje["id"] = change.doc.id;
                        mensaje["docRef"] = change.doc.ref;

                        this.setInfoMsgByPersona(mensaje, null);
                    }

                    if (change.type === "removed") {
                        this.setInfoMsgByPersona(mensaje, change.doc.id);

                    }

                    // if (numMensajes == contMensajes) {
                    //     if (this.cicloMensajesActual == this.cantCiclosMensajes) {
                    //         this.mensajesInicializado = true;
                    //     } else {
                    //         this.cicloMensajesActual++;
                    //     }
                    // }

                });

                /* Procedemos a consultar las personas que no se encontraban en el listado inicial precargado*/
                setTimeout(function () {this.agregarPersonasNuevas();}.bind(this), 0);

            });
        },

        setInfoMsgByPersona(mensaje, idDoc) {
            let indexPersona = this.listPersonas.findIndex(element => element.id == mensaje.idPersona);
            let persona = null;

            if (indexPersona >= 0) {
                persona = this.listPersonas[indexPersona];
            }

            if (persona){
                if (!idDoc) {
                    persona.cantMensajes += 1;
                    // if (this.mensajesInicializado) {
                    //     persona.idGrupoMensajeria = 1;
                    // }

                    persona.messages.push(mensaje);

                    // if (indexPersona > 0) {
                    //     this.arraymove(this.listPersonas, indexPersona, 0);
                    // }

                } else {
                    persona.cantMensajes += -1;

                    let mensajeIndex = persona.messages.findIndex(element => element.id == idDoc);
                    if (mensajeIndex >= 0) {
                        persona.messages.splice(mensajeIndex, 1);
                    }
                }
            } else {
                if (!this.consultaDesdeFiltros) {
                    /* Verificamos si la persona asociada al mensaje entrante existe localmente, de lo contrario
                    procedemos a agregar a dicha persona a un listado temporal para consultarlas posteriormente a BD */
                    let indexPersonaAgregar = this.listPersonasAgregar.findIndex(element => element.id == mensaje.idPersona);
                    let personaAgregar;

                    if (indexPersonaAgregar < 0) {
                        this.listPersonasAgregar.push({id:mensaje.idPersona, mensajes:[mensaje]});
                    } else {
                        personaAgregar = this.listPersonasAgregar[indexPersonaAgregar];
                        if (personaAgregar) {
                            personaAgregar.mensajes.push(mensaje);
                        }
                    }
                }
                
                // let doc = await this.getDocumentFirestore("personas", mensaje.idPersona, false);
                
                // if (doc) {
                //     persona = doc.data();
                //     persona["id"] = doc.id;
                //     persona["docRef"] = doc.ref;
                //     persona["cantMensajes"] = 1;
                //     persona["messages"] = [mensaje];
                //     persona.idGrupoMensajeria = persona.idGrupoMensajeria ? persona.idGrupoMensajeria : 1;

                //     if (!persona.mensajeVisto) {
                //         persona.mensajeVisto = 'N';
                //     }

                //     // this.listPersonas.unshift(persona);
                //     // this.ordenarLista(this.listPersonas);

                // }
            }
		},

        agregarPersonasNuevas() {
            
            for (const personaAgregar of this.listPersonasAgregar) {
                let indexPersona = this.listPersonas.findIndex(element => element.id == personaAgregar.id);

                if (indexPersona < 0) {
                    setTimeout(async function () {
                        let doc = await this.getDocumentFirestore("personas", personaAgregar.id, false);
                
                        if (doc) {
                            let persona = doc.data();
                            persona["id"] = doc.id;
                            persona["docRef"] = doc.ref;
                            persona["cantMensajes"] = personaAgregar.mensajes.length;
                            persona["messages"] = personaAgregar.mensajes;
                            persona.idGrupoMensajeria = persona.idGrupoMensajeria ? persona.idGrupoMensajeria : 1;
    
                            if (!persona.mensajeVisto) {
                                persona.mensajeVisto = 'N';
                            }
    
                            this.listPersonas.push(persona);
    
                        }
                    }.bind(this), 0);
                }
            }

        },

        arraymove(arr, fromIndex, toIndex) {
            let element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
        },

        mostrarMensajeria(persona, fromMessageAlerts) {
            this.personaMsg = {};

            try {

                if (persona && (fromMessageAlerts || this.isSamePersonDomAndObject(persona))) {
                    
                    this.actualizarAsesorAndVistoPersona(persona);

                    // if (this.personaMsg && this.personaMsg.idPersona != persona.id) {
                    
                    // setTimeout(function () {
                    this.personaMsg = {
                        idPersona : persona.id,
                        codArea: persona.codArea ? persona.codArea : '+57',
                        nombres: this.getNombresPersona(persona.nombres, persona.apellidos),
                        ...persona
                        
                    };
                    
                    this.marcarMensajesVisto(persona);

                    // }.bind(this), 300);

                    // } else {
                    //     this.marcarMensajesVisto(persona);

                    // }
                } else {
                    this.$router.go(0);
                }
            } catch (error) {
                this.$router.go(0);
            }    

        },

        refrescarContactos() {
            this.mostrarListadoPersonas = false;
            
            setTimeout(function () {
                this.mostrarListadoPersonas = true;
            }.bind(this), 500);
        },

        actualizarAsesorAndVistoPersona(persona) {
            if(persona.asesorMensajeria) {
                if (persona.asesorMensajeria.trim().toLowerCase() != this.$store.getters.getUserName.trim().toLowerCase()) {
                    this.$confirm({
                        message: this.$t('message.mensajeria.modificarAsesor'),
                        button: { no: this.$t('message.general.no'), yes: this.$t('message.general.si') },
                        callback: confirm => {
                            if (confirm) {
                                persona.asesorMensajeria= this.$store.getters.getUserName;
                                this.actualizarInfoPersona(persona);
                            } else {
                                this.actualizarInfoPersona(persona);
                            }
                        }
                    });
                }
            } else {
                persona.asesorMensajeria= this.$store.getters.getUserName;
                this.actualizarInfoPersona(persona);
            }
        },

        actualizarInfoPersona(persona) {
            if (persona.docRef) {
                persona.docRef.set({
                    mensajeVisto: persona.mensajeVisto,
                    asesorMensajeria: persona.asesorMensajeria
                }, { merge: true }).catch(
                    function(error) {
                        console.error("Error actualizando asesor mensajeria: ", error);

                    }.bind(this)
                );
            }
        },

        marcarMensajesVisto(persona) {
            this.$store.commit("ejecutarLoading");
            let batch = firestore.batch();

            persona.messages.forEach(function(mensaje) {
                batch.update(mensaje.docRef, {visto: true}, { merge: true });

            });

            batch.commit().then(
                function() {
                    this.contMostrarCompMsg++;
                    this.$store.commit("detenerLoading");

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },
        
        mostrarPersona(persona) {
            console.log("");
            console.log(new Date() + "Clic editar persona: " + persona.id);
            this.personaTmp = {};

            try {

                // setTimeout(function () {
                if (persona && this.isSamePersonDomAndObject(persona)) {
                    this.personaTmp = persona;
                    this.contMostrarCompPersona++;
                    setTimeout(function () {$('#popupPersonas').modal('show');}.bind(this), 100);
                    console.log(new Date() + "Mostrando popup persona: " + this.personaTmp.id);

                } else {
                    console.log(new Date() + "Persona no existe: " + persona.id);
                    this.$router.go(0);
                }
                // }.bind(this), 200);

            } catch (error) {
                console.log(new Date() + "Error editar persona: " + persona.id);
                this.$router.go(0);
            }
            
        },

        isSamePersonDomAndObject(persona) {  
            try {
                let textLabel   = document.getElementById('span_' + persona.id).textContent;
                let textPersona = persona.telefonoMovil + ' ('+ (this.formatSecondsToDate(persona.fechaUltimoMensaje ? persona.fechaUltimoMensaje.seconds : null, "DD/MM/YYYY HH:mm") + ')');
                
                return textLabel == textPersona;
            } catch (error) {
                return true;
            }
            
        },

        getNombresPersona(nombres, apellidos) {
            return nombres + " " + (apellidos ? apellidos : "")
        },

        marcarEstadoVistoMensaje(persona) {        
            if (persona) {
                if (persona.mensajeVisto == 'S') {
                    persona.mensajeVisto = 'N';
                } else {
                    persona.mensajeVisto = 'S';
                }

                this.actualizarInfoPersona(persona);
            }
        },

        actualizarGrupoMensajeria(persona, idGrupoMensajeria) {
            if (persona.docRef) {
                persona.docRef.set({
                    idGrupoMensajeria: idGrupoMensajeria
                }, { merge: true }).then(function(doc) {
                    persona.idGrupoMensajeria = idGrupoMensajeria;

                }.bind(this)).catch(
                    function(error) {
                        console.error("Error actualizando grupo mensajeria: ", error);

                    }.bind(this)
                );
            }
        }

    }

}