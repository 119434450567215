import store from '../store'

export default {
    install(Vue) { 
          Vue.mixin({
            created(){
                this.setDefaultDateRangeFilter();
            },
            methods: {
                getFechaActual(){
                    return this.$moment(new Date()).format("YYYY-MM-DD");

                },
                getMesPasado(){
                    var date = new Date()
                    date.setMonth(date.getMonth() - 1);
                    return this.$moment(date).format("YYYY-MM-DD");

                },
                setDefaultDateRangeFilter(){
                    this.$communicates.filtros.startdate= this.getMesPasado();
                    this.$communicates.filtros.enddate= this.getFechaActual();
                },
                getMonthsBetween(date1, date2, roundUpFractionalMonths) {
                    //Months will be calculated between start and end dates.
                    //Make sure start date is less than end date.
                    //But remember if the difference should be negative.
                    var startDate = date1;
                    var endDate = date2;
                    var inverse = false;
                    if (date1 > date2) {
                        startDate = date2;
                        endDate = date1;
                        inverse = true;
                    }

                    //Calculate the differences between the start and end dates
                    var yearsDifference = endDate.getFullYear() - startDate.getFullYear();
                    var monthsDifference = endDate.getMonth() - startDate.getMonth();
                    var daysDifference = endDate.getDate() - startDate.getDate();

                    var monthCorrection = 0;
                    //If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
                    //The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
                    if (roundUpFractionalMonths === true && daysDifference > 0) {
                        monthCorrection = 1;
                    }
                    //If the day difference between the 2 months is negative, the last month is not a whole month.
                    else if (roundUpFractionalMonths !== true && daysDifference < 0) {
                        monthCorrection = -1;
                    }

                    return (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection);
                },
                reset_date_native(idDateElement) {
                    var date_input = document.getElementById(idDateElement);

                    //erase the input value
                    date_input.value = '';

                    //prevent error on older browsers (aka IE8)
                    if (date_input.type === 'date') {
                        //update the input content (visually)
                        date_input.type = 'text';
                        date_input.type = 'date';
                    }
                },

                isNotValidRangoDeFechas(dateFechaInicio, dateFechaFin, cantidadMeses) {
                    if (dateFechaInicio.value != '' && dateFechaFin.value != '' && this.getMonthsBetween(new Date(dateFechaInicio.valueAsDate), new Date(dateFechaFin.valueAsDate), true) > cantidadMeses) {
                        var message = "";
                        if (cantidadMeses == 1) {
                            message = "1 mes.";
                        } else {
                            message = cantidadMeses + " meses."
                        }
                        alert("El rango de fechas no debe superar " + message);
                        return true;
                    } else {
                        return false;
                    }
                },
                eliminarValoresFiltrosFechaInicioFechaFin() {
                    this.$communicates.filtros.startdate = '';
                    this.$communicates.filtros.enddate = '';
                },
                validadorFechaInicioFechaFinHandler(idFechaInicio, idFechaFin, pCantidadMeses) {
                    var dateFechaInicio = document.getElementById(idFechaInicio);
                    var dateFechaFin = document.getElementById(idFechaFin);
                    var ref = this;
                    const cantidadMeses = pCantidadMeses ? pCantidadMeses : 1;
                    dateFechaInicio.onchange = function () {
                        dateFechaFin.setAttribute('min', this.value);
                        dateFechaInicio = document.getElementById(idFechaInicio);
                        dateFechaFin = document.getElementById(idFechaFin);
                        if (dateFechaFin.value != '' && dateFechaFin.value < this.value) {
                            dateFechaFin.value = this.value;
                        }
                        if (ref.isNotValidRangoDeFechas(dateFechaInicio, dateFechaFin, cantidadMeses)) {
                            ref.eliminarValoresFiltrosFechaInicioFechaFin();
                        }

                    };
                    dateFechaFin.onchange = function () {
                        dateFechaInicio = document.getElementById(idFechaInicio);
                        dateFechaFin = document.getElementById(idFechaFin);
                        if (dateFechaInicio.value != '' && dateFechaInicio.value > this.value) {
                            dateFechaInicio.value = this.value;
                        }
                        if (ref.isNotValidRangoDeFechas(dateFechaInicio, dateFechaFin, cantidadMeses)) {
                           ref.eliminarValoresFiltrosFechaInicioFechaFin();
                        }
                    };
                   
                }

            }
        });

        Vue.prototype.$communicates = Vue.observable({
            filtros: {
                startdate: '',
                enddate: ''
            }
          })

    }
}




