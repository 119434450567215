 <template>
	<div>
		<div id="page-top">
			<div id="wrapper">
				<!-- Navbar de menus  -->
				<nav-bar-menu-component/>

				<div class="d-flex flex-column" id="content-wrapper">
					<div id="content" style="margin-top: 5rem">
						<!-- Nav superior -->
						<nav-bar-header-component/>

						<div class="border rounded d-inline scroll-to-top" style="z-index: 100">
							<i class="fas fa-angle-up"></i>
						</div>

						<!-- Contenido -->
						<div class="container-fluid">
							<router-view></router-view>
						</div>
					</div>
					<footer-component/>
					<task-component/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue';
import NavBarMenuComponent from '../components/NavBarMenuComponent.vue';
import NavBarHeaderComponent from '../components/NavBarHeaderComponent.vue';
import TaskComponent from '../components/task/TaskComponent.vue';
import { firebase, auth } from '@/firebase/firebaseInit.js';

export default {
	components: {
		FooterComponent,
		NavBarMenuComponent,
		NavBarHeaderComponent,
		TaskComponent
	},

	data() {
		return {
			listPersonasFindTmp: [],
            listenerPersonasStop: null,
            listenerPersonasSnStop: null,
			inicializado: false,
            banderasCargaPersonas : {
                conAsesor: false,
                sinAsesor: false
            }
		};
	},

	mounted() {
		this.toggleSideNavigation();
		this.setZIndexModals();
		this.$store.commit("setSesionIniciada",true);
		this.inicializar();
	},

	beforeDestroy() {
        if (this.listenerPersonasStop) {
            this.listenerPersonasStop();
        }
        if (this.listenerPersonasSnStop) {
            this.listenerPersonasSnStop();
        }     

    },

	methods: {
		toggleSideNavigation() {
			// Toggle the side navigation
			window.$("#sidebarToggle, #sidebarToggleTop").on("click", function () {
				window.$("body").toggleClass("sidebar-toggled");
				window.$(".sidebar").toggleClass("toggled");
				if (window.$(".sidebar").hasClass("toggled")) {
					window.$(".sidebar .collapse").removeClass("show");
					$(this).css("margin-left", "0rem");

					// $('.sidebar .collapse').collapse('hide');
				} else {
					$(this).css("margin-left", "6rem");
				}
			});

			// Close any open menu accordions when window is resized below 768px
			window.$(window).resize(function () {
				if (window.$(window).width() < 768) {
					window.$(".sidebar .collapse").removeClass("show");
					// $('.sidebar .collapse').collapse('hide');
				}

				if (window.$(window).width() < 450) {
					window.$("#content").css("margin-top", "10rem");
				} else {
					window.$("#content").css("margin-top", "5rem");
				}
			});

			if (window.$(window).width() < 450) {
				window.$("#content").css("margin-top", "10rem");
			}

			// Prevent the content wrapper from scrolling when the fixed side navigation hovered over
			window
				.$("body.fixed-nav .sidebar")
				.on("mousewheel DOMMouseScroll wheel", function (e) {
					if (window.$(window).width() > 768) {
						var e0 = e.originalEvent,
							delta = e0.wheelDelta || -e0.detail;
						this.scrollTop += (delta < 0 ? 1 : -1) * 30;
						e.preventDefault();
					}
				});

			// Scroll to top button appear
			// window.$(document).on("scroll", function () {
			//     var scrollDistance = window.$(this).scrollTop();
			//     if (scrollDistance > 100) {
			//         window.$(".scroll-to-top").fadeIn();
			//     } else {
			//         window.$(".scroll-to-top").fadeOut();
			//     }
			// });

			// Smooth scrolling using jQuery easing
			window.$(document).on("click", "div.scroll-to-top", function (e) {
				var $anchor = window.$(this);
				window.$("html, body").animate({ scrollTop: 0 }, "slow");
				e.preventDefault();
			});
		},

		setZIndexModals() {
			$(document).on({
				'show.bs.modal': function () {
						var zIndex = 1040 + (10 * $('.modal:visible').length);
						$(this).css('z-index', zIndex);
						setTimeout(function() {
								$('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
						}, 0);
				},
				'hidden.bs.modal': function() {
						if ($('.modal:visible').length > 0) {
								// restore the modal-open class to the body element, so that scrolling works
								// properly after de-stacking a modal.
								setTimeout(function() {
										$(document.body).addClass('modal-open');
								}, 0);
						}
				}
			}, '.modal');
		},

		inicializar() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user?.uid && this.$store.getters.getIdOrganizacion) {
					try {
						this.consultarPersonas(user.uid);
					} catch (error) {
						this.$store.commit("detenerLoading");
					}
                }
            });
        },

        async consultarPersonas(uid) {
            this.listPersonasFindTmp = [];
            this.$store.commit("initListPersonasFind");
			
			if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
				this.banderasCargaPersonas.sinAsesor = false;
			} else {
				this.banderasCargaPersonas.sinAsesor = true;
			}

			
            await this.consultaPersonasConAsesor(uid);
            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                await this.consultaPersonasSinAsesor(uid);
            }
            
        },

        async consultaPersonasConAsesor(uid) {
            let collection;

            collection = this.getCollectionListFirestore('personas_find');
            collection = this.getCollectionFiltered(collection, uid, true);

            if (this.listenerPersonasStop) {
                this.listenerPersonasStop();
            }
            this.listenerPersonasStop = await this.getListenerPersonas(collection, true);

        },

        async consultaPersonasSinAsesor(uid) {
            let collection;
            /* Obtenemos un listado de personas para el escuchador que esta pendiente
            del cambio de nombres de la persona o el asesor de mensajeria */
            collection = this.getCollectionListFirestore('personas_find');
            collection = this.getCollectionFiltered(collection, uid, false);

            if (this.listenerPersonasSnStop) {
                this.listenerPersonasSnStop();
            }
            this.listenerPersonasSnStop = await this.getListenerPersonas(collection, false);

        },

        getCollectionFiltered(pCollection, uid, incluirAsesor) {
            let collection = pCollection;
            // collection = collection.where("temporal", "==", false);
            // collection = collection.where("id", "==", "dsprueba");

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (incluirAsesor) {
                    let uidTmp = uid ? uid : auth.currentUser.uid;
                    collection = collection.where('idUsuarioAsesor', '==', uidTmp);

                } else {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                }
            }

            return collection;
        },

        async getListenerPersonas(collection, incluirAsesor) {
            this.$store.commit("ejecutarLoading");
            
            return await collection.onSnapshot((snapshot) => {
                if (incluirAsesor) {
                    this.banderasCargaPersonas.conAsesor = false;
                } else {
                    this.banderasCargaPersonas.sinAsesor = false;
                }

                for (const change of snapshot.docChanges()) {
                    let persona = change.doc.data();
                    persona["idAlterno"] = change.doc.id;
                    // persona["docRef"] = change.doc.ref;

                    if (change.type === "added") {
                        if (!this.inicializado) {
                            this.listPersonasFindTmp.push(persona);
                        } else {
                            this.$store.commit("addListPersonasFind", persona);
                        }

                    } else if (change.type === "modified") {
                        this.$store.commit("updatePersonaFind", persona);

                    } else if (change.type === "removed") {
                        this.$store.commit("removePersonaFind", persona);

                    }

                };

                if (incluirAsesor) {
                    this.banderasCargaPersonas.conAsesor = true;
                } else {
                    this.banderasCargaPersonas.sinAsesor = true;
                }

                if (!this.inicializado && this.banderasCargaPersonas.conAsesor && this.banderasCargaPersonas.sinAsesor) {
                    this.inicializado = true;
                    this.$store.commit("setListPersonasFind", this.listPersonasFindTmp);
                    this.$store.commit("detenerLoadingCount",2);
                }
            });
        }
	},


};
</script>