<template>
  <nav
    id="navPrincipal"
    class="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0 toggled"
    style="z-index: 1031"
  >
    <div class="sticky-top container-fluid d-flex flex-column p-0">
      <a
        class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0"
        href="#"
      >
        <div class="sidebar-brand-icon">
          <img 	
                src="@/assets/img/logoFourBrainsPeq.png"
          />
        </div>
        <div class="sidebar-brand-text mx-3">
          <span>Suite Brains</span>
        </div>
      </a>
      <hr class="sidebar-divider my-0" />

      <div id="accordion" >
        <div  
          v-for="(moduloRecurso, key, index) in routesLinkMenuPrincipal"
          :key="index"
        >
          <div :id="'heading' + index" >
            <h5>
              <a
                href="#"
                class="nav-link text-light text-left"
                data-toggle="collapse"
                :data-target="'#collapse' + index"
                aria-expanded="true"
                :aria-controls="'collapse' + index"
                @click="verificarAlineacion()"
              >
                <i :class="moduloRecurso.classCssLinkMenu"> </i>
                <span class="text-left">{{ $t(key) }}</span>
              </a>
            </h5>
          </div>

          <div
            :id="'collapse' + index"
            class="collapse"
            :aria-labelledby="'heading' + index"
            data-parent="#accordion"
          >
            <div id="contenedorSubMenus" class="contenedorSubMenus">
              <ul class="nav navbar-nav text-light" id="accordionSidebar">
                <template  v-for="(recurso, keyResource, indexResources) in moduloRecurso.listRecursos">
                  <li
                    class="nav-item"
                    :key="indexResources"
                
                  >
                    <!-- <router-link
                      v-if="recurso.meta.params"
                      exact-active-class="text-success"
                      :to="{ name: recurso.path, params: recurso.meta.params }"
                      class="nav-link text-light"
                    >
                      <i :class="recurso.meta.classCssLinkMenu"> </i>
                      <span class="text-left">{{ $t(recurso.nombre) }}</span>
                    
                    </router-link> -->

                    <router-link
                      exact-active-class="text-success"
                      :to="{ name: recurso.path}"
                      class="nav-link text-light"
                    >
                      <i :class="recurso.meta.classCssLinkMenu"> </i>
                      <span>{{ $t(recurso.nombre) }}</span>
                    
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center d-none d-md-inline">
        <button
          class="btn rounded-circle border-0"
          id="sidebarToggle"
          type="button"
          @click="verificarAlineacion()"
        ></button>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  computed: {
    routesLinkMenuPrincipal(){
      let modulosRecursosUsuario = this.$store.getters.getListModulosRecursos;
      return JSON.parse(JSON.stringify(modulosRecursosUsuario));
    }
  },
  methods: {
    verificarAlineacion() {
      // Obtén el elemento <nav> por su id
      let navElement = document.getElementById('navPrincipal');

      // Verifica si el elemento <nav> tiene la clase "toggled"
      if (navElement.classList.contains('toggled')) {
        this.$nextTick(() => {
          // Obtén el div interno
          let myDivs = document.getElementsByClassName("card-body contenedorSubMenus")
          this.modifyClass(myDivs, false);
          this.modifyClass(myDivs, false);
        });

      } else {
        this.$nextTick(() => {
          // Obtén el div interno
          let myDivs = document.getElementsByClassName("contenedorSubMenus")
          this.modifyClass(myDivs, true);

        });
      }
    },

    modifyClass(myDivs, add) {
      for (let myDiv of myDivs) {
        if (add) {
          myDiv.classList.add('card-body');
        } else {
          myDiv.classList.remove('card-body');
        }
        
      }
    }
  }
};
</script>