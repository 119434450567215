import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend, configure } from "vee-validate";
import { required, email, min, confirmed, regex, numeric, min_value, max_value } from "vee-validate/dist/rules";
import { i18n } from "./i18n";

configure({
  defaultMessage: (field, values) => {
    // override the field name.

    let msg = i18n.t(`fields.${field}`);

    if (msg.includes('fields.')) {
        values._field_ = '';
        
    } else {
        values._field_ = msg;
    }

    // values._field_ = '';

    return i18n.t(`validation.${values._rule_}`, values);
  }
});

extend("required", required);
extend("confirmed", confirmed);
extend("email", email);
extend("min", min);
extend("regex", regex);
extend("numeric", numeric);
extend("min_value", min_value);
extend("max_value", max_value);

// Valida si un valor es menor que otro valor, de lo contrario genera error de validacion.
extend('menorQue', {
  params: ['target'],
  validate(value, { target }) {
    return value <= target;
  },
  message: 'Fecha desde mayor que fecha hasta'
});

// Valida si un valor es mayor que otro valor, de lo contrario genera error de validacion.
extend('mayorQue', {
  params: ['target'],
  validate(value, { target }) {
    return value >= target;
  },
  message: 'Fecha hasta menor que fecha desde'
});

// Valida si la cantidad de dias entre un rango de fechas es menor a 60 dias, de lo contrario genera error de validacion.
extend('rangoDiasDesde', {
  params: ['target'],
  validate(value, { target }) {
    var date1 = new Date(value);
    var date2 = new Date(target);
    
    var diferenciaTime = date2.getTime() - date1.getTime();
  
    // To calculate the no. of days between two dates
    var diferenciaDias = diferenciaTime / (1000 * 3600 * 24);

    return diferenciaDias <= 60;
  },
  message: 'La cantidad de dias entre la fecha desde y hasta no puede superar los 60 dias.'
});

// Valida si la cantidad de dias entre un rango de fechas es menor a 60 dias, de lo contrario genera error de validacion.
extend('rangoDiasHasta', {
  params: ['target'],
  validate(value, { target }) {
    var date1 = new Date(target);
    var date2 = new Date(value);
    
    var diferenciaTime = date2.getTime() - date1.getTime();
  
    // To calculate the no. of days between two dates
    var diferenciaDias = diferenciaTime / (1000 * 3600 * 24);

    return diferenciaDias <= 60;
  },
  message: ' '
});

Vue.component('vo', ValidationObserver);
Vue.component('vp', ValidationProvider);