import { firebase } from '@/firebase/firebaseInit.js';
import store from '@/store';

function getPathArchivo(idColeccion) {
    let path = `archivos/${store.getters.getIdOrganizacion}/`;
    
    if (idColeccion == store.state.constantes.tipoColeccion.HISTORIA_CLINICA) {
        path += store.state.constantes.carpetaStorage.HISTORIA_CLINICA;

    } else if (idColeccion == store.state.constantes.tipoColeccion.PERSONAS) {
        path += store.state.constantes.carpetaStorage.PERSONAS;

    }  else if (idColeccion == store.state.constantes.tipoColeccion.EMBUDOS) {
        path += store.state.constantes.carpetaStorage.EMBUDOS;

    } else if (idColeccion == store.state.constantes.tipoColeccion.PLANTILLAS) {
        path += store.state.constantes.carpetaStorage.PLANTILLAS;

    } else {
        path += store.state.constantes.carpetaStorage.OTROS;
    }

    if (path.slice(-1) != "/") {
        path += '/';
    }

    path += getFileName(45);

    return path;
}

function getFileName(cantCaracteres) {
    let cadena = '';
    let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 
            'abcdefghijklmnopqrstuvwxyz0123456789' +
            "_-";
      
    for (let i = 1; i <= cantCaracteres; i++) {
        let char = Math.floor(Math.random()
                    * str.length + 1);
        
        cadena += str.charAt(char);
    }

    return cadena;

}

function subirArchivo(pathFile, fileBase64) {
    let storageRef = firebase.storage().ref();
    storageRef = storageRef.child(pathFile);
    let uploadTask = storageRef.putString(fileBase64, 'data_url');

    return new Promise((resolve, reject) => {
        try {
            uploadTask.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        // console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        // console.log('Upload is running');
                        break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            console.log('User doesn\'t have permission to access the object', error);
                            break;
                            
                        case 'storage/canceled':
                            console.log('User canceled the upload', error);
                            break;

                        case 'storage/unknown':
                            console.log('Unknown error occurred, inspect error.serverResponse', error);
                            break;
                    }
                    reject(null);

                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve(downloadURL);
                    //   console.log('File available at', downloadURL);
                    });
                }
            );
        } catch(e) {
            console.log(e);
            reject(null);
    
        }
    });

}

export {
    getPathArchivo,
    subirArchivo
};