export const constantes = {
    PAGINA_INICIAL: 1,
    CANT_REG_POR_PAGINA: 6,
    FILTRO_TODOS: 0,
    GRUPO_INFORMACION_GLOBAL: 'Global',

    TIPO_DOCUMENTO_DEFECTO: 2,
    TIPO_PERSONA_DEFECTO: 2,

    ubicacionesGeograficas : {
        PAIS_INICIAL : 1,
        TIPO_PAIS : 1,
        TIPO_DEPARTAMETO: 2,
        TIPO_CIUDAD: 3
    },

    tipoPersona : {
        LEADS: 2,
        EMPLEADO: 3
    },

    tipoEvento : {
        LLAMADA: 'Llamada', 
        TAREA: 'Tarea', 
        WP: 'WhatsApp',
        EMAIL: 'Email',
        REUNION: 'Reunion',
        URL: 'URL',
        NOTA: 'Nota',
        REUNION: 'Reunión',
        INFORMACION: 'Información'
    },

    estadoEvento : {
        PENDIENTE: 'Pendiente', 
        PROCESO: 'En proceso',
        FINALIZADO: "Finalizado", 
        CANCELADO: "Candelado"
    },

    tipoEstadisticaPersona : {
        CANT_PERSONAS_DIA_ETIQUETA: 1
    },

    tipoEtiqueta: {
        ETIQUETA_DEFAULT: 'Sin etiqueta',
        ETIQUETA_NUEVAS_PERSONAS: 'Personas nuevas',
        ETIQUETA_EMBUDO: 'Embudo'
    },

    tipoColeccion: {
        HISTORIA_CLINICA: 1,
        PERSONAS: 2,
        EMBUDOS: 3,
        CANCIONES: 4,
        PLANTILLAS: 5
	},

    carpetaStorage: {
        HISTORIA_CLINICA: 'historias/',
        PERSONAS: 'personas/',
        EMBUDOS: 'embudos/',
        CANCIONES: 'canciones/',
        OTROS: 'otros/',
        PLANTILLAS: 'plantillas/'
    },

    tipoParentesco: [
        {id:1, descripcion: 'Esposa(a)'}, 
        {id:2, descripcion: 'Padre'}, 
        {id:3, descripcion: 'Madre'}, 
        {id:4, descripcion: 'Abuelo(a)'}, 
        {id:5, descripcion: 'Hermano(a)'}, 
        {id:6, descripcion: 'Primo(a)'}, 
        {id:7, descripcion: 'otro'}
    ],

    modosCanciones: {
        LETRA: '1',
        GUITARRA: '2',
        BAJO: '3',
        BATERIA: '4'
    },

    /* tipoEvento : {
        LLAMADA: 'message.embudo.tipoEvento.llamada', 
        TAREA: 'message.embudo.tipoEvento.tarea', 
        WP: 'message.embudo.tipoEvento.wp',
        EMAIL: 'message.embudo.tipoEvento.email',
        REUNION: 'message.embudo.tipoEvento.reunion'
    },

    estadoEvento : {
        PENDIENTE: 'message.embudo.estadoEvento.pendiente', 
        PROCESO: 'message.embudo.estadoEvento.proceso',
        FINALIZADO: "message.embudo.estadoEvento.finalizado", 
        CANCELADO: "message.embudo.estadoEvento.cancelado"
    }*/
}