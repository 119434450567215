import { firebase, auth } from '@/firebase/firebaseInit.js';
import accionesComp from "@/components/AccionesComponent";
import gestionDocumentalComponent from "@/components/gestionDocumental/GestionDocumentalComponent.vue";
import PersonasImpl from "@/classes/personas/PersonasImpl";
import personaComp from "@/views/personas/Personas_det.vue";

export default {
    name: 'HistoriaClinicaComp',
    props: {
        pHistoriaClinica: {type: Object, required: false}
    },
    components: {
        accionesComp,
        gestionDocumentalComponent,
        personaComp
    },
    data() {
        return {
            historiaClinica: {
                idTipoDocumento: 0,
                numeroIdentificacion: '',
                nombres: '',
                nombreAcompanante: '',
                idTipoParentesco: '',
                motivoConsulta: '',
                enfermedadActual: '',
                antecedentesPatologia: '',
                antecedentesTraumaticas: '',
                antecedentesQuirurgicas: '',
                antecedentesFarmalogicas: '',
                antecedentesAlergias: '',
                antecedentesTransfusiones: '',
                antecedentesInmunizaciones: '',
                antecedentesMenarquia: '',
                antecedentesCiclos: '',
                antecedentesPlantificacion: '',
                antecedentesFum: '',
                antecedentesFamiliares: '',
                sistemaGeneral: '',
                sistemaRespiratorio: '',
                sistemaCardiovascular: '',
                sistemaGastrointestinal: '',
                sistemaGenitourinario: '',
                sistemaNeurologico: '',
                examenGeneral: '',
                examenPas: 0,
                examenPad: 0,
                examenPam: 0,
                examenFrecuenciaCardiaca: 0,
                examenFrecuenciaRespiratoria: 0,
                examenTemperatura: 0,
                examenSaturacion: 0,
                examenPeso: 0,
                examenTalla: 0,
                examenImc: 0,
                examenCabezaCuelloEstadoOjos: '',
                examenCabezaCuelloOjosObs: '',
                examenCabezaCuelloEstadoOidos: '',
                examenCabezaCuelloOidosObs: '',
                examenCabezaCuelloEstadNariz: '',
                examenCabezaCuelloNarizObs: '',
                examenCabezaCuelloEstadBoca: '',
                examenCabezaCuelloBocaObs: '',
                examenCabezaCuelloEstadCuello: '',
                examenCabezaCuelloCuelloObs: '',
                analisisPlan: '',
                diagnostico: '',
                conducta: '',
                prescripcionMedicamentos: '',
                observaciones: ''
            },
            mostrarCompGestionDoc: false,
            filtros: {},
            persona: null,
            contMostrarCompPersona: 0,

        }
    },

    watch: {
        '$store.state.loading': function (val, oldVal) {
            if(val == false) {
                this.$refs.form.validate();

            }
        },
        'historiaClinica.idPersona':async function (val, oldVal) {
            if (val) {
                this.persona = await PersonasImpl.getPersonaById(val);

                if (this.persona) {
                    this.historiaClinica.idPersona = this.persona.id;
                    this.historiaClinica.idTipoDocumento = this.persona.idTipoDocumento
                    this.historiaClinica.nombres = this.persona.nombres + ' ' + this.persona.apellidos;

                } else {

                    this.$store.dispatch("addAlert", {
                        tipo: "danger",
                        titulo: "",
                        descripcion: 'No se pudo consultar la información de la persona relacionada, esto puede presentarse cuando la persona ya no pertenece al grupo de información del usuario, por problemas de permisos o un error interno.'
                    });

                }
                

            } else {
                this.persona = null;
                this.historiaClinica.nombres = "";
                
            }
        }
    },

    mounted() {
        this.inicializar();

    },

    beforeDestroy() {

    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoading");
            this.loadHistoriaClinica();

        },

        loadHistoriaClinica() {
            if (this.pHistoriaClinica) {
                this.historiaClinica = Object.assign({} , this.pHistoriaClinica);
                this.mostrarCompGestionDoc = true;
                
            } else {
                this.$refs.form.validate();
                firebase.auth().onAuthStateChanged((user) =>{
                    if (!this.$store.getters.getSesionIniciada) {
                        return;
                    }
                    if(user){
                        this.mostrarCompGestionDoc = true;
                        this.historiaClinica.idUsuarioAsesor= user.uid;
                        this.historiaClinica.idGrupoInformacion= this.$store.getters.getIdGrupoInfomacion ? this.$store.getters.getIdGrupoInfomacion : this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL;
                    }
                });
                
            }
            
            this.$store.commit("detenerLoading");
        },

        // findPersona() {
        //     if (this.historiaClinica.numeroIdentificacion) {
        //         this.$store.commit("ejecutarLoading");
        //         let collection;
        //         collection = this.getCollectionListFirestore('personas');
        //         collection = this.getCollectionFiltered(collection);
        //         this.getPersona(collection);
        //     }

        // },

        getCollectionFiltered(pCollection) {
            var collection = pCollection;

            if (this.historiaClinica.idTipoDocumento) {
                collection = collection.where("idTipoDocumento", "==", this.historiaClinica.idTipoDocumento);
            }

            collection = collection.where("numeroIdentificacion", "==", this.historiaClinica.numeroIdentificacion);

            return collection;

        },

        async getPersona(collection) {
            await collection.get().then((querySnapshot) => {
                if (querySnapshot.empty) {
                    this.historiaClinica.nombres = "";
                } else {
                    querySnapshot.forEach((doc) => {
                        this.historiaClinica.idPersona = doc.id;
                        this.historiaClinica.idTipoDocumento = doc.data().idTipoDocumento
                        this.historiaClinica.nombres = doc.data().nombres + ' ' + doc.data().apellidos;

                    });
                }

                this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    if (error.code == "permission-denied") {
                        console.log("Permisos insuficientes sobre archivos");
                    } else {
                        console.log("Error getting archivos:", error);
                    }
                    
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );
        },

        mostrarPersona() {
            try {
                this.contMostrarCompPersona++;
                setTimeout(function () {$('#popupPersonas').modal('show');}.bind(this), 100);
                
            } catch (error) {
                console.log(new Date() + "Error editar persona: ");
            }
            
        },

        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.historiaClinica.docRef) {
                        this.actualizarHistoria();
    
                    } else {
                        this.crearHistoria();
    
                    }

                } else {
                    this.$store.dispatch("addAlert", {
                        tipo: "warning",
                        titulo: "",
                        descripcion: this.$t('message.personasDetalle.camposObligatorios')
                    });
                }
            });
        },

        crearHistoria() {
            let historiaCollection = this.getCollectionAddFirestore("historiaClinica");

            historiaCollection.add({
                idPersona: this.historiaClinica.idPersona,
                idTipoDocumento: this.historiaClinica.idTipoDocumento,
                numeroIdentificacion: this.historiaClinica.numeroIdentificacion,
                nombres: this.historiaClinica.nombres,
                nombresSearchTermsArray: this.getSearchTermsArray(this.historiaClinica.nombres),
                idGrupoInformacion: this.historiaClinica.idGrupoInformacion,
                idUsuarioAsesor : this.historiaClinica.idUsuarioAsesor,
                fechaCreacion: new Date(),
                nombreAcompanante: this.historiaClinica.nombreAcompanante,
                idTipoParentesco: this.historiaClinica.idTipoParentesco,
                motivoConsulta: this.historiaClinica.motivoConsulta,
                enfermedadActual: this.historiaClinica.enfermedadActual,
                antecedentesPatologia: this.historiaClinica.antecedentesPatologia,
                antecedentesTraumaticas: this.historiaClinica.antecedentesTraumaticas,
                antecedentesQuirurgicas: this.historiaClinica.antecedentesQuirurgicas,
                antecedentesFarmalogicas: this.historiaClinica.antecedentesFarmalogicas,
                antecedentesAlergias: this.historiaClinica.antecedentesAlergias,
                antecedentesTransfusiones: this.historiaClinica.antecedentesTransfusiones,
                antecedentesInmunizaciones: this.historiaClinica.antecedentesInmunizaciones,
                antecedentesMenarquia: this.historiaClinica.antecedentesMenarquia,
                antecedentesCiclos: this.historiaClinica.antecedentesCiclos,
                antecedentesPlantificacion: this.historiaClinica.antecedentesPlantificacion,
                antecedentesFum: this.historiaClinica.antecedentesFum,
                antecedentesFamiliares: this.historiaClinica.antecedentesFamiliares,
                sistemaGeneral: this.historiaClinica.sistemaGeneral,
                sistemaRespiratorio: this.historiaClinica.sistemaRespiratorio,
                sistemaCardiovascular: this.historiaClinica.sistemaCardiovascular,
                sistemaGastrointestinal: this.historiaClinica.sistemaGastrointestinal,
                sistemaGenitourinario: this.historiaClinica.sistemaGenitourinario,
                sistemaNeurologico: this.historiaClinica.sistemaNeurologico,
                examenGeneral: this.historiaClinica.examenGeneral,
                examenPas: this.historiaClinica.examenPas,
                examenPad: this.historiaClinica.examenPad,
                examenPam: this.historiaClinica.examenPam,
                examenFrecuenciaCardiaca: this.historiaClinica.examenFrecuenciaCardiaca,
                examenFrecuenciaRespiratoria: this.historiaClinica.examenFrecuenciaRespiratoria,
                examenTemperatura: this.historiaClinica.examenTemperatura,
                examenSaturacion: this.historiaClinica.examenSaturacion,
                examenPeso: this.historiaClinica.examenPeso,
                examenTalla: this.historiaClinica.examenTalla,
                examenImc: this.historiaClinica.examenImc,
                examenCabezaCuelloEstadoOjos: this.historiaClinica.examenCabezaCuelloEstadoOjos,
                examenCabezaCuelloOjosObs: this.historiaClinica.examenCabezaCuelloOjosObs,
                examenCabezaCuelloEstadoOidos: this.historiaClinica.examenCabezaCuelloEstadoOidos,
                examenCabezaCuelloOidosObs: this.historiaClinica.examenCabezaCuelloOidosObs,
                examenCabezaCuelloEstadNariz: this.historiaClinica.examenCabezaCuelloEstadNariz,
                examenCabezaCuelloNarizObs: this.historiaClinica.examenCabezaCuelloNarizObs,
                examenCabezaCuelloEstadBoca: this.historiaClinica.examenCabezaCuelloEstadBoca,
                examenCabezaCuelloBocaObs: this.historiaClinica.examenCabezaCuelloBocaObs,
                examenCabezaCuelloEstadCuello: this.historiaClinica.examenCabezaCuelloEstadCuello,
                examenCabezaCuelloCuelloObs: this.historiaClinica.examenCabezaCuelloCuelloObs,
                analisisPlan: this.historiaClinica.analisisPlan,
                diagnostico: this.historiaClinica.diagnostico,
                conducta: this.historiaClinica.conducta,
                prescripcionMedicamentos: this.historiaClinica.prescripcionMedicamentos,
                observaciones: this.historiaClinica.observaciones

            }).then(
                function(docRef) {
                    this.historiaClinica.id= docRef.id;
                    this.historiaClinica.docRef= docRef;
                    this.$emit('guardarGestionDocumental', {idDocumento: docRef.id});
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        actualizarHistoria() {
            this.historiaClinica.docRef.update({
                idPersona: this.historiaClinica.idPersona,
                idTipoDocumento: this.historiaClinica.idTipoDocumento,
                numeroIdentificacion: this.historiaClinica.numeroIdentificacion,
                nombres: this.historiaClinica.nombres,
                nombresSearchTermsArray: this.getSearchTermsArray(this.historiaClinica.nombres),
                idGrupoInformacion: this.historiaClinica.idGrupoInformacion,
                idUsuarioAsesor : this.historiaClinica.idUsuarioAsesor,
                nombreAcompanante: this.historiaClinica.nombreAcompanante,
                idTipoParentesco: this.historiaClinica.idTipoParentesco,
                motivoConsulta: this.historiaClinica.motivoConsulta,
                enfermedadActual: this.historiaClinica.enfermedadActual,
                antecedentesPatologia: this.historiaClinica.antecedentesPatologia,
                antecedentesTraumaticas: this.historiaClinica.antecedentesTraumaticas,
                antecedentesQuirurgicas: this.historiaClinica.antecedentesQuirurgicas,
                antecedentesFarmalogicas: this.historiaClinica.antecedentesFarmalogicas,
                antecedentesAlergias: this.historiaClinica.antecedentesAlergias,
                antecedentesTransfusiones: this.historiaClinica.antecedentesTransfusiones,
                antecedentesInmunizaciones: this.historiaClinica.antecedentesInmunizaciones,
                antecedentesMenarquia: this.historiaClinica.antecedentesMenarquia,
                antecedentesCiclos: this.historiaClinica.antecedentesCiclos,
                antecedentesPlantificacion: this.historiaClinica.antecedentesPlantificacion,
                antecedentesFum: this.historiaClinica.antecedentesFum,
                antecedentesFamiliares: this.historiaClinica.antecedentesFamiliares,
                sistemaGeneral: this.historiaClinica.sistemaGeneral,
                sistemaRespiratorio: this.historiaClinica.sistemaRespiratorio,
                sistemaCardiovascular: this.historiaClinica.sistemaCardiovascular,
                sistemaGastrointestinal: this.historiaClinica.sistemaGastrointestinal,
                sistemaGenitourinario: this.historiaClinica.sistemaGenitourinario,
                sistemaNeurologico: this.historiaClinica.sistemaNeurologico,
                examenGeneral: this.historiaClinica.examenGeneral,
                examenPas: this.historiaClinica.examenPas,
                examenPad: this.historiaClinica.examenPad,
                examenPam: this.historiaClinica.examenPam,
                examenFrecuenciaCardiaca: this.historiaClinica.examenFrecuenciaCardiaca,
                examenFrecuenciaRespiratoria: this.historiaClinica.examenFrecuenciaRespiratoria,
                examenTemperatura: this.historiaClinica.examenTemperatura,
                examenSaturacion: this.historiaClinica.examenSaturacion,
                examenPeso: this.historiaClinica.examenPeso,
                examenTalla: this.historiaClinica.examenTalla,
                examenImc: this.historiaClinica.examenImc,
                examenCabezaCuelloEstadoOjos: this.historiaClinica.examenCabezaCuelloEstadoOjos,
                examenCabezaCuelloOjosObs: this.historiaClinica.examenCabezaCuelloOjosObs,
                examenCabezaCuelloEstadoOidos: this.historiaClinica.examenCabezaCuelloEstadoOidos,
                examenCabezaCuelloOidosObs: this.historiaClinica.examenCabezaCuelloOidosObs,
                examenCabezaCuelloEstadNariz: this.historiaClinica.examenCabezaCuelloEstadNariz,
                examenCabezaCuelloNarizObs: this.historiaClinica.examenCabezaCuelloNarizObs,
                examenCabezaCuelloEstadBoca: this.historiaClinica.examenCabezaCuelloEstadBoca,
                examenCabezaCuelloBocaObs: this.historiaClinica.examenCabezaCuelloBocaObs,
                examenCabezaCuelloEstadCuello: this.historiaClinica.examenCabezaCuelloEstadCuello,
                examenCabezaCuelloCuelloObs: this.historiaClinica.examenCabezaCuelloCuelloObs,
                analisisPlan: this.historiaClinica.analisisPlan,
                diagnostico: this.historiaClinica.diagnostico,
                conducta: this.historiaClinica.conducta,
                prescripcionMedicamentos: this.historiaClinica.prescripcionMedicamentos,
                observaciones: this.historiaClinica.observaciones

            }, { merge: true }).then(
                function() {
                    this.$emit('guardarGestionDocumental', {idDocumento: this.historiaClinica.id});
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );


        },

    }
};