<template>
    <div>
        <li class="nav-item dropdown no-arrow mx-1">
            <div class="nav-item dropdown no-arrow">
                <a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#" >
                    <i class="fas fa-envelope fa-fw"></i>
                    <span v-if="messages.length>0" class="badge badge-danger badge-counter">{{messages.length}}</span>
                </a>
                <div style="height: 400px; overflow-y: scroll;" class="dropdown-menu dropdown-menu-right dropdown-list dropdown-menu-right animated--grow-in" >
                    <h6 class="dropdown-header">Mensajes</h6>

                    <!-- <router-link 
                        v-for="(mensaje, index) in messages" :key="index"
                        :id="'linkMensajePendiente_' + index"
                        :to="{
                            name: 'mensajes'
                        }"
                    > -->
                        <a class="d-flex align-items-center dropdown-item" style="cursor: pointer;" v-for="(mensaje, index) in messages" :key="index" @click="marcarMensajeVisto(mensaje)">
                            <div class="dropdown-list-image mr-3">
                                <div class="bg-primary icon-circle">
                                    <i class="fas fa-file-alt text-white"></i>
                                </div>
                                <!-- <img class="rounded-circle" src="@/assets/img/avatars/avatar5.jpeg"/> -->
                                <div class="bg-success status-indicator"></div>
                            </div>
                            <div class="font-weight-bold">
                                <div class="text-truncate">
                                    <span>
                                        {{mensaje.contenido}}
                                    </span>
                                </div>
                                <p class="small mb-0">
                                    <span class=" text-gray-600">
                                        {{mensaje.remitente}} ({{ formatSecondsToDate(mensaje.fecha.seconds, "DD/MM/YYYY HH:mm")}})
                                    </span>
                                    
                                </p>
                                
                            </div>
                        </a>
                    <!-- </router-link> -->
                    

                    <!-- <a class="d-flex align-items-center dropdown-item" href="#">
                        <div class="dropdown-list-image mr-3">
                            <img class="rounded-circle" src="@/assets/img/avatars/avatar4.jpeg"/>
                            <div class="bg-success status-indicator"></div>
                        </div>
                        <div class="font-weight-bold">
                            <div class="text-truncate">
                                <span>
                                    Hi there! I am wondering if you can help me with a problem I've been having.
                                </span>
                            </div>
                            <p class="small text-gray-500 mb-0">Emily Fowler - 58m</p>
                        </div>
                    </a>

                    <a class="d-flex align-items-center dropdown-item" href="#">
                        <div class="dropdown-list-image mr-3">
                            <img class="rounded-circle" src="@/assets/img/avatars/avatar2.jpeg"/>
                            <div class="status-indicator"></div>
                        </div>
                        <div class="font-weight-bold">
                            <div class="text-truncate">
                                <span>I have the photos that you ordered last month!</span>
                            </div>
                            <p class="small text-gray-500 mb-0">Jae Chun - 1d</p>
                        </div>
                    </a>

                    <a class="d-flex align-items-center dropdown-item" href="#">
                        <div class="dropdown-list-image mr-3">
                            <img class="rounded-circle" src="@/assets/img/avatars/avatar3.jpeg"/>
                            <div class="bg-warning status-indicator"></div>
                        </div>
                        <div class="font-weight-bold">
                            <div class="text-truncate">
                                <span>
                                    Last month's report looks great, I am very happy with the progress so far, keep up the good work!
                                </span>
                            </div>
                            <p class="small text-gray-500 mb-0">Morgan Alvarez - 2d</p>
                        </div>
                    </a> -->

                    <!-- <a class="text-center dropdown-item small text-gray-500" href="#">
                        Show All Alerts
                    </a> -->
                </div>
            </div>
            <div class="shadow dropdown-list dropdown-menu dropdown-menu-right" ria-labelledby="alertsDropdown"></div>
        </li>
    </div>
</template>

<script>
    import { firebase, auth } from '@/firebase/firebaseInit.js';

    export default {
        name: 'NavBarMensajeriaComp',

        data() {
        	return {
				messages: [],
				listenerMensajesStop: null,
				listenerMensajesStopSA: null,
			}
		},

		mounted() {
			this.inicializar();

		},

		beforeDestroy() {
			if (this.listenerMensajesStop) {
				this.listenerMensajesStop();
			}
			if (this.listenerMensajesStopSA) {
				this.listenerMensajesStopSA();
			}
		},


        methods: {
            inicializar() {
				firebase.auth().onAuthStateChanged((user) =>{
					if (!this.$store.getters.getSesionIniciada) {
						return;
					}
					if(user){
						this.consultar(user.uid);

					}
				});

			},

			async consultar(uid) {
				var uidTmp = uid;
				if (!uidTmp) {
					uidTmp = auth.currentUser.uid

				}

				this.messages = [];

				await this.consultaConAsesor(uidTmp);

				if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
					await this.consultaSinAsesor(uidTmp);
				}
				
			},

			async consultaConAsesor(uid){
				let collection = this.getCollectionListFirestore('mensajes');
				collection = this.getCollectionFiltered(collection, uid, true);

				if (this.listenerMensajesStop) {
					this.listenerMensajesStop();
				}

				this.listenerMensajesStop = await this.getMensajes(collection);
			},

			async consultaSinAsesor(uid){
				let collection = this.getCollectionListFirestore('mensajes');
				collection = this.getCollectionFiltered(collection, uid, false);

				if (this.listenerMensajesStopSA) {
					this.listenerMensajesStopSA();
				}

				this.listenerMensajesStopSA = await this.getMensajes(collection);
			},

			getCollectionFiltered(pCollection, uid, sinAsesor) {
				var collection = pCollection;

				if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
					if (sinAsesor) {
						collection = collection.where('idUsuarioAsesor', '==', '');
					} else {
						let uidTmp = uid ? uid : auth.currentUser.uid;
						collection = collection.where('idUsuarioAsesor', '==', uidTmp);
					}

				}

				collection = collection.where('visto', '==', false).orderBy('fecha');;
		
				return collection;
			},

			async getMensajes(collection) {
				const SECONDS_60_TO_MILISECONDS = 60000;
				return await collection.onSnapshot((snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type === "added") {
							let mensaje = change.doc.data();
							mensaje["id"] = change.doc.id;
							mensaje["docRef"] = change.doc.ref;
							mensaje.content = change.doc.data().contenido;
							mensaje.myself = change.doc.data().mySelf;
							mensaje.participantId = change.doc.data().mySelf ? 1 : 2;
							mensaje.type = 'text';

							var fecha = new Date(0);
							fecha.setSeconds(change.doc.data().fecha.seconds);

							mensaje.timestamp= {
								year: fecha.getFullYear(), 
								month: fecha.getMonth() + 1, 
								day: fecha.getDate(), 
								hour: fecha.getHours(), 
								minute: fecha.getMinutes(), 
								second: fecha.getSeconds(), 
								millisecond: fecha.getMilliseconds()
							};

							this.messages.unshift(mensaje);

							// Solo se muestran notificaciones de windows si el mensaje entrante no tiene mas
							// de 60 segundos de antiguedad.
							if (((new Date()).getTime() - fecha.getTime()) <= SECONDS_60_TO_MILISECONDS) {
								this.mostrarNotificacion(mensaje.content, change.doc.data().remitente);
								let music = new Audio(window.location.origin + '/alerta.mp3');
								music.play();
							}
						}

						if (change.type === "removed") {
							let index = this.messages.findIndex(element => element.id == change.doc.id);
							if (index >= 0) {
								this.messages.splice(index, 1);
							}

						}

					});
				});
			},

			async mostrarNotificacion(textoMensaje, remitente) {
				let granted = false;

				if (Notification.permission === 'granted') {
					granted = true;
				} else if (Notification.permission !== 'denied') {
					let permission = await Notification.requestPermission();
					granted = permission === 'granted' ? true : false;
				}

				if (granted) {
					const notification = new Notification(remitente, {
						body: textoMensaje,
						icon: '/logoFourBrainsPeq.png'
					});
				}
			},

			marcarMensajeVisto(mensaje) {
				this.$store.commit("ejecutarLoading");
				this.$store.commit("setMsgPersona", mensaje);

				if (document.location.pathname != "/menuPrincipal/mensajes") {
					this.$router.push({name:'mensajes'});
				} else {
					this.$store.commit("detenerLoading");
				}

			}

        }
    }
</script>

<style>
</style>