import accionesComp from "@/components/AccionesComponent";

export default {
    components: {
        accionesComp,
    },
    data() {
        return {
            listPerfiles : [],
            listGruposInformacion : [],
            filtros: {
                nombre: '',
                idGrupoInformacion : '',
                idOrden: 1,
                activo : true

            },
            mostrarTabla: false
            // options: ["foo", "bar", "baz"]

        }
    },
    created() {
        this.inicializar();

    },
    methods: {
        inicializar() {
            this.loadGruposInformacion();
            this.consultar();
        },

        loadGruposInformacion() {
            // if (!this.$store.getters.getIdGrupoInfomacion) {
                this.listGruposInformacion.push({id:'', nombre: this.$t('message.general.select_todos')});
                this.listGruposInformacion.push({id:this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL, nombre: this.$t('message.general.grupoDeInformacionGlobal')});
            // }

            this.getCollectionListFirestore('gruposDeInformacion').get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {  
                    this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});
                });

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                }.bind(this)
            );

            
        },

        async consultar() {
            // this.$emit('pruebita');
            this.mostrarTabla = false;
            this.destroyDataTable('#dataTable');
            this.listPerfiles = [];
            this.$store.commit("ejecutarLoading");
            
            let collection = this.getCollectionListFirestore('perfiles').orderBy('nombre');

            collection = this.getCollectionFiltered(collection);
            let querySnapshot = await collection.get().catch(
                function(error) {
                    console.log("Error getting document perfiles:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );

            // collection.get().then(async (querySnapshot) => {
            //     await querySnapshot.forEach(async (doc) => {
            if (querySnapshot && !querySnapshot.empty) {
                for (let doc of querySnapshot.docs){
                    var perfil = doc.data();
                    let nombreGrupoDeInformacion = '';
                    
                    if (perfil.idGrupoInformacion != this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL) {
                        try {
                            let gruposDeInformacionCollection = this.getCollectionListFirestore('gruposDeInformacion');
                            gruposDeInformacionCollection=gruposDeInformacionCollection.where("idGrupoInformacion", "==", perfil.idGrupoInformacion);
                            let grupoDeInformacion = await gruposDeInformacionCollection.get();
                            if(grupoDeInformacion.docs[0]){
                              nombreGrupoDeInformacion = grupoDeInformacion.docs[0].data().nombre;
                            }else{
                                nombreGrupoDeInformacion = this.$t('message.general.sinGrupo');
                            }

                        } catch (error) {
                            nombreGrupoDeInformacion = perfil.idGrupoInformacion;
                            
                        }
                        

                    } else {
                        nombreGrupoDeInformacion = perfil.idGrupoInformacion;

                    }

                    perfil["id"] = doc.id;
                    perfil["docRef"] = doc.ref;
                    perfil["nombreGrupoDeInformacion"]= nombreGrupoDeInformacion;

                    this.listPerfiles.push(perfil)
                }
                // });
                
            }

            this.mostrarTabla = true;
            this.setDataTable();
            this.$store.commit("detenerLoading");
            $('#popupFiltro').modal('hide');

            // }).catch(
            //     function(error) {
            //         console.log("Error getting document:", error);
            //         this.$store.commit("detenerLoading");
            //     }.bind(this)
            // );
        },      

        getCollectionFiltered(pCollection){
            let collection = pCollection;

            if (this.filtros.nombre) {
                collection = collection.where("searchTermsArray", "array-contains", this.filtros.nombre.toLowerCase());
            }

            if (this.filtros.idGrupoInformacion) {
                collection = collection.where("idGrupoInformacion", "==", this.filtros.idGrupoInformacion);
            }

            return collection;
        },

        setDataTable() {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.applyDataTable('#dataTable', 2);
            });
        },

        eliminar: function(perfil) {
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: {no: this.$t('message.general.no'), yes: this.$t('message.general.si')},
                callback: confirm => {
                    if (confirm) {
                        this.eliminarPerfil(perfil);
                    }
                }
            })
        },

        async eliminarPerfil(perfil){
            this.$store.commit("ejecutarLoading");
            
            if (perfil) {
                perfil.docRef.delete().then(() => {
                    this.consultar();
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }

     
        },



        agregarDocumento() {
            this.$store.commit("ejecutarLoading");
            var storeGetters = this.$store.getters;
            let perfilesCollection = this.getCollectionAddFirestore("perfiles");

            perfilesCollection.add({
                nombre: "Nuevo perfil",
                idGrupoInformacion: storeGetters.getIdGrupoInfomacion

            })
            .then(function(docRef) {
                docRef.collection("recursos").doc("perfiles").set({
                    readInner: true,
                    idGrupoInformacion: storeGetters.getIdGrupoInfomacion
                }).then(function(docRefDos) {
                    storeGetters.commit("detenerLoading");

                }).catch(function(error) {
                    console.error("Error adding document: ", error);
                    storeGetters.commit("detenerLoading");

                });
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
                this.$store.commit("detenerLoading");
            });
        },
    }
}