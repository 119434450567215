<template>
    <select id="sltMenuIdioma"
            v-model="locale"
            class="form-control form-control-sm custom-select custom-select-sm"
            style="margin-top: 1.2rem;"
    >
        <option v-for="(idioma, index) in $store.getters.getListIdiomas"
                :key="index" 
                v-bind:value="idioma.locale">
            {{idioma.descripcion}}
        </option>
    </select>
</template>

<script>
export default {
    data() {
        return {
            locale: this.$store.state.locale
        };
    },
    watch: {
        'locale': function (val, oldVal) {
            this.$i18n.locale = val;
            this.$store.commit("setLocale", val);

        }
    }
}
</script>

<style>

</style>