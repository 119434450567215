import { firebase } from '@/firebase/firebaseInit.js';
import axios from 'axios';
import {getPathArchivo, subirArchivo} from "@/classes/gestionDocumental/gestionDocumental";

export default {
    name: 'GestionDocumentalComponent',
    props: {
        pIdColeccion: {type: Number, required: false},
        pIdDocumento: {type: String, required: false}
    },
    components: {
    },
    data() {
        return {
            listArchivos: [],
            extensionFile: '',
            fileType: '',
            hrefArchivo: ''
        }
    },
    mounted() {
        this.inicializar();
    },

    beforeDestroy() {
    },

    watch: {
    },

    methods: {
        inicializar() {
            this.setEventosEmit();
            if (this.pIdDocumento) {
                this.consultar();
            }

        },

        setEventosEmit() {
            this.$parent.$on('guardarGestionDocumental', (pInfoDocumento) => {
                this.guardar(pInfoDocumento);
            });
            this.$parent.$parent.$on('guardarGestionDocumental', (pInfoDocumento) => {
                this.guardar(pInfoDocumento);
            });
        },

        async consultar() {
            this.$store.commit("ejecutarLoading");
            let collection = this.getCollectionListFirestore("archivos", null, true);
            collection = this.getCollectionFiltered(collection);
            await this.getArchivos(collection);
        },

        getCollectionFiltered(pCollection) {
            var collection = pCollection;
            collection = collection.where('idDocumento', '==', this.pIdDocumento);

            return collection;
        },

        async getArchivos(collection) {
            await collection.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var archivo = doc.data();
                    archivo["id"] = doc.id;
                    archivo["docRef"] = doc.ref;
                    archivo.nuevo= false;
                    archivo.modificado= false;
                    archivo.eliminado= false;

                    this.listArchivos.push(archivo);
                });

                this.setDataTable();
                this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    if (error.code == "permission-denied") {
                        console.log("Permisos insuficientes sobre archivos");
                    } else {
                        console.log("Error getting archivos:", error);
                    }
                    
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );
        },

        setDataTable() {
            setTimeout(function(){
                this.setResponsiveTable();
            }.bind(this), 1000);

        },

        
        getFile() {
            document.getElementById("fileDocBtnNuevo").click()
        },

        agregarNuevoDocumento(pIndex){
            var documentoFile;
            var continuar = false;

            if(pIndex === null && this.$refs.fileDocBtnNuevo.files.length > 0){
                documentoFile = this.$refs.fileDocBtnNuevo.files[0];
                continuar = true;

            } else if(this.$refs.fileDocumentoList[pIndex].files.length > 0){
                documentoFile = this.$refs.fileDocumentoList[pIndex].files[0]
                continuar = true;

            }
            // && this.isExtensionValid(documentoFile.type)
            if (continuar) {
                var reader = new FileReader();
                reader.readAsDataURL(documentoFile);
                reader.onload = function () {
                    this.setListadoDocumentos(reader.result, pIndex, documentoFile.name);
                }.bind(this);
                reader.onerror = function (error) {
                    console.log('Error: ', error);

                };
            }

        },

        isExtensionValid(type){
            let isExtensionValid = false;
            if ([   'image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'application/pdf',
                    'audio/mpeg3', 'audio/x-mpeg-3'
                ].includes(type)
            ) {
                isExtensionValid = true;
            }
            return isExtensionValid;

            
        },
        
        setListadoDocumentos(pFile, pIndex, fileName){
            if( pIndex === null) {
                var archivo = {
                    nombreArchivo: fileName ? fileName : this.$t('message.gestionDocumental.archivoNuevo'),
                    eliminado: false,
                    nuevo: true,
                    modificado: false,
                    file: pFile,
                    extension: pFile.split("base64,")[0].split("data:")[1].split("/")[1].split(";")[0].toLowerCase(),
                    fechaCreacion:  new Date()

                };
                this.listArchivos.push(archivo);

            } /*else {
                var documentoPersona = this.listArchivos[pIndex];
                documentoPersona.file = pFile;
                documentoPersona.modificado = true;

            }*/
        },

        modificarDocumento(pIndex){
            if (!this.listArchivos[pIndex].nuevo) {
                this.listArchivos[pIndex].modificado = true;

            }

        },

        async guardar(pInfoDocumento) {
            for (const archivo of this.listArchivos) {
                this.$store.commit("ejecutarLoading");
                if (archivo.nuevo) {
                    let downloadURL = await this.crearArchivo(archivo, pInfoDocumento);
                    debugger
                    console.log(downloadURL);

                } else if (archivo.modificado) {
                    this.editarArchivo(archivo, pInfoDocumento);

                } else if (archivo.eliminado) {
                    this.eliminarArchivoBd(archivo);

                }
                this.$store.commit("detenerLoading");
            }


        },

        crearArchivo(archivo, pInfoDocumento) {
            let collection = this.getCollectionAddFirestore("archivos");
            let archivoSave = {
                idColeccion: this.pIdColeccion,
                idDocumento: this.pIdDocumento ? this.pIdDocumento : pInfoDocumento.idDocumento,
                nombreArchivo: archivo.nombreArchivo,
                fechaCreacion: archivo.fechaCreacion,
                idGrupoInformacion: this.$store.getters.getIdGrupoInfomacion,
                pathArchivo: getPathArchivo(this.pIdColeccion),
                extension: archivo.extension
            };

            return new Promise((resolve, reject) => {
                try {
                    collection.add(archivoSave).then(
                        async function(docRef) {
                            archivo["id"] = docRef.id;
                            archivo["docRef"] = docRef;
                            archivo.nuevo = false;

                            let downloadURL = await subirArchivo(archivoSave.pathArchivo, archivo.file);
                            resolve(downloadURL);

                        }.bind(this)
                    ).catch(
                        function(error) {
                            console.error("Error adding archivo gestion documental: ", error);
                            this.$store.commit("detenerLoading");
                            reject(null);
                        }.bind(this)
                    );
                } catch(e) {
                    console.log(e);
                    reject(null);
            
                }
            });
        },

        editarArchivo(archivo, pInfoDocumento) {
            let archivoSave = {
                idColeccion: this.pIdColeccion,
                idDocumento: this.pIdDocumento ? this.pIdDocumento : pInfoDocumento.idDocumento,
                nombreArchivo: archivo.nombreArchivo,
                idGrupoInformacion: this.$store.getters.getIdGrupoInfomacion
            };

            archivo.docRef.set(archivoSave, { merge: true }).then(
                function() {
                    archivo.modificado = false;
                    this.$store.commit("detenerLoading");

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document archivo: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        eliminar(pIndex) {
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: { no: this.$t('message.general.no'), yes: this.$t('message.general.si') },
                callback: confirm => {
                    if (confirm) {
                        this.eliminarArchivo(pIndex);
                    }
                }
            });

        },
        
        eliminarArchivo(pIndex){
            if (this.listArchivos[pIndex].nuevo) {
                this.listArchivos.splice(pIndex, 1);

            } else {
                this.listArchivos[pIndex].eliminado = true;

            }

        },

        eliminarArchivoBd(archivo) {
            if (archivo) {
                archivo.docRef.delete().then(() => {
                    let index = this.listArchivos.findIndex(element => element.id === archivo.id);
                    if (index >= 0) {
                        this.listArchivos.splice(index, 1);
                    }

                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        },

        verArchivo(archivo) {debugger
            this.$store.commit("ejecutarLoading");
            this.hrefArchivo = '';

            if (["jpeg", "jpg", "png", "bmp", "pdf"].includes(archivo.extension)) {
                this.fileType = archivo.extension == "pdf" ? 'pdf' : 'img';

            } else if (['mpeg', 'audio/mpeg3', 'audio/mpeg', 'audio/x-mpeg-3'].includes(archivo.extension)) {
                this.fileType = 'audio';

            } else {
                this.fileType = '';

            }

            this.extensionFile = archivo.extension;

            if (archivo.file) {
                this.hrefArchivo = archivo.file;
                
                if (this.fileType) {
                    $('#popupvistaPrevia').modal('show');
                    this.$store.commit("detenerLoading");
                } else {
                    // open link in new blanck window
                }
            } else {
                this.downloadFileAndShow(archivo);
            }


        },

        downloadFileAndShow(archivo) {
            var storageRef = firebase.storage().ref();

            storageRef.child(archivo.pathArchivo).getDownloadURL()
                .then((url) => {
                    this.hrefArchivo = url;
                    if (this.fileType) {
                        $('#popupvistaPrevia').modal('show');
                        this.$store.commit("detenerLoading");
                    } else {
                        this.downloadFile(url, archivo.nombreArchivo, archivo.extension);
                    }
                })
                .catch((error) => {
                    this.$store.commit("detenerLoading");
                    console.log('Error descargando archivo', error);
                });

        },

        downloadFileClick(archivo) {
            var storageRef = firebase.storage().ref();

            storageRef.child(archivo.pathArchivo).getDownloadURL()
                .then((url) => {
                    this.downloadFile(url, archivo.nombreArchivo, archivo.extension);
                    
                })
                .catch((error) => {
                    console.log('Error descargando archivo', error);
                });

        },

        downloadFile(url, nombre, extension) {
            let ext = "";
            
            if (extension == 'vnd.ms-excel') {
                ext = '.xls'
            } else if (extension == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                ext = '.xlsx'
            } else if (extension == 'msword') {
                ext = '.doc'
            } else if (extension == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
                ext = '.docx'
            } else if (extension == 'vnd.openxmlformats-officedocument.presentationml.presentation') {
                ext = '.pptx'
            } else if (extension == 'x-zip-compressed') {
                ext = '.zip'
            } else {
                ext = '.' + extension;
            }

            axios({
                url: url,
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', nombre + ext);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.$store.commit("detenerLoading");
            });

            // var linkDownload = document.createElement('a');
            // linkDownload.href = url;
            // linkDownload.download = nombre + ext;
            // linkDownload.target = '_blank';
            // document.body.appendChild(linkDownload);
            // linkDownload.click();
            // document.body.removeChild(linkDownload);

        },

        cerrarModalVistaPrevia() {
            $('#popupvistaPrevia').modal('hide');
        },

        getFechaCreacion(fechaCreacion) {
            if (fechaCreacion.seconds) {
                return this.formatSecondsToDate(fechaCreacion.seconds, "DD/MM/YYYY");
            } else {
                return this.formatSecondsToDate(fechaCreacion.getTime()/1000, "DD/MM/YYYY");
            }
        }

    }
};