import draggable from "vuedraggable";
import rawDisplayer from "vuedraggable";
import accionesComp from "@/components/AccionesComponent";
import {firebase, auth } from '@/firebase/firebaseInit.js';
import mensajeriaComp from "@/components/mensajeria/MensajeriaComponent.vue";
import MensajeriaImpl from "@/classes/mensajeria/MensajeriaImpl";
import PersonasImpl from "@/classes/personas/PersonasImpl";
import personaComp from "@/views/personas/Personas_det.vue";
import VueJsonToCsv from 'vue-json-to-csv'
import gestionDocumentalComponent from "@/components/gestionDocumental/GestionDocumentalComponent.vue";

export default {
    name: "embudo",
    display: "Embudo",
    components: {
        draggable,
        rawDisplayer,
        accionesComp,
        mensajeriaComp,
        personaComp,
        VueJsonToCsv,
        gestionDocumentalComponent
    },
    data() {
        return {
            listEmbudos : [],
            embudo: null,
            filtros: {
                activo: true,
                idGrupoInformacion: '',
                idUsuarioAsesor: ''
            },
            nuevoEmbudo:{
                id: '',
                nombre: '',
                listEtapas: []
            },
            listEtapasNuevas: [],
            tarjeta: {},
            tarjetaTmp: {},
            // listPersonas: [
            //     {nombresApellidos:"", labelBusqueda:""}
            // ],
            listProductos: [],
            listGruposInformacion : [],
            listEventos: [],
            listHistorialEtapasTarjeta: [],
            evento: {},
            listTiposEventos: [
                {nombre: this.$store.state.constantes.tipoEvento.LLAMADA}, 
                {nombre: this.$store.state.constantes.tipoEvento.TAREA}, 
                {nombre: this.$store.state.constantes.tipoEvento.WP}, 
                {nombre: this.$store.state.constantes.tipoEvento.EMAIL},
                {nombre: this.$store.state.constantes.tipoEvento.URL},
                {nombre: this.$store.state.constantes.tipoEvento.NOTA},
                {nombre: this.$store.state.constantes.tipoEvento.REUNION},
                {nombre: this.$store.state.constantes.tipoEvento.INFORMACION}
            ],
            listEstadosEventos: [
                {nombre: this.$store.state.constantes.estadoEvento.PENDIENTE}, 
                {nombre: this.$store.state.constantes.estadoEvento.PROCESO}, 
                {nombre: this.$store.state.constantes.estadoEvento.FINALIZADO}, 
                {nombre: this.$store.state.constantes.estadoEvento.CANCELADO}
            ],
            dataTableEventos:null,
            listUsuarios: [],
            mostrarCompMsg: false,
            personaMsg:{},
            personaTarjeta:{},
            noMostrarConfirmacionMsgAut: false,
            contMostrarCompPersona: 0,
            mostrarTablaEventos:false,
            listenerEmbudoDetStop: null,
            listenerEmbudoDetSnStop: null,
            listEtapasExportar: [],
            indexEtapaExportarExcel: 0,
            mostrarCompGestionDoc: false

        };
    },

    watch: {
        'embudo': function (val, oldVal) {
            if (this.$store.state.contadorLoading <= 0) {
                if (val) {
                    this.$store.commit("ejecutarLoading");
                    var index = this.listEmbudos.findIndex((element) => element.id == val.id);
                    this.listEmbudos[index].listEtapas.forEach(element => element.listTarjetas = []);

                    this.consultarEmbudosEnc();
                }
            }
        },
        'tarjeta.idPersonaLead':async function (val, oldVal) {
            if (val) {
                // this.personaTarjeta = this.listPersonas.find((element) => element.id == val);
                this.personaTarjeta = await PersonasImpl.getPersonaById(val);

                if (this.personaTarjeta) {
                    this.tarjeta.nombreLead = this.personaTarjeta.nombres + ' ' + this.personaTarjeta.apellidos;
                    this.tarjeta.idUsuarioAsesor = this.personaTarjeta.idUsuarioAsesor;
                    this.tarjeta.idGrupoInformacion = this.personaTarjeta.idGrupoInformacion;
                    this.tarjeta.idPaisLead= this.personaTarjeta.idPais;
                    this.tarjeta.idDepartamentoLead= this.personaTarjeta.idDepartamento;
                    this.tarjeta.idCiudadLead= this.personaTarjeta.idCiudad;
                    this.tarjeta.codAreaLead= this.personaTarjeta.codArea ? this.personaTarjeta.codArea : '+57';
                    this.tarjeta.telefonoMovilLead= this.personaTarjeta.telefonoMovil;
                } else {

                    this.$store.dispatch("addAlert", {
                        tipo: "danger",
                        titulo: "",
                        descripcion: 'No se pudo consultar la información de la persona relacionada, esto puede presentarse cuando la persona ya no pertenece al grupo de información del usuario, por problemas de permisos o un error interno.'
                    });

                }
                

            } else {
                this.personaTarjeta = {};
                this.tarjeta.nombreLead = null;
                this.tarjeta.idUsuarioAsesor = null;
                this.tarjeta.idPaisLead= null;
                this.tarjeta.idDepartamentoLead= null;
                this.tarjeta.idCiudadLead= null;
                this.tarjeta.codAreaLead= null;
                this.tarjeta.telefonoMovilLead= null;
            }
        },
        'evento.programarMensaje': function (val, oldVal) {
            if (val && val != oldVal && !this.noMostrarConfirmacionMsgAut) {
                this.$confirm({
                    message: this.$t('message.embudo.confirmacionMsgAutomatico'),
                    button: { no: this.$t('message.general.no'), yes: this.$t('message.general.si') },
                    callback: confirm => {
                        if (!confirm) {
                            this.evento.programarMensaje = false;
                        }
                    }
                });
            }
        }
    },

    computed: { 
        precioProducto:{
            get () {
                return this.tarjeta.precioProducto  ? this.formatMoneda(this.tarjeta.precioProducto) : 0;
            },
            set (newValue) {
                if(!isNaN(this.getOriginalFormatMoneda(newValue))) {
                    this.tarjeta.precioProducto = this.getOriginalFormatMoneda(newValue);
                }
                
            }
            
        },

        totalProducto: function () {
            let total = Number(this.tarjeta.precioProducto) * this.tarjeta.cantidadProducto;
            return this.tarjeta.precioProducto ? this.formatMoneda(total) : 0;
 
        },
    },

    beforeDestroy() {
        if (this.listenerEmbudoDetStop) {
            this.listenerEmbudoDetStop();
        }     
        if (this.listenerEmbudoDetSnStop) {
            this.listenerEmbudoDetSnStop();
        }     

    },

    created() {
        this.inicializar();

    },

    mounted() {
        this.listenerPopup();
    },

    methods: {
        
        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 8);
            this.setFechasFilter();
            this.consultarEmbudos();
            // this.consultarPersonas();
            this.consultarProductos();
            this.loadGruposInformacion();
            this.loadUsuarios();
            this.loadUsusarioAsesorFilter();
            
        },

        limpiarFiltros(selectObj){
                this.filtros.idPais='';
                this.filtros.idDepartamento='';
                this.filtros.idCiudad='';
                this.filtros.idUsuarioAsesor='';
                this.filtros.idGrupoInformacion='';
                this.filtros.idProducto='';
            // this.setFechasFilter();
        },

        listenerPopup() {
            $('#popupTarjeta').on('hidden.bs.modal', function (e) {
                // this.destroyDataTable('#dataTable');
                if (e?.target?.id == 'popupTarjeta') {
                    this.mostrarTablaEventos= false;
                    this.mostrarCompGestionDoc = false;
                    this.listEventos = [];
                    this.listHistorialEtapasTarjeta = [];
                    
                }

            }.bind(this))
    
        },

        consultarEmbudos() {
            this.listEmbudos = [];
            let collection = this.getCollectionListFirestore('embudos', null, true);
            
            // collection = this.getCollectionFiltered(collection);

            collection.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var embudo = doc.data();
                    embudo["id"] = doc.id;
                    embudo["docRef"] = doc.ref;
                    embudo.listEtapas = embudo.etapas;
                    delete embudo.etapas;

                    for (const etapa of embudo.listEtapas) {
                        delete etapa.listEtapas;
                        etapa.listTarjetas = [];

                    }

                    this.listEmbudos.push(embudo)

                });

                this.$store.commit("detenerLoading");
                $('#popupFiltro').modal('hide');

            }).catch(
                function(error) {
                    console.log("Error getting embudos:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );
        },

        consultarPersonas() {
            firebase.auth().onAuthStateChanged(async (user) =>{
                if (!this.$store.getters.getSesionIniciada) {
                    return;
                }
                this.listPersonas = [];

                let querySnapshot;
                let personasSnapshot;
                let personasSinAsesorSnapshot;
                let collection = this.getCollectionListFirestore('personas');

                if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                    // collection = collection.where('idUsuarioAsesor', '==', auth.currentUser.uid);
                    // collection = collection.where('idUsuarioAsesor', 'in', [auth.currentUser.uid, '']);
                    personasSnapshot = await collection.where('idUsuarioAsesor', '==', auth.currentUser.uid).get();
                    personasSinAsesorSnapshot = await collection.where('idUsuarioAsesor', '==', '').get();
                    
                } else {
                    personasSnapshot = await collection.get();
                }

                querySnapshot = personasSnapshot.docs;

                if (personasSinAsesorSnapshot) {
                    querySnapshot.push(...personasSinAsesorSnapshot.docs);
                }

                querySnapshot.forEach((doc) => {
                    let persona = doc.data();
                    persona.labelBusqueda = 'Id: ' + persona.numeroIdentificacion + ' - ' + persona.nombres + ' ' + persona.apellidos;
                    persona["id"] = doc.id;
                    persona["docRef"] = doc.ref;

                    this.listPersonas.push(persona)

                });

                // collection.get().then((querySnapshot) => {
                //     querySnapshot.forEach((doc) => {
                //         var persona = doc.data();
                //         persona.labelBusqueda = 'Id: ' + persona.numeroIdentificacion + ' - ' + persona.nombres + ' ' + persona.apellidos;
                //         persona["id"] = doc.id;
                //         persona["docRef"] = doc.ref;

                //         this.listPersonas.push(persona)

                //     });

                //     this.$store.commit("detenerLoading");

                // }).catch(
                //     function(error) {
                //         console.log("Error getting personas:", error);
                //         this.$store.commit("detenerLoading");
                //     }.bind(this)
                // );
            });
        },

        consultarProductos() {
            this.listProductos = [];
            let collection = this.getCollectionListFirestore("productos", null, true);

            collection.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var producto = doc.data();
                    producto["id"] = doc.id;
                    producto.labelBusqueda = producto.nombre + ' - SKU: ' + producto.codigoInterno;
                    this.listProductos.push(producto)

                });
                this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    console.log("Error getting productos:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );
        },

        loadGruposInformacion() {
            setTimeout(function(){

                this.getCollectionListFirestore('gruposDeInformacion').get()
                .then((querySnapshot) => {
                    // if (!this.$store.getters.getIdGrupoInfomacion) {
                    this.listGruposInformacion.push({
                        id: '', 
                        nombre: this.$t('message.general.select_todos')
                    });
                    this.listGruposInformacion.push({
                        id: this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL, 
                        nombre: this.$t('message.general.global')
                    });
                    
                    // }
                    querySnapshot.forEach((doc) => {  
                        this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function(error) {
                        console.log("Error getting grupos de informacion:", error);
                        this.$store.commit("detenerLoading");

                    }.bind(this)
                );
            }.bind(this), 1000);
            
        },

        loadUsuarios() {
            let collection = this.getCollectionListFirestore('usuarios');
            // this.listUsuarios.push({ id:'', descripcion: this.$t('message.personasDetalle.sinAsesor') });

            collection.get()
                .then((querySnapshot) => {
                    // this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });
                    querySnapshot.forEach((doc) => {
                        this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {

                        collection = collection.where('id', '==', auth.currentUser.uid);
                        collection.get()
                            .then((querySnapshot) => {
                                // this.listUsuarios.push({ id: '', descripcion: this.$t('message.general.select_todos') });
                                querySnapshot.forEach((doc) => {
                                    this.listUsuarios.push({ id: doc.id, descripcion: doc.data().usuario });

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function (errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );

                    }.bind(this)
                );
        },

        loadUsusarioAsesorFilter(){
            // firebase.auth().onAuthStateChanged((user) =>{
            //     if (!this.$store.getters.getSesionIniciada) {
            //         return;
            //     }
            //     this.$store.commit("detenerLoading");
            //     if (this.$store.getters.isAdmin) {
            //         this.filtros.idUsuarioAsesor='';
            //     } else {
            //         this.filtros.idUsuarioAsesor= auth.currentUser.uid;
            //     }

            // });
        },

        setFechasFilter(){
            var fechaDesde = new Date();
            fechaDesde.setDate(fechaDesde.getDate() - 30);

            this.filtros.fechaDesde= this.$moment(fechaDesde).format("YYYY-MM-DD");
            this.filtros.fechaHasta= this.$moment(new Date()).format("YYYY-MM-DD");

            fechaDesde.setDate(fechaDesde.getDate() - 30);
            this.filtros.fechaDesdePersonas= this.$moment(fechaDesde).format("YYYY-MM-DD");
            this.filtros.fechaHastaPersonas= this.$moment(new Date()).format("YYYY-MM-DD");
        },

        async consultarEmbudosEnc() {
            this.embudo.listEtapas.forEach((etapa) => {
                etapa.listTarjetas = [];
            });

            await this.consultarEmbudosEncConAsesor();
            if (!this.$store.getters.isAdmin && !this.filtros.idUsuarioAsesor && this.$store.getters.getIdGrupoInfomacion) {
                await this.consultarEmbudosEncSinAsesor();
            }
        },
            
        async consultarEmbudosEncConAsesor() {
            let collection = this.getCollectionListFirestore('embudos_enc');
            collection = this.getCollectionEmbEncFiltered(collection, true);

            if (this.listenerEmbudoDetStop) {
                this.listenerEmbudoDetStop();
            }
            this.listenerEmbudoDetStop = await this.getListenerEmbudosEnc(collection);
        },

        async consultarEmbudosEncSinAsesor() {
            let collection = this.getCollectionListFirestore('embudos_enc');
            collection = this.getCollectionEmbEncFiltered(collection, false);

            if (this.listenerEmbudoDetSnStop) {
                this.listenerEmbudoDetSnStop();
            }
            this.listenerEmbudoDetSnStop = await this.getListenerEmbudosEnc(collection);
        },

        getCollectionEmbEncFiltered(pCollection, incluirAsesor){
            var collection = pCollection;
            collection = collection.where('activo', '==', this.filtros.activo);

            if (this.embudo.id) {
                collection = collection.where('idEmbudo', '==', this.embudo.id);
            }

            if (this.filtros.idPersonaLead) {
                collection = collection.where("idPersonaLead", "==", this.filtros.idPersonaLead);
            }else{

                if (this.filtros.idPais) {
                    collection = collection.where("idPaisLead", "==", this.filtros.idPais);
                }

                if (this.filtros.idDepartamento) {
                    collection = collection.where("idDepartamentoLead", "==", this.filtros.idDepartamento);
                }

                if (this.filtros.idCiudad) {
                    collection = collection.where("idCiudadLead", "==", this.filtros.idCiudad);
                }

                if (this.filtros.idProducto) {
                    collection = collection.where("idProducto", "==", this.filtros.idProducto);
                }

                if (this.filtros.fechaDesde && this.filtros.fechaHasta) {
                    let fechaDesde= this.$moment(this.filtros.fechaDesde, "YYYY-MM-DD").toDate();
                    let fechaHasta= this.$moment(this.filtros.fechaHasta, "YYYY-MM-DD").toDate();
                    fechaHasta.setDate(fechaHasta.getDate() + 1);
                    collection = collection.where("fechaCreacion", ">=", fechaDesde).where("fechaCreacion", "<=", fechaHasta);

                }

                if (this.filtros.idGrupoInformacion) {
                    collection = collection.where("idGrupoInformacion", "==", this.filtros.idGrupoInformacion);
                }
            }
            // if (this.filtros.idUsuarioAsesor) {
            //     collection = collection.where('idUsuarioAsesor', '==', this.filtros.idUsuarioAsesor);
            // } /*else {
            //     collection = collection.where('idUsuarioAsesor', '==', auth.currentUser.uid);
            // }*/

            if (!this.$store.getters.isAdmin && this.$store.getters.getIdGrupoInfomacion) {
                if (incluirAsesor) {
                    collection = collection.where('idUsuarioAsesor', '==', auth.currentUser.uid);
                    
                } else {
                    collection = collection.where('idUsuarioAsesor', '==', '');
                }
            } else {
                if (this.filtros.idUsuarioAsesor) {
                    collection = collection.where("idUsuarioAsesor", "==", this.filtros.idUsuarioAsesor);
                }
            }

            return collection;
        },

        async getListenerEmbudosEnc(collection) {
            return await collection.onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    let tarjeta = change.doc.data();
                    tarjeta["id"] = change.doc.id;
                    tarjeta["docRef"] = change.doc.ref;

                    if (this.embudo && this.embudo.id == tarjeta.idEmbudo) {
                        if (change.type === "added") {
                            if (this.embudo.listEtapas[tarjeta.idEtapa]) {
                                this.embudo.listEtapas[tarjeta.idEtapa].listTarjetas.push(tarjeta);

                            } else if (this.embudo.listEtapas[0]) {
                                this.embudo.listEtapas[0].listTarjetas.push(tarjeta);
                            }

                        }

                        if (change.type === "modified") {
                            let realizarMovimiento = true;

                            for (let etapa of this.embudo.listEtapas) {
                                let indexTarjeta = etapa.listTarjetas.findIndex((element) => element.id == tarjeta.id);
                                
                                if (indexTarjeta >= 0) {
                                    let tarjetaTmp = etapa.listTarjetas[indexTarjeta];
                                    if (tarjeta.idEtapa != tarjetaTmp.idEtapa) {
                                        etapa.listTarjetas.splice(indexTarjeta, 1);
                                    } else {
                                        this.setTarjetaProperties(tarjetaTmp, tarjeta);
                                        realizarMovimiento = false;
                                    }
                                    break;
                                }
                            }

                            if (realizarMovimiento) {
                                this.embudo.listEtapas[tarjeta.idEtapa].listTarjetas.push(tarjeta);
                            }
                        }
                    }
                });

                this.$store.commit("detenerLoading");
                // $('#popupFiltro').modal('hide');

            });
        },

        onMove({ relatedContext, draggedContext }) {
            // console.log(draggedContext.element);
            // console.log(relatedContext.component.$el.id);
            this.$store.commit("ejecutarLoading");

            var tarjeta = draggedContext.element;
            tarjeta.idEtapa = Number(relatedContext.component.$el.id);
            
            tarjeta.docRef.set({
                idEtapa: tarjeta.idEtapa,
                fechaModificacion: new Date(),
                uidUser: auth.currentUser.uid
            }, { merge: true }).then(
                function() {
                    // console.log("Document Update: ");
                    this.$store.commit("detenerLoading");

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

            return true;
            

            // const relatedElement = relatedContext.element;
            // const draggedElement = draggedContext.element;
            // return (
            //   (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            // );
        },

        alistarNuevoEmbudo() {
            this.nuevoEmbudo = {
                id: '',
                nombre: '',
                listEtapas: []
            };
            this.listEtapasNuevas = []
        },

        guardarEmbudo() {
            // this.$refs.formEmbudo.validate().then(success => {
            //     if (success) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.nuevoEmbudo.docRef) {
                        this.actualizarEmbudo();
    
                    } else {
                        this.crearEmbudo();
    
                    }
                // }
            // });

        },

        crearEmbudo() {
            let collection = this.getCollectionAddFirestore("embudos");

            this.setEtapasNuevoEmbudo();

            collection.add({
                nombre: this.nuevoEmbudo.nombre,
                etapas: this.nuevoEmbudo.listEtapas

            }).then(
                function(docRef) {
                    // console.log("Document written with ID: ", docRef.id);
                    this.nuevoEmbudo.docRef= docRef;
                    this.nuevoEmbudo.id= docRef.id;
                    this.listEmbudos.push(this.nuevoEmbudo);

                    this.$store.commit("detenerLoading");
                    $('#popupNuevoEmbudo').modal('hide');
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        setEtapasNuevoEmbudo() {
            for (let etapa of this.listEtapasNuevas) {
                if (etapa) {
                    let nuevaEtapa = {
                        nombre: etapa,
                        listTarjetas: []
                    }

                    this.nuevoEmbudo.listEtapas.push(nuevaEtapa);
                }
            }
        },

        editarEmbudo() {
            this.listEtapasNuevas = [];
            this.nuevoEmbudo = Object.assign({} , this.embudo);
            for (let etapa of this.nuevoEmbudo.listEtapas) {
                if (etapa) {
                    this.listEtapasNuevas.push(etapa.nombre);
                }
                
            }
            this.nuevoEmbudo.listEtapas.forEach(element => element.listTarjetas = []);
        },

        actualizarEmbudo() {
            this.nuevoEmbudo.listEtapas = [];
            this.setEtapasNuevoEmbudo();

            this.nuevoEmbudo.docRef.set({
                nombre: this.nuevoEmbudo.nombre,
                etapas: this.nuevoEmbudo.listEtapas

            }).then(
                function() {
                    // console.log("Document embudo update with ID: ");
                    this.embudo.nombre = this.nuevoEmbudo.nombre;
                    this.embudo.listEtapas = this.nuevoEmbudo.listEtapas;
                    this.consultarEmbudosEnc();

                    this.$store.commit("detenerLoading");
                    $('#popupNuevoEmbudo').modal('hide');
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error update embudo: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        alistarNuevaTarjeta() {
            this.tarjeta = {
                idEmbudo: this.embudo.id,
                precioProducto: 0,
                cantidadProducto: 1,
                fechaCreacion: this.$moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                // idGrupoInformacion: this.$store.getters.getIdGrupoInfomacion ? this.$store.getters.getIdGrupoInfomacion : this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL,
                idEtapa: 0
            };

            // this.destroyDataTable('#dataTable');
            this.mostrarTablaEventos= true;
            this.mostrarCompGestionDoc = true;
            this.listEventos = [];
            
            setTimeout(function(){
                this.$refs.form.validate();
            }.bind(this), 500);

        },

        guardarTarjeta(alistarEvento) {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.tarjeta.docRef) {
                        this.actualizarTarjeta();
    
                    } else {
                        this.crearTarjeta(alistarEvento);
    
                    }
                }
            });
        },

        crearTarjeta(alistarEvento) {

            let collection = this.getCollectionAddFirestore("embudos_enc");

            collection.add({
                idPersonaLead: this.tarjeta.idPersonaLead,
                nombreLead: this.tarjeta.nombreLead,
                idProducto: this.tarjeta.idProducto,
                nombreProducto: this.tarjeta.nombreProducto,
                precioProducto: this.tarjeta.precioProducto,
                cantidadProducto: this.tarjeta.cantidadProducto,
                idUsuarioAsesor: this.tarjeta.idUsuarioAsesor,
                idGrupoInformacion: this.tarjeta.idGrupoInformacion,
                activo: true,
                fechaCreacion: this.$moment(this.tarjeta.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate(),
                // fechaCreacion: new Date(),
                fechaModificacion: new Date(),
                idEmbudo: this.tarjeta.idEmbudo,
                idEtapa: this.tarjeta.idEtapa,
                idPaisLead: this.tarjeta.idPaisLead,
                idDepartamentoLead: this.tarjeta.idDepartamentoLead,
                idCiudadLead: this.tarjeta.idCiudadLead,
                uidUser: auth.currentUser.uid

            }).then(
                function(docRef) {
                    this.tarjeta.docRef= docRef;
                    this.tarjeta.id= docRef.id;

                    try {
                        this.actualizarEtiquetaAndEstadisticaPersona(this.tarjeta.idPersonaLead);
                    } catch (error) {
                        console.log("Error actualizando la etiqueta o estadisticas de la persona asociada", error)
                    }

                    if (alistarEvento) {
                        this.alistarNuevoEvento(docRef.id);
                    }

                    this.$emit('guardarGestionDocumental', {idDocumento: docRef.id});

                    // console.log("Document written with ID: ", docRef.id);
                    // this.tarjeta.docRef= docRef;
                    // this.tarjeta.id= docRef.id;
                    // this.tarjeta.fechaCreacion= {
                    //     nanoseconds: 0,
                    //     seconds: this.$moment(new Date(), "YYYY-MM-DD").toDate().getTime()/1000
                    // };

                    // this.embudo.listEtapas[this.tarjeta.idEtapa].listTarjetas.push(this.tarjeta);

                    this.$store.commit("detenerLoading");
                    if (!alistarEvento) {
                        $('#popupTarjeta').modal('hide');
                    }
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        },

        async actualizarEtiquetaAndEstadisticaPersona(idPersona) {
            let personaDoc = await this.getDocumentFirestore("personas", idPersona, false);
                
            if (personaDoc) {
                let etiquetas;
                const ETIQUETA_EMBUDO = this.$store.state.constantes.tipoEtiqueta.ETIQUETA_EMBUDO;
                if (!personaDoc.data().etiquetas) {
                    etiquetas = { ETIQUETA_EMBUDO : true};

                } else if (!personaDoc.data().etiquetas[this.$store.state.constantes.tipoEtiqueta.ETIQUETA_EMBUDO]) {
                    etiquetas = personaDoc.data().etiquetas;
                    etiquetas[ETIQUETA_EMBUDO] = true;
                }

                if (etiquetas) {
                    personaDoc.ref.update({
                        etiquetas: etiquetas
                    }).then(
                        function() {
                            let fechaCreacionOriginalTmp = personaDoc.data().fechaCreacionOriginal ? personaDoc.data().fechaCreacionOriginal : new Date();
                            if (fechaCreacionOriginalTmp && fechaCreacionOriginalTmp.hasOwnProperty('seconds')) {
                                let fechaTmp = new Date(0);
                                fechaCreacionOriginalTmp = fechaTmp.setSeconds(fechaCreacionOriginalTmp.seconds);
                            }
                            let fechaDia = this.$moment(fechaCreacionOriginalTmp).format("DD/MM/YYYY");
                            this.actualizarEstadistica(fechaDia, ETIQUETA_EMBUDO, 1, true);
                            if (Object.keys(etiquetas).length == 1) {
                                this.actualizarEstadistica(fechaDia, this.$store.state.constantes.tipoEtiqueta.ETIQUETA_DEFAULT, -1, false);
                            }
                        }.bind(this)
                    ).catch(
                        function(error) {
                            console.error("Error Updating document persona: ", error);
                            this.$store.commit("detenerLoading");
        
                        }.bind(this)
                    );
                }
            }
        },

        actualizarEstadistica(fechaDia, descripcionEtiqueta, cantidadParam, insertarSiNoSeEncuentra) {
            let collection = this.getCollectionListFirestore("personas_estadisticas", null, true);
            collection = collection.where("fechaDia", "==", fechaDia).where("etiqueta", "==", descripcionEtiqueta);
            collection = collection.where("tipoEstadistica", "==", this.$store.state.constantes.tipoEstadisticaPersona.CANT_PERSONAS_DIA_ETIQUETA);

            collection.get().then((querySnapshot) => {
                // Si encontramos la estadistica, procedemos a actualizarla aumentando uno en la cantidad.
                querySnapshot.forEach((doc) => {
                    var cantidad = doc.data().cantidad + cantidadParam;
                    if (cantidad < 0){
                        cantidad = 0;
                    }

                    doc.ref.update({
                        cantidad: cantidad
                    }, { merge: true }).catch(
                        function(error) {
                            console.error("Error Updating document personas_estadisticas: ", error);
        
                        }.bind(this)
                    );
        
                });

                // Si no se encuentra la estadistica se procede a crearla con cantidad inicial en 1
                if (querySnapshot.empty && insertarSiNoSeEncuentra) {
                    let personasCollection = this.getCollectionAddFirestore("personas_estadisticas");
                    
                    personasCollection.add({
                        tipoEstadistica:1,
                        fechaDia: fechaDia,
                        etiqueta: descripcionEtiqueta,
                        cantidad: 1,
                        fecha: this.$moment(fechaDia, "DD/MM/YYYY").toDate(),
                        idGrupoInformacion: this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL
                    }).catch(
                        function(error) {
                            console.error("Error adding document personas_estadisticas: ", error);
        
                        }.bind(this)
                    );
                }

            }).catch(
                function(error) {
                    console.log("Error getting document personas_estadisticas:", error);
                }.bind(this)
            );
        },

        editarTarjeta(tarjetaParam) {
            this.$store.commit("ejecutarLoading");
            $('#popupTarjeta').modal('show');
            // this.destroyDataTable('#dataTable');
            this.mostrarTablaEventos= true;
            this.listEventos = [];
            this.listHistorialEtapasTarjeta = [];

            this.tarjetaTmp = tarjetaParam;
            this.tarjeta = Object.assign({} , tarjetaParam);
            this.tarjeta.fechaCreacion=  this.formatSecondsToDate(this.tarjeta.fechaCreacion.seconds, "YYYY-MM-DDTHH:mm");
            this.getEventosTarjeta(tarjetaParam);
            this.getHistoricoTarjeta(tarjetaParam);
            this.mostrarCompGestionDoc = true;
            setTimeout(function(){
                this.$refs.form.validate();
            }.bind(this), 500);
            
        },

        onChangeProduct() {
            var idProducto = this.tarjeta.idProducto;
            if (idProducto) {
                let producto = this.listProductos.find((element) => element.id == idProducto);
                this.tarjeta.nombreProducto = producto.nombre;
                this.tarjeta.precioProducto = producto.precio;
                
            } else {
                this.tarjeta.nombreProducto = null;
                this.tarjeta.precioProducto = null;
            }
            
        },

        actualizarTarjeta() {
            // this.tarjeta.idUsuarioAsesor = this.tarjeta.idUsuarioAsesor;
            // this.tarjeta.activo = true;
            // this.tarjeta.fechaCreacion = new Date();
            // this.tarjeta.idEmbudo = this.embudo.id;

            let fechaModificacion = this.tarjeta.fechaModificacion;
            if (this.tarjetaTmp.idEtapa != this.tarjeta.idEtapa) {
                fechaModificacion= new Date();
            }

            this.tarjeta.docRef.set({
                idPersonaLead: this.tarjeta.idPersonaLead,
                nombreLead: this.tarjeta.nombreLead,
                idProducto: this.tarjeta.idProducto,
                nombreProducto: this.tarjeta.nombreProducto,
                precioProducto: this.tarjeta.precioProducto,
                cantidadProducto: this.tarjeta.cantidadProducto,
                idUsuarioAsesor: this.tarjeta.idUsuarioAsesor,
                idGrupoInformacion: this.tarjeta.idGrupoInformacion,
                fechaCreacion: this.$moment(this.tarjeta.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate(),
                fechaModificacion: fechaModificacion ? fechaModificacion : new Date(),
                // activo: this.tarjeta.activo,
                // fechaCreacion: this.tarjeta.fechaCreacion,
                // idEmbudo: this.tarjeta.idEmbudo,
                idEtapa: this.tarjeta.idEtapa,
                idPaisLead: this.tarjeta.idPaisLead,
                idDepartamentoLead: this.tarjeta.idDepartamentoLead,
                idCiudadLead: this.tarjeta.idCiudadLead,
                uidUser: auth.currentUser.uid

            }, { merge: true }).then(
                function() {
                    this.$emit('guardarGestionDocumental', {idDocumento: this.tarjeta.id});
                    // console.log("Document updated");
                    // let indexEtapa = this.embudo.listEtapas[this.tarjetaTmp.idEtapa].listTarjetas.findIndex((element) => element.id == this.tarjeta.id);
                    // // let etapa = this.embudo.listEtapas[this.tarjetaTmp.idEtapa].listTarjetas.find((element) => element.id == this.tarjeta.id);
                    // let tarjetafind = this.embudo.listEtapas[this.tarjetaTmp.idEtapa].listTarjetas[indexEtapa];


                    // this.embudo.listEtapas[this.tarjetaTmp.idEtapa].listTarjetas.splice(indexEtapa, 1);
                    // this.embudo.listEtapas[this.tarjeta.idEtapa].listTarjetas.push(this.tarjeta);

                    // this.setTarjetaProperties(tarjetafind, this.tarjeta);

                    this.$store.commit("detenerLoading");
                    $('#popupTarjeta').modal('hide');
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        },

        setTarjetaProperties(tarjetaDestino, tarjetaOrigen) {
            tarjetaDestino.idPersonaLead= tarjetaOrigen.idPersonaLead,
            tarjetaDestino.nombreLead= tarjetaOrigen.nombreLead,
            tarjetaDestino.idProducto= tarjetaOrigen.idProducto,
            tarjetaDestino.nombreProducto= tarjetaOrigen.nombreProducto,
            tarjetaDestino.precioProducto= tarjetaOrigen.precioProducto,
            tarjetaDestino.cantidadProducto = tarjetaOrigen.cantidadProducto,
            tarjetaDestino.idUsuarioAsesor= tarjetaOrigen.idUsuarioAsesor,
            tarjetaDestino.idGrupoInformacion= tarjetaOrigen.idGrupoInformacion,
            tarjetaDestino.activo= tarjetaOrigen.activo,
            tarjetaDestino.fechaCreacion= tarjetaOrigen.fechaCreacion,
            tarjetaDestino.idEmbudo= tarjetaOrigen.idEmbudo,
            tarjetaDestino.idEtapa= tarjetaOrigen.idEtapa

        },

        async getEventosTarjeta(tarjeta) {
            let collection = this.getCollectionListFirestore('embudos_det_evts');
            collection = collection.where('idEmbudoEnc', '==', tarjeta.id);

            if (!this.$store.getters.isAdmin) {
                collection = collection.where('idUsuarioAsesor', '==', tarjeta.idUsuarioAsesor);
            }

            let querySnapshot = await collection.get().catch(
                function(error) {
                    console.log("Error getting eventos:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );

            if (querySnapshot && !querySnapshot.empty) {
                for (let doc of querySnapshot.docs){
                    var evento = doc.data();
                    evento["id"] = doc.id;
                    evento["docRef"] = doc.ref;
                    evento["fechaOrdenar"] = evento.fechaCreacion;

                    this.listEventos.push(evento)
                }
            }
              
            // this.setDataTable();
            this.ordenarLista(this.listEventos);
            this.$store.commit("detenerLoading");
            
        },

        async getHistoricoTarjeta(tarjeta) {
            let collection = this.getCollectionListFirestore('embudos_hist_tarjeta');
            collection = collection.where('idEmbudoEnc', '==', tarjeta.id);

            if (!this.$store.getters.isAdmin) {
                collection = collection.where('idUsuarioAsesor', '==', tarjeta.idUsuarioAsesor);
            }

            let querySnapshot = await collection.get().catch(
                function(error) {
                    console.log("Error getting eventos:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );

            if (querySnapshot && !querySnapshot.empty) {
                for (let doc of querySnapshot.docs){
                    var historicoEtapa = doc.data();
                    historicoEtapa["id"] = doc.id;
                    historicoEtapa["fechaOrdenar"] = historicoEtapa.fechaIni;
                    // historicoEtapa["docRef"] = doc.ref;

                    this.listHistorialEtapasTarjeta.push(historicoEtapa)
                }
            }
              
            this.ordenarLista(this.listHistorialEtapasTarjeta, "ASC");
            this.$store.commit("detenerLoading");
            
        },

        setDataTable() {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.applyDataTable(
                    '#dataTable', 
                    null, 
                    [   { "orderable": false, "targets": 2 },
                        { "orderable": false, "targets": 3 }   
                    ],
                    [[1,'asc']]
                );
                // this.applyDataTable();
            });
        },

        ordenarLista(listEventos, tipoOrdenamiento) {
            listEventos.sort(function (a, b) {
                // if (a.fechaUltimoMensaje && b.fechaUltimoMensaje && a.fechaUltimoMensaje.seconds > b.fechaUltimoMensaje.seconds) {
                //     return 1;
                // }
                // if (a.fechaUltimoMensaje && b.fechaUltimoMensaje && a.fechaUltimoMensaje.seconds < b.fechaUltimoMensaje.seconds) {
                //     return -1;
                // }
                // a must be equal to b
                let vlr = 0;
                let vlrA = a.fechaOrdenar ? a.fechaOrdenar.seconds : 0;
                let vlrB = b.fechaOrdenar ? b.fechaOrdenar.seconds : 0;

                vlr = tipoOrdenamiento=="ASC" ? vlrA - vlrB : vlrB - vlrA;

                return vlr;
            });
        },

        // applyDataTable() {
        //     // setTimeout(function () {
        //         if ($.fn.dataTable.isDataTable('#dataTable') ) {
        //             this.dataTableEventos = $('#dataTable').DataTable();

        //         } else {
        //             this.dataTableEventos = $('#dataTable').DataTable({
        //                 retrieve: true,
        //                 order: [],
        //                 columnDefs: [
        //                     { "orderable": false, "targets": 2 },
        //                     { "orderable": false, "targets": 3 }   
        //                 ],
        //                 order:[[1,'asc']],
        //                 responsive: true,
        //                 bPaginate: false,
        //                 language: {
        //                     info: "Mostrando _PAGE_ de _PAGES_",
        //                     lengthMenu: "Mostrar _MENU_ "
        //                 },
            
        //                 dom:
        //                     '<"top"<"row mx-0 datatable-cont-inputs"<"col-sm-12 d-flex justify-content-end px-0"l>>>rt<"bottom dataTables_wrapper dataTables_paginate mb-1 flex-md-row flex-column align-items-center justify-content-between"ip><"clear">',
            
        //             });
        //         }
        //     // }.bind(this), 1000);
        // },
        
        alistarNuevoEventoAndSave(idEmbudoEnc) {
            if (this.tarjeta.docRef) {
                this.alistarNuevoEvento(idEmbudoEnc);
            } else {
                this.guardarTarjeta(true);
            }
        },

        alistarNuevoEvento(idEmbudoEnc) {
            this.evento= {
                fechaCreacion: this.$moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                // fechaProgramacion: this.$moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                tipo:this.$store.state.constantes.tipoEvento.LLAMADA,
                estado:this.$store.state.constantes.estadoEvento.PENDIENTE,
                observaciones:"",
                idEmbudoEnc: idEmbudoEnc,
                programarAlerta: false,
                programarMensaje: false,
                idPersonaAsociada: this.tarjeta.idPersonaLead,
                idUsuarioAlerta: this.tarjeta.idUsuarioAsesor ? this.tarjeta.idUsuarioAsesor : auth.currentUser.uid,
                nombreUsuarioCrea: this.$store.getters.getUserName
            };

            setTimeout(function(){
                this.$refs.formEvento.validate();
            }.bind(this), 500);
        },

        alistarEdicionEvento(evento) {
            this.noMostrarConfirmacionMsgAut = true;
            this.evento= Object.assign({} , evento);
            this.evento.fechaCreacion=  this.formatSecondsToDate(this.evento.fechaCreacion.seconds, "YYYY-MM-DDTHH:mm");

            if (!this.evento.idPersonaAsociada) {
                this.evento.idPersonaAsociada = this.tarjeta.idPersonaLead;
            }
            if (!this.evento.idUsuarioAlerta) {
                this.evento.idUsuarioAlerta = this.tarjeta.idUsuarioAsesor;
            }
            if (!this.evento.nombreUsuarioCrea) {
                this.evento.nombreUsuarioCrea = '';
            }
            setTimeout(function () {this.noMostrarConfirmacionMsgAut = false;}.bind(this), 300);
            
            // this.evento.fechaProgramacion= this.formatSecondsToDate(this.evento.fechaProgramacion.seconds, "YYYY-MM-DDTHH:mm");

        },

        guardarEvento() {
            this.$refs.formEvento.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                    // this.destroyDataTable();
                
                    if (this.evento.docRef) {
                        this.actualizarEvento();
    
                    } else {
                        this.crearEvento();
    
                    }
                }
            });

            
        },

        crearEvento() {
            let collection = this.getCollectionAddFirestore("embudos_det_evts");
            
            collection.add({
                fechaCreacion: this.$moment(this.evento.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate(),
                // fechaProgramacion: this.$moment(this.evento.fechaProgramacion, "YYYY-MM-DDTHH:mm").toDate(),
                tipo: this.evento.tipo,
                estado: this.evento.estado,
                observaciones: this.evento.observaciones,
                idEmbudoEnc: this.evento.idEmbudoEnc,
                idUsuarioAsesor: this.tarjeta.idUsuarioAsesor,
                idGrupoInformacion: this.tarjeta.idGrupoInformacion,
                programarAlerta: this.evento.programarAlerta,
                programarMensaje: this.evento.programarMensaje,
                idPersonaAsociada: this.evento.idPersonaAsociada,
                idUsuarioAlerta: this.evento.idUsuarioAlerta,
                nombreUsuarioCrea: this.evento.nombreUsuarioCrea

            }).then(
                async function(docRef) {
                    // console.log("Document written with ID: ", docRef.id);
                    let fechaTmpCreacion = this.$moment(this.evento.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate()
                    this.evento.docRef= docRef;
                    this.evento.id= docRef.id;
                    this.evento.fechaCreacion= {
                        nanoseconds: 0,
                        seconds: this.$moment(this.evento.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate().getTime()/1000
                    };
                    // this.evento.fechaProgramacion= {
                    //     nanoseconds: 0,
                    //     seconds: this.$moment(this.evento.fechaProgramacion, "YYYY-MM-DDTHH:mm").toDate().getTime()/1000
                    // };

                    this.listEventos.push(this.evento);
                    this.ordenarLista(this.listEventos);
                    // this.setDataTable();

                    if (this.evento.programarAlerta) {
                        this.crearAlerta(
                            this.evento.observaciones, 
                            fechaTmpCreacion,
                            this.evento.id
                        )
                        
                    }

                    if (this.evento.programarMensaje) {
                        MensajeriaImpl.crearMsgAut(await this.getParamsCrearMsgAut(fechaTmpCreacion))
                    }
                    
                    this.$store.commit("detenerLoading");
                    $('#popupEvento').modal('hide');

                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding event: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        },

        async getParamsCrearMsgAut(fechaTmpCreacion){
            // let personaMsg = this.listPersonas.find((element) => element.id == this.evento.idPersonaAsociada);
            let personaMsg = await PersonasImpl.getPersonaById(this.evento.idPersonaAsociada);

            return {
                descripcion: this.evento.observaciones,
                fecha: fechaTmpCreacion, 
                origen: 'Embudos',
                idOrigen: this.evento.id,
                idUsuarioAsesor: this.tarjeta.idUsuarioAsesor,
                idGrupoInformacion: this.tarjeta.idGrupoInformacion,
                idPersonaAsociada: this.evento.idPersonaAsociada,
                idEtiquetaEmbudo: this.tarjeta.id,
                number: MensajeriaImpl.getTelefonoMovil(personaMsg.codArea, personaMsg.telefonoMovil), 
                hostConsumo: window.location.host,
                permiteInactivar: personaMsg.idTipoPersona != this.$store.state.constantes.tipoPersona.EMPLEADO
            }

        },

        actualizarEvento() {
            this.evento.nombreUsuarioCrea = this.evento.nombreUsuarioCrea ? this.evento.nombreUsuarioCrea : this.$store.getters.getUserName;

            this.evento.docRef.set({
                fechaCreacion: this.$moment(this.evento.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate(),
                // fechaProgramacion: this.$moment(this.evento.fechaProgramacion, "YYYY-MM-DDTHH:mm").toDate(),
                tipo: this.evento.tipo,
                estado: this.evento.estado,
                observaciones: this.evento.observaciones,
                idEmbudoEnc: this.evento.idEmbudoEnc,
                idUsuarioAsesor: this.tarjeta.idUsuarioAsesor,
                idGrupoInformacion: this.tarjeta.idGrupoInformacion,
                programarAlerta: this.evento.programarAlerta ? this.evento.programarAlerta : false,
                programarMensaje: this.evento.programarMensaje ? this.evento.programarMensaje : false,
                idPersonaAsociada: this.evento.idPersonaAsociada ? this.evento.idPersonaAsociada : '',
                idUsuarioAlerta: this.evento.idUsuarioAlerta,
                nombreUsuarioCrea: this.evento.nombreUsuarioCrea
            }, { merge: true}).then(
                function() {
                    // console.log("Document updated");
                    let evento = this.listEventos.find((element) => element.id == this.evento.id);
                    this.setEventosProperties(evento, this.evento);
                    this.ordenarLista(this.listEventos);
                    // this.setDataTable();
                    this.$store.commit("detenerLoading");
                    
                    if (this.evento.programarAlerta) {
                        this.modificarAlerta(
                            this.evento.id, 
                            this.$moment(this.evento.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate(), 
                            this.evento.observaciones
                        );

                    } else {
                        this.eliminarAlerta(this.evento.id);
                    }

                    if (this.evento.programarMensaje) {
                        MensajeriaImpl.modificarMsgAut(
                            this.getParamsCrearMsgAut(this.$moment(this.evento.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate())
                        );

                    } else {
                        MensajeriaImpl.eliminarMsgAut(this.evento.id);
                    }

                    $('#popupEvento').modal('hide');
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        },

        crearAlerta(descripcion, fecha, idOrigen) {
            let collection = this.getCollectionAddFirestore("alertas");

            collection.add({
                tipoAlerta: 'info',
                descripcion: descripcion,
                fecha: fecha,
                idUsuarioAsesor: this.evento.idUsuarioAlerta ? this.evento.idUsuarioAlerta : this.tarjeta.idUsuarioAsesor,
                idGrupoInformacion: this.tarjeta.idGrupoInformacion,
                origen: 'Embudos',
                activa: false,
                vista: false,
                idOrigen: idOrigen,
                idPersonaAsociada: this.tarjeta.idPersonaLead,
                idEtiquetaEmbudo: this.tarjeta.id

            }).catch(
                function(error) {
                    console.error("Error adding alert: ", error);
                    // this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        async modificarAlerta(idOrigen, fecha, descripcion) {

            let alertaDoc = await this.getDocumentFirestore("alertas", null, false, "idOrigen", idOrigen);
                
            if (alertaDoc) {
                alertaDoc.ref.update({
                    fecha: fecha,
                    descripcion: descripcion,
                    idUsuarioAsesor: this.evento.idUsuarioAlerta ? this.evento.idUsuarioAlerta : this.tarjeta.idUsuarioAsesor,
                    idGrupoInformacion: this.tarjeta.idGrupoInformacion,
                    idPersonaAsociada: this.tarjeta.idPersonaLead,
                    idEtiquetaEmbudo: this.tarjeta.id
                }).catch(
                    function(error) {
                        console.error("Error Updating document alerta: ", error);
                        this.$store.commit("detenerLoading");
    
                    }.bind(this)
                );
            } else {
                this.crearAlerta(
                    descripcion, 
                    fecha,
                    idOrigen
                )
            }

        },

        async eliminarAlerta(idOrigen) {
            let alertaDoc = await this.getDocumentFirestore("alertas", null, false, "idOrigen", idOrigen);
                
            if (alertaDoc) {
                alertaDoc.ref.delete().catch(
                    function(error) {
                        console.error("Error eliminando document alerta: ", error);
    
                    }.bind(this)
                );
            }
        },

        setEventosProperties(evento, nuevoEvento) {
            evento.fechaCreacion= {
                nanoseconds: 0,
                seconds: this.$moment(nuevoEvento.fechaCreacion, "YYYY-MM-DDTHH:mm").toDate().getTime()/1000
            };
            // evento.fechaProgramacion= {
            //     nanoseconds: 0,
            //     seconds: this.$moment(nuevoEvento.fechaProgramacion, "YYYY-MM-DDTHH:mm").toDate().getTime()/1000
            // };
            evento.tipo= nuevoEvento.tipo;
            evento.estado= nuevoEvento.estado;
            evento.observaciones= nuevoEvento.observaciones;
            evento.programarAlerta= nuevoEvento.programarAlerta;
            evento.programarMensaje= nuevoEvento.programarMensaje;
            evento.nombreUsuarioCrea= nuevoEvento.nombreUsuarioCrea
            
        },

        getCantProductosByEtapa(indexEtapa) {
            let cantidadProducto = 0;

            for (let tarjeta of this.embudo.listEtapas[indexEtapa].listTarjetas) {
                if (tarjeta && tarjeta.cantidadProducto) {
                    cantidadProducto += Number(tarjeta.cantidadProducto);
                }
            }

            return cantidadProducto;
        },

        getCantTarjetasByEtapa(indexEtapa) {
            return this.embudo.listEtapas[indexEtapa].listTarjetas.length;
        },

        getTotalProductosByEtapa(indexEtapa) {
            let cantidadProducto = 0;

            for (let tarjeta of this.embudo.listEtapas[indexEtapa].listTarjetas) {
                if (tarjeta && tarjeta.cantidadProducto && tarjeta.cantidadProducto) {
                    cantidadProducto += Number(tarjeta.precioProducto * tarjeta.cantidadProducto);
                    
                }
            }

            return cantidadProducto;
        },

        async mostrarMensajeria(value, idPersona) {
            if (idPersona) {
                // let persona = this.listPersonas.find(element => element.id == idPersona);
                let persona = await PersonasImpl.getPersonaById(idPersona);
                this.personaMsg = {
                    idPersona : persona.id,
                    codArea: persona.codArea ? persona.codArea : '+57',
                    telefonoMovil : persona.telefonoMovil,
                    idGrupoInformacion: persona.idGrupoInformacion,
                    idUsuarioAsesor: persona.idUsuarioAsesor,
                    nombres: persona.nombres + (persona.apellidos ? persona.apellidos : "")
                };
            } else {
                this.personaMsg = null;
                
            }
            
            this.mostrarCompMsg = value;
        },

        mostrarPersona() {
            try {
                if (this.personaTarjeta && this.personaTarjeta.id) {
                    this.contMostrarCompPersona++;
                    setTimeout(function () {$('#popupPersonas').modal('show');}.bind(this), 100);
                }
            } catch (error) {
                console.log(new Date() + "Error editar persona: ");
            }
            
        },

        mostrarEtapasExportar() {
            this.listEtapasExportar = [];
            for (let etapa of this.embudo.listEtapas) {
                if (etapa) {
                    this.listEtapasExportar.push(etapa.nombre);
                }
                
            }

            this.embudo.listEtapas.forEach(etapa => {
                etapa.listTarjetas.forEach(async tarjeta => {
                    // let persona = this.listPersonas.find(persona => persona.id == tarjeta.idPersonaLead);
                    let persona = await PersonasImpl.getPersonaById(tarjeta.idPersonaLead);
                    if (persona) {
                        if (persona.etiquetas && Object.keys(persona.etiquetas).length > 0) {
                            tarjeta.etiquetas = Object.keys(persona.etiquetas).join(", ");
                        }   
                    }
                });
            
            });
        },

        add: function () {
            this.list.push({ name: "Juan" });
        },
        replace: function () {
            this.list = [{ name: "Edgard" }];
        },
        clone: function (el) {
            return {
                name: el.name + " cloned",
            };
        },
        log: function (evt) {
            // window.console.log(evt);
        },

        /*controlRendered(index){
            console.log(index);
            if (index == this.listEventos.length-1) {
                console.log("Termino");
            }
        }*/
    },
};