<template>
	<div>
		<li class="nav-item dropdown no-arrow mx-1">
			<div class="nav-item dropdown no-arrow">
				<a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">
					<span class="badge badge-danger badge-counter">{{alertas.length}}</span>
					<i class="fas fa-bell fa-fw"></i>
				</a>
				<div style="height: 400px; overflow-y: scroll;" class="dropdown-menu dropdown-menu-right dropdown-list dropdown-menu-right animated--grow-in">
					<h6 class="dropdown-header">Centro de alertas</h6>

					<a v-for="(alerta, index) in alertas" :key="index" @click="marcarAlertaVista(alerta)" class="d-flex align-items-center dropdown-item" style="cursor: pointer;">
						<div class="mr-3">
							<div class="bg-warning icon-circle">
								<i class="fas fa-exclamation-triangle text-white"></i>
							</div>
						</div>
						<div>
							<span class="small text-gray-500">{{alerta.fechaFormat}}</span>
							<br>
							{{alerta.descripcion}}
							<br>
							{{alerta.infoPersona}}
						</div>
						
					</a>

					<!-- <a class="d-flex align-items-center dropdown-item" href="#">
						<div class="mr-3">
							<div class="bg-success icon-circle">
								<i class="fas fa-donate text-white"></i>
							</div>
						</div>
						<div>
							<span class="small text-gray-500">December 7, 2019</span>
							<p>$290.29 has been deposited into your account!</p>
						</div>
					</a>
					<a class="d-flex align-items-center dropdown-item" href="#">
						<div class="mr-3">
							<div class="bg-warning icon-circle">
								<i class="fas fa-exclamation-triangle text-white"></i>
							</div>
						</div>
						<div>
							<span class="small text-gray-500">December 2, 2019</span>
							<p>
								Spending Alert: We've noticed unusually high spending for
								your account.
							</p>
						</div>
					</a> -->

					<!-- <a class="text-center dropdown-item small text-gray-500" href="#"
						>Show All Alerts
					</a> -->
				</div>
			</div>
		</li>
	</div>
</template>

<script>
	import { firebase, auth } from '@/firebase/firebaseInit.js';

	export default {
		name: 'NavBarAlertasComp',
		data() {
        	return {
				alertas: [],
				listenerAlertas: null
			}
		},
		mounted() {
			this.inicializar();

		},

		beforeDestroy() {
			if (this.listenerAlertas) {
				this.listenerAlertas();
			}
		},

		methods: {
			inicializar() {
				firebase.auth().onAuthStateChanged((user) =>{
					if (!this.$store.getters.getSesionIniciada) {
						return;
					}
					if(user){
						this.consultar(user.uid);

					}
				});

			},

			async consultar(uid) {
				var uidTmp = uid;
				if (!uidTmp) {
					uidTmp = auth.currentUser.uid

				}

				this.alertas = [];

				this.consultarAlertas(uidTmp);
				
			},

			async consultarAlertas(uid){
				let collection = this.getCollectionListFirestore("alertas", null, true);
				collection = this.getCollectionAlertasFiltered(collection, uid);

				if (this.listenerAlertas) {
					this.listenerAlertas();
				}

				this.listenerAlertas = await this.getAlertas(collection);
			},

			getCollectionAlertasFiltered(pCollection, uid) {
				var collection = pCollection;
				collection = collection.where('idUsuarioAsesor', '==', uid);
				collection = collection.where('activa', '==', true);
				collection = collection.where('vista', '==', false);
		
				return collection;
			},

			async getAlertas(collection) {
				return await collection.onSnapshot((snapshot) => {
					snapshot.docChanges().forEach(async (change) => {
						if (change.type === "added") {
							let alerta = change.doc.data();
							alerta["id"] = change.doc.id;
							alerta["docRef"] = change.doc.ref;

							var fecha = new Date(0);
							fecha.setSeconds(change.doc.data().fecha.seconds);

							alerta.fechaFormat = this.$moment(fecha).format("DD/MM/YYYY hh:mm A");
							alerta.infoPersona = '';

							if (alerta.idPersonaAsociada) {
								alerta.infoPersona= await this.getInfoPersona(alerta.idPersonaAsociada);
							}

							this.alertas.unshift(alerta);
							this.mostrarNotificacion(alerta.descripcion, '');
							let music = new Audio(window.location.origin + '/alerta.mp3');
							music.play();
							
						}

						if (change.type === "modified") {

						}

						if (change.type === "removed") {
							let index = this.alertas.findIndex(element => element.id === change.doc.id);
							if (index >= 0) {
								this.alertas.splice(index, 1);
							}
						}

					});
				});
			},

			async getInfoPersona(idPersona)  {
				let info = '';
				let personaDoc = await this.getDocumentFirestore("personas", idPersona);
                if (personaDoc) {
					let nombre= personaDoc.data().nombres + ' ' + (personaDoc.data().apellidos ? personaDoc.data().apellidos : '');
                    let numeroIdentificacion= personaDoc.data().numeroIdentificacion;
                    let telefonoMovil= personaDoc.data().telefonoMovil;

					info = ` (Id:${numeroIdentificacion} - ${nombre} - Cel: ${telefonoMovil}`;
                    
				}

				return info;
			},

			marcarAlertaVista(alerta) {
				alerta.docRef.set({
					vista: true,

				}, { merge: true}).catch(
					function(error) {
						console.error("Error updating alerta: ", error);

					}.bind(this)
				);
			},

			async mostrarNotificacion(textoMensaje, remitente) {
				let granted = false;

				if (Notification.permission === 'granted') {
					granted = true;
				} else if (Notification.permission !== 'denied') {
					let permission = await Notification.requestPermission();
					granted = permission === 'granted' ? true : false;
				}

				if (granted) {
					const notification = new Notification(remitente, {
						body: textoMensaje,
						icon: '/logoFourBrainsPeq.png'
					});
				}
			},
		}
	};
</script>

<style>
</style>