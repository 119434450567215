<template>
  <div>
    <div class="text-center">
      <router-link :to="'/login'" class="small">
        {{ $t("message.home.bienvenidos") }}
      </router-link>
      <h4>Contador:{{ $store.state.count }}</h4>

      <button @click="incrementar">Incrementar</button>
      <button @click="decrementar">Decrementar</button>

      <br />

      <h4>Moneda:{{ $store.state.moneda }}</h4>
      <label>¿Cuantos digitos tiene su moneda?: </label>
      <input @input="setDigitosDecimalesMoneda" />

      <br />

      <label>¿Idioma?: </label>
      <idiomas-component />

      <br />
      <h1>Vue Select</h1>
      <v-select :options="options" style="width: 200px; left: 43%"></v-select>

      <br />

      <vp rules="required" v-slot="{ errors }">
        <input
          v-model="value"
          type="text"
          placeholder="type something"
          v-bind:class="{ 'is-danger': errors[0] }"
        />
      </vp>

      <br />

      <label>Validaciones y mascara</label>
      <vp rules="required" v-slot="{ errors }">
        <input
          v-model="valueCompute"
          type="text"
          placeholder="type something"
          v-bind:class="{ 'is-danger': errors[0] }"
        />
      </vp>
      {{ value }}
      <button @click="useCleanMask(value)">useCleanMask</button>

      <br />

      <label>Mascara </label>
      <input v-model="otro" type="text" />

      <br />

      <label>Mascara en labels</label>
      <input v-model="campo" type="text" />
      {{ campo }}

      <br />
      <div class="contenedorPagination">
        <paginate
          :page-count="$store.state.constantes.CANT_REG_POR_PAGINA"
          :click-handler="clickCallback"
          :prev-text="this.$t('message.paginator.anterior')"
          :next-text="this.$t('message.paginator.siguiente')"
          :container-class="'paginationbs'"
        >
        </paginate>

        <br />
        <button @click="invokeWs()">Invocar WS Rest</button>
        <br />
        <button @click="createUser()">Crear Usuario</button>
        <br />
        <button @click="updateUser()">Actualizar Usuario</button>
        <br />
        <button @click="deleteTelefonoField()">Borrar campo teléfono</button>
        <br />
        <button @click="getUser()">Consultar usuarios</button>
        <br />
        <button @click="getEmpresas()">Consultar empresas</button>
        <br />
        <button @click="logOut()">LogOut</button>
        <br />
        <br />
        <button @click="addAlert()">Alerta</button>
        <br />
        <button @click="goToMenu()">MEnu Principal</button>
        <br />
        <button @click="getOrganizacion()">Obtener organización</button>
        <br />
        <button @click="getPersonas()">Obtener Personas</button>
        <br />
        <button @click="getOrganizacionByUsuario()">
          Obtener Organización by usuario
        </button>
        <br />
        <button @click="getRecursosUsuario()">
          Obtener recursos usuario logueado
        </button>
        <br />
        <button @click="getModulosRecursosUsuario()">
          Obtener modulos recursos usuario logueado
        </button>
        <br />
        <button @click="getTablasTipo()">
          Obtener tablas tipo
        </button>
        <br />
        <button @click="getTablaTipoPersona()">
          Obtener tablas tipo personas
        </button>
        <br />
        <button @click="getOrganizacionInterna()">
          Obtener Organizacion interna
        </button>
        <br />
        <button @click="ubdateUbicacionesGeograficas()">
          Actualizar ubicaciones geograficas
        </button>
        <br />
        <button @click="updateTemporalPersonas()">
          Actualizar temporal personas
        </button>
        <br />
        <button @click="getEstadisticasPersonas()">
          Obtener estadisticas personas
        </button>
        <br />
        <button @click="getMensajes()">
          Obtener Mensajes personas
        </button>
        <br />
        <button @click="getAlertas()">
          Obtener Alertas
        </button>
        <br />
        <button @click="pruebaClases()">
          Prueba de clase
        </button>
        <br />
        <button @click="getMensajesAutomaticos()">
          Obtener mensajes automaticos
        </button>
        <br />
        <button @click="getHistorias()">
          Obtener historias
        </button>
        <br />
        <button @click="subirArchivo()">
          Subir archivo
        </button>
        <br />
        <button @click="updateTarjetasEmbudos()">
          Actualizar embudos
        </button>
        <br />
        <button @click="updateSearchTermsPersonas()">
          Actualizar search terms personas
        </button>
        <br />
        <button @click="createPersonasFind()">
          Crear personas find
        </button>
        <br />
        <button @click="createGenerosCanciones()">
          Crear generos canciones
        </button>
        <br />

        {{ $store.state.listRecursos }}
      </div>
    </div>
  </div>
</template>

<script>
import { useCleanMask } from "../hooks/useCleanMask";
import { FIREBASE_SERVICES } from "../axios/http-common";
import { auth, firestore, firebase } from "@/firebase/firebaseInit.js";
import IdiomasComponent from "../components/IdiomasComponent";
import Punto from "@/classes/pruebaClase";

export function timeRangeMask(value) {
  return ["$", "d"];
}

export default {
  name: "Home",
  components: {
    IdiomasComponent,
  },
  data() {
    return {
      options: ["foo", "bar", "baz"],
      otro: "",
      campo: "",
      value: 1234,
      user: {
        name: "Perano",
        email: "perano@gmail.com",
        phone: "131254621",
      },
      perfil: {
        nombre: "",
        recursos: [],
        idGrupoInformacion: this.$store.getters.getIdGrupoInfomacion,
      },
      modulosRecursos: [],
      mask: timeRangeMask
    };
  },

  computed: { 
    valueCompute:{
      // getter
      get () {
        return this.formatMoneda(this.value);
      },
      // setter
      set (newValue) {
        this.value = this.getOriginalFormatMoneda(newValue);
      }
        
    },
  },
  
  methods: {
    incrementar() {
      this.$store.commit("incrementar");
    },

    decrementar() {
      this.$store.commit("decrementar");
    },

    setDigitosDecimalesMoneda: function (event) {
      this.$store.commit("setDigitosDecimalesMoneda", event.target.value);
    },

    useCleanMask(valor) {
      console.log(useCleanMask(valor));
    },

    clickCallback(pageNum) {
      console.log(pageNum);
    },

    invokeWs() {
      FIREBASE_SERVICES.get(
        "http://calapi.inadiutorium.cz/api/v0/en/calendars/general-en.json"
      )
        .then(
          function (response) {
            console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
          }.bind(this)
        );
    },
    createUser() {
      firestore
        .collection("users")
        .add(this.user)
        .then(() => {
          alert("User successfully created!");
          this.user.name = "";
          this.user.email = "";
          this.user.phone = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateUser() {
      var userRef = firestore.collection("users").doc("qDrhgFRWQxwyNhhdCk6A");

      // Set the "capital" field of the city 'DC'
      return userRef
        .update({
          telefono: "12312132",
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    deleteTelefonoField() {
      var userRef = firestore.collection("users").doc("qDrhgFRWQxwyNhhdCk6A");

      // Remove the 'telefono' field from the document
      var removeTelefono = userRef.update({
        telefono: firebase.firestore.FieldValue.delete(),
      });
    },
    getUser() {
      var collection = this.getCollectionListFirestore('usuarios');
      collection.get()
      .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {  
              // this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});
              console.log(doc.data());

          });
          // this.$store.commit("detenerLoading");

      }).catch(
          function(error) {

            collection = collection.where('id', '==', auth.currentUser.uid);
            collection.get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {  
                    // this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});
                    console.log(doc.data());

                });
                // this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                    // this.$store.commit("detenerLoading");

                }.bind(this)
            );

          }.bind(this)
      );

        
    },
    getEmpresas() {
      var uid = auth.currentUser.uid; //firebase.auth().currentUser.uid;
      // var uid = '9y9wZpzW2SUNpicvLCukf7r2lPq1';
      firestore
        .collection("empresas")
        .where("uid", "==", uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    logOut() {
      auth.signOut().then(() => {
        console.log("SignOut");
      });
    },
    addAlert() {
      this.$store.dispatch("addAlert", {
        tipo: "success",
        titulo: "Alerta Uno",
        descripcion: "Este es un mensaje correcto",
      }),
        this.$store.dispatch("addAlert", {
          tipo: "danger",
          titulo: "Alerta Dos",
          descripcion: "Este es un mensaje de peligro",
        }),
        this.$store.dispatch("addAlert", {
          tipo: "warning",
          titulo: "Alerta Tres",
          descripcion: "Este es el mensaje de advertencia",
        }),
        this.$store.dispatch("addAlert", {
          tipo: "info",
          titulo: "Alerta Cuatro",
          descripcion: "Este es un mensaje informativo",
        });
    },
    goToMenu() {
      this.$router.push("/menuPrincipal");
    },
    getOrganizacion() {
      // var uid = firebase.auth().currentUser.uid;
      // var uid = '9y9wZpzW2SUNpicvLCukf7r2lPq1';
      var firestoreOrg = firebase
        .firestore()
        .collection("Organizaciones")
        .doc(this.$store.getters.getIdOrganizacion)
        // .collection('productos');
        .collection("organizacion")
        .where("__name__", "==", this.$store.getters.getIdOrganizacion)
        .where(
          "idGrupoInformacion",
          "==",
          this.$store.getters.getIdGrupoInfomacion
        );

      firestoreOrg
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(JSON.stringify(doc.data()));
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });

      // firestoreOrg.get()
      //     .then(
      //         function(doc) {
      //             if (doc.exists) {
      //                 console.log("Document data:", JSON.stringify(doc.data()));
      //             } else {
      //                 // doc.data() will be undefined in this case
      //                 console.log("No such document!");
      //             }
      //         }
      //     ).catch(function(error) {
      //         console.log("Error getting document:", error);
      //     });;
    },
    getPersonas() {
      // var uid = firebase.auth().currentUser.uid;
      // var uid = '9y9wZpzW2SUNpicvLCukf7r2lPq1';
      // firestore
      //     .collection("Organizaciones")
      //     .doc('QEzGcjvKh4uvzCFXb9Bn')

      // firestoreOrg
      //     .collection("personas")
      //     .where('idGrupoInformacion', '==', 'RKIayWqgur18oaQ3Oyg7')

      this.getCollectionListFirestore("personas")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    getOrganizacionByUsuario() {
      var uid = auth.currentUser.uid;
      // var uid = '9y9wZpzW2SUNpicvLCukf7r2lPq1';
      // var uid = 'LFrLaMtBX8akKLvbWiRUYMEIeR63';
      firestore
        .collection("OrganizacionesUsuarios")
        .doc(uid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log("Document data:", JSON.stringify(doc.data()));
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    getPersonasDirecto() {
      // var uid = firebase.auth().currentUser.uid;
      // var uid = '9y9wZpzW2SUNpicvLCukf7r2lPq1';
      // firestore
      //     .collection("Organizaciones")
      //     .doc('QEzGcjvKh4uvzCFXb9Bn')

      firestore
        .collection("Organizaciones")
        .doc("QEzGcjvKh4uvzCFXb9Bn")
        .collection("personas")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    getRecursosUsuario_() {
      // var uid = firebase.auth().currentUser.uid;
      // var uid = '9y9wZpzW2SUNpicvLCukf7r2lPq1';
      // firestore
      //     .collection("Organizaciones")
      //     .doc('QEzGcjvKh4uvzCFXb9Bn')
      var uid = auth.currentUser.uid;
      firestoreOrg
        .collection("usuarios")
        .doc(uid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            firestoreOrg
              .collection("perfiles")
              .doc(doc.data().perfil)
              .collection("recursos")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                });
              })
              .catch(function (error) {
                console.log("Error getting document:", error);
              });

            // console.log("Document data:", JSON.stringify(doc.data()));
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    async getRecursosUsuario() {
      return new Promise(async (resolve, reject) => {
        var uid = auth.currentUser.uid;

        // firestoreOrg
        //     .collection("usuarios")
        //     .where('idGrupoInformacion', '==', 'RKIayWqgur18oaQ3Oyg7')
        this.getCollectionListFirestore("usuarios", uid)
          // .where('__name__', '==', uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((usuarioDoc) => {
              // console.log(`usuario: ${usuarioDoc.id} => ${JSON.stringify(usuarioDoc.data())}`);

              this.getCollectionListFirestore(
                "perfiles",
                usuarioDoc.data().perfil
              )
                // .where('__name__', '==', usuarioDoc.data().perfil)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((perfilDoc) => {
                    //console.log(`perfil: ${perfilDoc.id} => ${JSON.stringify(perfilDoc.data())}`);
                    this.perfil = perfilDoc.data();
                    this.perfil["recursos"] = [];
                    //console.log("recursos: -->");
                    perfilDoc.ref
                      .collection("recursos")
                      .where(
                        "idGrupoInformacion",
                        "==",
                        this.$store.getters.getIdGrupoInfomacion
                      )
                      .get()
                      .then((querySnapshot) => {
                        querySnapshot.forEach((recursoDoc) => {
                          this.perfil.recursos.push({
                            id: recursoDoc.id,
                            idGrupoInformacion: recursoDoc.data()
                              .idGrupoInformacion,
                            readInner: recursoDoc.data().readInner
                              ? recursoDoc.data().readInner
                              : false,
                            read: recursoDoc.data().read
                              ? recursoDoc.data().read
                              : false,
                            delete: recursoDoc.data().delete
                              ? recursoDoc.data().delete
                              : false,
                            update: recursoDoc.data().update
                              ? recursoDoc.data().update
                              : false,
                            write: recursoDoc.data().write
                              ? recursoDoc.data().write
                              : false,
                            docRef: recursoDoc.ref,
                          });
                          //console.log(`${recursoDoc.id} => ${JSON.stringify(recursoDoc.data())}`);
                        });
                        resolve(null);
                      })
                      .catch(function (error) {
                        console.log("Error getting document:", error);
                        resolve(null);
                      });
                  });
                })
                .catch(function (error) {
                  console.log("Error getting document:", error);
                  resolve(null);
                });
            });
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
            resolve(null);
          });
      });

      /*firestoreOrg
                .collection("usuarios")
                .doc(uid)
                .get()
                .then(
                    function(doc) {
                        if (doc.exists) {

                            firestoreOrg
                            .collection("perfiles")
                            .doc(doc.data().perfil)
                            .collection("recursos")
                            .get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                                });
                            }).catch(function(error) {
                                console.log("Error getting document:", error);
                            });;

                            // console.log("Document data:", JSON.stringify(doc.data()));
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                    }
                ).catch(function(error) {
                    console.log("Error getting document:", error);
                });*/
    },
    getRecursoUsuarioByClave(recursos, pClave) {
      return recursos.find((recurso) => recurso.id == pClave);
    },
    replacer(key, value) {
      if (key == "docRef") return undefined;
      else return value;
    },
    snapshotToArray(snapshot) {
      var returnArr = [];

      snapshot.forEach(function (childSnapshot) {
        var item = childSnapshot.val();
        item.key = childSnapshot.key;

        returnArr.push(item);
      });

      return returnArr;
    },
    getRecursosModulosBySnapshotAndIdModulo(
      querySnapshotRecursosMod,
      idModulo
    ) {
      var recursosModulosUsuario = [];

      querySnapshotRecursosMod.forEach((recursoModDoc) => {
        let recursoUsuario = this.getRecursoUsuarioByClave(
          this.perfil.recursos,
          recursoModDoc.data().clave
        );

        let recursoModulo = {
          id: recursoModDoc.id,
          idModulo: idModulo,
          nombre: recursoModDoc.data().nombre,
          clave: recursoModDoc.data().clave,
          idGrupoInformacion: this.perfil.idGrupoInformacion,
          readInner: false,
          read: false,
          delete: false,
          update: false,
          write: false,
          modificado: false,
          docRef: null,
        };

        if (recursoUsuario) {
          recursoModulo.idGrupoInformacion = recursoUsuario.idGrupoInformacion;
          recursoModulo.readInner = recursoUsuario.readInner;
          recursoModulo.read = recursoUsuario.read;
          recursoModulo.delete = recursoUsuario.delete;
          recursoModulo.update = recursoUsuario.update;
          recursoModulo.write = recursoUsuario.write;
          recursoModulo.docRef = recursoUsuario.docRef;
        }

        recursosModulosUsuario.push(recursoModulo);
      });

      return recursosModulosUsuario;
    },
    async getModulosRecursosUsuarioBySnapshot(querySnapshotModulosRecursos) {
      var modulosRecursos = [];
      querySnapshotModulosRecursos.forEach(async (moduloRecursosDoc) => {
        var querySnapshotRecursosMod = await moduloRecursosDoc.ref
          .collection("recursosMod")
          .get();

        var moduloRecurso = await moduloRecursosDoc.data();
        //moduloRecurso.recursosModulo = [];

        moduloRecurso.recursosModulo = await this.getRecursosModulosBySnapshotAndIdModulo(
          querySnapshotRecursosMod,
          moduloRecursosDoc.id
        );

        modulosRecursos.push(moduloRecurso);
      });

      return modulosRecursos;
    },

    async getModulosRecursosUsuario() {
      try {
        this.modulosRecursos = [];

        const recursosUsuarioload = await this.getRecursosUsuario();

        const getModulosRecursosUsuarios = async () => {
          let allModulosRecursos = await this.getCollectionListFirestore(
            "modulosRecursos",
            null,
            true
          ).get();

          for (const moduloRecursosDoc of allModulosRecursos.docs) {
            let allRecursosMod = await moduloRecursosDoc.ref
              .collection("recursosMod")
              .get();
            var recursosModulosUsuario = [];
            for (const recursoModDoc of allRecursosMod.docs) {
              let recursoModData = await recursoModDoc.data();
              let recursoUsuario = await this.getRecursoUsuarioByClave(
                this.perfil.recursos,
                recursoModData.clave
              );

              let recursoModulo = {
                id: recursoModDoc.id,
                idModulo: moduloRecursosDoc.id,
                nombre: recursoModData.nombre,
                clave: recursoModData.clave,
                idGrupoInformacion: this.perfil.idGrupoInformacion,
                readInner: false,
                read: false,
                delete: false,
                update: false,
                write: false,
                modificado: false,
                docRef: null,
              };

              if (recursoUsuario) {
                recursoModulo.idGrupoInformacion =
                  recursoUsuario.idGrupoInformacion;
                recursoModulo.readInner = recursoUsuario.readInner;
                recursoModulo.read = recursoUsuario.read;
                recursoModulo.delete = recursoUsuario.delete;
                recursoModulo.update = recursoUsuario.update;
                recursoModulo.write = recursoUsuario.write;
                recursoModulo.docRef = recursoUsuario.docRef;
              }

              recursosModulosUsuario.push(recursoModulo);
            }
            var moduloRecurso = await moduloRecursosDoc.data();
            moduloRecurso.recursosModulo = recursosModulosUsuario;
            this.modulosRecursos.push(moduloRecurso);
          }
        };
        await getModulosRecursosUsuarios();
        console.log(`${JSON.stringify(this.modulosRecursos, this.replacer)}`);
      } catch (e) {
        console.error(e);
      }
    },

    getTablasTipo() {
          this.getCollectionListFirestore('tablasTipo', null, true).doc("list").get().then((doc) => {
              if (doc.exists) {
                  console.log(JSON.stringify(doc.data()));
                  this.$store.commit("setTablasTipo", doc.data());
                                    
              } else {
                  console.log("No such document!");

              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });
    },

    getTablaTipoPersona() {
      console.log(this.$store.state.tablasTipo.tipoUbicacionGeografica);
    },

    async getOrganizacionInterna() {
      var doc = await this.getDocumentFirestore("organizacion", this.$store.getters.getIdOrganizacion, true);
      if (doc) {
          console.log("Document data:", doc.data());
      } else {
          console.log("No such document!");
      }
    },

    ubdateUbicacionesGeograficas() {
          this.getCollectionListFirestore('tablasTipo', null, true).doc("list").get().then((doc) => {
              if (doc.exists) {
                  var listUbicacionesGeograficas = 
                    [   {"idUbicacionPadre":0,"descripcion":"Colombia","id":1,"idTipo":1},
                        {"idUbicacionPadre":1,"descripcion":"Amazonas","id":2,"idTipo":2},
                        {"idUbicacionPadre":2,"descripcion":"El Encanto","id":3,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"La Chorrera","id":4,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"La Pedrera","id":5,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"La Victoria","id":6,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"Leticia","id":7,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"Miriti Paraná","id":8,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"Puerto Alegría","id":9,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"Puerto Arica","id":10,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"Puerto Nariño","id":11,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"Puerto Santander","id":12,"idTipo":3},
                        {"idUbicacionPadre":2,"descripcion":"Tarapacá","id":13,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Antioquia","id":14,"idTipo":2},
                        {"idUbicacionPadre":14,"descripcion":"Abejorral","id":15,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Abriaquí","id":16,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Alejandría","id":17,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Amagá","id":18,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Amalfi","id":19,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Andes","id":20,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Angelópolis","id":21,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Angostura","id":22,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Anorí","id":23,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Anza","id":24,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Apartadó","id":25,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Arboletes","id":26,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Argelia","id":27,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Armenia","id":28,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Barbosa","id":29,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Bello","id":30,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Belmira","id":31,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Betania","id":32,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Betulia","id":33,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Briceño","id":34,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Buriticá","id":35,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Cáceres","id":36,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Caicedo","id":37,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Caldas","id":38,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Campamento","id":39,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Cañasgordas","id":40,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Caracolí","id":41,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Caramanta","id":42,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Carepa","id":43,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Carolina","id":44,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Caucasia","id":45,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Chigorodó","id":46,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Cisneros","id":47,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Ciudad Bolívar","id":48,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Cocorná","id":49,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Concepción","id":50,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Concordia","id":51,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Copacabana","id":52,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Dabeiba","id":53,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Don Matías","id":54,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Ebéjico","id":55,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"El Bagre","id":56,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"El Carmen de Viboral","id":57,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"El Santuario","id":58,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Entrerrios","id":59,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Envigado","id":60,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Fredonia","id":61,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Frontino","id":62,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Giraldo","id":63,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Girardota","id":64,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Gómez Plata","id":65,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Granada","id":66,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Guadalupe","id":67,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Guarne","id":68,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Guatapé","id":69,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Heliconia","id":70,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Hispania","id":71,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Itagui","id":72,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Ituango","id":73,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Jardín","id":74,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Jericó","id":75,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"La Ceja","id":76,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"La Estrella","id":77,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"La Pintada","id":78,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"La Unión","id":79,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Liborina","id":80,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Maceo","id":81,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Marinilla","id":82,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Medellín","id":83,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Montebello","id":84,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Murindó","id":85,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Mutatá","id":86,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Nariño","id":87,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Nechí","id":88,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Necoclí","id":89,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Olaya","id":90,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Peñol","id":91,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Peque","id":92,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Pueblorrico","id":93,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Puerto Berrío","id":94,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Puerto Nare","id":95,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Puerto Triunfo","id":96,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Remedios","id":97,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Retiro","id":98,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Rionegro","id":99,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Sabanalarga","id":100,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Sabaneta","id":101,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Salgar","id":102,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Andrés de Cuerquía","id":103,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Carlos","id":104,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Francisco","id":105,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Jerónimo","id":106,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San José de La Montaña","id":107,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Juan de Urabá","id":108,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Luis","id":109,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Pedro","id":110,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Pedro de Uraba","id":111,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Rafael","id":112,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Roque","id":113,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"San Vicente","id":114,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Santa Bárbara","id":115,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Santa Rosa de Osos","id":116,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Santafé de Antioquia","id":117,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Santo Domingo","id":118,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Segovia","id":119,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Sonsón","id":120,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Sopetrán","id":121,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Támesis","id":122,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Tarazá","id":123,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Tarso","id":124,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Titiribí","id":125,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Toledo","id":126,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Turbo","id":127,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Uramita","id":128,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Urrao","id":129,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Valdivia","id":130,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Valparaíso","id":131,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Vegachí","id":132,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Venecia","id":133,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Vigía del Fuerte","id":134,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Yalí","id":135,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Yarumal","id":136,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Yolombó","id":137,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Yondó","id":138,"idTipo":3},
                        {"idUbicacionPadre":14,"descripcion":"Zaragoza","id":139,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Arauca","id":140,"idTipo":2},
                        {"idUbicacionPadre":140,"descripcion":"Arauca","id":141,"idTipo":3},
                        {"idUbicacionPadre":140,"descripcion":"Arauquita","id":142,"idTipo":3},
                        {"idUbicacionPadre":140,"descripcion":"Cravo Norte","id":143,"idTipo":3},
                        {"idUbicacionPadre":140,"descripcion":"Fortul","id":144,"idTipo":3},
                        {"idUbicacionPadre":140,"descripcion":"Puerto Rondón","id":145,"idTipo":3},
                        {"idUbicacionPadre":140,"descripcion":"Saravena","id":146,"idTipo":3},
                        {"idUbicacionPadre":140,"descripcion":"Tame","id":147,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Archipiélago de San Andrés, Providencia y Santa Catalina","id":148,"idTipo":2},
                        {"idUbicacionPadre":148,"descripcion":"Providencia","id":149,"idTipo":3},
                        {"idUbicacionPadre":148,"descripcion":"San Andrés","id":150,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Atlántico","id":151,"idTipo":2},
                        {"idUbicacionPadre":151,"descripcion":"Baranoa","id":152,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Barranquilla","id":153,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Campo de La Cruz","id":154,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Candelaria","id":155,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Galapa","id":156,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Juan de Acosta","id":157,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Luruaco","id":158,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Malambo","id":159,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Manatí","id":160,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Palmar de Varela","id":161,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Piojó","id":162,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Polonuevo","id":163,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Ponedera","id":164,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Puerto Colombia","id":165,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Repelón","id":166,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Sabanagrande","id":167,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Sabanalarga","id":168,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Santa Lucía","id":169,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Santo Tomás","id":170,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Soledad","id":171,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Suan","id":172,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Tubará","id":173,"idTipo":3},
                        {"idUbicacionPadre":151,"descripcion":"Usiacurí","id":174,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Bogotá D.C.","id":175,"idTipo":2},
                        {"idUbicacionPadre":175,"descripcion":"Bogotá","id":176,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Bolívar","id":177,"idTipo":2},
                        {"idUbicacionPadre":177,"descripcion":"Achí","id":178,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Altos del Rosario","id":179,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Arenal","id":180,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Arjona","id":181,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Arroyohondo","id":182,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Barranco de Loba","id":183,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Calamar","id":184,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Cantagallo","id":185,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Cartagena","id":186,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Cicuco","id":187,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Clemencia","id":188,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Córdoba","id":189,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"El Carmen de Bolívar","id":190,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"El Guamo","id":191,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"El Peñón","id":192,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Hatillo de Loba","id":193,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Magangué","id":194,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Mahates","id":195,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Margarita","id":196,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"María la Baja","id":197,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Mompós","id":198,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Montecristo","id":199,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Morales","id":200,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Norosí","id":201,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Pinillos","id":202,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Regidor","id":203,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Río Viejo","id":204,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"San Cristóbal","id":205,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"San Estanislao","id":206,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"San Fernando","id":207,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"San Jacinto","id":208,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"San Jacinto del Cauca","id":209,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"San Juan Nepomuceno","id":210,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"San Martín de Loba","id":211,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"San Pablo de Borbur","id":212,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Santa Catalina","id":213,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Santa Rosa","id":214,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Santa Rosa del Sur","id":215,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Simití","id":216,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Soplaviento","id":217,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Talaigua Nuevo","id":218,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Tiquisio","id":219,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Turbaco","id":220,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Turbaná","id":221,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Villanueva","id":222,"idTipo":3},
                        {"idUbicacionPadre":177,"descripcion":"Zambrano","id":223,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Boyacá","id":224,"idTipo":2},
                        {"idUbicacionPadre":224,"descripcion":"Almeida","id":225,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Aquitania","id":226,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Arcabuco","id":227,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Belén","id":228,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Berbeo","id":229,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Betéitiva","id":230,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Boavita","id":231,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Boyacá","id":232,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Briceño","id":233,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Buena Vista","id":234,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Busbanzá","id":235,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Caldas","id":236,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Campohermoso","id":237,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Cerinza","id":238,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Chinavita","id":239,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Chiquinquirá","id":240,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Chíquiza","id":241,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Chiscas","id":242,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Chita","id":243,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Chitaraque","id":244,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Chivatá","id":245,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Chivor","id":246,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Ciénega","id":247,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Cómbita","id":248,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Coper","id":249,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Corrales","id":250,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Covarachía","id":251,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Cubará","id":252,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Cucaita","id":253,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Cuítiva","id":254,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Duitama","id":255,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"El Cocuy","id":256,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"El Espino","id":257,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Firavitoba","id":258,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Floresta","id":259,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Gachantivá","id":260,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Gameza","id":261,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Garagoa","id":262,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Guacamayas","id":263,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Guateque","id":264,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Guayatá","id":265,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Güicán","id":266,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Iza","id":267,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Jenesano","id":268,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Jericó","id":269,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"La Capilla","id":270,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"La Uvita","id":271,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"La Victoria","id":272,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Labranzagrande","id":273,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Macanal","id":274,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Maripí","id":275,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Miraflores","id":276,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Mongua","id":277,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Monguí","id":278,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Moniquirá","id":279,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Motavita","id":280,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Muzo","id":281,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Nobsa","id":282,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Nuevo Colón","id":283,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Oicatá","id":284,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Otanche","id":285,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Pachavita","id":286,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Páez","id":287,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Paipa","id":288,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Pajarito","id":289,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Panqueba","id":290,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Pauna","id":291,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Paya","id":292,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Paz de Río","id":293,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Pesca","id":294,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Pisba","id":295,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Puerto Boyacá","id":296,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Quípama","id":297,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Ramiriquí","id":298,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Ráquira","id":299,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Rondón","id":300,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Saboyá","id":301,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Sáchica","id":302,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Samacá","id":303,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"San Eduardo","id":304,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"San José de Pare","id":305,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"San Luis de Gaceno","id":306,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"San Mateo","id":307,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"San Miguel de Sema","id":308,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"San Pablo de Borbur","id":309,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Santa María","id":310,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Santa Rosa de Viterbo","id":311,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Santa Sofía","id":312,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Santana","id":313,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Sativanorte","id":314,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Sativasur","id":315,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Siachoque","id":316,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Soatá","id":317,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Socha","id":318,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Socotá","id":319,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Sogamoso","id":320,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Somondoco","id":321,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Sora","id":322,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Soracá","id":323,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Sotaquirá","id":324,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Susacón","id":325,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Sutamarchán","id":326,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Sutatenza","id":327,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tasco","id":328,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tenza","id":329,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tibaná","id":330,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tibasosa","id":331,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tinjacá","id":332,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tipacoque","id":333,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Toca","id":334,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Togüí","id":335,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tópaga","id":336,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tota","id":337,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tunja","id":338,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tununguá","id":339,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Turmequé","id":340,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tuta","id":341,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Tutazá","id":342,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Umbita","id":343,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Ventaquemada","id":344,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Villa de Leyva","id":345,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Viracachá","id":346,"idTipo":3},
                        {"idUbicacionPadre":224,"descripcion":"Zetaquira","id":347,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Caldas","id":348,"idTipo":2},
                        {"idUbicacionPadre":348,"descripcion":"Aguadas","id":349,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Anserma","id":350,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Aranzazu","id":351,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Belalcázar","id":352,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Chinchiná","id":353,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Filadelfia","id":354,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"La Dorada","id":355,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"La Merced","id":356,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Manizales","id":357,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Manzanares","id":358,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Marmato","id":359,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Marquetalia","id":360,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Marulanda","id":361,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Neira","id":362,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Norcasia","id":363,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Pácora","id":364,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Palestina","id":365,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Pensilvania","id":366,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Riosucio","id":367,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Risaralda","id":368,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Salamina","id":369,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Samaná","id":370,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"San José","id":371,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Supía","id":372,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Victoria","id":373,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Villamaría","id":374,"idTipo":3},
                        {"idUbicacionPadre":348,"descripcion":"Viterbo","id":375,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Caquetá","id":376,"idTipo":2},
                        {"idUbicacionPadre":376,"descripcion":"Albania","id":377,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Belén de Los Andaquies","id":378,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Cartagena del Chairá","id":379,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Curillo","id":380,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"El Doncello","id":381,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"El Paujil","id":382,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Florencia","id":383,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"La Montañita","id":384,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Milán","id":385,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Morelia","id":386,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Puerto Rico","id":387,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"San José del Fragua","id":388,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"San Vicente del Caguán","id":389,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Solano","id":390,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Solita","id":391,"idTipo":3},
                        {"idUbicacionPadre":376,"descripcion":"Valparaíso","id":392,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Casanare","id":393,"idTipo":2},
                        {"idUbicacionPadre":393,"descripcion":"Aguazul","id":394,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Chámeza","id":395,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Hato Corozal","id":396,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"La Salina","id":397,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Maní","id":398,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Monterrey","id":399,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Nunchía","id":400,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Orocué","id":401,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Paz de Ariporo","id":402,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Pore","id":403,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Recetor","id":404,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Sabanalarga","id":405,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Sácama","id":406,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"San Luis de Gaceno","id":407,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Támara","id":408,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Tauramena","id":409,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Trinidad","id":410,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Villanueva","id":411,"idTipo":3},
                        {"idUbicacionPadre":393,"descripcion":"Yopal","id":412,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Cauca","id":413,"idTipo":2},
                        {"idUbicacionPadre":413,"descripcion":"Almaguer","id":414,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Argelia","id":415,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Balboa","id":416,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Bolívar","id":417,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Buenos Aires","id":418,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Cajibío","id":419,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Caldono","id":420,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Caloto","id":421,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Corinto","id":422,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"El Tambo","id":423,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Florencia","id":424,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Guachené","id":425,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Guapi","id":426,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Inzá","id":427,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Jambaló","id":428,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"La Sierra","id":429,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"La Vega","id":430,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"López","id":431,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Mercaderes","id":432,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Miranda","id":433,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Morales","id":434,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Padilla","id":435,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Páez","id":436,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Patía","id":437,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Piamonte","id":438,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Piendamó","id":439,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Popayán","id":440,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Puerto Tejada","id":441,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Puracé","id":442,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Rosas","id":443,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"San Sebastián","id":444,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Santa Rosa","id":445,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Santander de Quilichao","id":446,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Silvia","id":447,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Sotara","id":448,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Suárez","id":449,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Sucre","id":450,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Timbío","id":451,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Timbiquí","id":452,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Toribio","id":453,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Totoró","id":454,"idTipo":3},
                        {"idUbicacionPadre":413,"descripcion":"Villa Rica","id":455,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Cesar","id":456,"idTipo":2},
                        {"idUbicacionPadre":456,"descripcion":"Aguachica","id":457,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Agustín Codazzi","id":458,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Astrea","id":459,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Becerril","id":460,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Bosconia","id":461,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Chimichagua","id":462,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Chiriguaná","id":463,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Curumaní","id":464,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"El Copey","id":465,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"El Paso","id":466,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Gamarra","id":467,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"González","id":468,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"La Gloria","id":469,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"La Jagua de Ibirico","id":470,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"La Paz","id":471,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Manaure","id":472,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Pailitas","id":473,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Pelaya","id":474,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Pueblo Bello","id":475,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Río de Oro","id":476,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"San Alberto","id":477,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"San Diego","id":478,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"San Martín","id":479,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Tamalameque","id":480,"idTipo":3},
                        {"idUbicacionPadre":456,"descripcion":"Valledupar","id":481,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Chocó","id":482,"idTipo":2},
                        {"idUbicacionPadre":482,"descripcion":"Acandí","id":483,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Alto Baudo","id":484,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Atrato","id":485,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Bagadó","id":486,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Bahía Solano","id":487,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Bajo Baudó","id":488,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Belén de Bajira","id":489,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Bojaya","id":490,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Carmen del Darien","id":491,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Cértegui","id":492,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Condoto","id":493,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"El Cantón del San Pablo","id":494,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"El Carmen de Atrato","id":495,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"El Litoral del San Juan","id":496,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Istmina","id":497,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Juradó","id":498,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Lloró","id":499,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Medio Atrato","id":500,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Medio Baudó","id":501,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Medio San Juan","id":502,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Nóvita","id":503,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Nuquí","id":504,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Quibdó","id":505,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Río Iro","id":506,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Río Quito","id":507,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Riosucio","id":508,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"San José del Palmar","id":509,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Sipí","id":510,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Tadó","id":511,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Unguía","id":512,"idTipo":3},
                        {"idUbicacionPadre":482,"descripcion":"Unión Panamericana","id":513,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Córdoba","id":514,"idTipo":2},
                        {"idUbicacionPadre":514,"descripcion":"Ayapel","id":515,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Buenavista","id":516,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Canalete","id":517,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Cereté","id":518,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Chimá","id":519,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Chinú","id":520,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Ciénaga de Oro","id":521,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Cotorra","id":522,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"La Apartada","id":523,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Lorica","id":524,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Los Córdobas","id":525,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Momil","id":526,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Montelíbano","id":527,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Montería","id":528,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Moñitos","id":529,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Planeta Rica","id":530,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Pueblo Nuevo","id":531,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Puerto Escondido","id":532,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Puerto Libertador","id":533,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Purísima","id":534,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Sahagún","id":535,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"San Andrés Sotavento","id":536,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"San Antero","id":537,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"San Bernardo del Viento","id":538,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"San Carlos","id":539,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"San José de Uré","id":540,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"San Pelayo","id":541,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Tierralta","id":542,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Tuchín","id":543,"idTipo":3},
                        {"idUbicacionPadre":514,"descripcion":"Valencia","id":544,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Cundinamarca","id":545,"idTipo":2},
                        {"idUbicacionPadre":545,"descripcion":"Agua de Dios","id":546,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Albán","id":547,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Anapoima","id":548,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Anolaima","id":549,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Apulo","id":550,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Arbeláez","id":551,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Beltrán","id":552,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Bituima","id":553,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Bojacá","id":554,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Cabrera","id":555,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Cachipay","id":556,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Cajicá","id":557,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Caparrapí","id":558,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Caqueza","id":559,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Carmen de Carupa","id":560,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Chaguaní","id":561,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Chía","id":562,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Chipaque","id":563,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Choachí","id":564,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Chocontá","id":565,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Cogua","id":566,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Cota","id":567,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Cucunubá","id":568,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"El Colegio","id":569,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"El Peñón","id":570,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"El Rosal","id":571,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Facatativá","id":572,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Fomeque","id":573,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Fosca","id":574,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Funza","id":575,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Fúquene","id":576,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Fusagasugá","id":577,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Gachala","id":578,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Gachancipá","id":579,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Gachetá","id":580,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Gama","id":581,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Girardot","id":582,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Granada","id":583,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Guachetá","id":584,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Guaduas","id":585,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Guasca","id":586,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Guataquí","id":587,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Guatavita","id":588,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Guayabal de Siquima","id":589,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Guayabetal","id":590,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Gutiérrez","id":591,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Jerusalén","id":592,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Junín","id":593,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"La Calera","id":594,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"La Mesa","id":595,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"La Palma","id":596,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"La Peña","id":597,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"La Vega","id":598,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Lenguazaque","id":599,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Macheta","id":600,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Madrid","id":601,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Manta","id":602,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Medina","id":603,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Mosquera","id":604,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Nariño","id":605,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Nemocón","id":606,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Nilo","id":607,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Nimaima","id":608,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Nocaima","id":609,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Pacho","id":610,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Paime","id":611,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Pandi","id":612,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Paratebueno","id":613,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Pasca","id":614,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Puerto Salgar","id":615,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Pulí","id":616,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Quebradanegra","id":617,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Quetame","id":618,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Quipile","id":619,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Ricaurte","id":620,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"San Antonio del Tequendama","id":621,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"San Bernardo","id":622,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"San Cayetano","id":623,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"San Francisco","id":624,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"San Juan de Río Seco","id":625,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Sasaima","id":626,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Sesquilé","id":627,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Sibaté","id":628,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Silvania","id":629,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Simijaca","id":630,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Soacha","id":631,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Sopó","id":632,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Subachoque","id":633,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Suesca","id":634,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Supatá","id":635,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Susa","id":636,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Sutatausa","id":637,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Tabio","id":638,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Tausa","id":639,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Tena","id":640,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Tenjo","id":641,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Tibacuy","id":642,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Tibirita","id":643,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Tocaima","id":644,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Tocancipá","id":645,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Topaipí","id":646,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Ubalá","id":647,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Ubaque","id":648,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Une","id":649,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Útica","id":650,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Venecia","id":651,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Vergara","id":652,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Vianí","id":653,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Villa de San Diego de Ubate","id":654,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Villagómez","id":655,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Villapinzón","id":656,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Villeta","id":657,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Viotá","id":658,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Yacopí","id":659,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Zipacón","id":660,"idTipo":3},
                        {"idUbicacionPadre":545,"descripcion":"Zipaquirá","id":661,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Guainía","id":662,"idTipo":2},
                        {"idUbicacionPadre":662,"descripcion":"Barranco Minas","id":663,"idTipo":3},
                        {"idUbicacionPadre":662,"descripcion":"Cacahual","id":664,"idTipo":3},
                        {"idUbicacionPadre":662,"descripcion":"Inírida","id":665,"idTipo":3},
                        {"idUbicacionPadre":662,"descripcion":"La Guadalupe","id":666,"idTipo":3},
                        {"idUbicacionPadre":662,"descripcion":"Mapiripana","id":667,"idTipo":3},
                        {"idUbicacionPadre":662,"descripcion":"Morichal","id":668,"idTipo":3},
                        {"idUbicacionPadre":662,"descripcion":"Pana Pana","id":669,"idTipo":3},
                        {"idUbicacionPadre":662,"descripcion":"Puerto Colombia","id":670,"idTipo":3},
                        {"idUbicacionPadre":662,"descripcion":"San Felipe","id":671,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Guaviare","id":672,"idTipo":2},
                        {"idUbicacionPadre":672,"descripcion":"Calamar","id":673,"idTipo":3},
                        {"idUbicacionPadre":672,"descripcion":"El Retorno","id":674,"idTipo":3},
                        {"idUbicacionPadre":672,"descripcion":"Miraflores","id":675,"idTipo":3},
                        {"idUbicacionPadre":672,"descripcion":"San José del Guaviare","id":676,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Huila","id":677,"idTipo":2},
                        {"idUbicacionPadre":677,"descripcion":"Acevedo","id":678,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Agrado","id":679,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Aipe","id":680,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Algeciras","id":681,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Altamira","id":682,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Baraya","id":683,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Campoalegre","id":684,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Colombia","id":685,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Elías","id":686,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Garzón","id":687,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Gigante","id":688,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Guadalupe","id":689,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Hobo","id":690,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Iquira","id":691,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Isnos","id":692,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"La Argentina","id":693,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"La Plata","id":694,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Nátaga","id":695,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Neiva","id":696,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Oporapa","id":697,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Paicol","id":698,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Palermo","id":699,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Palestina","id":700,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Pital","id":701,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Pitalito","id":702,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Rivera","id":703,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Saladoblanco","id":704,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"San Agustín","id":705,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Santa María","id":706,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Suaza","id":707,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Tarqui","id":708,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Tello","id":709,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Teruel","id":710,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Tesalia","id":711,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Timaná","id":712,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Villavieja","id":713,"idTipo":3},
                        {"idUbicacionPadre":677,"descripcion":"Yaguará","id":714,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"La Guajira","id":715,"idTipo":2},
                        {"idUbicacionPadre":715,"descripcion":"Albania","id":716,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Barrancas","id":717,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Dibula","id":718,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Distracción","id":719,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"El Molino","id":720,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Fonseca","id":721,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Hatonuevo","id":722,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"La Jagua del Pilar","id":723,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Maicao","id":724,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Manaure","id":725,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Riohacha","id":726,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"San Juan del Cesar","id":727,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Uribia","id":728,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Urumita","id":729,"idTipo":3},
                        {"idUbicacionPadre":715,"descripcion":"Villanueva","id":730,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Magdalena","id":731,"idTipo":2},
                        {"idUbicacionPadre":731,"descripcion":"Algarrobo","id":732,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Aracataca","id":733,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Ariguaní","id":734,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Cerro San Antonio","id":735,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Chivolo","id":736,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Ciénaga","id":737,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Concordia","id":738,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"El Banco","id":739,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"El Piñon","id":740,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"El Retén","id":741,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Fundación","id":742,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Guamal","id":743,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Nueva Granada","id":744,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Pedraza","id":745,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Pijiño del Carmen","id":746,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Pivijay","id":747,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Plato","id":748,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Pueblo Viejo","id":749,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Remolino","id":750,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Sabanas de San Angel","id":751,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Salamina","id":752,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"San Sebastián de Buenavista","id":753,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"San Zenón","id":754,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Santa Ana","id":755,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Santa Bárbara de Pinto","id":756,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Santa Marta","id":757,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Sitionuevo","id":758,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Tenerife","id":759,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Zapayán","id":760,"idTipo":3},
                        {"idUbicacionPadre":731,"descripcion":"Zona Bananera","id":761,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Meta","id":762,"idTipo":2},
                        {"idUbicacionPadre":762,"descripcion":"Acacias","id":763,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Barranca de Upía","id":764,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Cabuyaro","id":765,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Castilla la Nueva","id":766,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Cubarral","id":767,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Cumaral","id":768,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"El Calvario","id":769,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"El Castillo","id":770,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"El Dorado","id":771,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Fuente de Oro","id":772,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Granada","id":773,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Guamal","id":774,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"La Macarena","id":775,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Lejanías","id":776,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Mapiripán","id":777,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Mesetas","id":778,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Puerto Concordia","id":779,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Puerto Gaitán","id":780,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Puerto Lleras","id":781,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Puerto López","id":782,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Puerto Rico","id":783,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Restrepo","id":784,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"San Carlos de Guaroa","id":785,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"San Juan de Arama","id":786,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"San Juanito","id":787,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"San Martín","id":788,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Uribe","id":789,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Villavicencio","id":790,"idTipo":3},
                        {"idUbicacionPadre":762,"descripcion":"Vista Hermosa","id":791,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Nariño","id":792,"idTipo":2},
                        {"idUbicacionPadre":792,"descripcion":"Albán","id":793,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Aldana","id":794,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Ancuyá","id":795,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Arboleda","id":796,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Barbacoas","id":797,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Belén","id":798,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Buesaco","id":799,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Chachagüí","id":800,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Colón","id":801,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Consaca","id":802,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Contadero","id":803,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Córdoba","id":804,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Cuaspud","id":805,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Cumbal","id":806,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Cumbitara","id":807,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"El Charco","id":808,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"El Peñol","id":809,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"El Rosario","id":810,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"El Tablón de Gómez","id":811,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"El Tambo","id":812,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Francisco Pizarro","id":813,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Funes","id":814,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Guachucal","id":815,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Guaitarilla","id":816,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Gualmatán","id":817,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Iles","id":818,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Imués","id":819,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Ipiales","id":820,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"La Cruz","id":821,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"La Florida","id":822,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"La Llanada","id":823,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"La Tola","id":824,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"La Unión","id":825,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Leiva","id":826,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Linares","id":827,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Los Andes","id":828,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Magüí","id":829,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Mallama","id":830,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Mosquera","id":831,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Nariño","id":832,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Olaya Herrera","id":833,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Ospina","id":834,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Pasto","id":835,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Policarpa","id":836,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Potosí","id":837,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Providencia","id":838,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Puerres","id":839,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Pupiales","id":840,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Ricaurte","id":841,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Roberto Payán","id":842,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Samaniego","id":843,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"San Andrés de Tumaco","id":844,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"San Bernardo","id":845,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"San Lorenzo","id":846,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"San Pablo","id":847,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"San Pedro de Cartago","id":848,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Sandoná","id":849,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Santa Bárbara","id":850,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Santacruz","id":851,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Sapuyes","id":852,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Taminango","id":853,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Tangua","id":854,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Túquerres","id":855,"idTipo":3},
                        {"idUbicacionPadre":792,"descripcion":"Yacuanquer","id":856,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Norte de Santander","id":857,"idTipo":2},
                        {"idUbicacionPadre":857,"descripcion":"Abrego","id":858,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Arboledas","id":859,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Bochalema","id":860,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Bucarasica","id":861,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Cachirá","id":862,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Cácota","id":863,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Chinácota","id":864,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Chitagá","id":865,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Convención","id":866,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Cúcuta","id":867,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Cucutilla","id":868,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Durania","id":869,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"El Carmen","id":870,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"El Tarra","id":871,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"El Zulia","id":872,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Gramalote","id":873,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Hacarí","id":874,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Herrán","id":875,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"La Esperanza","id":876,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"La Playa","id":877,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Labateca","id":878,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Los Patios","id":879,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Lourdes","id":880,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Mutiscua","id":881,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Ocaña","id":882,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Pamplona","id":883,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Pamplonita","id":884,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Puerto Santander","id":885,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Ragonvalia","id":886,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Salazar","id":887,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"San Calixto","id":888,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"San Cayetano","id":889,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Santiago","id":890,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Sardinata","id":891,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Silos","id":892,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Teorama","id":893,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Tibú","id":894,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Toledo","id":895,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Villa Caro","id":896,"idTipo":3},
                        {"idUbicacionPadre":857,"descripcion":"Villa del Rosario","id":897,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Putumayo","id":898,"idTipo":2},
                        {"idUbicacionPadre":898,"descripcion":"Colón","id":899,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Leguízamo","id":900,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Mocoa","id":901,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Orito","id":902,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Puerto Asís","id":903,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Puerto Caicedo","id":904,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Puerto Guzmán","id":905,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"San Francisco","id":906,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"San Miguel","id":907,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Santiago","id":908,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Sibundoy","id":909,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Valle de Guamez","id":910,"idTipo":3},
                        {"idUbicacionPadre":898,"descripcion":"Villagarzón","id":911,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Quindío","id":912,"idTipo":2},
                        {"idUbicacionPadre":912,"descripcion":"Armenia","id":913,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Buenavista","id":914,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Calarcá","id":915,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Circasia","id":916,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Córdoba","id":917,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Filandia","id":918,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Génova","id":919,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"La Tebaida","id":920,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Montenegro","id":921,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Pijao","id":922,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Quimbaya","id":923,"idTipo":3},
                        {"idUbicacionPadre":912,"descripcion":"Salento","id":924,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Risaralda","id":925,"idTipo":2},
                        {"idUbicacionPadre":925,"descripcion":"Apía","id":926,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Balboa","id":927,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Belén de Umbría","id":928,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Dosquebradas","id":929,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Guática","id":930,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"La Celia","id":931,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"La Virginia","id":932,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Marsella","id":933,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Mistrató","id":934,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Pereira","id":935,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Pueblo Rico","id":936,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Quinchía","id":937,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Santa Rosa de Cabal","id":938,"idTipo":3},
                        {"idUbicacionPadre":925,"descripcion":"Santuario","id":939,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Santander","id":940,"idTipo":2},
                        {"idUbicacionPadre":940,"descripcion":"Aguada","id":941,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Albania","id":942,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Aratoca","id":943,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Barbosa","id":944,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Barichara","id":945,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Barrancabermeja","id":946,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Betulia","id":947,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Bolívar","id":948,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Bucaramanga","id":949,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Cabrera","id":950,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"California","id":951,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Capitanejo","id":952,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Carcasí","id":953,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Cepitá","id":954,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Cerrito","id":955,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Charalá","id":956,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Charta","id":957,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Chimá","id":958,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Chipatá","id":959,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Cimitarra","id":960,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Concepción","id":961,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Confines","id":962,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Contratación","id":963,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Coromoro","id":964,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Curití","id":965,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"El Carmen de Chucurí","id":966,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"El Guacamayo","id":967,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"El Peñón","id":968,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"El Playón","id":969,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Encino","id":970,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Enciso","id":971,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Florián","id":972,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Floridablanca","id":973,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Galán","id":974,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Gambita","id":975,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Girón","id":976,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Guaca","id":977,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Guadalupe","id":978,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Guapotá","id":979,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Guavatá","id":980,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Güepsa","id":981,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Hato","id":982,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Jesús María","id":983,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Jordán","id":984,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"La Belleza","id":985,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"La Paz","id":986,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Landázuri","id":987,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Lebríja","id":988,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Los Santos","id":989,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Macaravita","id":990,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Málaga","id":991,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Matanza","id":992,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Mogotes","id":993,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Molagavita","id":994,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Ocamonte","id":995,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Oiba","id":996,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Onzaga","id":997,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Palmar","id":998,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Palmas del Socorro","id":999,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Páramo","id":1000,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Piedecuesta","id":1001,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Pinchote","id":1002,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Puente Nacional","id":1003,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Puerto Parra","id":1004,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Puerto Wilches","id":1005,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Rionegro","id":1006,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Sabana de Torres","id":1007,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"San Andrés","id":1008,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"San Benito","id":1009,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"San Gil","id":1010,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"San Joaquín","id":1011,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"San José de Miranda","id":1012,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"San Miguel","id":1013,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"San Vicente de Chucurí","id":1014,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Santa Bárbara","id":1015,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Santa Helena del Opón","id":1016,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Simacota","id":1017,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Socorro","id":1018,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Suaita","id":1019,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Sucre","id":1020,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Suratá","id":1021,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Tona","id":1022,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Valle de San José","id":1023,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Vélez","id":1024,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Vetas","id":1025,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Villanueva","id":1026,"idTipo":3},
                        {"idUbicacionPadre":940,"descripcion":"Zapatoca","id":1027,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Sucre","id":1028,"idTipo":2},
                        {"idUbicacionPadre":1028,"descripcion":"Buenavista","id":1029,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Caimito","id":1030,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Chalán","id":1031,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Coloso","id":1032,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Corozal","id":1033,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Coveñas","id":1034,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"El Roble","id":1035,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Galeras","id":1036,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Guaranda","id":1037,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"La Unión","id":1038,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Los Palmitos","id":1039,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Majagual","id":1040,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Morroa","id":1041,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Ovejas","id":1042,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Palmito","id":1043,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Sampués","id":1044,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"San Benito Abad","id":1045,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"San Juan de Betulia","id":1046,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"San Luis de Sincé","id":1047,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"San Marcos","id":1048,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"San Onofre","id":1049,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"San Pedro","id":1050,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Santiago de Tolú","id":1051,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Sincelejo","id":1052,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Sucre","id":1053,"idTipo":3},
                        {"idUbicacionPadre":1028,"descripcion":"Tolú Viejo","id":1054,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Tolima","id":1055,"idTipo":2},
                        {"idUbicacionPadre":1055,"descripcion":"Alpujarra","id":1056,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Alvarado","id":1057,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Ambalema","id":1058,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Anzoátegui","id":1059,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Armero","id":1060,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Ataco","id":1061,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Cajamarca","id":1062,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Carmen de Apicala","id":1063,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Casabianca","id":1064,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Chaparral","id":1065,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Coello","id":1066,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Coyaima","id":1067,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Cunday","id":1068,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Dolores","id":1069,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Espinal","id":1070,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Falan","id":1071,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Flandes","id":1072,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Fresno","id":1073,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Guamo","id":1074,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Herveo","id":1075,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Honda","id":1076,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Ibagué","id":1077,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Icononzo","id":1078,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Lérida","id":1079,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Líbano","id":1080,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Mariquita","id":1081,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Melgar","id":1082,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Murillo","id":1083,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Natagaima","id":1084,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Ortega","id":1085,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Palocabildo","id":1086,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Piedras","id":1087,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Planadas","id":1088,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Prado","id":1089,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Purificación","id":1090,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Rio Blanco","id":1091,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Roncesvalles","id":1092,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Rovira","id":1093,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Saldaña","id":1094,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"San Antonio","id":1095,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"San Luis","id":1096,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Santa Isabel","id":1097,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Suárez","id":1098,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Valle de San Juan","id":1099,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Venadillo","id":1100,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Villahermosa","id":1101,"idTipo":3},
                        {"idUbicacionPadre":1055,"descripcion":"Villarrica","id":1102,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Valle del Cauca","id":1103,"idTipo":2},
                        {"idUbicacionPadre":1103,"descripcion":"Alcalá","id":1104,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Andalucía","id":1105,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Ansermanuevo","id":1106,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Argelia","id":1107,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Bolívar","id":1108,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Buenaventura","id":1109,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Bugalagrande","id":1110,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Caicedonia","id":1111,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Cali","id":1112,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Calima","id":1113,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Candelaria","id":1114,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Cartago","id":1115,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Dagua","id":1116,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"El Águila","id":1117,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"El Cairo","id":1118,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"El Cerrito","id":1119,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"El Dovio","id":1120,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Florida","id":1121,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Ginebra","id":1122,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Guacarí","id":1123,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Guadalajara de Buga","id":1124,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Jamundí","id":1125,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"La Cumbre","id":1126,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"La Unión","id":1127,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"La Victoria","id":1128,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Obando","id":1129,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Palmira","id":1130,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Pradera","id":1131,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Restrepo","id":1132,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Riofrío","id":1133,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Roldanillo","id":1134,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"San Pedro","id":1135,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Sevilla","id":1136,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Toro","id":1137,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Trujillo","id":1138,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Tuluá","id":1139,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Ulloa","id":1140,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Versalles","id":1141,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Vijes","id":1142,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Yotoco","id":1143,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Yumbo","id":1144,"idTipo":3},
                        {"idUbicacionPadre":1103,"descripcion":"Zarzal","id":1145,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Vaupés","id":1146,"idTipo":2},
                        {"idUbicacionPadre":1146,"descripcion":"Caruru","id":1147,"idTipo":3},
                        {"idUbicacionPadre":1146,"descripcion":"Mitú","id":1148,"idTipo":3},
                        {"idUbicacionPadre":1146,"descripcion":"Pacoa","id":1149,"idTipo":3},
                        {"idUbicacionPadre":1146,"descripcion":"Papunaua","id":1150,"idTipo":3},
                        {"idUbicacionPadre":1146,"descripcion":"Taraira","id":1151,"idTipo":3},
                        {"idUbicacionPadre":1146,"descripcion":"Yavaraté","id":1152,"idTipo":3},
                        {"idUbicacionPadre":1,"descripcion":"Vichada","id":1153,"idTipo":2},
                        {"idUbicacionPadre":1153,"descripcion":"Cumaribo","id":1154,"idTipo":3},
                        {"idUbicacionPadre":1153,"descripcion":"La Primavera","id":1155,"idTipo":3},
                        {"idUbicacionPadre":1153,"descripcion":"Puerto Carreño","id":1156,"idTipo":3},
                        {"idUbicacionPadre":1153,"descripcion":"Santa Rosalía","id":1157,"idTipo":3}
                      ]

                  doc.ref.set({
                      tipoUbicacionGeografica: listUbicacionesGeograficas
        
                  }, { merge: true }).then(
                      function() {
                          console.log("Document Update: ");

                      }.bind(this)
                  ).catch(
                      function(error) {
                          console.error("Error Updating document: ", error);

                      }.bind(this)
                  );
 
                                    
              } else {
                  console.log("No such document!");

              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });
    },

    updateTemporalPersonas() {
      let collection = this.getCollectionListFirestore('personas');
      // collection = collection.where("temporal", "==", false);
      collection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log("Document Update: " + doc.id);
            doc.ref.set({
                temporal: false
            }, { merge: true }).then(
                function() {
                    console.log("Document Update: " + doc.id);

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
            
          });
        })
        .catch(function (error) {
          console.log("Error updating document:", error);
        });
    },

    getEstadisticasPersonas() {
      let fechaDia = "28/04/2022";
      let collection = this.getCollectionListFirestore("personas_estadisticas", null, true);
      collection = collection.where("fechaDia", "==", fechaDia)/*.where("etiqueta", "==", etiqueta.descripcion)*/;

      collection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
          });
          this.$store.commit("detenerLoading");
          $('#popupFiltro').modal('hide');

      }).catch(
          function(error) {
              console.log("Error getting document:", error);
              this.$store.commit("detenerLoading");
          }.bind(this)
      );
    },

    getMensajes() {
      var fechaIni= this.$moment("01/05/2022 10:20", "DD/MM/YYYY HH:mm").toDate();
      var fechaFin= this.$moment("15/05/2022 10:25", "DD/MM/YYYY HH:mm").toDate();
      // let fechaIni = new Date("08/05/2022 08:00:00");
      // let fechaFin = new Date("10/05/2022 12:00:00");
      let collection = this.getCollectionListFirestore("mensajes");
      collection = collection.where("fecha", ">=", fechaIni).where("fecha", "<=", fechaFin);
      // collection = collection.where("visto", "==", true);

      collection.get().then((querySnapshot) => {
        // console.table(querySnapshot.docs);
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
          });

      }).catch(
          function(error) {
              console.log("Error getting document:", error);
              this.$store.commit("detenerLoading");
          }.bind(this)
      );
    },

    getAlertas() {
      let fechaDesde = new Date();
      let fechaHasta = new Date(new Date().getTime() + 18000000);

      // let collection = firestore.collection("Organizaciones").doc(idOrganizacion).collection("alertas");
      let collection = this.getCollectionListFirestore("alertas", null, true);
      // collection = collection.where('idUsuarioAsesor', '==', auth.currentUser.uid);
			collection = collection.where('activa', '==', false);
			collection = collection.where('vista', '==', false);
      collection = collection.where("fecha", ">=", fechaDesde).where("fecha", "<=", fechaHasta);

      collection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
            // doc.ref.update({
            //     activa: true
            // }, { merge: true }).catch(
            //     function(error) {
            //         console.log("Error Updating document alertas: ", error);

            //     }.bind(this)
            // );
          });

      }).catch(
          function(error) {
              console.log("Error getting document:", error);
              this.$store.commit("detenerLoading");
          }.bind(this)
      );
    },

    getMensajesAutomaticos() {
      let fechaDesde = new Date();
      let fechaHasta = new Date(new Date().getTime() + 18000000);
      let collection = firestore.collection("Organizaciones").doc('ajkCxFzKK6OKEwFrDspE').collection("mensajesAutomaticos");
      collection = collection.where("activa", "==", true);
      collection = collection.where("ejecutada", "==", false);
      // collection = collection.where("idPersonaAsociada", "==", 'blKgJEoZxtMpsgLVym8R');
      collection = collection.where("fecha", ">=", fechaDesde).where("fecha", "<=", fechaHasta);
      
      collection.get().then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {

          });

      }).catch(
          function(error) {
              console.log("Error getting document:", error);
              this.$store.commit("detenerLoading");
          }.bind(this)
      );
    },

    getHistorias() {
      let fechaDesde = new Date();
      let fechaHasta = new Date(new Date().getTime() + 18000000);

      // let collection = firestore.collection("Organizaciones").doc(idOrganizacion).collection("alertas");
      let collection = this.getCollectionListFirestore("historiaClinica", null, true);

      collection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
            // doc.ref.update({
            //     activa: true
            // }, { merge: true }).catch(
            //     function(error) {
            //         console.log("Error Updating document alertas: ", error);

            //     }.bind(this)
            // );
          });

      }).catch(
          function(error) {
              console.log("Error getting document:", error);
              this.$store.commit("detenerLoading");
          }.bind(this)
      );
    },

    subirArchivo() {
      // Create a root reference
      var storageRef = firebase.storage().ref();

      // Create a reference to 'mountains.jpg'
      var storageRef = storageRef.child(`archivos/${this.$store.getters.getIdOrganizacion}/historias/mountains5.jpg`);

      // Data URL string
      var message = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=';
      var uploadTask = storageRef.putString(message, 'data_url');

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
          });
        }
      );


    },

    updateTarjetasEmbudos() {

      let collection = this.getCollectionListFirestore("embudos_enc", null, true);

      collection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
            doc.ref.update({
                fechaModificacion: doc.data().fechaCreacion
            }, { merge: true }).catch(
                function(error) {
                    console.log("Error Updating document alertas: ", error);

                }.bind(this)
            );
          });

      }).catch(
          function(error) {
              console.log("Error getting document:", error);
              this.$store.commit("detenerLoading");
          }.bind(this)
      );
    },

    updateSearchTermsPersonas() {
      let collection = this.getCollectionListFirestore('personas');
      // collection = collection.where("numeroIdentificacion", "==", "15447881");

      collection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log("Document Update: " + doc.id);
            doc.ref.set({
                nombresSearchTermsArray: this.getSearchTermsArrayV2(doc.data().nombres),
                apellidosSearchTermsArray: this.getSearchTermsArrayV2(doc.data().apellidos)
            }, { merge: true }).then(
                function() {
                    console.log("Document Update: " + doc.id);

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
            
          });
        })
        .catch(function (error) {
          console.log("Error updating document:", error);
        });
    },

    createPersonasFind() {
      let collection = this.getCollectionListFirestore('personas');
      // collection = collection.where("numeroIdentificacion", "==", "15447881");

      collection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let persona = doc.data();
            persona["id"] = doc.id;
            persona["docRef"] = doc.ref;

            let personasCollection = this.getCollectionAddFirestore("personas_find");

            try {
              personasCollection.add({
                  id: persona.id,
                  labelBusqueda: 'Id: ' + persona.numeroIdentificacion + ' - ' + persona.nombres + ' ' + persona.apellidos,
                  fechaCreacion: persona.fechaCreacion ? persona.fechaCreacion : new Date(),
                  fechaCreacionOriginal: persona.fechaCreacionOriginal ? persona.fechaCreacionOriginal : new Date(),
                  temporal: persona.temporal,
                  idUsuarioAsesor: persona.idUsuarioAsesor,
                  idGrupoInformacion: persona.idGrupoInformacion
                
              }).then(
                  function(docRef) {
                      console.log("Document written with ID: ", docRef.id);
                      
                  }.bind(this)
              ).catch(
                  function(error) {
                      console.error("Error adding document personas find: ", error);

                  }.bind(this)
              );
            } catch (error) {
              console.log("Error adding document personas find:", error);
            }
              
          });
        })
        .catch(function (error) {
          console.log("Error adding document personas find:", error);
        });
    },

    createGenerosCanciones() {
      let generoCollection = this.getCollectionAddFirestore("canciones_genero");

      try {
        generoCollection.add({id:1, descripcion:'Balada/Pop'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:2, descripcion:'Tropipop'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:3, descripcion:'Plancha'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:4, descripcion:'Rock'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:5, descripcion:'Pop/Rock'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:6, descripcion:'Cristiana'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:7, descripcion:'Romantica'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:8, descripcion:'Otro'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:9, descripcion:'Bolero'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

        generoCollection.add({id:10, descripcion:'Ranchera'}).then(
            function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                
            }.bind(this)
        ).catch(
            function(error) {
                console.error("Error adding document genero find: ", error);

            }.bind(this)
        );

      } catch (error) {
        console.log("Error adding document genero find:", error);
      }

    },

    pruebaClases() {
      const p1 = new Punto(5, 5);
      const p2 = new Punto(10, 10);

      console.log (Punto.distancia(p1, p2));
    }
  },
};
</script>
