import { VueEditor } from "vue2-editor";
import VueHtml2pdf from "vue-html2pdf";
import {FIREBASE_SERVICES} from '@/axios/http-common';
import {getPathArchivo, subirArchivo} from "@/classes/gestionDocumental/gestionDocumental";
import ImageResize from 'image-resize';

export default {
    name: 'PlantillasComponent',
    props: {
        pPlantilla: {type: Object, required: false},
        pMostrarRetornar: {type: Boolean, required: false},
        pTituloBotonRetornar: {type: String, required: false},
        origenDatosVariables: {type: Object, required: false}
    },
    components: {
        VueEditor,
        VueHtml2pdf
    },
    data() {
        return {
            contentBase64: "",
            variableSeleccionada: '',
            tipoVariablesPlantillas: [
                {idTipoVariable:1, idGrupo:1, etiqueta:'{TIPO_DOCUMENTO_PERSONA}',  expresion:"this.$t(this.$store.state.tablasTipo.tipoDocumento.find(tipoDocumento => tipoDocumento.id = this.origenDatosVariables.idTipoDocumento).descripcion)"},
                {idTipoVariable:2, idGrupo:1, etiqueta:'{NUMERO_ID_PERSONA}',  expresion:"this.origenDatosVariables?.numeroIdentificacion"},
                {idTipoVariable:3, idGrupo:1, etiqueta:'{NOMBRES_PERSONA}',  expresion:"this.origenDatosVariables?.nombres"},
                {idTipoVariable:4, idGrupo:1, etiqueta:'{APELLIDOS_PERSONA}',  expresion:"this.origenDatosVariables?.apellidos"},
                {idTipoVariable:5, idGrupo:1, etiqueta:'{TELEFONO_MOVIL_PERSONA}',  expresion:"this.origenDatosVariables?.telefonoMovil"},
                {idTipoVariable:6, idGrupo:1, etiqueta:'{EMAIL_PERSONA}',  expresion:"this.origenDatosVariables?.email"},
                {idTipoVariable:7, idGrupo:1, etiqueta:'{DIRECCION_PERSONA}',  expresion:"this.origenDatosVariables?.direccion"}
            ]
        }
    },
    mounted() {
        // console.log(eval("this.origenDatosVariables"));
        this.inicializar();
    },

    beforeDestroy() {
    },

    watch: {
        'variableSeleccionada': function (val, oldVal) {
            if (val) {
                this.insertarEtiqueta(val);
            }
        }
    },

    methods: {
        inicializar() {

        },

        visualizarPdf() {
            this.$store.commit("ejecutarLoading");
            let htmlReemplazado = this.getHtmlReemplazado();
            FIREBASE_SERVICES.defaults.responseType = 'application/json';
            FIREBASE_SERVICES.post(
                '/plantillas-getPdf',
                {html: htmlReemplazado, rtaBase64: true}
            ).then(
                function (response) {
                    this.contentBase64 = response.data;
                    // console.log(response.data);
                    $('#popupvistaPreviaPlantilla').modal('show');
                    this.$store.commit("detenerLoading");
                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                    return true;
                }.bind(this)
            );
        },

        downloadPdf() {
            this.$store.commit("ejecutarLoading");
            let htmlReemplazado = this.getHtmlReemplazado();
            FIREBASE_SERVICES.defaults.responseType = 'blob';
            FIREBASE_SERVICES.post(
                '/plantillas-getPdf',
                {html: htmlReemplazado}
            ).then(
                function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Plantilla.pdf');
                    document.body.appendChild(link);
                    link.click();
                    this.$store.commit("detenerLoading");
                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                    return true;
                }.bind(this)
            );
        },

        retornarPdf() {
            this.$store.commit("ejecutarLoading");
            let htmlReemplazado = this.getHtmlReemplazado();
            FIREBASE_SERVICES.defaults.responseType = 'application/json';
            FIREBASE_SERVICES.post(
                '/plantillas-getPdf',
                {html: htmlReemplazado, rtaBase64: true}
            ).then(
                function (response) {
                    this.$emit("onRetornarPdf", response.data)
                    this.$store.commit("detenerLoading");
                }.bind(this)
            ).catch(
                function (error) {
                    this.$store.commit("detenerLoading");
                    console.log(error);
                    return true;
                }.bind(this)
            );
            
        },

        getHtmlReemplazado() {
            let html = this.pPlantilla.html;

            for (let variable of this.tipoVariablesPlantillas){
                let respuestaPregunta="";
                
                if (html.includes(variable.etiqueta)) {
                    try {
                        if (eval(variable.expresion)) {
                            html = html.replaceAll(variable.etiqueta, eval(variable.expresion));
        
                        } else {
                            respuestaPregunta = prompt(`Ingrese el valor para la variable ${variable.etiqueta}:`);
                            html = html.replaceAll(variable.etiqueta, respuestaPregunta);
                        }
                    } catch (error) {
                        respuestaPregunta = prompt(`Ingrese el valor para la variable ${variable.etiqueta}:`);
                        html = html.replaceAll(variable.etiqueta, respuestaPregunta);
                    }
                }

            }

            return html;
        },

        handleImageAdded(file, editor, cursorLocation, resetUploader) {
            this.$store.commit("ejecutarLoading");

            try {
                this.validarTamanioArchivo(file.size, resetUploader);
        
                // Crea una instancia de FileReader para leer el archivo
                const reader = new FileReader();
            
                reader.onload = (event) => {
                    const image = new Image();
                    image.src = event.target.result;
            
                    image.onload = async () => {
                        debugger
                        const resizedDataUrl = this.getImagenRedimensionada(image);
                        let pathArchivo = getPathArchivo(this.$store.state.constantes.tipoColeccion.PLANTILLAS);
                        let downloadURL = await subirArchivo(pathArchivo, resizedDataUrl);
                        editor.insertEmbed(editor.getSelection().index, 'image', downloadURL);
                        this.$store.commit("detenerLoading");
                    };
                };
            
                // Lee el archivo como una URL de datos
                reader.readAsDataURL(file);
            } catch (error) {
                this.$store.dispatch("addAlert", {
                    tipo: "success",
                    titulo: "",
                    descripcion: this.$t('message.general.errorInesperado')
                });
                this.$store.commit("detenerLoading");
            }

            
        },

        validarTamanioArchivo(size, resetUploader) {
            // Verifica el tamaño de la imagen
            const fileSizeLimit = 1024 * 1024; // Tamaño límite del archivo en bytes (1MB en este ejemplo)
                    
            if (size > fileSizeLimit) {
                // El archivo excede el límite de tamaño
                this.$store.dispatch("addAlert", {
                    tipo: "success",
                    titulo: "",
                    descripcion: this.$t('message.plantillasDet.limiteTamanio')
                });

                resetUploader(); // Restablece el cargador de archivos para cancelar la carga del archivo
                return;
            }
        },

        getImagenRedimensionada(image) {
            const maxWidth = 957; // Ancho máximo permitido para la imagen
            const maxHeight = 1222; // Alto máximo permitido para la imagen

            let newWidth = image.width;
            let newHeight = image.height;

            // Redimensiona la imagen si supera los límites de tamaño
            if (newWidth > maxWidth || newHeight > maxHeight) {
                const ratio = newWidth / newHeight;

                if (newWidth > maxWidth) {
                    newWidth = maxWidth;
                    newHeight = newWidth / ratio;
                }

                if (newHeight > maxHeight) {
                    newHeight = maxHeight;
                    newWidth = newHeight * ratio;
                }
            }

            // Crea un lienzo (canvas)
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Dibuja la imagen redimensionada en el lienzo
            ctx.drawImage(image, 0, 0, newWidth, newHeight);

            return canvas.toDataURL();
        },

        insertarEtiqueta(pNombreEtiqueta) {
            this.focusEditor();
            this.pasteHtmlAtCaret(pNombreEtiqueta);
        },

        focusEditor() {
            this.$refs.editor1.quill.focus();
        },

        pasteHtmlAtCaret(pNombreEtiqueta) {
            var sel, range;
            if (window.getSelection) {
                // IE9 and non-IE
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0);
                    range.deleteContents();

                    // Range.createContextualFragment() would be useful here but is
                    // non-standard and not supported in all browsers (IE9, for one)

                    var el = document.createElement("div");
                    el.innerHTML = pNombreEtiqueta;

                    var frag = document.createDocumentFragment(), node, lastNode;

                    while ((node = el.firstChild)) {
                        lastNode = frag.appendChild(node);
                    }
                    range.insertNode(frag);

                    // Preserve the selection
                    if (lastNode) {
                        range = range.cloneRange();
                        range.setStartAfter(lastNode);
                        range.collapse(true);
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                }
            } else if (document.selection && document.selection.type != "Control") {
                // IE < 9
                document.selection.createRange().pasteHTML(pNombreEtiqueta);
            }
        },

        cerrarModalVistaPrevia() {
            $('#popupvistaPreviaPlantilla').modal('hide');
        },

    }
};