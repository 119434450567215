import plantillasComp from "@/components/plantillas/PlantillasComponent.vue";

export default {
    name: 'SelectorPlantillasComponent',
    props: {
        listPlantillas: {required: false, default() {return []}},
        origenDatosVariables: {type: Object, required: false}
    },
    components: {
        plantillasComp
    },
    data() {
        return {
            plantilla : {
                nombre: '',
                html: ''
                
            }
        }
    },
    mounted() {
        this.inicializar();
    },

    beforeDestroy() {
    },

    watch: {
    },

    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoading");
            this.setListeners();
            this.verificarParametroPlantillas();
        },

        setListeners() {
            $('#popupPlantilla').on('hidden.bs.modal', function (e) {
                this.storeHtmlUltimaPlantilla();
            }.bind(this))

        },

        verificarParametroPlantillas() {
            if (this.listPlantillas.length == 0) {
                this.loadPlantillas();
            } else {
                this.$store.commit("detenerLoading");
            }
        },
    
        loadPlantillas() {
            
            this.getCollectionListFirestore("plantillas", null, true).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listPlantillas.push({ id: doc.id, nombre: doc.data().nombre, html: doc.data().html });

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document plantillas:", error);
                    }.bind(this)
                );

        },

        cerrarModalPlantilla() {
            $('#popupPlantilla').modal('hide');
        },

        onRetornarPdf(pdfBase64) {
            this.$emit("onRetornarPdf", pdfBase64)
        },
    
        storeHtmlUltimaPlantilla() {
            this.$store.commit("setUltimaPlantilla", this.plantilla.html);
        },

        getUltimaPlantilla() {
            this.plantilla.html = this.$store.getters.getUltimaPlantilla;
        }

    }
};