import accionesComp from "@/components/AccionesComponent";

export default {
    components: {
        accionesComp,
    },
    data() {
        return {
            listProductos : [],
            filtros: {
                idOrden: 1,
                activo : true,
                
            }

        }
    },

    watch: {
        
    },

    computed: {
        
    },

    created() {
        this.inicializar();

    },
    methods: {
        inicializar() {
            this.consultar();
        },

        limpiarFiltros() {
            this.filtros= {}
            this.consultar();
        },

        async consultar() {
            this.$store.commit("ejecutarLoading");
            this.destroyDataTable('#dataTable');
            this.listProductos = [];

            let collection = this.getCollectionListFirestore("productos", null, true);
            collection = this.getCollectionFiltered(collection);
            let querySnapshot = await collection.get().catch(
                function(error) {
                    console.log("Error getting document productos:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );

            if (querySnapshot && !querySnapshot.empty) {
                for (let doc of querySnapshot.docs){
                    var producto = doc.data();
                    producto["id"] = doc.id;
                    producto["docRef"] = doc.ref;
                    this.listProductos.push(producto)
                }
            }

            this.setDataTable();
            this.$store.commit("detenerLoading");
            $('#popupFiltro').modal('hide');

           
        },

        getCollectionFiltered(pCollection){
            var collection = pCollection;

            if (this.filtros.nombre) {
                collection = collection.where("nombreSearchTermsArray", "array-contains", this.filtros.nombre.toLowerCase());
            }

            if (this.filtros.codigoSKU) {
                collection = collection.where("codigoInterno", "==", this.filtros.codigoSKU);
            }
            if (this.filtros.precioProducto) {
                collection = collection.where("precio", "==", parseInt(this.filtros.precioProducto, 10));
            }

            return collection;
        },

        setDataTable() {
            this.$nextTick(() => {
                this.setResponsiveTable();
                this.applyDataTable('#dataTable', 3);
            });
        },

        eliminar(producto){
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: {no: this.$t('message.general.no'), yes: this.$t('message.general.si')},
                callback: confirm => {
                    if (confirm) {
                        this.eliminarProducto(producto);
                    }
                }
            });
                
        },

        eliminarProducto(producto){
            this.$store.commit("ejecutarLoading");
                
            if (producto) {
                producto.docRef.delete().then(() => {
                    this.consultar();
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        }

    }

}