import accionesComp from "@/components/AccionesComponent";

export default {
    props: {
        pPerfil: {type: Object, required: false}
    },
    components: {
        accionesComp,
    },
    data() {
        return {
            perfil: {
                nombre: '',
                recursos: [],
                idGrupoInformacion : this.$store.getters.getIdGrupoInfomacion
            },
            listModulos: [],
            listModulosRecursos: [],
            listGruposInformacion : [],
            idModuloSelect: '',
            listRecursosObligatorios: ['gruposDeInformacion', 'perfiles', 'usuarios', 'modulosRecursos', 'tablasTipo', 'organizacion', 'menuPrincipal'],
            grupoInformacionModificado: false
            
        }
    },

    created() {
        this.inicializar();

    },

    watch: {
        'perfil.idGrupoInformacion': function (val, oldVal) {
            this.grupoInformacionModificado = true;
        },
        '$store.state.loading': function (val, oldVal) {
            if(val == false) {
                this.$refs.form.validate();

            }
        }
    },
    
    methods: {
        async inicializar() {
            this.$store.commit("ejecutarLoading");
            await this.loadPerfil();
            await this.loadModulosRecursos();
            this.loadGruposInformacion();
        },

        loadPerfil() {
            return new Promise((resolve, reject) => {
                if (this.pPerfil) {
                    this.perfil = this.pPerfil;
                    this.perfil["recursos"] = [];

                    let collection = this.perfil.docRef.collection("recursos");

                    if (this.$store.getters.getIdGrupoInfomacion) {
                        collection = collection.where('idGrupoInformacion', '==', this.$store.getters.getIdGrupoInfomacion);
                    }

                    collection.get()
                    .then((querySnapshotRecur) => {
                        querySnapshotRecur.forEach((recurso) => {
                            this.perfil.recursos.push(
                                {
                                    id:recurso.id, 
                                    idGrupoInformacion:recurso.data().idGrupoInformacion,
                                    readInner:recurso.data().readInner?recurso.data().readInner:false,
                                    read:recurso.data().read?recurso.data().read:false,
                                    delete:recurso.data().delete?recurso.data().delete:false,
                                    update:recurso.data().update?recurso.data().update:false,
                                    write:recurso.data().write?recurso.data().write:false,
                                    docRef: recurso.ref
                                }
                            );
        
                        });
                        resolve(null);

                    }).catch(
                        function(error) {
                            console.log("Error getting document:", error);
                            resolve(null);

                        }.bind(this)
                    );
                } else {
                    resolve(null);
                }
            });
        },

        loadModulosRecursos() {
            return new Promise((resolve, reject) => {
                this.listModulos.push({id:'', nombre:'message.general.select_todos'});

                this.getCollectionListFirestore('modulosRecursos', null, true).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listModulos.push({id:doc.id, nombre:doc.data().nombre});

                        doc.ref.collection("recursosMod").get()
                        .then((querySnapshotRecur) => {
                            querySnapshotRecur.forEach((recurso) => {
                                let recursoUsuario = this.getRecursoUsuario(recurso.data().clave);
                                let recursoModulo = {};
                                if(recurso.data().clave == 'menuPrincipal'){
                                    recursoModulo = {
                                        id: recurso.id, 
                                        idModulo: doc.id, 
                                        nombre: recurso.data().nombre, 
                                        clave: recurso.data().clave,
                                        interno: recurso.data().interno,
                                        idGrupoInformacion: this.perfil.idGrupoInformacion,
                                        readInner: false,
                                        read: true,
                                        delete: false,
                                        update: false,
                                        write: false,
                                        modificado: false,
                                        enableRead: false,
                                        enableDelete: false,
                                        enableUpdate: false,
                                        enableWrite: false,
                                        docRef: null
                                    };
                                } else {
                                    recursoModulo = {
                                        id: recurso.id, 
                                        idModulo: doc.id, 
                                        nombre: recurso.data().nombre, 
                                        clave: recurso.data().clave,
                                        interno: recurso.data().interno,
                                        idGrupoInformacion: this.perfil.idGrupoInformacion,
                                        readInner: false,
                                        read: false,
                                        delete: false,
                                        update: false,
                                        write: false,
                                        modificado: true,
                                        enableRead: true,
                                        enableDelete: true,
                                        enableUpdate: true,
                                        enableWrite: true,
                                        docRef: null
                                    };
                                }
                                

                                if (recursoUsuario) {
                                    recursoModulo.idGrupoInformacion= recursoUsuario.idGrupoInformacion;
                                    recursoModulo.readInner= recursoUsuario.readInner;
                                    recursoModulo.read= recursoUsuario.read;
                                    recursoModulo.delete= recursoUsuario.delete;
                                    recursoModulo.update= recursoUsuario.update;
                                    recursoModulo.write= recursoUsuario.write;
                                    recursoModulo.docRef= recursoUsuario.docRef;

                                }

                                this.listModulosRecursos.push(recursoModulo);
            
                            });
                            this.setResponsiveTable();
                            this.$store.commit("detenerLoading");
                            resolve(null);

                        }).catch(
                            function(error) {
                                this.$store.commit("detenerLoading");
                                console.log("Error getting document:", error);
                                resolve(null);
                            }.bind(this)
                        );
                    });

                }).catch(
                    function(error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document:", error);
                        resolve(null);

                    }.bind(this)
                );
            });
        },

        loadGruposInformacion() {
            this.listGruposInformacion.push({id:this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL, nombre: this.$t('message.general.grupoDeInformacionGlobal')});
            
            if (!this.$store.getters.getIdGrupoInfomacion) {
                this.listGruposInformacion.push({id:'', nombre: this.$t('message.general.sinGrupo')});
                
            }

            this.getCollectionListFirestore('gruposDeInformacion').get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {  
                    this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                });

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                }.bind(this)
            );

            
        },

        getRecursoUsuario(pClave){
            return this.perfil.recursos.find(
                recurso => recurso.id == pClave

            );
        },

        setRecursoModificado(pRecurso){
            pRecurso.modificado= true;

        },

        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.perfil.docRef) {
                        this.actualizarPerfil();
    
                    } else {
                        this.insertarPerfil();
    
                    }
                }
            });
        },

        
       async insertarPerfil() {
            let perfilesCollection = this.getCollectionListFirestore('perfiles');
            perfilesCollection=perfilesCollection.where("nombre", "==", this.perfil.nombre);
            let perfilesDocs = await perfilesCollection.get();
            
            

            if(perfilesDocs.docs[0] && perfilesDocs.docs[0].data().nombre){
                this.$store.dispatch("addAlert", {
                    tipo: "warning",
                    titulo: this.$t('message.general.guardarFallido'),
                    descripcion: this.$t('message.general.errorExisteRegistro')
                });
                this.$store.commit("detenerLoading");
                return;
            }


            perfilesCollection = this.getCollectionAddFirestore("perfiles");

            perfilesCollection.add({
                nombre: this.perfil.nombre,
                idGrupoInformacion: this.perfil.idGrupoInformacion,
                searchTermsArray: this.getSearchTermsArray(this.perfil.nombre)

            }).then(
                function(docRef) {
                    // console.log("Document written with ID: ", docRef.id);
                    this.perfil.docRef= docRef;
                    this.setRecursos(docRef);
                    
                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        },

        actualizarPerfil() {
            this.perfil.docRef.set({
                nombre: this.perfil.nombre,
                idGrupoInformacion: this.perfil.idGrupoInformacion,
                searchTermsArray: this.getSearchTermsArray(this.perfil.nombre)
    
            }).then(
                function() {
                    this.setRecursos(this.perfil.docRef);

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );
        },

        setRecursos(docRef) {
            this.listModulosRecursos.forEach(recurso => {
                let isRecursoObligatorio = this.listRecursosObligatorios.includes(recurso.clave);

                if (recurso.modificado || isRecursoObligatorio || this.grupoInformacionModificado) {
                    let recursoTmp = {};

                    recursoTmp.idGrupoInformacion= this.perfil.idGrupoInformacion;
                    recursoTmp.readInner= isRecursoObligatorio;
                    recursoTmp.read= recurso.read;
                    recursoTmp.delete= recurso.delete;
                    recursoTmp.update= recurso.update;
                    recursoTmp.write= recurso.write;

                    this.setRecurso(docRef, recurso.clave, recursoTmp)
                }
            });

            this.$store.commit("detenerLoading");
            this.grupoInformacionModificado = false;
            this.$store.dispatch("addAlert", {
                tipo: "success",
                titulo: "",
                descripcion: this.$t('message.general.guardarExitoso')
            });
        },

        setRecurso(docRef, claveRecurso, recurso) {
            docRef.collection("recursos").doc(claveRecurso).set(
                recurso
            ).then(
                function(docRefDos) {
                    // console.log("Document", docRefDos);

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);

                }.bind(this)
            );
        }

    }

}