import accionesComp from "@/components/AccionesComponent";

export default {
    components: {
        accionesComp,
    },
    data() {
        return {
            listUsuarios : [],
            listGruposInformacion : [],
            listPerfiles : [],
            filtros: {
                nombres: '',
                apellidos: '',
                idPerfil:'',
                idGrupoInformacion : this.$store.getters.getIdGrupoInfomacion,
                idIsAdmin: 0,
                usuario: '',
                idOrden: 1,
                activo : true

            },
            // options: ["foo", "bar", "baz"]

        }
    },
    created() {
        this.inicializar();

    },
    methods: {
        inicializar() {
            this.consultar();
        },

        consultar() {
            // this.$emit('pruebita');

            this.listGruposInformacion = [];
            this.$store.commit("ejecutarLoading");
            
            let collection = this.getCollectionListFirestore('gruposDeInformacion');

            collection = this.getCollectionFiltered(collection);

            collection.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                    var grupoDeInformacion = doc.data();
                    grupoDeInformacion["id"] = doc.id;
                    grupoDeInformacion["docRef"] = doc.ref;

                    this.listGruposInformacion.push(grupoDeInformacion)
                });
                this.setResponsiveTable();
                this.$store.commit("detenerLoading");
                $('#popupFiltro').modal('hide');

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );
        },

        getCollectionFiltered(pCollection){
            var collection = pCollection;

            if (this.filtros.nombre) {
                collection = collection.where("nombre", "==", this.filtros.nombre);
            }

            return collection;
        },

        eliminar(grupoDeInformacion){
            this.$confirm({
                message: this.$t('message.general.eliminarConfirm'),
                button: {no: this.$t('message.general.no'), yes: this.$t('message.general.si')},
                callback: confirm => {
                    if (confirm) {
                        this.eliminarGrupoDeInformacion(grupoDeInformacion);
                    }
                }
            });
                
        },

        eliminarGrupoDeInformacion(grupoDeInformacion){
            this.$store.commit("ejecutarLoading");
                
            if (grupoDeInformacion) {
                grupoDeInformacion.docRef.delete().then(() => {
                    this.consultar();
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.eliminarExitoso')
                    });
                    this.$store.commit("detenerLoading");

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                    this.$store.commit("detenerLoading");
                });
            } else {
                console.log("No such document!");
                this.$store.commit("detenerLoading");

            }
        }

    }

}