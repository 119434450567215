import { render, staticRenderFns } from "./HistoriaClinica_det.vue?vue&type=template&id=4bb07794&"
import script from "./HistoriaClinica_det.js?vue&type=script&lang=js&"
export * from "./HistoriaClinica_det.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports