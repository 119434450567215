export default {
    install(Vue, options) {
        // 1. Añadir un método global o una propiedad
        Vue.miMetodoGlobal = function () {
            // mi lógica ...
        };

        // 2. Añadir un asset global (directiva, filtro, ...)
        Vue.directive('mi-directiva', {
            bind(el, binding, vnode, oldVnode) {
                // mi lógica ...
            }
        });

        // 3. Inyectar más opciones a un componente
        Vue.mixin({
            created: function () {
                // mi lógica ...
            }
        });

        // 4. Añadir un método de instancia
        Vue.prototype.$miMetodo = function (opciones) {
            // mi lógica
        }

        Vue.prototype.$log = {
            info: (text) => console.info(text),
            error: (text) => console.error(text),
            debug: (text) => console.log(text),
            warn: (text) => console.warn(text)
        }
    }
}