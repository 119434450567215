<template>
    <div>
        <div class="d-sm-flex justify-content-between align-items-center mb-4">
            <h3 class="text-dark mb-0">
                {{ $t("message.general.dashboard") }}
            </h3>
            <a
                class="btn btn-primary btn-sm d-none d-sm-inline-block"
                role="button"
                href="#"
            >
                <i class="fas fa-download fa-sm text-white-50"></i
                >&nbsp;Generate Report
            </a>
        </div>

		<accionesComp>
			<button @click="consultar()" class="btn btn-primary toolBoxBtn" type="button" v-if="$canRead()">Consultar</button>
            <button class="btn btn-secondary toolBoxBtn" type="button" v-if="$canUpdate()">Actualizar</button>
            <button @click="agregarDocumento()" class="btn btn-info toolBoxBtn" type="button" v-if="$canWrite()">Insertar</button>
            <button @click="eliminar()" class="btn btn-danger toolBoxBtn" type="button" v-if="$canDelete()">Eliminar</button>
			<button class="btn btn-dark toolBoxBtn" type="button" @click="addError()">AddAlert</button>
			<button class="btn btn-light toolBoxBtn" type="button">Volver</button>
        </accionesComp>

        <div class="row">
            <div class="col-md-6 col-xl-3 mb-4">
                <div class="card shadow border-left-primary py-2">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col mr-2">
                                <div
                                    class="text-uppercase text-primary font-weight-bold text-xs mb-1"
                                >
                                    <span>Earnings (monthly)</span>
                                </div>
                                <div class="text-dark font-weight-bold h5 mb-0">
                                    <span>$40,000</span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i
                                    class="fas fa-calendar fa-2x text-gray-300"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

			<div class="col-md-6 col-xl-3 mb-4">
                <div class="card shadow border-left-primary py-2">
                    <div class="card-body">
						
                        <button type="button" class="btn btn-primary btn-sm accionesBtn" data-title="Ver" >
							<i class="fas fa-info-circle"></i>
						</button>
						<button type="button" class="btn btn-secondary btn-sm accionesBtn" data-title="Editar">
							<i class="fas fa-edit"></i>
						</button>
						<button type="button" class="btn btn-danger btn-sm accionesBtn" data-title="Eliminar">
							<i class="fas fa-trash-alt"></i>
						</button>
						<button type="button" class="btn btn-info btn-sm accionesBtn" data-title="Nuevo">
							<i class="fas fa-plus-circle"></i>
						</button>
					
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3 mb-4">
                <div class="card shadow border-left-success py-2">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col mr-2">
                                <div
                                    class="text-uppercase text-success font-weight-bold text-xs mb-1"
                                >
                                    <span>Earnings (annual)</span>
                                </div>
                                <div class="text-dark font-weight-bold h5 mb-0">
                                    <span>$215,000</span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i
                                    class="fas fa-dollar-sign fa-2x text-gray-300"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-3 mb-4">
                <div class="card shadow border-left-info py-2">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col mr-2">
                                <div
                                    class="text-uppercase text-info font-weight-bold text-xs mb-1"
                                >
                                    <span>Tasks</span>
                                </div>
                                <div class="row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <div
                                            class="text-dark font-weight-bold h5 mb-0 mr-3"
                                        >
                                            <span>50%</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="progress progress-sm">
                                            <div
                                                class="progress-bar bg-info"
                                                aria-valuenow="50"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style="width: 50%"
                                            >
                                                <span class="sr-only">50%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i
                                    class="fas fa-clipboard-list fa-2x text-gray-300"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-3 mb-4">
                <div class="card shadow border-left-warning py-2">
                    <div class="card-body">
                        <div class="row align-items-center no-gutters">
                            <div class="col mr-2">
                                <div
                                    class="text-uppercase text-warning font-weight-bold text-xs mb-1"
                                >
                                    <span>Pending Requests</span>
                                </div>
                                <div class="text-dark font-weight-bold h5 mb-0">
                                    <span>18</span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i
                                    class="fas fa-comments fa-2x text-gray-300"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <acciones-component>
			<template slot="consultar">
			
			<span></span>
			</template>
			<template slot="evento">
			<button class="btn btn-primary" @click="addError()" type="button">
				Button Uno
			</button>
			&nbsp;
			<button class="btn btn-secondary" type="button">Button Dos</button>
			</template>
	
    	</acciones-component> -->


        <div class="row">
            <div class="col-lg-7 col-xl-8">
                <div class="card shadow mb-4">
                    <div
                        class="card-header d-flex justify-content-between align-items-center"
                    >
                        <h6 class="text-primary font-weight-bold m-0">
                            Earnings Overview
                        </h6>
                        <div class="dropdown no-arrow">
                            <button
                                class="btn btn-link btn-sm dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                type="button"
                            >
                                <i class="fas fa-ellipsis-v text-gray-400"></i>
                            </button>
                            <div
                                class="dropdown-menu shadow dropdown-menu-right animated--fade-in"
                            >
                                <p class="text-center dropdown-header">
                                    dropdown header:
                                </p>
                                <a class="dropdown-item" href="#"
                                    >&nbsp;Action</a
                                ><a class="dropdown-item" href="#"
                                    >&nbsp;Another action</a
                                >
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#"
                                    >&nbsp;Something else here</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="chart-area">
                            <canvas
                                data-bs-chart='{"type":"line","data":{"labels":["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug"],"datasets":[{"label":"Earnings","fill":true,"data":["0","10000","5000","15000","10000","20000","15000","25000"],"backgroundColor":"rgba(78, 115, 223, 0.05)","borderColor":"rgba(78, 115, 223, 1)"}]},"options":{"maintainAspectRatio":false,"legend":{"display":false},"title":{},"scales":{"xAxes":[{"gridLines":{"color":"rgb(234, 236, 244)","zeroLineColor":"rgb(234, 236, 244)","drawBorder":false,"drawTicks":false,"borderDash":["2"],"zeroLineBorderDash":["2"],"drawOnChartArea":false},"ticks":{"fontColor":"#858796","padding":20}}],"yAxes":[{"gridLines":{"color":"rgb(234, 236, 244)","zeroLineColor":"rgb(234, 236, 244)","drawBorder":false,"drawTicks":false,"borderDash":["2"],"zeroLineBorderDash":["2"]},"ticks":{"fontColor":"#858796","padding":20}}]}}}'
                            ></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-xl-4">
                <div class="card shadow mb-4">
                    <div
                        class="card-header d-flex justify-content-between align-items-center"
                    >
                        <h6 class="text-primary font-weight-bold m-0">
                            Revenue Sources
                        </h6>
                        <div class="dropdown no-arrow">
                            <button
                                class="btn btn-link btn-sm dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                type="button"
                            >
                                <i class="fas fa-ellipsis-v text-gray-400"></i>
                            </button>
                            <div
                                class="dropdown-menu shadow dropdown-menu-right animated--fade-in"
                            >
                                <p class="text-center dropdown-header">
                                    dropdown header:
                                </p>
                                <a class="dropdown-item" href="#"
                                    >&nbsp;Action</a
                                ><a class="dropdown-item" href="#"
                                    >&nbsp;Another action</a
                                >
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#"
                                    >&nbsp;Something else here</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="chart-area">
                            <canvas
                                data-bs-chart='{"type":"doughnut","data":{"labels":["Direct","Social","Referral"],"datasets":[{"label":"","backgroundColor":["#4e73df","#1cc88a","#36b9cc"],"borderColor":["#ffffff","#ffffff","#ffffff"],"data":["50","30","15"]}]},"options":{"maintainAspectRatio":false,"legend":{"display":false},"title":{}}}'
                            ></canvas>
                        </div>
                        <div class="text-center small mt-4">
                            <span class="mr-2"
                                ><i class="fas fa-circle text-primary"></i
                                >&nbsp;Direct</span
                            ><span class="mr-2"
                                ><i class="fas fa-circle text-success"></i
                                >&nbsp;Social</span
                            ><span class="mr-2"
                                ><i class="fas fa-circle text-info"></i
                                >&nbsp;Refferal</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 mb-4">
                <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="text-primary font-weight-bold m-0">
                            Projects
                        </h6>
                    </div>
                    <div class="card-body">
                        <h4 class="small font-weight-bold">
                            Server migration<span class="float-right">20%</span>
                        </h4>
                        <div class="progress mb-4">
                            <div
                                class="progress-bar bg-danger"
                                aria-valuenow="20"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style="width: 20%"
                            >
                                <span class="sr-only">20%</span>
                            </div>
                        </div>
                        <h4 class="small font-weight-bold">
                            Sales tracking<span class="float-right">40%</span>
                        </h4>
                        <div class="progress mb-4">
                            <div
                                class="progress-bar bg-warning"
                                aria-valuenow="40"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style="width: 40%"
                            >
                                <span class="sr-only">40%</span>
                            </div>
                        </div>
                        <h4 class="small font-weight-bold">
                            Customer Database<span class="float-right"
                                >60%</span
                            >
                        </h4>
                        <div class="progress mb-4">
                            <div
                                class="progress-bar bg-primary"
                                aria-valuenow="60"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style="width: 60%"
                            >
                                <span class="sr-only">60%</span>
                            </div>
                        </div>
                        <h4 class="small font-weight-bold">
                            Payout Details<span class="float-right">80%</span>
                        </h4>
                        <div class="progress mb-4">
                            <div
                                class="progress-bar bg-info"
                                aria-valuenow="80"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style="width: 80%"
                            >
                                <span class="sr-only">80%</span>
                            </div>
                        </div>
                        <h4 class="small font-weight-bold">
                            Account setup<span class="float-right"
                                >Complete!</span
                            >
                        </h4>
                        <div class="progress mb-4">
                            <div
                                class="progress-bar bg-success"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style="width: 100%"
                            >
                                <span class="sr-only">100%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="text-primary font-weight-bold m-0">
                            Todo List
                        </h6>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-2">
                                    <h6 class="mb-0">
                                        <strong>Lunch meeting</strong>
                                    </h6>
                                    <span class="text-xs">10:30 AM</span>
                                </div>
                                <div class="col-auto">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            class="custom-control-input"
                                            type="checkbox"
                                            id="formCheck-1"
                                        /><label
                                            class="custom-control-label"
                                            for="formCheck-1"
                                        ></label>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-2">
                                    <h6 class="mb-0">
                                        <strong>Lunch meeting</strong>
                                    </h6>
                                    <span class="text-xs">11:30 AM</span>
                                </div>
                                <div class="col-auto">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            class="custom-control-input"
                                            type="checkbox"
                                            id="formCheck-2"
                                        /><label
                                            class="custom-control-label"
                                            for="formCheck-2"
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-2">
                                    <h6 class="mb-0">
                                        <strong>Lunch meeting</strong>
                                    </h6>
                                    <span class="text-xs">12:30 AM</span>
                                </div>
                                <div class="col-auto">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            class="custom-control-input"
                                            type="checkbox"
                                            id="formCheck-3"
                                        /><label
                                            class="custom-control-label"
                                            for="formCheck-3"
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-lg-6 mb-4">
                        <div class="card text-white bg-primary shadow">
                            <div class="card-body">
                                <p class="m-0">Primary</p>
                                <p class="text-white-50 small m-0">#4e73df</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <div class="card text-white bg-success shadow">
                            <div class="card-body">
                                <p class="m-0">Success</p>
                                <p class="text-white-50 small m-0">#1cc88a</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <div class="card text-white bg-info shadow">
                            <div class="card-body">
                                <p class="m-0">Info</p>
                                <p class="text-white-50 small m-0">#36b9cc</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <div class="card text-white bg-warning shadow">
                            <div class="card-body">
                                <p class="m-0">Warning</p>
                                <p class="text-white-50 small m-0">#f6c23e</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <div class="card text-white bg-danger shadow">
                            <div class="card-body">
                                <p class="m-0">Danger</p>
                                <p class="text-white-50 small m-0">#e74a3b</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <div class="card text-white bg-secondary shadow">
                            <div class="card-body">
                                <p class="m-0">Secondary</p>
                                <p class="text-white-50 small m-0">#858796</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import accionesComp from "../components/AccionesComponent";

export default {
    data() {
        return {
            contador: 3,
        };
    },
    components: {
        accionesComp,
    },
    methods: {
        addError() {
            this.$log.info("esto es una maravilla");

            this.$store.dispatch("addAlert", {
                tipo: "danger",
                titulo: "Alerta Danger",
                descripcion: "Este es un mensaje de peligro",
            });
        },
    },
};
</script>