import accionesComp from "@/components/AccionesComponent";
// import axios from 'axios';
import {FIREBASE_SERVICES} from '@/axios/http-common';

export default {
    props: {
        pUsuario: {type: Object, required: false}
    },
    components: {
        accionesComp,
    },
    data() {
        return {
            usuario: {
                nombres: '',
                apellidos: '',
                perfil:'',
                idGrupoInformacion : this.$store.getters.getIdGrupoInfomacion,
                isAdmin: false,
                usuario: '',
                usuarioTmp:'',
                imagen:'',
                activo: true,
                contrasenia: ''
            },
            listGruposInformacion : [],
            listPerfiles : [],
            mostrarPassword: true
            
        }
    },

    mounted() {
        this.inicializar();

    },

    watch: {
        '$store.state.loading': function (val, oldVal) {
            if(val == false) {
                this.$refs.form.validate();

            }
        }
    },

    computed: {
        listPerfilesFil: function () {
            return this.listPerfiles.filter(
                perfil => [this.usuario.idGrupoInformacion, this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL].includes(perfil.idGrupoInformacion)
            );
        },
    },
    
    methods: {
        inicializar() {
            this.$store.commit("ejecutarLoadingCount", 2);
            this.loadPerfiles();
            this.loadGruposInformacion();
            this.loadUsuario();
        },

        loadUsuario() {
            if (this.pUsuario) {
                this.usuario = this.pUsuario;
                this.usuario.contraseniaDos = this.pUsuario.contrasenia;
                this.usuario.usuarioTmp = this.pUsuario.usuario;
                this.mostrarPassword = false;

            } else {
                // setTimeout(function () {
                    console.log(this.usuario)
                    this.usuario.usuario = 'ingresa un correo aqui';
                    this.usuario.contrasenia = ''
                // }, 300);
                
            }
        },

        loadPerfiles() {
            this.getCollectionListFirestore('perfiles').get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {  
                    this.listPerfiles.push({id: doc.id, nombre: doc.data().nombre, idGrupoInformacion: doc.data().idGrupoInformacion});

                });
                this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                    this.$store.commit("detenerLoading");
                }.bind(this)
            );

            
        },

        loadGruposInformacion() {
            if (!this.$store.getters.getIdGrupoInfomacion) {
                this.listGruposInformacion.push({id:'', nombre: this.$t('message.general.sinGrupo')});
            }

            this.getCollectionListFirestore('gruposDeInformacion').get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {  
                    this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                });
                this.$store.commit("detenerLoading");

            }).catch(
                function(error) {
                    console.log("Error getting document:", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

            
        },

        async verificarEmailRepetido() {
            
            if (this.usuario.usuario && this.usuario.usuario != this.usuario.usuarioTmp && this.usuario.usuario.includes("@")) {
                this.$store.commit("ejecutarLoading");
                return await FIREBASE_SERVICES.get(
                    '/usuarios-usuarioExiste',
                    {params: {usuario: this.usuario.usuario}}
                ).then(
                    function (response) {
                        this.$store.commit("detenerLoading");
                        if (response.data.existe) {
                            this.usuario.usuario = "";
                            this.$store.dispatch("addAlert", {
                                tipo: "warning",
                                titulo: "",
                                descripcion: this.$t('message.usuariosDetalle.usuarioExiste')
                            });

                            return false;
                        } else {
                            return true;
                        }
                    }.bind(this)
                ).catch(
                    function (error) {
                        console.log(error);
                        return true;

                    }.bind(this)
                );
            }
        },

        guardar() {
            this.$refs.form.validate().then(success => {
                if (success && this.verificarEmailRepetido()) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.usuario.docRef) {
                        this.actualizarUsuario();
    
                    } else {
                        this.crearUsuario();
    
                    }
                }
            });
        },

        
        crearUsuario() {
            let usuariosCollection = this.getCollectionAddFirestore("usuarios");

            usuariosCollection.add({
                nombres: this.usuario.nombres,
                nombresSearchTermsArray: this.getSearchTermsArray(this.usuario.nombres),
                apellidos: this.usuario.apellidos,
                apellidosSearchTermsArray: this.getSearchTermsArray(this.usuario.apellidos),
                perfil:this.usuario.perfil,
                usuario: this.usuario.usuario,
                idGrupoInformacion: this.usuario.idGrupoInformacion,
                isAdmin: this.usuario.isAdmin,
                // imagen:this.usuario.imagen,
                contrasenia:this.usuario.contrasenia,
                activo:this.usuario.activo,
                temporal: true

            }).then(
                function(docRef) {
                    // console.log("Document written with ID: ", docRef.id);
                    this.usuario.docRef= docRef;
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        actualizarUsuario() {
            this.usuario.docRef.set({
                nombres: this.usuario.nombres,
                nombresSearchTermsArray: this.getSearchTermsArray(this.usuario.nombres),
                apellidos: this.usuario.apellidos,
                apellidosSearchTermsArray: this.getSearchTermsArray(this.usuario.apellidos),
                perfil:this.usuario.perfil,
                usuario: this.usuario.usuario,
                idGrupoInformacion: this.usuario.idGrupoInformacion,
                isAdmin: this.usuario.isAdmin,
                // imagen:this.usuario.imagen,
                activo: this.usuario.activo,
                contrasenia: this.usuario.contrasenia

            }, { merge: true }).then(
                function() {
                    // console.log("Document Update: ");
                    this.$store.commit("detenerLoading");
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );


        },

        setPassword() {
            var pass = '';
            var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 
                    'abcdefghijklmnopqrstuvwxyz0123456789@#$';
            var str2 = "*-+."
              
            for (var i = 1; i <= 8; i++) {
                var char = Math.floor(Math.random()
                            * str.length + 1);
                var char2 = Math.floor(Math.random()
                            * str2.length + 1);
                
                pass += str.charAt(char) + str2.charAt(char2)
            }
              
            this.usuario.contrasenia = pass;
            this.usuario.contraseniaDos = pass;
        }

    }

}