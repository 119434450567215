import Vue from "vue";
import VueI18n from "vue-i18n";
import {messages} from './messages/mensajes'
import store from './store'

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: store.state.locale,
    messages,
    silentTranslationWarn: true
});

export { i18n };