import { firebase, auth } from '@/firebase/firebaseInit.js';
import accionesComp from "@/components/AccionesComponent";
import gestionDocumentalComponent from "@/components/gestionDocumental/GestionDocumentalComponent.vue";

const DIRECCION_ATRAS = -1;
const DIRECCION_ADELANTE = +1;
const NOTES = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

export default {
    name: 'CancionesComp',
    props: {
        pCancion: {type: Object, required: false}
    },
    components: {
        accionesComp,
        gestionDocumentalComponent
    },
    data() {
        return {
            listGruposInformacion : [],
            listUsuarios : [],
            cancion: {
                nombre: '',
                tonalidad: null,
                listasAsociadas: null,
                idGenero: null,
                numTonosTransportar: 0,
                letra: '',
                acordesGuitarra: '',
                acordesBajo: '',
                observacionesBateria: '',
                orden: 0,
                bpm:0,
                secuenciaNotas: '',
                secuencia:false
            },
            mostrarCompGestionDoc: false,
            listTonalidades: [
                {id:'C mayor', descripcion:'C mayor'},
                {id:'C menor', descripcion:'C menor'},
                {id:'C# mayor', descripcion:'C# mayor'},
                {id:'C# menor', descripcion:'C# menor'},
                {id:'D mayor', descripcion:'D mayor'},
                {id:'D menor', descripcion:'D menor'},
                {id:'D# mayor', descripcion:'D# mayor'},
                {id:'D# menor', descripcion:'D# menor'},
                {id:'E mayor', descripcion:'E mayor'},
                {id:'E menor', descripcion:'E menor'},
                {id:'F mayor', descripcion:'F mayor'},
                {id:'F menor', descripcion:'F menor'},
                {id:'F# mayor', descripcion:'F# mayor'},
                {id:'F# menor', descripcion:'F# menor'},
                {id:'G mayor', descripcion:'G mayor'},
                {id:'G menor', descripcion:'G menor'},
                {id:'G# mayor', descripcion:'G# mayor'},
                {id:'G# menor', descripcion:'G# menor'},
                {id:'A mayor', descripcion:'A mayor'},
                {id:'A menor', descripcion:'A menor'},
                {id:'A# mayor', descripcion:'A# mayor'},
                {id:'A# menor', descripcion:'A# menor'},
                {id:'B mayor', descripcion:'B mayor'},
                {id:'B menor', descripcion:'B menor'},
                
            ],
            listasAsociadasCanciones: [
                // {id:1, labelBusqueda:'Cumpleaños hermana de sofi (04 de Junio del 2023)'},
                // {id:2, labelBusqueda:'Pentecostes 2023 (27 de Mayo del 2023)'}
            ],
            listModoCancion: [
                {id:1, descripcion:'Letra'},
                {id:2, descripcion:'Guitarra'},
                {id:3, descripcion:'Bajo'},
                {id:4, descripcion:'Observaciones bateria'},
            ],
            listGenero: [
                // {id:1, descripcion:'Balada/Pop'},
                // {id:2, descripcion:'Tropipop'},
                // {id:3, descripcion:'Plancha'},
                // {id:4, descripcion:'Rock'},
                // {id:5, descripcion:'Pop/Rock'},
                // {id:6, descripcion:'Cristiana'},
                // {id:7, descripcion:'Romantica'},
                // {id:8, descripcion:'Otro'},
                // {id:9, descripcion:'Bolero'},
            ],
            listTonos: [
                'C',
                'C#&Db',
                'D',
                'D#&Eb',
                'E',
                'F',
                'F#&Gb',
                'G',
                'G#&Ab',
                'A',
                'A#&Bb',
                'B'
            ],
            listCaracteresBusqueda: [
                {caracterInicio:' ', caracterFin: ' '},
                {caracterInicio:' ', caracterFin: '\n'},
                {caracterInicio:' ', caracterFin: ')'},
				{caracterInicio:'', caracterFin: ' '},
				
				{caracterInicio:'', caracterFin: '-'},
                {caracterInicio:'', caracterFin: ' -'},
                {caracterInicio:'-', caracterFin: '-'},
                {caracterInicio:'- ', caracterFin: ' -'},
                {caracterInicio:'- ', caracterFin: '-'},
                {caracterInicio:'-', caracterFin: '- '},
                {caracterInicio:'-', caracterFin: ' -'},
                {caracterInicio:'-', caracterFin: '\n'},
				{caracterInicio:'- ', caracterFin: '\n'},
                {caracterInicio:'-', caracterFin: ' '},
				{caracterInicio:'- ', caracterFin: ' '},
				{caracterInicio:'- ', caracterFin: ')'},
				//{caracterInicio:'- ', caracterFin: ''},
                
				{caracterInicio:'', caracterFin: '~'},
                {caracterInicio:'', caracterFin: ' ~'},
                {caracterInicio:'~', caracterFin: '~'},
                {caracterInicio:'~ ', caracterFin: ' ~'},
                {caracterInicio:'~ ', caracterFin: '~'},
                {caracterInicio:'~', caracterFin: '~ '},
                {caracterInicio:'~', caracterFin: ' ~'},
                {caracterInicio:'~', caracterFin: '\n'},
				{caracterInicio:'~ ', caracterFin: '\n'},
                {caracterInicio:'~', caracterFin: ' '},
				{caracterInicio:'~ ', caracterFin: ' '},
				{caracterInicio:'~ ', caracterFin: ')'},
                //{caracterInicio:'~ ', caracterFin: ''},
                
                {caracterInicio:'/', caracterFin: '\n'},
                {caracterInicio:'/', caracterFin: ' -'},
                {caracterInicio:'/', caracterFin: ' ~'},
                {caracterInicio:'/', caracterFin: '-'},
                {caracterInicio:'/', caracterFin: '~'},
                {caracterInicio:'/', caracterFin: ' '},
                {caracterInicio:'/', caracterFin: ')'},
                {caracterInicio:'', caracterFin: '/'}
            ],
            tonosModificados: '',
            indexTonoModificado: 0,
            listTonosModificar:[],
            acordesAdaptados:'',
            idModoCancion: this.$store.getters.getModoCancion,
            bloquearEdicion: false,
            scrollInterval: null,
            velocidad: 1,
            intervaloBpmId: null,
            audioNotaPrincipal: [],
            audioNotaSecundaria: [],
            indexSecuenciaNotas: -1,
            colaReproduccion: [],
            direccionActual: null,
            ultimaDireccion: null

        }
    },

    watch: {
        '$store.state.loading': function (val, oldVal) {
            if(!val) {
                this.$refs.form.validate();

            }
        },
        'velocidad': function (velocidad, oldVal) {
            this.stopScroll();
            this.startScroll(1, velocidad);
        },
        'cancion.bpm': function (bpm, oldVal) {
            if (this.intervaloBpmId) {
                this.detenerBpm();
                this.reproducirBpm();
            }
            
        }
    },

    computed: {
        isUsuarioCancionInListUsuarios: function () {
            return this.listUsuarios.findIndex( usuario => usuario.id == this.cancion.idUsuarioAsesor) >= 0;
        },

        listSecuenciaNotas: function() {
            return this.cancion.secuenciaNotas.split(" ");
        }
    },

    mounted() {
        this.inicializar();

    },

    beforeDestroy() {
        this.stopScroll();
        this.detenerBpm();

    },

    methods: {
        inicializar() {
            this.addMouseEvents();
            this.$store.commit("ejecutarLoadingCount", 5);
            this.loadUsuarios();
            this.loadGruposInformacion();
            this.loadListasAsociadasCanciones();
            this.loadGeneroCanciones();
            this.setEvents()
            this.loadCancion();
        },

        addMouseEvents() {
            let scrollInterval;
            let enEjecucion = false;
            const self = this;

            // Función para iniciar el desplazamiento
            function startScroll(element, direction, speed) {
                scrollInterval = setInterval(() => {
                    element.scrollBy(0, direction * speed);
                }, 10); // Intervalo en milisegundos
            }

            // Función para detener el desplazamiento
            function stopScroll() {
                clearInterval(scrollInterval);
            }

            function handleMouseDown(event) {
                const textarea = document.querySelector('#fullscreenModal .textarea-fullscreen:not([style*="display: none"])');
                
                if (!enEjecucion && textarea && textarea.contains(event.target)) {
                    if (self.cancion.secuencia) {
                        self.gestionarReproduccionNota(event.button);

                    } else {
                        enEjecucion = true;
                        if (event.button === 0) { // Clic izquierdo
                            startScroll(textarea, 1, 2);
                        } else if (event.button === 2) { // Clic derecho
                            startScroll(textarea, -1, 1);
                        }
                    }
                    
                }
            }

            function handleMouseUp(event) {
                if (enEjecucion && (event.button === 0 || event.button === 2)) {
                    if (self.cancion.secuencia) {

                    } else {
                        enEjecucion = false;
                        stopScroll();
                    }
                }
            }

            function preventContextMenu(event) {
                event.preventDefault();
            }

            $('#fullscreenModal').on('shown.bs.modal', function () {
                // handleModalShown('fullscreenModal');
                document.addEventListener('mousedown', handleMouseDown);
                document.addEventListener('mouseup', handleMouseUp);
                document.addEventListener('contextmenu', preventContextMenu);
            });

            $('#fullscreenModal').on('hidden.bs.modal', function () {
                document.removeEventListener('mousedown', handleMouseDown);
                document.removeEventListener('mouseup', handleMouseUp);
                document.removeEventListener('contextmenu', preventContextMenu);
                stopScroll(); // Detener cualquier desplazamiento activo
            });

        },

        // Manejador de eventos de clic derecho e izquierdo
        gestionarReproduccionNota(idClic) {
            if (idClic === 0) { // Clic izquierdo
                this.reproducirSecuenciaNotas(DIRECCION_ATRAS);
            } else if (idClic === 2) { // Clic derecho
                this.reproducirSecuenciaNotas(DIRECCION_ADELANTE);
            }
        },

        reproducirSecuenciaNotas(direccion) {

            if ((this.indexSecuenciaNotas === 0 && direccion === DIRECCION_ATRAS) ||
                (this.indexSecuenciaNotas >= this.listSecuenciaNotas.length && direccion === DIRECCION_ADELANTE)) {
                return;
            }

            // Actualiza el índice de secuencia antes de pausar
            this.indexSecuenciaNotas += direccion;

            // Pausa las notas actuales antes de reproducir la siguiente
            this.pausarTodasLasNotas();

            const nota = this.listSecuenciaNotas[this.indexSecuenciaNotas];
            if (nota) {
                this.encolarReproduccion(nota);
                this.iniciarReproduccion();
            }

        },

        // Encolar la nota para reproducción
        encolarReproduccion(nota) {
            this.colaReproduccion.push(nota);
        },

        // Iniciar reproducción desde la cola
        iniciarReproduccion() {
            if (this.colaReproduccion.length > 0) {
                const nota = this.colaReproduccion.shift();
                this.reproducirNotaOacorde(nota);
            }
        },

        // Analizar el acorde y determinar su reproducción
        reproducirNotaOacorde(nota) {
            const [notaBase, volumenStr] = nota.split("_");
            const volumen = volumenStr ? parseInt(volumenStr) : 100;

            const conOctava = notaBase.includes("<8");
            const esAcorde = notaBase.includes("+");
            const tipoAcorde = esAcorde ? notaBase.split("+")[0] : this.getNotaFundamental(notaBase);

            if (esAcorde) {
                this.reproducirAcorde(tipoAcorde, volumen, conOctava);
            } else {
                this.reproducirNotaSimple(tipoAcorde, volumen, conOctava);
            }
        },

        getNotaFundamental(notaBase) {
            let acorde = notaBase.split("")[0];
            
            if (notaBase.includes("#")) {
                acorde += "#";
            }

            return acorde;
        },

        // Reproduce un acorde y, si se requiere, con octava
        reproducirAcorde(acorde, volumen, conOctava) {
            const listAcorde = this.getChordNotes(acorde);
            if (listAcorde) {
                listAcorde.forEach((nota, index) => this.reproducirNotaSimple(nota, volumen, false, index));
                if (conOctava) {
                    const notaFundamental = listAcorde[0] + "8";
                    this.reproducirNotaSimple(notaFundamental, volumen, false, 3);
                }
            }
        },

        // Reproduce una nota simple, considerando volumen y octava
        reproducirNotaSimple(nota, volumen, conOctava, canal = 0) {
            const notaTmp = nota.replace("#", "s");
            const archivoNota = require(`@/assets/audio/${notaTmp}.wav`);
            this.pausarNotaCanal(canal);
            this.audioNotaPrincipal[canal] = new Audio(archivoNota);
            this.audioNotaPrincipal[canal].volume = volumen / 100;
            this.audioNotaPrincipal[canal].play();

            if (conOctava) {
                const archivoOctava = require(`@/assets/audio/${notaTmp}8.wav`);
                this.audioNotaSecundaria[canal] = new Audio(archivoOctava);
                this.audioNotaSecundaria[canal].volume = volumen / 100;
                this.audioNotaSecundaria[canal].play();
            }
        },

        // Pausa todas las notas de los canales
        pausarTodasLasNotas(reiniciarIndexSecuencia) {
            [0, 1, 2, 3].forEach(canal => this.pausarNotaCanal(canal));

            if (reiniciarIndexSecuencia) {
                this.indexSecuenciaNotas = -1;
            }
        },

        // Pausa una nota en un canal específico
        pausarNotaCanal(canal) {
            if (this.audioNotaPrincipal[canal]) {
                this.audioNotaPrincipal[canal].pause();
                this.audioNotaPrincipal[canal] = null;
            }
            if (this.audioNotaSecundaria[canal]) {
                this.audioNotaSecundaria[canal].pause();
                this.audioNotaSecundaria[canal] = null;
            }
        },

        // Obtener el índice de una nota
        getNoteIndex(note) {
            return NOTES.indexOf(note);
        },

        // Obtener una nota a una cierta distancia en semitonos
        getNoteBySemitone(startNote, semitones) {
            const startIndex = this.getNoteIndex(startNote);
            return NOTES[(startIndex + semitones) % NOTES.length];
        },

        // Función principal para calcular las notas de un acorde
        getChordNotes(chord) {
            // Extraer la raíz y el tipo de acorde
            const root = chord.match(/[A-G]#?/)[0]; // Nota raíz (puede incluir sostenido)
            const type = chord.slice(root.length);   // Tipo de acorde

            // Iniciar con la raíz
            let chordNotes = [root];

            // Acorde mayor
            if (type === "") {
                chordNotes.push(
                    this.getNoteBySemitone(root, 4), // Tercera mayor
                    this.getNoteBySemitone(root, 7)  // Quinta justa
                );
            }
            // Acorde menor
            else if (type === "m") {
                chordNotes.push(
                    this.getNoteBySemitone(root, 3), // Tercera menor
                    this.getNoteBySemitone(root, 7)  // Quinta justa
                );
            }
            // Acorde mayor con séptima
            else if (type === "7") {
                chordNotes.push(
                    this.getNoteBySemitone(root, 4), // Tercera mayor
                    this.getNoteBySemitone(root, 7), // Quinta justa
                    this.getNoteBySemitone(root, 10) // Séptima menor
                );
            }
            // Acorde menor con séptima
            else if (type === "m7") {
                chordNotes.push(
                    this.getNoteBySemitone(root, 3), // Tercera menor
                    this.getNoteBySemitone(root, 7), // Quinta justa
                    this.getNoteBySemitone(root, 10) // Séptima menor
                );
            }
            // Tipo de acorde no soportado
            else {
                console.log(`Tipo de acorde no soportado: ${chord}`);
                return null;
            }

            return chordNotes;
        },

        // gestionarReproduccionNota(idClic) {
        //     if (idClic === 0) { // Clic izquierdo
        //         this.reproducirSecuenciaNotas(DIRECCION_ATRAS);

        //     } else if (idClic === 2) { // Clic derecho
        //         this.reproducirSecuenciaNotas(DIRECCION_ADELANTE);

        //     }
        // },

        // reproducirSecuenciaNotas(direccion) {
        //     if (this.indexSecuenciaNotas == 0 && direccion == DIRECCION_ATRAS) {
        //         return;
        //     }
        //     if (this.indexSecuenciaNotas >= this.listSecuenciaNotas.length && direccion == DIRECCION_ADELANTE) {
        //         return;
        //     }

        //     const nota = this.listSecuenciaNotas[this.indexSecuenciaNotas];

        //     if (nota != undefined) {
        //         this.determinarNotaReproducir(nota);
                
        //     }

        //     this.indexSecuenciaNotas = this.indexSecuenciaNotas + direccion;
            
        // },

        // determinarNotaReproducir(nota) {
        //     let volumen = 100;
        //     let listNotaAnalizar = nota.split("_");

        //     if (listNotaAnalizar.length > 1) {
        //         volumen = parseInt(listNotaAnalizar[1]);
        //     }

        //     listNotaAnalizar = listNotaAnalizar[0].split("");

        //     this.detenerReproduccionNotas(false);
            
        //     if (listNotaAnalizar[listNotaAnalizar.length-1] == "+") {
        //         this.reproducirAcorde(this.getAcorde(listNotaAnalizar), volumen);

        //     } else {
        //         this.reproducirNota(listNotaAnalizar[0], volumen, 0);
        //         this.detenerReproduccionNotaCanal(1, false);
        //         this.detenerReproduccionNotaCanal(2, false);
        //     }

            
        // },

        // getAcorde(listNotaAnalizar) {
        //     let acorde = "";
        //     for (let index = 0; index < listNotaAnalizar.length-1; index++) {
        //         acorde += listNotaAnalizar[index];
                
        //     }
        //     return acorde;
        // },

        // reproducirAcorde(acorde, volumen){
        //     const listAcorde = this.getChordNotes(acorde);

        //     if (listAcorde) {
        //         this.reproducirNota(listAcorde[0], volumen, 0);
        //         this.reproducirNota(listAcorde[1], volumen, 1);
        //         this.reproducirNota(listAcorde[2], volumen, 2);
        //     }
            

        // },

        // reproducirNota(nota, volumen, canal) {
        //     // Pausar el canal actual y limpiar después de un retardo si está en uso
        //     if (this.audioNotaPrincipal[canal]) {
        //         setTimeout(() => this.detenerReproduccionNotaCanal(canal, false), 300);
        //     }

        //     // Crear el nuevo objeto de audio para el canal especificado
        //     const nuevaNota = new Audio(require('@/assets/audio/' + nota + '.wav'));
        //     nuevaNota.volume = volumen / 100;
        //     nuevaNota.loop = true;
        //     nuevaNota.play();

        //     // Asignar la nueva nota al canal principal o secundario
        //     if (!this.audioNotaPrincipal[canal]) {
        //         this.audioNotaPrincipal[canal] = nuevaNota;
        //     } else {
        //         this.audioNotaSecundaria[canal] = nuevaNota;
        //     }
            
        //     // if (this.audioNotaPrincipal[canal]) {
        //     //     setTimeout(() => {
        //     //         this.audioNotaPrincipal[canal].pause();
        //     //         this.audioNotaPrincipal[canal] = null;
        //     //     }, 300);

        //     //     this.audioNotaSecundaria[canal] = new Audio(require('@/assets/audio/' + nota + '.wav'));
        //     //     this.audioNotaSecundaria[canal].volume = volumen / 100;
        //     //     this.audioNotaSecundaria[canal].loop = true;
        //     //     this.audioNotaSecundaria[canal].play();


        //     // } else {
        //     //     if (this.audioNotaSecundaria[canal]) {
        //     //         setTimeout(() => {
        //     //             this.audioNotaSecundaria[canal].pause();
        //     //             this.audioNotaSecundaria[canal] = null;
        //     //         }, 300);
        //     //     }

        //     //     this.audioNotaPrincipal[canal] = new Audio(require('@/assets/audio/' + nota + '.wav'));
        //     //     this.audioNotaPrincipal[canal].volume = volumen / 100;
        //     //     this.audioNotaPrincipal[canal].loop = true;
        //     //     this.audioNotaPrincipal[canal].play();
        //     // }


        // },

        // detenerReproduccionNotas(reiniciarIndexSecuencia) {
        //     this.detenerReproduccionNotaCanal(0, reiniciarIndexSecuencia);
        //     this.detenerReproduccionNotaCanal(1, reiniciarIndexSecuencia);
        //     this.detenerReproduccionNotaCanal(2, reiniciarIndexSecuencia);
        // },

        // detenerReproduccionNotaCanal(canal, reiniciarIndexSecuencia) {
        //     if (this.audioNotaPrincipal[canal]) {
        //         setTimeout(() => {
        //             this.audioNotaPrincipal[canal].pause();
        //             this.audioNotaPrincipal[canal] = null;
        //         }, 300);
        //     }

        //     if (this.audioNotaSecundaria[canal]) {
        //         setTimeout(() => {
        //             this.audioNotaSecundaria[canal].pause();
        //             this.audioNotaSecundaria[canal] = null;
        //         }, 300);
        //     }

        //     if (reiniciarIndexSecuencia) {
        //         this.indexSecuenciaNotas = 0;
        //     }
            
        // },

        // // Función para obtener el índice de una nota
        // getNoteIndex(note) {
        //     return NOTES.indexOf(note);
        // },

        // // Función para obtener una nota a una cierta distancia en semitonos
        // getNoteBySemitone(startNote, semitones) {
        //     const startIndex = this.getNoteIndex(startNote);
        //     return NOTES[(startIndex + semitones) % NOTES.length];
        // },

        // // Función principal para calcular las notas de un acorde
        // getChordNotes(chord) {
        //     // Extraer la raíz y el tipo de acorde
        //     const root = chord.match(/[A-G]#?/)[0]; // Nota raíz (puede incluir sostenido)
        //     const type = chord.slice(root.length);   // Tipo de acorde

        //     // Iniciar con la raíz
        //     let chordNotes = [root];

        //     // Acorde mayor
        //     if (type === "") {
        //         chordNotes.push(
        //             this.getNoteBySemitone(root, 4), // Tercera mayor
        //             this.getNoteBySemitone(root, 7)  // Quinta justa
        //         );
        //     }
        //     // Acorde menor
        //     else if (type === "m") {
        //         chordNotes.push(
        //             this.getNoteBySemitone(root, 3), // Tercera menor
        //             this.getNoteBySemitone(root, 7)  // Quinta justa
        //         );
        //     }
        //     // Acorde mayor con séptima
        //     else if (type === "7") {
        //         chordNotes.push(
        //             this.getNoteBySemitone(root, 4), // Tercera mayor
        //             this.getNoteBySemitone(root, 7), // Quinta justa
        //             this.getNoteBySemitone(root, 10) // Séptima menor
        //         );
        //     }
        //     // Acorde menor con séptima
        //     else if (type === "m7") {
        //         chordNotes.push(
        //             this.getNoteBySemitone(root, 3), // Tercera menor
        //             this.getNoteBySemitone(root, 7), // Quinta justa
        //             this.getNoteBySemitone(root, 10) // Séptima menor
        //         );
        //     }
        //     // Tipo de acorde no soportado
        //     else {
        //         console.log(`Tipo de acorde no soportado: ${chord}`);
        //         return null;

        //     }

        //     return chordNotes;
        // },

        startScroll(direction, speed) {
            if (!this.scrollInterval) {
                const element = document.querySelector('#fullscreenModal .textarea-fullscreen:not([style*="display: none"])');

                this.scrollInterval = setInterval(() => {
                    element.scrollBy(0, direction * 1);
                }, 80 - (10 * speed)); // Intervalo en milisegundos
            }
        },

        stopScroll() {
            if (this.scrollInterval) {
                clearInterval(this.scrollInterval);
                this.scrollInterval = null;
            }
            
        },

        reproducirBpm() {
            if (!this.intervaloBpmId && this.cancion?.bpm) {
                // BPM deseado
                const bpm = this.cancion.bpm;

                // Calcula el intervalo de tiempo en milisegundos basado en el BPM
                const intervalo = 60000 / bpm; // 60000 ms (1 minuto) dividido por BPM

                // Función para reproducir el clic y el sonido
                function reproducirClicYSonido() {
                    // Reproduce el sonido
                    const music = new Audio(require('@/assets/audio/click.mp3'));
                    music.play();
                }

                // Configura el intervalo para reproducir el clic y el sonido
                this.intervaloBpmId = setInterval(reproducirClicYSonido, intervalo);
            }

        },

        detenerBpm() {
            if (this.intervaloBpmId) {
                clearInterval(this.intervaloBpmId);
                this.intervaloBpmId = null;
            }
        },

        loadUsuarios() {
            let  collection = this.getCollectionListFirestore('usuarios');
            collection.get()
                .then((querySnapshot) => {
                    this.listUsuarios.push({id:'', descripcion: this.$t('message.personasDetalle.sinAsesor')});
                    querySnapshot.forEach((doc) => {  
                        this.listUsuarios.push({id:doc.id, descripcion:doc.data().usuario});

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function(error) {

                        collection = collection.where('id', '==', auth.currentUser.uid);
                        collection.get()
                            .then((querySnapshot) => {
                                this.listUsuarios.push({id:'', descripcion: this.$t('message.personasDetalle.sinAsesor')});
                                querySnapshot.forEach((doc) => {  
                                    this.listUsuarios.push({id:doc.id, descripcion:doc.data().usuario});

                                });
                                this.$store.commit("detenerLoading");

                            }).catch(
                                function(errorDos) {
                                    console.log("Error getting users:", errorDos);
                                    this.$store.commit("detenerLoading");

                                }.bind(this)
                            );

                    }.bind(this)
                );
        },

        loadGruposInformacion() {
            setTimeout(function(){
                this.getCollectionListFirestore('gruposDeInformacion').get()
                .then((querySnapshot) => {
                    // if (!this.$store.getters.getIdGrupoInfomacion) {
                    this.listGruposInformacion.push({
                        id: this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL, 
                        nombre: this.$t('message.general.global')
                    });
                    // }
                    querySnapshot.forEach((doc) => {  
                        this.listGruposInformacion.push({id:doc.id, nombre:doc.data().nombre});

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function(error) {
                        console.log("Error getting grupos de informacion:", error);
                        this.$store.commit("detenerLoading");

                    }.bind(this)
                );
            }.bind(this), 1000);
            
        },

        loadListasAsociadasCanciones() {
            this.getCollectionListFirestore('canciones_listas', null, true).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listasAsociadasCanciones.push(
                            {   id:doc.id, nombre: doc.data().nombre, 
                                labelBusqueda: doc.data().nombre + ' ' + this.formatSecondsToDate(doc.data().fecha.seconds, "DD/MM/YYYY"), 
                                fecha: doc.data().fecha
                            }
                        );
                        

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document:", error);
                    }.bind(this)
                );
        },

        loadGeneroCanciones() {
            this.getCollectionListFirestore('canciones_genero', null, true).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.listGenero.push(
                            {   id: doc.data().id, 
                                descripcion: doc.data().descripcion
                            }
                        );
                        

                    });
                    this.$store.commit("detenerLoading");

                }).catch(
                    function (error) {
                        this.$store.commit("detenerLoading");
                        console.log("Error getting document:", error);
                    }.bind(this)
                );
        },

        setEvents() {
            let txtLetra = document.getElementById('txtLetra');
            let txtGuitarra = document.getElementById('txtGuitarra');
            let txtBajo = document.getElementById('txtBajo');
            let txtBateria = document.getElementById('txtBateria');

            // Agrega un event listener para el evento 'blur'
            txtLetra.addEventListener('blur', function() {
                this.adaptarAcordes();
            }.bind(this));

            txtGuitarra.addEventListener('blur', function() {
                this.adaptarAcordes();
            }.bind(this));

            txtBajo.addEventListener('blur', function() {
                this.adaptarAcordes();
            }.bind(this));

            txtBateria.addEventListener('blur', function() {
                this.adaptarAcordes();
            }.bind(this));
        },

        loadCancion() {
            if (this.pCancion) {
                this.cancion = Object.assign({} , this.pCancion);
                this.cancion.numTonosTransportar = 0;
                // this.cancion.secuencia = false;
                this.mostrarCompGestionDoc = true;
                this.adaptarAcordes();
                
            } else {
                this.$refs.form.validate();
                firebase.auth().onAuthStateChanged((user) =>{
                    if (!this.$store.getters.getSesionIniciada) {
                        return;
                    }
                    if(user){
                        this.mostrarCompGestionDoc = true;
                        // this.cancion.idUsuarioAsesor= user.uid;
                        this.cancion.idUsuarioAsesor= '';
                        this.cancion.idGrupoInformacion= this.$store.getters.getIdGrupoInfomacion ? this.$store.getters.getIdGrupoInfomacion : this.$store.state.constantes.GRUPO_INFORMACION_GLOBAL;
                    }
                });
                
            }
            
            this.$store.commit("detenerLoading");
        },

        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.$store.commit("ejecutarLoading");
                
                    if (this.cancion.docRef) {
                        this.actualizarCancion();
    
                    } else {
                        this.crearCancion();
    
                    }

                } else {
                    this.$store.dispatch("addAlert", {
                        tipo: "warning",
                        titulo: "",
                        descripcion: this.$t('message.personasDetalle.camposObligatorios')
                    });
                }
            });
        },

        crearCancion() {
            let cancionesCollection = this.getCollectionAddFirestore("canciones");

            cancionesCollection.add({
                nombre: this.cancion.nombre,
                tonalidad: this.cancion.tonalidad,
                listasAsociadas: this.cancion.listasAsociadas,
                idGenero: this.cancion.idGenero,
                numTonosTransportar: this.cancion.numTonosTransportar,
                // idModo: this.cancion.idModo,
                letra: this.cancion.letra,
                acordesGuitarra: this.cancion.acordesGuitarra,
                acordesBajo: this.cancion.acordesBajo,
                observacionesBateria: this.cancion.observacionesBateria,
                idGrupoInformacion: this.cancion.idGrupoInformacion,
                idUsuarioAsesor : this.cancion.idUsuarioAsesor,
                fechaCreacion: new Date(),
                orden: this.cancion.orden,
                bpm: this.cancion.bpm,
                secuenciaNotas: this.cancion.secuenciaNotas,
                secuencia: this.cancion.secuencia
            }).then(
                function(docRef) {
                    this.cancion.id= docRef.id;
                    this.cancion.docRef= docRef;
                    this.$emit('guardarGestionDocumental', {idDocumento: docRef.id});
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });
                    $('#popupCancion').modal('hide');
                    this.$store.commit("detenerLoading");

                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error adding document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );

        },

        actualizarCancion() {
            this.cancion.docRef.update({
                nombre: this.cancion.nombre,
                tonalidad: this.cancion.tonalidad,
                listasAsociadas: this.cancion.listasAsociadas,
                idGenero: this.cancion.idGenero,
                numTonosTransportar: this.cancion.numTonosTransportar,
                // idModo: this.cancion.idModo,
                letra: this.cancion.letra,
                acordesGuitarra: this.cancion.acordesGuitarra,
                acordesBajo: this.cancion.acordesBajo,
                observacionesBateria: this.cancion.observacionesBateria,
                idGrupoInformacion: this.cancion.idGrupoInformacion,
                idUsuarioAsesor : this.cancion.idUsuarioAsesor,
                fechaModificacion: new Date(),
                orden: this.cancion.orden ? this.cancion.orden : 0,
                bpm: this.cancion.bpm ? this.cancion.bpm : 0,
                secuenciaNotas: this.cancion.secuenciaNotas ? this.cancion.secuenciaNotas : '',
                secuencia: this.cancion.secuencia ? this.cancion.secuencia : false
            }, { merge: true }).then(
                function() {
                    this.$emit('guardarGestionDocumental', {idDocumento: this.cancion.id});
                    this.$store.dispatch("addAlert", {
                        tipo: "success",
                        titulo: "",
                        descripcion: this.$t('message.general.guardarExitoso')
                    });
                    $('#popupCancion').modal('hide');
                    this.$store.commit("detenerLoading");
                    
                }.bind(this)
            ).catch(
                function(error) {
                    console.error("Error Updating document: ", error);
                    this.$store.commit("detenerLoading");

                }.bind(this)
            );


        },

        modificarModo() {
            this.$store.commit("setModoCancion", this.idModoCancion);
            this.cancion.numTonosTransportar = 0;
            this.adaptarAcordes();
        },

        adaptarAcordes() {
            let regex = /(?<=\S)(?<!\s-)\s*([~-])\s*(?!\s-)(?=\S)/g;

            if (this.idModoCancion == this.$store.state.constantes.modosCanciones.LETRA) {
                this.cancion.letra =this.cancion.letra.replace(regex, " $1 ");
                this.acordesAdaptados = this.cancion.letra.replaceAll('\n', ' \n') + ' \n';

            } else if (this.idModoCancion == this.$store.state.constantes.modosCanciones.GUITARRA) {
                this.cancion.acordesGuitarra =this.cancion.acordesGuitarra.replace(regex, " $1 ");
                this.acordesAdaptados = this.cancion.acordesGuitarra.replaceAll('\n', ' \n') + ' \n';

            } else if (this.idModoCancion == this.$store.state.constantes.modosCanciones.BAJO) {
                this.cancion.acordesBajo =this.cancion.acordesBajo.replace(regex, " $1 ");
                this.acordesAdaptados = this.cancion.acordesBajo.replaceAll('\n', ' \n') + ' \n';

            } else if (this.idModoCancion == this.$store.state.constantes.modosCanciones.BATERIA) {
                this.cancion.observacionesBateria =this.cancion.observacionesBateria.replace(regex, " $1 ");
                this.acordesAdaptados = this.cancion.observacionesBateria.replaceAll('\n', ' \n') + ' \n';

            }

        },

        modificarTono() {
            if (this.cancion.numTonosTransportar > 14) {
                this.cancion.numTonosTransportar = 14;
            }
            if (this.cancion.numTonosTransportar < -14) {
                this.cancion.numTonosTransportar = -14;
            }

            this.tonosModificados = this.acordesAdaptados;
            this.indexTonoModificado= 0

            let indexTono = 0;
            for (let tono of this.listTonos) {
                this.bucarModificarTono(tono, '', indexTono);
                this.bucarModificarTono(tono, '7', indexTono);
                this.bucarModificarTono(tono, 'm', indexTono);
                this.bucarModificarTono(tono, 'm7', indexTono);
                this.bucarModificarTono(tono, '*', indexTono);
                this.bucarModificarTono(tono, '7*', indexTono);
                this.bucarModificarTono(tono, 'm*', indexTono);
                this.bucarModificarTono(tono, 'm7*', indexTono);
                
                indexTono++;
            }

            for (let index = 0; index < this.indexTonoModificado; index++) {
                const element = this.listTonosModificar[index].split('&')[0];
                this.tonosModificados = this.tonosModificados.replaceAll('{' + index + '}', element);
            }

            if (this.idModoCancion == this.$store.state.constantes.modosCanciones.LETRA) {
                this.cancion.letra = this.tonosModificados;

            } else if (this.idModoCancion == this.$store.state.constantes.modosCanciones.GUITARRA) {
                this.cancion.acordesGuitarra = this.tonosModificados;

            } else if (this.idModoCancion == this.$store.state.constantes.modosCanciones.BAJO) {
                this.cancion.acordesBajo = this.tonosModificados;

            } else if (this.idModoCancion == this.$store.state.constantes.modosCanciones.BATERIA) {
                this.cancion.observacionesBateria = this.tonosModificados;
            }
            // this.cancion.letra = this.tonosModificados;
        },

        bucarModificarTono(tono, complemento, indexTono) {
            for (let caracterBusqueda of this.listCaracteresBusqueda) {
                let tonoArray = tono.split('&');
                for (let tonoTmp of tonoArray) {
                    let tonoBusqueda = caracterBusqueda.caracterInicio + tonoTmp + complemento + caracterBusqueda.caracterFin;
                    this.busquedaConAlteraciones(tonoBusqueda, caracterBusqueda, complemento, indexTono);
                }
            }
        },

        busquedaConAlteraciones(tonoBusqueda, caracterBusqueda, complemento, indexTono) {
            let existe = this.tonosModificados.includes(tonoBusqueda);

            if (existe) {
                let numTonosTransportar = Number(this.cancion.numTonosTransportar);
                let indexTranspose = indexTono + numTonosTransportar;

                if (indexTranspose > 11) {
                    let semitonosFaltantes = 11 - indexTono;
                    indexTranspose = numTonosTransportar - semitonosFaltantes - 1;
                }

                if (indexTranspose < 0) {
                    let semitonosFaltantes = indexTono;
                    indexTranspose = 11 + semitonosFaltantes + numTonosTransportar + 1;
                }

                let tonoTransportar = caracterBusqueda.caracterInicio + this.listTonos[indexTranspose].split('&')[0] + complemento + caracterBusqueda.caracterFin;
                this.tonosModificados = this.tonosModificados.replaceAll(tonoBusqueda, '{' + this.indexTonoModificado + '}');
                this.listTonosModificar[this.indexTonoModificado] = tonoTransportar;
                this.indexTonoModificado++;
                
            }
        }
    }
};