<template>
	<nav class="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar fixed-top">
		<div class="container-fluid">
			<button
				class="btn btn-link d-md-none rounded-circle mr-3"
				id="sidebarToggleTop"
				type="button"
			>
				<i class="fas fa-bars"></i>
			</button>

			<ul class="nav navbar-nav flex-nowrap ml-auto">

				<idiomas-component/>

				<!-- Alertas -->
				<nav-bar-alertas-component/>

				<!-- Mensajes -->
				<nav-bar-mensajeria-component/>

				<!-- Tareas -->
				<li class="nav-item">
					<a class="nav-link" href="#popupTareas" data-toggle="modal">
						<i class="fas fa-tasks"></i>
					</a>
				</li>

				<div class="d-none d-sm-block topbar-divider"></div>

				<!-- Menu de usuario -->
				<li class="nav-item dropdown no-arrow">
					<div class="nav-item dropdown no-arrow">
						
						<a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">
							<div class="col">
								<div class="row-md-3">
									<span class="d-none d-lg-inline mr-2 text-gray-600 small">
										{{ $store.getters.getUserName }} 
									</span>
									<img class="border rounded-circle img-profile" src="@/assets/img/avatars/avatar5.jpeg"/>
								</div>
								<div class="row">
									<p class="text-info">{{ $store.getters.getNombreOrganizacion }}</p>
								</div>
							</div>
							
						</a>
						<div class="dropdown-menu shadow dropdown-menu-right animated--grow-in">
							<a class="dropdown-item" href="#">
								<i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
								&nbsp;Usuario
							</a>
							<a class="dropdown-item" href="#">
								<i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
								&nbsp;Organizacion
							</a>
							<!-- <a class="dropdown-item" href="#">
								<i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
								&nbsp;Activity log
							</a> -->
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" href="#" @click="logOut()">
								<i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
								&nbsp;Logout
							</a>
						</div>
					</div>
				</li>

			</ul>
		</div>
	</nav>
</template>

<script>
	import { auth } from '@/firebase/firebaseInit.js';
	import IdiomasComponent from './IdiomasComponent';
	import NavBarAlertasComponent from './NavBarAlertasComponent.vue';
	import NavBarMensajeriaComponent from './NavBarMensajeriaComponent.vue';

	export default {
		components: {
			IdiomasComponent,
			NavBarAlertasComponent,
			NavBarMensajeriaComponent
		},
		data() {
        	return {

			}
		},

		mounted() {},

		methods: {
			logOut() {
				this.$store.commit("setSesionIniciada",false);
				auth.signOut().then(() => {
					this.$router.push("/");
				});
			}
		}
	};
</script>